<template>
    <div>
        <div class="mobile-table" v-if="$mq === 'xs' || $mq === 'sm'">
            <FormSelect v-model="sorting" class="mb-2"
                          v-if="options.sortable && options.sortable.length > 0"
                          placeholder="Sort" type="select"
                          :options="sorting_options"
            />
            <div class="mobile-table-item" :key="options.row_key ? row[options.row_key] : row.id" v-for="(row, index) in data">
                <table>
                    <tr :key="column" v-for="column in columns" style="vertical-align: middle">
                        <td class="mobile-table-label">
                            <template v-if="options.headings && options.headings[column]">{{ options.headings[column] }}</template>
                            <template v-else>{{ column | beautify }}</template>:
                        </td>
                        <td class="mobile-table-value">
                            <slot :row="row" :rowIndex="index" v-if="hasSlot(column)" :name="column"></slot>
                            <template v-else>{{ row[column] }}</template>
                        </td>
                    </tr>
                    <tr>
                        <td class="mobile-table-actions" colspan="2">
                            <slot :row="row" :rowIndex="index" name="actions"></slot>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else>
            <div class="table-responsive">
                <table class="table" :class="{'table-striped': striped, 'table-sm': small, 'table-xs': xsmall, 'table-vcentered': centered }">
                    <thead>
                    <tr>
                        <template v-for="column in columns">
                            <ThSortable :key="column" v-if="options.sortable && options.sortable.indexOf(column) > -1"
                                         :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}"
                                         :filters="filters"
                                         :field='column' @click="setSort(column)">
                                <div :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}" v-if="options.headings && options.headings[column]">{{ options.headings[column] }}</div>
                                <div v-else :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}">{{ column | beautify }}</div>
                            </ThSortable>
                            <th :key="column"
                                :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}"
                                v-else>
                                <div :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}" v-if="options.headings && options.headings[column]">{{ options.headings[column] }}</div>
                                <div v-else :style="{width: options.width && options.width[column] ? options.width[column] + 'px' : null}">{{ column | beautify }}</div>
                            </th>
                        </template>
                        <th v-if="hasSlot('actions')" style="white-space: nowrap"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="options.row_key ? row[options.row_key] : row.id" v-for="(row, index) in data" :class="{'bg-dark': row.highlight_row}">
                        <td :key="column" v-for="column in columns" :class="{'text-white font-weight-bold': row.highlight_row}">
                            <slot :row="row" :rowIndex="index" v-if="hasSlot(column)" :name="column"></slot>
                            <div v-else>{{ row[column] }}</div>
                        </td>
                        <td class="px-3" v-if="hasSlot('actions')" style="white-space: nowrap; min-width: 60px; text-align: right;">
                            <slot :row="row" :rowIndex="index" name="actions"></slot>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="text-center my-2" v-if="noDataText && (!data || data.length === 0)"> {{ noDataText }}</div>
            </div>
        </div>
    </div>

</template>

<script>

import ThSortable from "@/components/ThSortable.vue";
import FormSelect from "@/components/FormSelect.vue";

export default {
  name: 'VueTable',
  components: {FormSelect, ThSortable },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    hover: {
      type: Boolean,
      default() {
        return false;
      },
    },
    striped: {
      type: Boolean,
      default() {
        return false;
      },
    },
    small: {
      type: Boolean,
      default() {
        return false;
      },
    },
    xsmall: {
      type: Boolean,
      default() {
        return false;
      },
    },
    centered: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {
          sortable: [],
          headings: [],
          width: [],
        };
      },
    },
    data: {
      required: false,
    },
    filters: {
      required: false,
    },
    noShadow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    noDataText: {
      type: String,
      default() {
        return null;
      },
    },

  },
  data() {
    return {
      sorting: {
        sort: '',
        order: '',
      },
    };
  },
  methods: {
    setSort(column) {
      this.$emit('setSort', column);
    },
    hasSlot(name) {
      return this.$scopedSlots && this.$scopedSlots[name];
    },
  },
  watch: {
    'filters.sort': function (a) {
      this.sorting.sort = a;
    },
    'filters.order': function (a) {
      this.sorting.order = a;
    },
    sorting(a) {
      this.setSort(a);
    },
  },
  mounted() {
    if (this.filters) {
      this.sorting.sort = this.filters.sort;
      this.sorting.order = this.filters.order;
    }
  },
  computed: {
    sorting_options() {
      const options = [];
      if (!this.options || !this.options.sortable) {
        return options;
      }
      this.options.sortable.forEach((column) => {
        const name = (this.options.headings && this.options.headings[column]) ? this.options.headings[column] : this.$options.filters.beautify(column);
        options.push({
          label: `${name} Asc`,
          value: {
            sort: column,
            order: 'asc',
          },
        });
        options.push({
          label: `${name} Desc`,
          value: {
            sort: column,
            order: 'desc',
          },
        });
      });
      return options;
    },
  },
};
</script>

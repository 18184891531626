<template>
    <div>
        <div class="l-page-hero --small"
             style="background-image: url('/images/hero_bg.svg')">
            <div style="max-width: 100%">
                <div class="l-page-hero__title">Find Biotech Company</div>
                <SearchInput
                    v-model="filters.term"
                    @search="search"
                    url="/clients/suggestions"
                    :filters="{user_type: 'company'}"
                    :loading="loading.search"/>
            </div>
        </div>
        <div class="container">
            <ItemsGrid
                :loaded="loaded"
                :loading="loading"
                :data="data"
                :filters="available_filters"
                @setFilterValue="setFilterValue"
                @clearFilterValue="clearFilterValue"
                @getPage="getPage"
                resultsString="companies"
            >
                <template #item="{item}">
                    <CompanyCard @onSelect="onSelect" :client="item"/>
                </template>
            </ItemsGrid>
        </div>
        <ModalSidebar name="CompanyDetailsModal" :openLink="{name: 'companies.details', params: {username: selected_profile?.username}}">
            <CompanyDetails :profile="selected_profile" :modal="true"/>
        </ModalSidebar>
    </div>
</template>

<script>

import { metaMixin } from '~/mixins/metaMixin'
import ItemsGrid from "~/components/ItemsGrid.vue";
import FormInput from "~/components/FormInput.vue";
import Btn from "~/components/Btn.vue";
import CompanyDetails from "./CompanyDetails.vue";
import SearchIcon from "~/assets/icons/search_black.svg?inline";
import {searchMixin} from "@/mixins/searchMixin";
import CompanyCard from "~/components/CompanyCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import SearchInput from "@/components/SearchInput.vue";

export default {
    name: 'CompaniesList',
    components: {SearchInput, ModalSidebar, CompanyCard, CompanyDetails, Btn, FormInput, ItemsGrid, SearchIcon},
    mixins: [metaMixin, searchMixin],
    data() {
        return {
            loaded: false,
            selected_profile: null,
            filters: {
                user_type: 'company'
            },
        }
    },
    async fetch () {

        // this.filters.sortorder = this.itemsSort ? this.itemsSort.value : null
        // this.filters.sel_params = []
        //
        // if (this.$route.query) {
        //     if (this.$route.query.filters) {
        //         this.filters.sel_params = JSON.parse(this.$route.query.filters)
        //     }
        //     if (this.$route.query.price) {
        //         this.filters.sel_params_price = JSON.parse(this.$route.query.price)
        //     }
        //     if (this.$route.query.page) {
        //         this.filters.page = JSON.parse(this.$route.query.page)
        //     }
        //     if (this.$route.query.sort) {
        //         let sort = this.$store.state.itemsSortOptions.find(o => o.value === this.$route.query.sort)
        //         if (sort) {
        //             this.setSort(sort)
        //         }
        //     }
        // }
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    beforeDestroy() {
        // if (this.getDataRequest) {
        //     if (!this.getDataRequest.signal.aborted) {
        //         this.getDataRequest.abort();
        //     }
        // }
    },
    activated () {
        // this.sendToAnalytics()
    },
    methods: {
        getFilters () {
            this.loading.filters = true;
            return this.$axios.post(`/clients/filters`, {
                filters: this.filters.filters,
            })
                .then(async (res) => {
                    this.filters = res.data
                    this.loading.filters = false;
                    this.loaded = true;
                    await this.getData()
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.filters = false;
                });
        },
        getData () {
            if (this.getDataRequest) {
                if (!this.getDataRequest.signal.aborted) {
                    this.getDataRequest.abort();
                }
            }
            this.getDataRequest = new AbortController();

            this.loading.data = true;
            return this.$axios.post('/clients/search', this.filters,
                {
                    signal: this.getDataRequest ? this.getDataRequest.signal : null
                })
                .then((res) => {
                    this.data = res.data
                    this.available_filters = res.filters
                    this.$store.commit('setSortOptions', res.sorting_options)
                    // this.$store.commit('setSort', res.sorting_option)
                    this.loading.data = false;
                    this.loading.search = false;
                    // this.getDataRequest = null
                    // this.sendToAnalytics()
                })
                .catch((error) => {
                    console.error({error});
                    this.loading.data = false;
                    this.loading.search = false;
                    // this.getDataRequest = null
                });
        },
        onSelect(profile) {
            this.selected_profile = profile;
            this.$modal.show('CompanyDetailsModal')
        },
    }
}
</script>

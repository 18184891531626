<template>
    <div :id="id" class="item-counter-wrapper">
        <slot/>
        <span class="badge badge-sm badge-secondary-light px-1" v-if="countHidden">+{{ countHidden }}</span>
    </div>
</template>

<script>

export default {
    props: {
        item_class: 'badge badge-sm badge-secondary-light'
    },
    data: () => ({
        id: null,
        itemsWidths: [],
        parentContainerWidth: 0,
        timeout: null,
        items: []
    }),
    computed: {
        countHidden() {
            let total = 0;
            return this.itemsWidths.filter(i => {
                total += i
                if (total < this.parentContainerWidth) {
                    return false
                }
                return true
            }).length
        },
    },
    watch: {
        '$children'() {
            this.calculateItemWidths();
            this.setVisible();
        },
        parentContainerWidth() {
            this.setVisible();
        },
    },
    created() {
        this.id = `counter-tags-${this._uid}`
        this.items = this.$children
    },
    mounted() {
        this.calculateItemWidths();
        this.calculate();
        window.addEventListener("resize", (event) => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.calculate, 500);
        });
        this.setVisible();
    },
    methods: {
        calculateItemWidths() {
            this.itemsWidths = [...document.getElementById(this.id).children].map((i) => {
                const style = i.currentStyle || window.getComputedStyle(i),
                margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
                borders = parseFloat(style.borderLeft) + parseFloat(style.borderRight);
                return i.getBoundingClientRect().width + margin + borders;
            })
        },
        calculate() {
            this.parentContainerWidth = document.getElementById(this.id).getBoundingClientRect().width - 40;
        },
        setVisible() {
            let total = 0;
            [...document.getElementById(this.id).children].map((i, index) => {
                if (!this.itemsWidths[index]) {
                    return
                }
                total += this.itemsWidths[index];
                if (total < this.parentContainerWidth) {
                    i.classList.remove('d-none')
                }else{
                    i.classList.add('d-none')
                }

            })
        },
    },
}
</script>

<template>
    <div class="o-dropdown">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                <template #trigger>
                    <EditDotsIcon height="24"/>
                </template>
                <div class="c-dropdown-menu" :class="{'loading': this.loading}">
                    <NuxtLink :to="{name: 'account.projects.offers', params: { id: project.id, project: project }}" class="c-dropdown-menu__item">View Offers</NuxtLink>
                    <a href="#" @click.prevent="openDetails" class="c-dropdown-menu__item">View Project</a>
                    <NuxtLink :to="{name: 'account.projects.form', params: { id: project.id }}" class="c-dropdown-menu__item">Edit Project</NuxtLink>
                    <a href="#" class="c-dropdown-menu__item" v-if="project.status !== -3" @click.prevent="saveStatus(-3)">Mark as Archived</a>
                    <a href="#" class="c-dropdown-menu__item" v-if="project.status !== 1" @click.prevent="saveStatus(1)">Post project</a>
                    <a href="#" class="c-dropdown-menu__item text-danger" v-if="project.status <= 1" @click.prevent="deleteProject()">Delete Project</a>
                </div>
            </tippy>
        </ClientOnly>
    </div>

</template>

<script>

export default {
    name: 'ProjectDropdown',
    props: ['project'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        openDetails() {
            this.$emit('onViewDetails')
            this.closeDropdown();
        },
        deleteProject() {
            this.$dialog.confirm('Are you sure you want to delete this project?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/delete', {id: this.project.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                            this.closeDropdown();
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        // markAsArchived() {
        //     this.loading = true;
        //     this.$axios.post('/account/projects/archive-project', {id: this.project.id})
        //         .then((res) =>{
        //             this.$emit('onReload')
        //             this.loading = false;
        //         })
        //         .catch((error) => {
        //             this.loading = false;
        //         });
        // },
        saveStatus(status) {
            this.loading = true;
            this.$axios.post('/account/projects/save-status', {id: this.project.id, status})
                .then((res) =>{
                    this.$emit('onReload')
                    this.loading = false;
                    this.closeDropdown();
                })
                .catch((error) => {
                    this.loading = false;
                });

        },
        closeDropdown() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    }
}
</script>

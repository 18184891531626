<template functional>
    <FormGroup :help="props.help" :class="[data.staticClass]" class="form-input" :label="props.label">
        <div style="min-height: 38px; display: flex; align-items: center;">
            <div class="checkbox checkbox-primary">
                <input :id="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
                       :class="{'is-invalid' : props.v ? props.v.$error : null}"
                       class="form-check-input"
                       type="checkbox"
                       :name="props.name"
                       :checked="Array.isArray(props.value) ? props.value.findIndex(v => {
                           if(typeof v === 'object'){
                                return v.id == props.fieldValue.id;
                           }
                           return v == props.fieldValue;
                       }) > -1 : props.value"
                       :readonly="props.readonly"
                       :disabled="props.disabled"
                       v-on:change="($event) => {
                           var $$a = props.value,
                               value,
                               $$i,
                               $$el = $event.target,
                               $$c = $$el.checked ? true : false;
                           if (Array.isArray($$a)) {
                               value = $$a
                               if ($$el.checked) {
                                   if(typeof props.fieldValue === 'object'){
                                      $$i = $$a.findIndex( v => v.id == props.fieldValue.id);
                                   }else{
                                      $$i = $$a.indexOf(props.fieldValue);
                                   }
                                   if($$i === -1){
                                       value = $$a.concat([props.fieldValue])
                                   }
                               } else {
                                    if(typeof props.fieldValue === 'object'){
                                      $$i = $$a.findIndex( v => v.id == props.fieldValue.id);
                                   }else{
                                      $$i = $$a.indexOf(props.fieldValue);
                                   }
                                   if($$i > -1){
                                       value = $$a.slice(0, $$i).concat($$a.slice($$i + 1));
                                   }
                               }
                           } else {
                               value = $$c;
                           }
                           listeners.input && listeners.input(value);
                           listeners.change && listeners.change(value);
                           if(props.v){
                               props.v.$touch();
                           }
                      }"
                >
                <label :for="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
                       class="form-check-label" :class="{'--lg': props.size === 'lg'}">{{ props.text }} <slot name="labelslot"></slot></label>
                <slot/>
            </div>
        </div>
    </FormGroup>
</template>

<script>
    import FormGroup from "./FormGroup.vue";

    export default {
        name: 'FormCheckbox',
        components: {FormGroup},
    }
</script>

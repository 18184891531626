<template>
    <div class="document-uploader">
        <div class="document-uploader__dropbox" @drop.prevent="addDocument" @dragover.prevent>
            <div class="document-uploader__icon">
                <ImageIcon width="24" height="24"/>
            </div>
            <div class="document-uploader__text">Drag & Drop files here or
                <label class="mb-0" style="cursor: pointer; display: inline-block">
                    <input style="display: none" type="file" name="file" accept="image/png,image/jpeg,image/webp,application/pdf" multiple @change="addDocument" ref="input"/>
                    <span class="text-success">Browse files</span>
                </label>
            </div>
            <div class="document-uploader__desc">Max. Size For Each Document 2 MB, JPEG, PNG or PDF</div>
        </div>
        <div class="document-uploader__list">
            <div class="document-uploader__item" v-for="(document, index) in documents" :key="index">
                <div style="flex: 1" v-if="document.loading">
                    <div class="document-uploader__item-uploading">
                        <div class="document-uploader__item-info">
                            <div class="document-uploader__item-uploading-text">Uploading</div>
                            <div class="document-uploader__item-uploading-text">{{ (document.progress ? document.progress : 0).toFixed(0) }} %<template v-if="document.remaining"> - {{ document.remaining }} Sec Remaining</template></div>
                        </div>
                        <a href="#" class="document-delete-btn" @click.prevent="cancelUpload(document, index)">
                            <CloseIcon width="24" height="24"/>
                        </a>
                    </div>
                    <div class="document-uploader__item-progress">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" :style="{width: (document.progress ? document.progress : 0) + '%'}"></div>
                        </div>
                    </div>
                </div>

                <template v-else>
                    <template v-if="document.id">
                        <div class="document-uploader__item-icon">
                            <template v-if="document.mime">
                                <ImageFileIcon v-if="document.mime.indexOf('image') > -1"/>
                                <PdfFileIcon v-else-if="document.mime.indexOf('pdf') > -1"/>
                            </template>
                        </div>
                        <div class="document-uploader__item-info">
                            <div class="document-uploader__item-title">{{ document.file }}</div>
                            <div class="document-uploader__item-desc">{{ (document.size / 1024).toFixed(0) }} KB</div>
                        </div>
                    </template>
                    <template v-else-if="document.error">
                        <div class="document-uploader__item-info">
                            <div class="document-uploader__item-title text-danger">Error uploading</div>
                        </div>
                    </template>
                    <div class="document-uploader__item-actions">
                        <a href="#" class="document-delete-btn" @click.prevent="removeDocument(document, index)">
                            <CloseIcon width="24" height="24"/>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'DocumentsUploader',
    props: ['value', 'target', 'target_id', 'editable', 'role'],
    data() {
        return {
            loading: {
                upload: false,
                save: false,
            },
            id: '',
            documents: [],
            hoverDropZone: false
        }
    },
    mounted() {
        this.id = this._uid
        this.documents = this.value
    },
    watch: {
        value(a) {
            this.documents = a;
        },
        documents(documents) {
            this.$emit('input', documents);
        },
    },
    methods: {
        addDocument(e) {
            this.hoverDropZone = false;
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            if (files[0].size > 2097152) {
                this.$toast.error('File size is to big. Maximum size is 2 MB');
                return;
            }

            let current_max_index = this.documents.length;
            Array.from(files).forEach(() => {
                this.documents.push({
                    loading: true,
                    progress: 0,
                    remaining: null,
                    controller: new AbortController(),
                });
            });
            this.uploadDocument(files, 0, current_max_index, e.target);
        },
        uploadDocument(files, index, current_max_index, file_input) {
            if (['image/png', 'image/jpeg', 'image/webp', 'application/pdf'].indexOf(files[index].type) < 0) {
                this.$toast.error('File type not allowed');
                this.documents.splice(index, 1);
                return;
            }
            var formData = new FormData();
            formData.append('file', files[index]);
            if (this.target) {
                formData.append('target', this.target);
            }
            if (this.target_id) {
                formData.append('id', this.target_id);
            }
            if (this.role) {
                formData.append('role', this.role);
            }
            let timeStarted = new Date();

            return this.$axios.post("/upload-document", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                emulateJSON: true,
                files: true,
                signal: this.documents[index].controller?.signal,
                onUploadProgress: (e) => {
                    if (e.lengthComputable) {
                        this.documents[current_max_index + index].progress = e.loaded / e.total * 100;

                        const timeElapsed = Date.now() - timeStarted;
                        const uploadSpeed = e.loaded / (timeElapsed / 1000);
                        this.documents[current_max_index + index].remaining = ((e.total - e.loaded) / uploadSpeed).toFixed(0);
                    }
                }
            })
                .then((res) => {
                    this.documents[current_max_index + index] = res.document;
                    this.$forceUpdate();
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        this.documents.splice(current_max_index + index, 1);
                        return;
                    }
                    this.documents[current_max_index + index].loading = false;
                    this.documents[current_max_index + index].error = true;
                    this.$forceUpdate();
                }).finally(() => {
                    if (typeof files[index + 1] !== 'undefined') {
                        this.uploadDocument(files, index + 1, current_max_index, file_input);
                    } else if (file_input !== 'undefined') {
                        file_input.value = null
                    }
                });
        },
        removeDocument(document, index) {
            if (!this.target_id) {
                this.documents.splice(index, 1);
                return;
            }
            this.$dialog.confirm('Are you sure you want to delete this document?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    document.loading = true;
                    this.$forceUpdate();
                    this.$axios.post("/delete-document", {id: document.id})
                        .then(() => {
                            this.documents.splice(index, 1);
                            document.loading = false;
                        })
                        .catch(() => {
                            document.loading = false;
                        })
                });
        },
        cancelUpload(document, index) {
            document.controller.abort();
        },
    }
};
</script>


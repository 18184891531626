<template>
    <div class="c-faq" :class="{'--expanded' : expanded}">
        <div style="flex: 1">
            <div class="c-faq__title" @click.prevent="expanded = true">{{ item.name }}</div>
            <div v-show-slide="expanded" class="c-faq__body" v-html="item.content"></div>
        </div>
        <a href="#" @click.prevent="expanded = !expanded"><MinusIcon v-if="expanded"/><PlusIcon v-else/></a>
    </div>
</template>

<script>

export default {
    name: 'FAQitem',
    props: ['item', 'preexpanded'],
    data() {
        return {
            expanded: false
        }
    },
    mounted() {
        if (this.preexpanded) {
            this.expanded = true;
        }
    }
}
</script>

<template>
    <a class="c-block-toggle" :class="{'loading': loading, 'active': current_value}" @click.prevent="toggleBlock">
        <BlockIcon/>
    </a>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: 'BlockToggle',
    props: ['foreign_model', 'foreign_id', 'value'],
    data() {
        return {
            loading: false,
            current_value: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted () {
        this.current_value = this.value
    },
    watch: {
        current_value () {
            this.$emit('input', this.current_value)
        },
    },
    methods: {
        toggleBlock () {
            if (!this.user.id) {
                this.$toast.error('You must be signed in.')
                return
            }
            if (this.loading) {
                return
            }

            this.loading = true;
            this.$axios.post('/account/groups/block', {
                foreign_model: this.foreign_model,
                foreign_id: this.foreign_id
            })
                .then((res) => {
                    this.loading = false;
                    this.current_value = res.status
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        },
    }
}
</script>

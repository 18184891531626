<template>
    <div class="c-company-card" @click="onSelect" :style="{'background': client.company.background}">
        <div class="d-flex align-items-center mb-2">
            <CompanyAvatar :company="client.company" style="flex: 1" :displayName="true">
                <template v-slot:subtitle v-if="client.attributes.offering_jobs?.value && client.career_page">
                    Jobs Available <a :href="client.career_page" class="ml-1 text-secondary">View</a>
                </template>
            </CompanyAvatar>
            <GroupToggle class="ml-3" foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.groups" type="company"/>
        </div>
        <div class="c-company-card__content truncate-overflow-sm">
            {{ client.company.description }}
        </div>
        <div class="c-company-card__info">
            <div v-if="client.attributes.color_code_categories">
                <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in client.attributes.color_code_categories.slice(0, 1)" :attr="attr"/>
                <span class="badge badge-sm badge-secondary-light px-1" v-if="client.attributes.color_code_categories.length > 1">+{{ client.attributes.color_code_categories.length - 1}}</span>
            </div>
            <div v-if="client.company.address?.city_name || client.company.address?.country_name">
                <div class="c-company-card__info-icon">
                    <LocationIcon height="20" width="20"/>
                </div>
                <div class="c-company-card__info-title">{{ [client.company.address.city_name, client.company.address.country_name].join(', ') }}</div>
            </div>
            <div>
                <div class="c-company-card__info-icon">
                    <StarIcon height="16" width="16"/>
                </div>
                <div class="c-company-card__info-title">{{ client.avgReview }}/5 ({{ client.countReview }})</div>
            </div>
        </div>
        <div class="c-company-card__tags-wrapper" v-if="client.attributes.biotech_sectors && client.attributes.biotech_sectors.length">
            <span class="c-company-card__tags-title">Biotech Sector(s) Covered:</span>
            <ClientOnly>
                <ItemCounter class="c-company-card__tags">
                    <span class="badge badge-sm badge-secondary-light mr-1" v-for="area in client.attributes.biotech_sectors">{{ area.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>
    </div>
</template>

<script>


import GroupIcon from "./GroupToggle.vue";
import ColorCodeAttribute from "./ColorCodeAttribute.vue";
import CompanyAvatar from "@/components/CompanyAvatar.vue";
import GroupToggle from "@/components/GroupToggle.vue";
import ItemCounter from "@/components/ItemCounter.vue";

export default {
    name: 'CompanyCard',
    components: {ItemCounter, GroupToggle, CompanyAvatar, ColorCodeAttribute, GroupIcon},
    props: ['client'],
    methods: {
        onSelect() {
            console.log(this)
            console.log(this.client)
            this.$emit('onSelect', this.client)
        },
    }
}
</script>

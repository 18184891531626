<template>
    <div class="d-flex flex-column" style="flex: 1;">
        <div class="l-auth__onboarding" :class="{'loading': loading.data, '--white-bg': finished}">
            <Bubbles class="l-auth__onboarding-bubbles"/>
            <OnboardingCard
                    v-if="finished"
                    title="Congratulations and See You in the Future"
                    style="width: 680px"
                    icon="/images/success_review.svg"
            >
                <div class="mx-auto" style="max-width: 488px">
                    <div class="text-center">
                        <div>
                            <Btn class="btn-secondary --w380 mb-2" :to="{name: 'home'}">Back To Homepage</Btn>
                        </div>
                    </div>
                </div>
            </OnboardingCard>
            <Steps :index="step" v-else @stepsChange="stepsChange">
                <Step name="AddReview">
                    <OnboardingCard
                        title="Add Your Feedback & review"
                        style="width: 1109px"
                    >
                        <div class="mx-auto" v-if="review.user">
                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <CompanyAvatar v-if="review.user.type === 'company'" :company="review.user.company" :displayName="true" size="large"/>
                                <UserAvatar v-else :user="review.user" :displayName="true" :subtitle="review.user.description" size="large"/>
                            </div>
                            <template v-if="review.user.type === 'client'">
                                <div class="review_question">
                                    <div class="review_question__label">Do you recommend other people to work with this Client in the future?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 0">No</Btn>
                                    </div>
                                </div>
                                <div class="review_question">
                                    <div class="review_question__label">Will you be working with this Client in the Future?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 0">No</Btn>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="review.user.type === 'freelancer'">
                                <div class="review_question">
                                    <div class="review_question__label">Do you recommend other people to work with this Biotech Expert in the future?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 0">No</Btn>
                                    </div>
                                </div>
                                <div class="review_question">
                                    <div class="review_question__label">Will you be working with this Biotech Expert in the Future?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 0">No</Btn>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="review.user.type === 'company'">
                                <div class="review_question">
                                    <div class="review_question__label">Do you recommend other People to work with this Company in the future?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.recommend == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.recommend = 0">No</Btn>
                                    </div>
                                </div>
                                <div class="review_question">
                                    <div class="review_question__label">Want to work with this Company?</div>
                                    <div class="review_question__answer">
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 1 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 1">Yes</Btn>
                                        <Btn class="btn-sm --w80" :class="review.stats.come_back == 0 ? 'btn-secondary' : 'btn-outline-light'" @click="review.stats.come_back = 0">No</Btn>
                                    </div>
                                </div>
                            </template>
                            <div class="review_question">
                                <div class="review_question__label" v-if="review.user.type === 'client'">Are you satisfied with this Client?</div>
                                <div class="review_question__label" v-else-if="review.user.type === 'freelancer'">Are you satisfied with services provided by this Biotech Expert?</div>
                                <div class="review_question__label" v-else-if="review.user.type === 'company'">Are you satisfied with services provided by this Company?</div>
                            </div>
                            <div class="review_satisfaction_items">
                                <a class="review_satisfaction_item" :class="{'active': review.stars == 5}" @click.prevent="review.stars = 5">
                                    <img class="review_satisfaction_item__icon" src="/images/smiley/smiley_5.png"/>
                                    <span class="review_satisfaction_item__title">Extremelty satisfied</span>
                                </a>
                                <a class="review_satisfaction_item" :class="{'active': review.stars == 4}" @click.prevent="review.stars = 4">
                                    <img class="review_satisfaction_item__icon" src="/images/smiley/smiley_4.png"/>
                                    <span class="review_satisfaction_item__title">Satisfied</span>
                                </a>
                                <a class="review_satisfaction_item" :class="{'active': review.stars == 3}" @click.prevent="review.stars = 3">
                                    <img class="review_satisfaction_item__icon" src="/images/smiley/smiley_3.png"/>
                                    <span class="review_satisfaction_item__title">Neutral</span>
                                </a>
                                <a class="review_satisfaction_item" :class="{'active': review.stars == 2}" @click.prevent="review.stars = 2">
                                    <img class="review_satisfaction_item__icon" src="/images/smiley/smiley_2.png"/>
                                    <span class="review_satisfaction_item__title">Dissatisfied</span>
                                </a>
                                <a class="review_satisfaction_item" :class="{'active': review.stars == 1}" @click.prevent="review.stars = 1">
                                    <img class="review_satisfaction_item__icon" src="/images/smiley/smiley_1.png"/>
                                    <span class="review_satisfaction_item__title">Extremely dissatisfied</span>
                                </a>
                            </div>
                            <template  v-if="review.user.type === 'client' || review.user.type === 'freelancer'">
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Communication</label> <Stars :editable="true" v-model="review.stats.communication"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Quality of work</label> <Stars :editable="true" v-model="review.stats.quality_of_work"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Skills</label> <Stars :editable="true" v-model="review.stats.skills"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Flexibility</label> <Stars :editable="true" v-model="review.stats.flexibility"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Response time</label> <Stars :editable="true" v-model="review.stats.response_time"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Adaptability to the project</label> <Stars :editable="true" v-model="review.stats.adaptability"/></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="review_rating"><label for="">Schedule</label> <Stars :editable="true" v-model="review.stats.schedule"/></div>
                                    </div>
                                </div>
                            </template>

                            <div class="text-center">
                                <Btn class="btn-secondary --w380" @click="goToNextStep">Next</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Visibility" key="Visibility">
                    <OnboardingCard
                        title="Profile Visibility"
                        subtitle="Who do you want to see your profile? Simply, select an option to control your visibiity. You can change this later in your profile data"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="width: 488px">
                            <div class="o-privacy-select mb-2" :class="{'active': review.privacy === 'public'}" @click="review.privacy = 'public'">
                                <div class="o-privacy-select__icon"><PrivacyPublicIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Public</div>
                                    <div class="o-privacy-select__info-description">Anyone can see it, it will be indexed in search engines</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': review.privacy === 'registered'}" @click="review.privacy = 'registered'">
                                <div class="o-privacy-select__icon"><PrivacyRegisteredIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">BOLG Users only</div>
                                    <div class="o-privacy-select__info-description">Only users logged in to BOLG can find it</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': review.privacy === 'private'}" @click="review.privacy = 'private'">
                                <div class="o-privacy-select__icon"><PrivacyPrivateIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Private*</div>
                                    <div class="o-privacy-select__info-description">Only BOLG admins can see it.</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <Btn class="btn-secondary --w380" @click="saveReview" :loading="loading.save">Submit</Btn>
                        </div>
                    </OnboardingCard>
                </Step>

            </Steps>
        </div>
    </div>
</template>

<script>


import Btn from "~/components/Btn.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";

import Steps from "~/components/Steps.vue";
import Step from "~/components/Step.vue";
import XImgset from "~/components/XImgSet.vue";
import TagSelect from "~/components/TagSelect.vue";
import Stars from "../components/Stars.vue";
import UserAvatar from "../components/UserAvatar.vue";
import CompanyAvatar from "@/components/CompanyAvatar.vue";


export default {
    name: 'AddReview',
    components: {
        CompanyAvatar,
        UserAvatar,
        Stars,
        TagSelect,
        XImgset,
        Step,
        Steps,
        OnboardingCard,
        Btn,
        Bubbles,
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            stepsCount: 0,
            step: 0,
            finished: false,
            review: {
                user: null,
                application_id: null,
                privacy: 'public',
                stars: null,
                stats: {
                    recommend: null,
                    come_back: null,
                    communication: 0,
                    skills: 0,
                    response_time: 0,
                    schedule: 0,
                    quality_of_work: 0,
                    flexibility: 0,
                    adaptability: 0,
                },
            },
        }
    },
    validations: {

    },
    created: function () {
        this.review.application_id = this.$route.params.applicationId
        this.getData()
    },
    methods: {
        goToPrevStep() {
            if (this.loading.data) {
                return;
            }
            if (this.step > 0) {
                this.step--;
            }
        },
        goToNextStep() {
            if (this.loading.data) {
                return;
            }
            if (this.step <= this.stepsCount) {
                this.step++;
            }
        },
        async getData() {
            this.loading.data = true;

            await this.$axios.post("/clients/reviews/details", {
                username: this.$route.params.username,
                application_id: this.$route.params.applicationId,
            })
                .then((res) =>{
                    console.log(res.user)
                    this.review.user = res.user
                });

            this.loading.data = false;
        },
        saveReview() {
            this.loading.save = true;
            this.$axios.post("/clients/reviews/save", this.review)
                .then((res) =>{
                    this.loading.save = false;
                    this.finished = true;
                })
                .catch((res) =>{
                    this.loading.save = false;
                })
        },
        stepsChange(count) {
            this.stepsCount = count
        }
    },
}
</script>

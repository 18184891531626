<template>
    <div :class="{'loading': loading.data}">
        <div class="l-account-content__header">
            <div class="l-account-content__title">Linked accounts</div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="linked-account mb-2">
                    <FacebookIcon class="me-2" width="32" height="32"/>
                    <div class="ml-2 flex-1 me-2">Facebook</div>
                    <EditIcon class="edit-btn" @click="editAccount('facebook')"/>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="linked-account mb-2">
                    <LinkedInIcon class="me-2" width="32" height="32"/>
                    <div class="ml-2 flex-1 me-2">LinkedIn</div>
                    <EditIcon class="edit-btn" @click="editAccount('linkedin')"/>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="linked-account mb-2">
                    <GoogleIcon class="me-2" width="32" height="32"/>
                    <div class="ml-2 flex-1 me-2">Google</div>
                    <EditIcon class="edit-btn" @click="editAccount('google')"/>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="linked-account mb-2">
                    <InstagramIcon class="me-2" width="32" height="32"/>
                    <div class="ml-2 flex-1 me-2">Instagram</div>
                    <EditIcon class="edit-btn" @click="editAccount('instagram')"/>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="linked-account mb-2">
                    <TwitterIcon class="me-2" width="32" height="32"/>
                    <div class="ml-2 flex-1 me-2">Twitter</div>
                    <EditIcon class="edit-btn" @click="editAccount('twitter')"/>
                </div>
            </div>
        </div>
        <modal name="editAccountModal" height="auto" :scrollable="true" style="border-radius: 10px">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button class="close" @click="$modal.hide('editAccountModal')">
                        <CloseIcon class="close"/>
                    </button>
                    <div class="modal-header">
                        <h4 class="modal-title">Set linked account URL</h4>
                    </div>
                    <div class="modal-body">
                        <form-input class="mb-2"
                                    label="Account url" placeholder=""
                                    v-model="account.url"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveAccount()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import FacebookIcon from "~/assets/icons/account/facebook-square.svg?inline";
import LinkedInIcon from "~/assets/icons/account/linkedin-square.svg?inline";
import GoogleIcon from "~/assets/icons/account/google-square.svg?inline";
import TwitterIcon from "~/assets/icons/account/twitter-square.svg?inline";
import InstagramIcon from "~/assets/icons/account/instagram-square.svg?inline";
import EditIcon from "~/assets/icons/account/edit.svg?inline";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import FormGroup from "~/components/FormGroup.vue";
import FormInput from "~/components/FormInput.vue";
import Btn from "~/components/Btn.vue";

    export default {
        name: 'LinkedAccounts',
        components: {Btn, FormInput, FormGroup, FacebookIcon, LinkedInIcon, GoogleIcon, TwitterIcon, InstagramIcon, EditIcon, CloseIcon},
        head: {
            title: 'Linked accounts',
        },
        data () {
            return {
                loading: {
                    data: false,
                    save: false,
                },
                linkedAccounts: {
                    facebook: '',
                    google: '',
                    linkedin: '',
                    instagram: '',
                    twitter: '',
                },
                account: {
                    network: '',
                    url: ''
                }
            }
        },
        methods: {
            getData() {
                this.loading.data = true;
                this.$axios.get("/account/linked-accounts")
                    .then((res) =>{
                        this.linkedAccounts = {...this.linkedAccounts, ...res.data};
                        this.loading.data = false;
                    })
                    .catch((error) => {
                        this.loading.data = false;
                    });
            },
            editAccount(network) {
                this.account.network = network;
                if (typeof this.linkedAccounts[network] !== "undefined") {
                    this.account.url = this.linkedAccounts[network];
                }else{
                    this.account.url = '';
                }
                this.$modal.show('editAccountModal')
            },
            saveAccount() {
                this.loading.save = true;
                this.$axios.post("/account/linked-accounts", this.account)
                    .then((res) =>{
                        this.linkedAccounts = {...this.linkedAccounts, ...res.data};
                        this.loading.save = false;
                        this.$modal.hide('editAccountModal')
                    })
                    .catch((error) => {
                        this.loading.save = false;
                    });
            }
        },
        mounted() {
            this.getData();
        }
    };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-group',{staticClass:"form-input",attrs:{"help":_vm.$props.help,"label":_vm.$props.label}},[_c('div',{staticClass:"input-group"},[(!!_vm.$slots.prepend)?_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2):(_vm.$props.prepend_text)?_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$props.prepend_text))]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"form-control",class:{'is-invalid' : _vm.$props.v ? _vm.$props.v.$error : null, 'no-label': !_vm.$props.label},attrs:{"type":_vm.visible ? 'text' : 'password',"placeholder":_vm.$props.placeholder,"disabled":_vm.$props.disabled,"readonly":_vm.$props.readonly},domProps:{"value":_vm.$props.value},on:{"input":($event) => {
                   _vm.$emit('input', $event.target.value)
                   if(_vm.$props.v){
                       _vm.$props.v.$touch();
                   }
               },"change":($event) => {
                   _vm.$emit('change', $event.target.value)
               },"keyup":($event) => {
                   _vm.$emit('keyup', $event)
               }}}),_vm._v(" "),(!!_vm.$slots.append)?_c('span',{staticClass:"input-group-text"},[_vm._t("append")],2):(_vm.$props.append_text)?_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$props.append_text))]):_c('span',{staticClass:"input-group-append",on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('div',{staticClass:"input-group-text d-flex align-items-center",style:({opacity: _vm.visible ? 1 : 0.4})},[(_vm.visible)?_c('EyeIcon'):_c('EyeIcon')],1)])]),_vm._v(" "),_vm._t("help")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <NuxtLink class="c-article-card" :to="{name: 'articles.details', params: { slug: post.slug }}">
        <div class="c-article-card__img-wrapper">
            <XImgset class="c-article-card__img img-fluid" :src="post.image" :width="354" :height="153" :alt="post.title"/>
        </div>
        <div class="c-article-card__content-wrapper">
            <div class="c-article-card__tags">
                <span class="badge badge-secondary"></span>
            </div>
            <div class="c-article-card__content">
                <div class="c-article-card__content-title">{{ post.name }}</div>
                <div class="c-article-card__content-desc" v-html="post.content"></div>
            </div>
            <div class="c-article-card__author">
                <div class="c-article-card__author-img" v-if="post.user">
                    <XImgset class="img-fluid" :src="post.user.image" :width="42" :height="42" :alt="post.user.name"/>
                </div>
                <div class="c-article-card__author-info">
                    <div class="c-article-card__author-name" v-if="post.user">
                        {{ post.user.name }}
                    </div>
                    <div class="c-article-card__author-stats">
                        <div>{{ post.date | moment('MMM D, YYYY') }}</div>
                        <div v-if="post.attributes?.read_duration">
                            <span class="dot"></span>
                            <ClockIcon height="24"/>
                            {{ post.attributes?.read_duration }} Min Read
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </NuxtLink>
</template>

<script>
import XImgset from "./XImgSet.vue";

export default {
    name: 'ArticleCard',
    components: {XImgset},
    props: ['post'],
    data() {
        return {
            loading: {

            },
        }
    }
}
</script>

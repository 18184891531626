<template>
    <span class="o-dropdown d-inline-block">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                <template #trigger>
                    <EditDotsIcon height="24"/>
                </template>
                <div class="c-dropdown-menu">
                    <NuxtLink :to="{name: 'account.offer.details', params: {id: this.offer.id}}" class="c-dropdown-menu__item" v-if="offer.status === 1">View Offer Details</NuxtLink>
                    <a href="#" @click.prevent="openDetails" class="c-dropdown-menu__item" v-else>View Offer Details</a>
                    <div class="c-dropdown-menu__item" @click="sendMessage">Send Message</div>
<!--                    <div  class="c-dropdown-menu__item">Invite For an Interview</div>-->
<!--                    <div class="c-dropdown-menu__item">Book Consultation Session</div>-->
                    <div class="c-dropdown-menu__item text-danger" v-if="offer.status <= 0" @click="deleteOffer">Delete Offer</div>
                </div>
            </tippy>
        </ClientOnly>
    </span>

</template>

<script>


import {mapState} from "vuex";

export default {
    name: 'ProjectOfferDropdown',
    props: ['offer'],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        deleteOffer() {
            this.$dialog.confirm('Are you sure you want to delete this offer?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/offers/delete', {id: this.offer.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        sendMessage() {
            if (this.offer.client.id === this.user.id) {
                this.$router.push({name: 'account.messages', params: {username: this.offer.project.client.username, project_id: this.offer.project.id}});
            }else{
                this.$router.push({name: 'account.messages', params: {username: this.offer.client.username, project_id: this.offer.project.id}});
            }
        },
        openDetails() {
            console.log('onViewDetails')
            this.$emit('onViewDetails')
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    }
}
</script>

<template>
    <div class="l-auth__onboarding">
        <Bubbles class="l-auth__onboarding-bubbles"/>
        <div class="container">
            <OnboardingCard
                title="Welcome to BOLG!"
            >
                <div class="row --item-card-grid mx-auto" style="width: 1080px; max-width: 100%">
                    <div class="col-md-4 mb-2">
                        <div class="c-auth-card" @click="setUserType('client')">
                            <div class="c-auth-card__icon">
                                <ClientIcon/>
                            </div>
                            <div class="c-auth-card__name">I am a client</div>
                            <div class="c-auth-card__desc">looking for biotechnology expert</div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                        <div class="c-auth-card" @click="setUserType('freelancer')">
                            <div class="c-auth-card__icon">
                                <FreelancerIcon/>
                            </div>
                            <div class="c-auth-card__name">I am a biotechnology freelancer,</div>
                            <div class="c-auth-card__desc">looking for job</div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                        <div class="c-auth-card" @click="setUserType('company')">
                            <div class="c-auth-card__icon">
                                <CompanyIcon/>
                            </div>
                            <div class="c-auth-card__name">We are biotechnology company,</div>
                            <div class="c-auth-card__desc">advertising our products and services</div>
                        </div>
                    </div>
                </div>
            </OnboardingCard>

        </div>
    </div>
</template>

<script>

import FormInput from "~/components/FormInput.vue";
import Btn from "~/components/Btn.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";

import ClientIcon from "~/assets/icons/auth/client.svg?inline";
import FreelancerIcon from "~/assets/icons/auth/freelancer.svg?inline";
import CompanyIcon from "~/assets/icons/auth/company.svg?inline";

import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";
import {metaMixin} from "@/mixins/metaMixin";

export default {
    name: 'AuthUserSelect',
    components: {OnboardingCard, FormCheckbox, Btn, FormInput, ClientIcon, FreelancerIcon, CompanyIcon, Bubbles},
    mixins: [metaMixin],
    data() {
        return {
            user_type: null,
        }
    },
    methods: {
        setUserType(type) {
            this.user_type = type
            this.$router.push({name: 'auth.register', params: {type: type}})
        },
    },
}
</script>

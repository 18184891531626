<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Agency members</div>
            <Btn class="btn btn-secondary mt-3" @click="addEmployee"><PlusWhiteIcon/> Add a member</Btn>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :centered="true"
                    :data="data.data"
                    :columns="['member', 'invite_date', 'status']">
                    <template #member="{row}">
                        <template v-if="row.user.id !== user.id">
                            <UserAvatar size="medium" :user="row.user" :displayName="true"></UserAvatar>
                        </template>
                        <template v-else>
                            <UserAvatar v-if="row.employee" size="medium" :user="row.employee" :displayName="true"></UserAvatar>
                            <div v-else-if="row.email">{{ row.email }}</div>
                        </template>
                    </template>
                    <template #invite_date="{row}">
                        {{ row.invite_date | moment('DD.MM.YYYY') }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
                        <template v-if="row.status === '0' && row.user.id !== user.id">
                            <Btn class="btn-outline-light" @click="acceptEmployee(row)">Accept</Btn>
                        </template>
                        <Btn class="btn-outline-light" @click="removeEmployee(row)">Remove</Btn>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length && !loading.data">
                <h4>No agency members added</h4>
            </div>
            <div class="d-flex mt-2" v-if="data && data.data.total > 1">
                <pagination :limit="10" :data="data.data" v-on:pagination-change-page="getPage"></pagination>
            </div>
        </div>
        <modal name="addEmployeeModal" height="auto" :scrollable="true" style="border-radius: 10px">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button class="close" @click="$modal.hide('addEmployeeModal')">
                        <CloseIcon class="close"/>
                    </button>
                    <div class="modal-header">
                        <h4 class="modal-title">Add a agency member</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2" label="Email" type="email" v-model="employee.email"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveEmployee()">Send invitation</Btn>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import UserAvatar from "~/components/UserAvatar.vue";

import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import VueTable from "@/components/VueTable.vue";
import {mapState} from "vuex";

export default {
    name: "Employees",
    mixins: [modelListMixin],
    components: {
        VueTable,
        FormSelect, FormInput, Btn, UserAvatar, CloseIcon
    },
    head: {
        title: 'Agency members',
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            employee: {
                id: null,
                email: '',
                role: 'editor_admin'
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/employees")
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        addEmployee() {
            this.employee = {
                id: null,
                email: '',
                role: 'editor_admin'
            };
            this.$modal.show('addEmployeeModal')
        },
        editEmployee(employee) {
            this.employee = JSON.parse(JSON.stringify(employee));
            this.$modal.show('editEmployeeModal')
        },
        saveEmployee(edit) {
            this.loading.save = true;
            this.employee.edited = edit;
            this.$axios.post("/account/employees/save", this.employee)
                .then((res) =>{
                    this.getData();
                    this.loading.save = false;
                    this.$modal.hide('addEmployeeModal')
                    this.$modal.hide('editEmployeeModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        removeEmployee(employee) {
            this.$dialog.confirm('Are you sure you want to delete this member?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    employee.loading = true;
                    this.$axios.post("/account/employees/delete", {
                        id: employee.id
                    })
                        .then((res) =>{
                            this.getData();
                            employee.loading = false;
                        })
                        .catch((error) => {
                            employee.loading = false;
                        });
                    });
        },
        acceptEmployee(employee) {
            this.$dialog.confirm('Are you sure you want to accept this invitation?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    employee.loading = true;
                    this.$axios.post("/account/employees/accept", {
                        id: employee.id
                    })
                        .then((res) =>{
                            this.getData();
                            employee.loading = false;
                        })
                        .catch((error) => {
                            employee.loading = false;
                        });
                });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

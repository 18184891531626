import Vue from 'vue';

export default function ({ $axios, redirect, store, $toast, i18n, route }) {

    let allowRefresh = true;
    let refreshingToken = false;

    $axios.onRequest(config => {

        if (config.files === undefined) {
            config.data = typeof config.data !== 'string' ? JSON.stringify(config.data) : config.data;
        }
        if (config.url[0] === '/') {
            // if (store?.state?.position) {
            //     config.headers['Latitude'] = String(store.state.position.latitude);
            //     config.headers['Longitude'] = String(store.state.position.longitude);
            // }
            let token = typeof Vue.$auth !== 'undefined' ? Vue.$auth.getToken() : null;
            if (token && config.url !== '/auth/login') {
                config.headers['Authorization'] =  `Bearer ${token}`;
            }
            let profileId = typeof Vue.$auth !== 'undefined' ? Vue.$auth.getProfileId() : null;
            if (profileId && config.url !== '/auth/login') {
                config.headers['Profile-Id'] = profileId;
            }
            if ((config.url).indexOf('/api') !== 0) {
                config.url = process.env.API_BASE_URL + '/api' + config.url;
            }
            config.headers['Content-type'] = 'application/json';
            // config.headers['Accept'] = 'application/json';
        }

        config.headers['Lang'] = i18n.locale;

    })

    $axios.onResponse(res => {
        // if (refreshingToken) {
        //     setTimeout(function () {
        //         console.log('redo reqest timeout')
        //         $axios(res.config).then(res => {
        //             allowRefresh = true;
        //             return res
        //         })
        //     }, 1000);
        //     return false;
        // }
        // console.log('onResponse', {res})

        if (typeof res.data.message !== 'undefined') {
            $toast.success(res.data.message);
        }

        return res.data;
    })

    $axios.onResponseError( (err) => {
        if (err.code === 'ERR_CANCELED') {
            return;
        }
        if(typeof err.response !== 'undefined' && err.response.status > 200 && (err.response.data.error === 'token_not_provided' || err.response.data.error === 'invalid_token')){
            Vue.$auth.setCurrentRoute(route, store.state.user.id);
            Vue.$auth.destroyToken();
            store.commit('logout');
            // Vue.$acl.change('public');
            if (route.meta.rule === 'isLogged' && route.meta.rule !== 'isAny') {
                redirect('/auth/login')
            }
            throw err;
        }

        if (err.config && err.config.url.indexOf('refresh-token') === -1 && err.response.status === 401 && err.response.data.message === 'token_expired') {
            Vue.$auth.setCurrentRoute(route, store.state.user.id);
            allowRefresh = false;
            refreshingToken = true;
            // console.log('ref')
            return $axios.get('/auth/refresh-token').then(function(res){
                // console.log('res refresh token')
                refreshingToken = false;
                if (res.token) {
                    // console.log('could refresh')

                    Vue.$auth.setToken(res.token)
                    err.config.headers.Authorization = `Bearer ${res.token}`;

                    return $axios(err.config).then(res => {
                        allowRefresh = true
                        // console.log('retry req then')
                        return res
                    }).catch(err => {
                        // console.log('retry req catch')

                        allowRefresh = true
                        throw err
                    })
                }else{
                    // console.log('could not refresh')
                    refreshingToken = false;
                    allowRefresh = true;
                    Vue.$auth.destroyToken();
                    store.dispatch('logout');
                    // Vue.$acl.change('public');
                    if (route.meta.rule === 'isLogged' && route.meta.rule !== 'isAny') {
                        redirect('/auth/login')
                    }
                }
            }).catch(function (e) {
                // console.log('catch refresh', e)
                // console.log(Vue.$auth)
                // console.log(Vue.$acl)
                refreshingToken = false;
                allowRefresh = true;
                Vue.$auth.destroyToken();
                store.dispatch('logout');
                // Vue.$acl.change('public');
                if (route.meta.rule === 'isLogged' && route.meta.rule !== 'isAny') {
                    redirect('/auth/login')
                }

                throw err
            })

        } else if(typeof err.response !== 'undefined' && err.response.status === 401){
            allowRefresh = true;
            Vue.$auth.destroyToken();
            store.dispatch('logout');
            // Vue.$acl.change('public');
            if (route.meta.rule === 'isLogged' && route.meta.rule !== 'isAny') {
                redirect('/auth/login')
            }
        }

        if (err.response && typeof err.response.data.message !== 'undefined' && err.response.data.message !== 'token_expired' && err.response.data.message !== 'token_not_provided' && err.response.data.message !== 'invalid_token') {
            if (err.response.status !== 422) {
                $toast.error(err.response.data.message);
            }
        }
        if (err.response && typeof err.response.data.error !== 'undefined' && err.response.data.error !== 'token_expired' && err.response.data.error !== 'token_not_provided' && err.response.data.error !== 'invalid_token') {
            if (err.response.status !== 422) {
                $toast.error(err.response.data.error);
            }
        }
        if (err.response.status === 422 && typeof err.response.data.errors !== 'undefined') {
            for (let error in err.response.data.errors) {
                $toast.error(err.response.data.errors[error]);
            }
        }
        throw err
    })
}

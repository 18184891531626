var render = function render(_c,_vm){return _c('form-group',{staticClass:"form-input",class:[_vm.data.staticClass, _vm.data.class, {'error' : _vm.props.v ? _vm.props.v.$error : null}],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('select',{staticClass:"form-control",class:[_vm.props.inputClass, {'no-label': !_vm.props.label}],attrs:{"placeholder":_vm.props.label,"name":_vm.props.name,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"change":($event) => {
                    _vm.listeners.input && _vm.listeners.input($event.target.value);
                    _vm.listeners.change && _vm.listeners.change($event.target.value);
                    if (_vm.props.v) {
                       _vm.props.v.$touch();
                    }
                }}},[(_vm.props.label)?_c('option',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.props.label))]):_vm._e(),_vm._v(" "),_vm._l((24),function(n){return [_c('option',{domProps:{"value":_vm.$options.filters.pad((n - 1), 2) + ':00'}},[_vm._v(_vm._s(_vm._f("momentTime")(_vm.$options.filters.pad((n - 1), 2) + ':00:00','HH:mm')))]),_vm._v(" "),(_vm.props.interval === 15)?_c('option',{domProps:{"value":_vm.$options.filters.pad((n - 1), 2) + ':15'}},[_vm._v(_vm._s(_vm._f("momentTime")(_vm.$options.filters.pad((n - 1), 2) + ':15:00','HH:mm')))]):_vm._e(),_vm._v(" "),_c('option',{domProps:{"value":_vm.$options.filters.pad((n - 1), 2) + ':30'}},[_vm._v(_vm._s(_vm._f("momentTime")(_vm.$options.filters.pad((n - 1), 2) + ':30:00','HH:mm')))]),_vm._v(" "),(_vm.props.interval === 15)?_c('option',{domProps:{"value":_vm.$options.filters.pad((n - 1), 2) + ':45'}},[_vm._v(_vm._s(_vm._f("momentTime")(_vm.$options.filters.pad((n - 1), 2) + ':45:00','HH:mm')))]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
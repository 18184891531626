<template>
   <div v-if="isVisible">
       <slot/>
   </div>
</template>

<script>

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        isVisible: false,
    }),
};
</script>

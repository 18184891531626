<template>
    <div style="width: 100%;">
        <div class="c-header-menu">
            <ul class="c-header-menu__nav nav">
                <template v-if="loading.data">
                    <li class="nav-item me-2" v-for="i in 3" style="width: 80px">
                        <Shimmer height="20px" width="80px"/>
                    </li>
                </template>
                <template v-else>
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="'/about-us'">About Us</nuxt-link>
                    </li>
<!--                    <li class="nav-item" v-if="['client'].includes(user.type) || !user.type">-->
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="{name: 'freelancers.list'}">Find Biotech Experts</nuxt-link>
                    </li>
<!--                    <li class="nav-item" v-if="['freelancer'].includes(user.type)">-->
                    <li class="nav-item">
                        <a class="nav-link" href="#" :class="{'router-link-exact-active active': $route.name === 'jobs.list'}" @click.prevent="goToJobsList">Find Biotech Jobs</a>
<!--                        <nuxt-link class="nav-link" :to="{name: 'jobs.list'}">Find Biotech Jobs</nuxt-link>-->
                    </li>
<!--                    <li class="nav-item" v-if="['client'].includes(user.type)">-->
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="{name: 'projects.list'}">Projects</nuxt-link>
                    </li>
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="{name: 'companies.list'}">Biotech Companies Directory</nuxt-link>
                    </li>
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="{name: 'faq'}">FAQs</nuxt-link>
                    </li>
                    <li class="nav-item">
                        <nuxt-link class="nav-link" :to="{name: 'page.details', params: {slug: 'blog'}}">Blog</nuxt-link>
                    </li>
                    <li class="nav-item" v-if="pwaInstall">
                        <a class="nav-link --download-pwa" href="#" @click.prevent="pwaInstall.prompt();"><DownloadGreenIcon/> Download Our PWA</a>
                    </li>
<!--                    <li class="nav-item" v-for="menuitem in menuitems">-->
<!--                        <nuxt-link class="nav-link" :to="menuitem.url.path">{{ menuitem.title }}</nuxt-link>-->
<!--                    </li>-->
                </template>
            </ul>
        </div>
        <div class="c-header-menu-mobile" v-show="showMainMenu">
<!--            <div class="c-header-menu-mobile__item" v-for="menuitem in menuitems">-->
<!--                <nuxt-link :to="menuitem.url" class="c-header-menu-mobile__item-title">{{ menuitem.title }}</nuxt-link>-->
<!--            </div>-->
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="'/about-us'">About us</nuxt-link>
            </div>
<!--            <div class="c-header-menu-mobile__item" v-if="['client'].includes(user.type) || !user.type">-->
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'freelancers.list'}">Find Biotech Experts</nuxt-link>
            </div>
<!--            <div class="c-header-menu-mobile__item" v-if="['freelancer'].includes(user.type)">-->
            <div class="c-header-menu-mobile__item">
                <a class="c-header-menu-mobile__item-title" :class="{'router-link-exact-active active': $route.name === 'jobs.list'}" href="#" @click.prevent="goToJobsList">Find Biotech Jobs</a>
<!--                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'jobs.list'}">Find Biotech Jobs</nuxt-link>-->
            </div>
<!--            <div class="c-header-menu-mobile__item" v-if="['client'].includes(user.type)">-->
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'projects.list'}">Projects</nuxt-link>
            </div>
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'companies.list'}">Biotech Companies Directory</nuxt-link>
            </div>
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'faq'}">FAQs</nuxt-link>
            </div>
            <div class="c-header-menu-mobile__item">
                <nuxt-link class="c-header-menu-mobile__item-title" :to="{name: 'blog.articles'}">Blog</nuxt-link>
            </div>
            <div class="c-header-menu-mobile__item" v-if="pwaInstall">
                <a class="c-header-menu-mobile__item-title --download-pwa" href="#" @click.prevent="pwaInstall.prompt();">Download Our PWA</a>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import Bugsnag from "@bugsnag/js";

export default {
    name: 'MainMenu',
    data() {
        return {
            loading: {
                data: false,
            },
            menuitems: [],
            isActive: false,
            visible: false,
        }
    },
    watch:{
        '$route.fullPath' (to, from){
            this.hideMenu()
            if (this.showMainMenu) {
                this.$store.commit('toggleMainMenu', false)
            }
        }
    },
    computed: {
        ...mapState({
            showMainMenu: state => state.showMainMenu,
            user: state => state.user,
            pwaInstall: state => state.pwaInstall,
        })
    },
    created () {
        // this.getData();
    },
    methods: {
        showMenu () {
            setTimeout(() => {
                this.visible = true
                document.getElementById('contentWrapper').classList.add('overlayContent')
            }, 100)
            this.isActive = true;
        },
        hideMenu () {
            if (this.visible) {
                this.visible = false
                document.getElementById('contentWrapper').classList.remove('overlayContent')
                this.isActive = false
            }
        },
        getData () {
            this.loading.data = true;
            this.$axios.get('/utilities/menu')
                .then((res) => {
                    this.menuitems = res.menuitems
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        goToJobsList() {
            if(this.user.type !== 'freelancer') {
                this.$toast.error('You need to login using a Freelancer account to access the jobs list.');
                this.$router.push({name: 'auth.login'});
                return;
            }else if (this.user.status !== 1) {
                this.$toast.error('Your account needs to be approved to be able to access the jobs list.');
                return;
            }
            this.$router.push({name: 'jobs.list'});
        },
    }
}
</script>

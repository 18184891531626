<template>
    <div>
        <div class="card card-dark" v-if="user.type === 'client'">
            <div class="card-body">
                <FormGroup label="Invite Biotech Expert" class="--label-xl mt-2">
                    <ProjectSelect placeholder="Select Project"
                                   type="job"
                                   :key="'interviewprojects'"
                                   :statuses="[1]"
                                   v-model="invitation.project"/>
                    <div class="vue-multiselect__input-group">
                        <div class="input-group-prepend">
                            <SearchIcon class="--black-icon"/>
                        </div>
                        <InvitationSelect placeholder="Search User"
                                          v-model="invitation.user"
                                          style="flex: 1"
                        >
                            <template v-slot:option="props">
                                <img class="multiselect__image" :src="props.option.image">
                                <span>{{ props.option.name }}</span>
                                <span class="multiselect__action">{{ props.option.action }}</span>
                            </template>
                        </InvitationSelect>
                    </div>
                </FormGroup>
                <Btn class="btn-secondary" @click="sendInvitation" :loading="loading.send">Send Invitation</Btn>
            </div>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <div class="table-responsive">
                    <table class="table table-vcentered">
                        <thead>
                        <tr>
                            <th v-if="user.type === 'client'">Biotech Expert</th>
                            <th v-else-if="user.type === 'freelancer'">Client</th>
                            <th>Date</th>
                            <th>Project Title</th>
                            <th></th>
                            <th width="140"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="invitation in data.data" v-bind:key="invitation.id">
                            <td v-if="user.type === 'client'">
                                <UserAvatar v-if="invitation.freelancer" :user="invitation.freelancer" :display-name="true"/>
                                <div v-else-if="invitation.email">{{ invitation.email }}</div>
                            </td>
                            <td v-else-if="user.type === 'freelancer'">
                                <UserAvatar :user="invitation.client" :display-name="true"/>
                            </td>
                            <td>
                                {{ invitation.date | moment('DD.MM.YYYY') }}
                            </td>
                            <td>
                                {{ invitation.project?.title }}
                            </td>
                            <td style="white-space: nowrap; text-align: right">
                                <div v-if="invitation.status == 0">
                                    <span class="mr-2"> {{ invitation.status_label }}</span>
                                    <template v-if="invitation.received">
                                        <Btn @click="inviteForInterview(invitation)" class="btn btn-secondary">Accept</Btn>
                                        <Btn @click="declineInvitation(invitation)" class="btn btn-outline-light">Decline</Btn>
                                    </template>
                                </div>
                                <div class="d-inline-flex align-items-center" v-else>
                                    <AcceptedIcon v-if="invitation.status == 1" class="mr-1"/>
                                    <DeclinedIcon v-else-if="invitation.status == -1" class="mr-1"/>
                                    {{ invitation.status_label }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No invitations</h4>
            </div>
            <div class="d-flex mt-2" v-if="data && data.total > 1">
                <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
            </div>
        </div>
        <modal name="sendInterviewMessageModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('sendInterviewMessageModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Accept invitation for interview</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 380px">
                            <FormTextarea rows="14" class="mb-2" label="Message" v-model="send_message.message"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('sendInterviewMessageModal')">Cancel</Btn>
                            <Btn :loading="loading.save" class="btn btn-lg btn-secondary --w150" @click="sendMessage()">Send</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Btn from "@/components/Btn.vue";
import SearchIcon from "~/assets/icons/search_black.svg?inline";
import Tab from "@/components/Tab.vue";
import Tabs from "@/components/Tabs.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import InvitationSelect from "@/components/InvitationSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import {mapState} from "vuex";
import FormTextarea from "@/components/FormTextarea.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
    name: "InvitationsIntrerview",
    mixins: [modelListMixin],
    components: {UserAvatar, FormTextarea, ProjectSelect, InvitationSelect, Tabs, Tab, Btn, SearchIcon},
    data() {
        return {
            filters: {
                type: 'interview'
            },
            loading: {
                data: false,
                send: false,
                save: false,
            },
            invitation: {
                user: null,
                project: null,
            },
            selected_invitation: null,
            send_message: {
                message: '',
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/invitations", {params: this.filters})
                .then((res) => {
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        sendInvitation() {
            this.loading.send = true;
            let data = {
                type: 'interview',
                project_id: this.invitation.project?.id,
            };
            if (this.invitation.user?.email) {
                data.email = this.invitation.user.email
            }else{
                data.user_id = this.invitation.user?.id
            }
            this.$axios.post("/account/invitations/send", data)
                .then((res) => {
                    this.invitation = {
                        user: null,
                        project: null,
                    };
                    this.getData();
                    this.loading.send = false;
                })
                .catch((error) => {
                    this.loading.send = false;
                });
        },
        acceptInvitation(invitation) {
            invitation.loading = true;
            this.$axios.post("/account/invitations/save-status", {
                id: invitation.id,
                status: 1
            })
                .then((res) => {
                    this.getData()
                    invitation.loading = false;
                })
                .catch((error) => {
                    invitation.loading = false;
                });
        },
        declineInvitation(invitation) {
            this.$dialog.confirm('Are you sure you want to decline this invitation?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    invitation.loading = true;
                    this.$axios.post("/account/invitations/save-status", {
                        id: invitation.id,
                        status: -1
                    })
                        .then((res) => {
                            this.getData()
                            invitation.loading = false;
                        })
                        .catch((error) => {
                            invitation.loading = false;
                        });
                });
        },
        inviteForInterview(invitation) {
            this.selected_invitation = invitation
            this.send_message.message = '' +
                `Hey ${invitation.client.firstname}\n` +
                'I want to accept your interview request.\n' +
                '\n' +
                'Looking forward to receiving a message from you.\n' +
                '\n' +
                'Best regards,\n' +
                `${this.user.firstname}`;
            this.$modal.show('sendInterviewMessageModal');
        },
        sendMessage () {
            this.loading.save = true;
            return this.$axios.post('/messaging/send-message', {
                message: this.send_message.message,
                to_user_id: this.selected_invitation.client.id,
                project_id: this.selected_invitation.project?.id
            })
                .then((res) => {
                    this.acceptInvitation(this.selected_invitation);
                    this.loading.save = false;
                    this.$modal.hide('sendInterviewMessageModal');
                    this.$router.push({name: 'account.messages', params: {ref: res.conversation.ref}})
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
    }
}
</script>

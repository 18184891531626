<template>
    <div style="max-width: 100%;">
        <slot/>
    </div>
</template>

<script>

export default {
    props: [
       'index'
    ],
    data: () => ({
        steps: [],
    }),
    created() {
        this.steps = this.$children;
    },
    mounted() {
        this.setSelectedStep()
    },
    watch: {
        index() {
            this.setSelectedStep()
        },
        '$slots.default': function () {
            this.setSelectedStep()
        },
        steps() {
            this.steps = this.$children;
            this.$emit('stepsChange', this.steps.length)
        },
    },
    methods: {
        setSelectedStep() {
            const $stepSlots = this.$slots.default.filter(s => {
                return s.componentOptions?.tag === 'Step'
            })
            this.steps.forEach((step, index) => {
                const slotIndex = $stepSlots.findIndex(s => {
                    return s.componentOptions?.propsData.name == step._props.name
                })
                step.isVisible = this.index == slotIndex;
            });
        },
    }
}
</script>

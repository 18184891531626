<template>
    <form-group :help="$props.help" class="form-input" :label="$props.label">
        <div class="input-group">
            <span class="input-group-text" v-if="!!$slots.prepend"><slot name="prepend"></slot></span>
            <span class="input-group-text" v-else-if="$props.prepend_text">{{ $props.prepend_text }}</span>
            <input :type="visible ? 'text' : 'password'" class="form-control"
                   :class="{'is-invalid' : $props.v ? $props.v.$error : null, 'no-label': !$props.label}"
                   :placeholder="$props.placeholder"
                   :value="$props.value"
                   :disabled="$props.disabled"
                   :readonly="$props.readonly"
                   v-on:input="($event) => {
                       $emit('input', $event.target.value)
                       if($props.v){
                           $props.v.$touch();
                       }
                   }"
                   v-on:change="($event) => {
                       $emit('change', $event.target.value)
                   }"
                   v-on:keyup="($event) => {
                       $emit('keyup', $event)
                   }"
            >
            <span class="input-group-text" v-if="!!$slots.append"><slot name="append"></slot></span>
            <span class="input-group-text" v-else-if="$props.append_text">{{ $props.append_text }}</span>
            <span class="input-group-append" @click="visible = !visible" v-else>
                <div class="input-group-text d-flex align-items-center" :style="{opacity: visible ? 1 : 0.4}">
                    <EyeIcon v-if="visible"/>
                    <EyeIcon v-else/>
                </div>
            </span>
        </div>
        <slot name="help"></slot>
    </form-group>
</template>

<script>
    export default {
        props: ['help', 'label', 'dataClass', 'prepend_text', 'v', 'placeholder', 'value', 'disabled', 'readonly', 'append_text'],
        name: 'FormInputPassword',
        data () {
            return {
                visible: false
            }
        },
    }
</script>

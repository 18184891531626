import Vue from 'vue';
import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/light.css";

export default () => {
    Vue.use(VueTippy, {
        directive: 'tippy',
        defaultProps: {
            allowHTML: true,
            theme: 'light search',
            maxWidth: '100%',
            interactiveVueSocialSharing: true,
            offset: [0, 20],
            hideOnClick: true,
            placement: "bottom",
            interactive: true,
        },
    })
    Vue.component("tippy", TippyComponent);
};

<template>
    <div class="p-details-wrapper" :class="{'--gray-bg' : !this.profile}">
        <div class="p-details" :class="{'loading': loading.data, 'container pt-4 pb-4' : !this.profile}">
            <div class="row" v-if="client">
                <div class="col-md-5 mb-2">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="p-details__info">
                                <div class="p-details__info-actions">
                                    <BlockToggle foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.blocked" type="freelancer" class="mr-2"/>
                                    <GroupToggle foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.groups" type="freelancer"/>
                                </div>
                                <UserAvatar class="mb-3" size="large" :user="client"/>
                                <template v-if="client.isAgency">
<!--                                    <CompanyAvatar v-if="client.company" :company="client.company" class="mb-3" size="large"/>-->
                                    <div class="p-details__info__title">{{ client.company.name }}
                                        <template v-if="client.company.website">
                                            <br>
                                            <div class="p-details__info__subtitle">{{ client.company.website }}</div>
                                        </template>
                                    </div>

                                </template>
                                <template v-else>
<!--                                    <UserAvatar class="mb-3" size="large" :user="client"/>-->
                                    <div class="p-details__info__title">{{ client.fullname }}</div>
                                </template>

                                <div class="p-details__info__desc">
                                    {{ client.description }}
                                </div>
                                <div class="badge badge-secondary-light badge-lg mb-3" v-if="client.attributes.availability">Availability: {{ client.attributes.availability?.value }}</div>
                                <div class="p-details__info__stats">
                                    <span><LocationIcon/> {{ [client.address.city_name, client.address.country_name].join(', ') }}</span>
                                    <span><StarIcon/> {{ client.avgReview ? client.avgReview : '-' }}/5 <template v-if="client.countReview">({{ client.countReview }})</template></span>
                                </div>
                                <Btn class="btn-secondary btn-block mb-2" @click="showSendMessage">Send Message</Btn>
                                <div class="row">
                                    <div class="col-6">
                                        <Btn class="btn-outline-light btn-block mb-2" @click="inviteToApplyToJob">Invite To Apply For a Job</Btn>
                                    </div>
                                    <div class="col-6">
                                        <Btn class="btn-outline-light btn-block mb-2" @click="inviteForInterview">Invite For an Interview</Btn>
                                    </div>
                                </div>
                                <template v-if="client.attributes.consultation?.value">
                                    <Btn class="btn-outline-light btn-block mb-2 " @click="showConsultationModal">Book Consultation Session</Btn>
                                    <div class="p-details__info__footer">30 or 60 minutes</div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2" v-if="client.isAgency">
                        <div class="card-header pb-0">
                            <div class="p-details__card-title">Employees’ BOLG Profiles</div>
                            <div class="p-details__card-subtitle">{{ client.employees ? client.employees.length : 0 }} Total Employees</div>
                        </div>
                        <div class="card-body" v-if="client.employees">
                            <div class="d-flex flex-row justify-content-between align-items-center mb-2" v-for="employee in client.employees">
                                <UserAvatar :display-name="true" size="xsmall" :user="employee" :displayName="true"/>
                                <NuxtLink target="_blank" class="p-details__profile-link" :to="{name: 'freelancers.details', params: {username: employee.username}}">View BOLG Profile</NuxtLink>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2" v-if="client.projects && !client.isAgency">
                        <div class="card-header pb-0">
                            <div class="p-details__card-title">Projects List</div>
                        </div>
                        <div class="card-body">
                            <ProjectInfoCard :project="project" :key="project.id" v-for="project in client.projects"/>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="p-details__specs">
                                <div class="p-details__specs__label">English Language Level</div>
                                <div class="p-details__specs__content">{{ client.attributes.english_language_level?.value }}</div>

                                <div class="p-details__specs__label">Other Language(s)</div>
                                <div class="p-details__specs__content">
                                    <template v-if="client.attributes.other_languages">
                                        {{ client.attributes.other_languages.map(i => i.value).join(', ') }}
                                    </template>
                                    <template v-else>
                                        None
                                    </template>
                                </div>

                                <template v-if="client.isAgency">
                                    <div class="p-details__specs__label">Established date</div>
                                    <div class="p-details__specs__content">{{ client.company.established_date | moment('MMMM DD, YYYY') }}</div>
                                </template>

                                <div class="p-details__specs__label">Color Code Category(s)</div>
                                <div class="p-details__specs__content" v-if="client.attributes.color_code_categories">
                                    <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in client.attributes.color_code_categories" :attr="attr"/>
                                </div>

                                <div class="p-details__specs__label">Services(s)</div>
                                <div class="p-details__specs__content" v-if="client.attributes.services">
                                    <div class="badge badge-secondary-light badge-lg mr-1 mb-1" v-for="service in client.attributes.services">{{ service.value }}</div>
                                </div>

                                <div class="p-details__specs__label">Expertise Level</div>
                                <div class="p-details__specs__content">
                                    <template v-if="client.attributes.expertise_level">
                                        <img :src="client.attributes.expertise_level.icon"/> {{ client.attributes.expertise_level.value }}
                                    </template>
                                </div>

                                <div class="p-details__specs__label">Expertise Area(s)</div>
                                <div class="p-details__specs__content" v-if="client.attributes.expertise_areas?.length">
                                    <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="expertise_area in client.attributes.expertise_areas">{{ expertise_area.value }}</div>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    Any
                                </div>

                                <div class="p-details__specs__label">Total Hours of Work</div>
                                <div class="p-details__specs__content">{{ client.work_hours }} hours</div>

                                <div class="p-details__specs__label">Total Earnings</div>
                                <div class="p-details__specs__content">{{ client.attributes.total_earnings?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Number of Reviews</div>
                                <div class="p-details__specs__content">{{ client.countReview }}</div>

                                <div class="p-details__specs__label">Member Since</div>
                                <div class="p-details__specs__content">{{ client.member_since | moment('MMMM DD, YYYY') }}</div>

                                <div class="p-details__specs__label">Response Time</div>
                                <div class="p-details__specs__content">{{ client.attributes.response_time?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Last Online</div>
                                <div class="p-details__specs__content">{{ client.online_at | onlineAtFormat }}</div>

                                <div class="p-details__specs__label">Hourly Rate</div>
                                <div class="p-details__specs__content" v-if="client.hourly_rate"><DolarCircleIcon height="20"/> ${{ client.hourly_rate }}/hr</div>
                                <div class="p-details__specs__content" v-else>-</div>

                                <template v-if="client.isAgency">
                                    <div class="p-details__specs__label">Minimum Project Size</div>
                                    <div class="p-details__specs__content" v-if="client.min_project_price"><DolarCircleIcon height="20"/> ${{ client.min_project_price }}/hr</div>
                                    <div class="p-details__specs__content" v-else>-</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 mb-2">
                    <div class="card" style="padding-top: 18px;">
                        <div class="p-details__tabs">
                            <div role="tablist" class="nav">
                                <ClientOnly>
                                    <Swiper
                                            style="width: 100%"
                                            ref="tabsSlider"
                                            :navigation="true"
                                            slidesPerView="auto"
                                            :slidesOffsetBefore="32"
                                            :slidesOffsetAfter="32"
                                            :spaceBetween="16"
                                            @swiper="onSwiper"
                                    >
                                        <SwiperSlide v-if="client.biography?.length">
                                            <span class="nav-item">
                                                <a href="#bio" @click.prevent="scrollToSection('bio')" class="nav-link" :class="{'active': currentSection === 'bio'}">{{ client.isAgency ? 'Brief Description' : 'Bio' }}</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.employment_history?.length">
                                            <span class="nav-item">
                                                <a href="#employment-history" @click.prevent="scrollToSection('employment-history')" class="nav-link" :class="{'active': currentSection === 'employment-history'}">Employment History</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.education_history?.length">
                                            <span class="nav-item">
                                                <a href="#education" @click.prevent="scrollToSection('education')" class="nav-link" :class="{'active': currentSection === 'education'}">Education</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.skills?.length">
                                            <span class="nav-item">
                                                <a href="#skills" @click.prevent="scrollToSection('skills')" class="nav-link" :class="{'active': currentSection === 'skills'}">Skills and Experience</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.isAgency && client.projects">
                                            <span class="nav-item">
                                                <a href="#projects" @click.prevent="scrollToSection('projects')" class="nav-link" :class="{'active': currentSection === 'projects'}">Projects List</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.certificates?.length">
                                            <span class="nav-item">
                                                <a href="#training" @click.prevent="scrollToSection('training')" class="nav-link" :class="{'active': currentSection === 'training'}">Training Certificates</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.publications?.length">
                                            <span class="nav-item">
                                                <a href="#publications" @click.prevent="scrollToSection('publications')" class="nav-link" :class="{'active': currentSection === 'publications'}">Publications</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="client.profile.awards?.length">
                                            <span class="nav-item">
                                                <a href="#awards" @click.prevent="scrollToSection('awards')" class="nav-link" :class="{'active': currentSection === 'awards'}">Awards</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <span class="nav-item">
                                                <a href="#history" @click.prevent="scrollToSection('history')" class="nav-link" :class="{'active': currentSection === 'history'}">BOLG's Job History</a>
                                            </span>
                                        </SwiperSlide>
                                    </Swiper>
                                </ClientOnly>
                            </div>
                        </div>
                        <div class="p-details__section" id="bio" v-if="client.biography?.length">
                            <div class="p-details__section-title">
                                {{ client.isAgency ? 'Brief Description' : 'Bio' }}
                            </div>
                            {{ client.biography }}
                        </div>
                        <div class="p-details__section" id="employment-history" v-if="client.profile.employment_history?.length">
                            <div class="p-details__section-title">
                                Employment History
                            </div>
                            <div class="p-details__info-item" v-for="employment in client.profile.employment_history">
                                <div class="p-details__info-item__icon">
                                    <EmploymentIcon height="18" width="18"/>
                                </div>
                                <div class="p-details__info-item__body">
                                    <div class="p-details__info-item__title">{{ employment.name }}</div>
                                    <div class="p-details__info-item__subtitle">{{ employment.company }}</div>
                                    <div class="p-details__info-item__stats">
                                        <span>{{ employment.period.from | moment('MMM YYYY') }} - <template v-if="employment.period.to">{{ employment.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></span>
                                        <span>{{ getDatesDiff(employment.period.from, employment.period.to) }}</span>
                                    </div>

                                </div>
                                <div class="p-details__info-item__actions">
                                    <div class="badge badge-secondary-light">{{ employment.type }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="p-details__section" id="education" v-if="client.profile.education_history?.length">
                            <div class="p-details__section-title">
                                Education
                            </div>
                            <div class="p-details__info-item" v-for="education in client.profile.education_history">
                                <div class="p-details__info-item__icon">
                                    <EducationIcon height="18" width="18"/>
                                </div>
                                <div class="p-details__info-item__body">
                                    <div class="p-details__info-item__title">{{ education.institute }}</div>
    <!--                                <div class="p-details__info-item__subtitle">{{ education.institute }}</div>-->
                                    <div class="p-details__info-item__stats">
                                        <span>{{ education.level }}</span>
                                        <span>{{ education.period.from | moment('MMM YYYY') }} - <template v-if="education.period.to">{{ education.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></span>
                                    </div>
                                    <div class="p-details__info-item__content">
                                        {{ education.description }}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="p-details__section" id="skills" v-if="client.profile.skills?.length">
                            <div class="p-details__section-title">
                                Skills and Experience
                            </div>
                            <p>
                                {{ client.profile.skills }}
                            </p>
<!--                            <div class="p-details__docs-wrapper">-->
<!--                                <DocumentCard :key="document.id" :document="document" v-for="document in client.profile.skill_documents" />-->
<!--                            </div>-->
                        </div>
                        <div class="p-details__section" id="projects" v-if="client.isAgency && client.projects">
                            <div class="p-details__section-title">
                                Projects List
                            </div>
                            <div class="c-project-info-card__wrapper" v-for="project in client.projects">
                                <ProjectInfoCard :project="project" :key="project.id"/>
                            </div>
                        </div>
                        <div class="p-details__section" id="training" v-if="client.profile.certificates?.length">
                            <div class="p-details__section-title">
                                Training Certificates
                            </div>
                            <div class="p-details__info-item" v-for="certificate in client.profile.certificates">
                                <div class="p-details__info-item__icon">
                                    <TrainingIcon height="18" width="18"/>
                                </div>
                                <div class="p-details__info-item__body">
                                    <div class="p-details__info-item__title">{{ certificate.title }}</div>
                                    <div class="p-details__info-item__stats">
                                        <span>{{ certificate.issued_by }}</span>
                                    </div>
                                    <div class="p-details__info-item__content">
                                        {{ certificate.description }}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="p-details__section" id="publications" v-if="client.profile.publications?.length">
                            <div class="p-details__section-title">
                                Publications
                            </div>
                            <div class="p-details__info-item" v-for="publication in client.profile.publications">
                                <div class="p-details__info-item__icon">
                                    <PublicationIcon height="18" width="18"/>
                                </div>
                                <div class="p-details__info-item__body">
                                    <div class="p-details__info-item__title">{{ publication.authors.join(', ') }} ({{ publication.year }})</div>
                                    <div class="p-details__info-item__subtitle">{{ publication.title }}</div>
                                    <div class="p-details__info-item__stats">
    <!--                                    <span></span>-->
                                        <span>{{ publication.year }}</span>
                                    </div>
                                    <div class="p-details__info-item__content">
                                        {{ publication.description }}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="p-details__section" id="awards" v-if="client.profile.awards?.length">
                            <div class="p-details__section-title">
                                Awards
                            </div>
                            <div class="p-details__info-item" v-for="award in client.profile.awards">
                                <div class="p-details__info-item__icon">
                                    <CheckCircleIcon height="18" width="18"/>
                                </div>
                                <div class="p-details__info-item__body">
                                    <div class="p-details__info-item__title">{{ award.title }} {{ award.date | moment('YYYY') }}</div>
                                    <div class="p-details__info-item__subtitle">{{ award.awarded_by }}</div>
                                    <div class="p-details__info-item__content">
                                        {{ award.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-details__section" id="history" v-if="reviews?.length">
                            <div class="p-details__section-title">
                                BOLG's Job History
                            </div>
                            <div class="p-details__review" v-for="review in reviews">
                                <div class="p-details__review__rating">
                                    <img :src="review.icon" height="24" width="24" alt=""> {{ review.label }}
                                </div>
                                <div class="p-details__review__stats mb-2">
                                    <span style="white-space: nowrap"><Stars :value="review.stars"/> {{ review.stars.toFixed(1) }}</span>
                                    <span class="d-inline-flex align-items-center">Reviewed {{ review.date | momentAgo }} By <UserAvatar class="d-inline-flex ml-1" :displayName="true" :user="review.user" size="xs"/></span>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Do you recommend other People to work with this freelancer in the future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light">{{ review.stats.recommend == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Will you be working with this Biotech Expert in the Future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light">{{ review.stats.come_back == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>
                                <template v-if="review.project">
                                    <div class="p-details__review__title">{{ review.project.name }}</div>
                                    <div class="p-details__review__description">{{ review.project.description }}</div>
                                    <div class="p-details__review__stats">
                                        <span>Duration: <span>5-6 Days</span></span>
                                        <span>Total Price: <span>$400.00</span></span>
                                    </div>
                                </template>
                            </div>
                            <div class="text-center" v-if="total_reviews - reviews.length > 0">
                                <Btn class="btn-light" @click="loadMoreReviews" :loading="loading.load_more">More reviews ({{ total_reviews - reviews.length }})</Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="sendMessageModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('sendMessageModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Send message</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 480px">
                            <FormTextarea class="mb-2" label="Message" v-model="send_message.message"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('sendMessageModal')">Cancel</Btn>
                            <Btn :loading="loading.save" class="btn btn-lg btn-secondary --w150" @click="sendMessage()">Send</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="sendInterviewMessageModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('sendInterviewMessageModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Invite for an interview</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 380px">
                            <ProjectSelect placeholder="Select Project"
                                           type="job"
                                           v-model="send_message.project"/>
                            <FormTextarea rows="14" class="mb-2" label="Message" v-model="send_message.message"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('sendInterviewMessageModal')">Cancel</Btn>
                            <Btn :loading="loading.save" class="btn btn-lg btn-secondary --w150" @click="sendMessage()">Send</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="sendInvitationModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('sendInvitationModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Invite to apply for job</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 380px">
                            <ProjectSelect placeholder="Select Project"
                                           type="job"
                                           :statuses="[1]"
                                           v-model="invitation.project"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('sendInvitationModal')">Cancel</Btn>
                            <Btn :loading="loading.save" class="btn btn-lg btn-secondary --w150" @click="sendInvitationToApplyForJob()">Send</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <ModalSidebar name="ConsultationModal" v-if="client">
            <BookConsultation :client="client"/>
        </ModalSidebar>
    </div>
</template>

<script>

import UserAvatar from "~/components/UserAvatar.vue";
import Btn from "~/components/Btn.vue";
import ProjectInfoCard from "~/components/ProjectInfoCard.vue";
import GroupToggle from "~/components/GroupToggle.vue";
import BlockToggle from "~/components/BlockToggle.vue";
import ColorCodeAttribute from "~/components/ColorCodeAttribute.vue";
import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import dayjs from "dayjs";
import Stars from "../../components/Stars.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import FormRadio from "@/components/FormRadio.vue";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import ProjectSelect from "@/components/ProjectSelect.vue";
import {mapState} from "vuex";
import CompanyAvatar from "@/components/CompanyAvatar.vue";
import DocumentCard from "@/components/DocumentCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import BookConsultation from "@/pages/BookConsultation.vue";

export default {
    name: 'FreelancerDetails',
    components: {
        BookConsultation,
        ModalSidebar,
        DocumentCard,
        CompanyAvatar,
        ProjectSelect,
        FormRadio, FormTextarea,
        Stars, Tab, Tabs, ColorCodeAttribute, BlockToggle, GroupToggle, ProjectInfoCard, Btn, UserAvatar,
        CloseIcon
    },
    props: ['modal', 'profile', 'preview'],
    data() {
        return {
            loaded: false,
            loading: {
                data: false,
                reviews: false,
                load_more: false,
                save: false,
            },
            client: null,
            reviews: [],
            total_reviews: 0,
            currentSection: null,
            scrollElement: null,
            swiper: null,
            send_message: {
                message: '',
                project: null
            },
            invitation: {
                project_id: null
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch();
        }
        if (this.modal) {
            this.scrollElement = document.querySelector('.modal-sidebar.vm--container.scrollable');
        } else {
            this.scrollElement = window;
        }
        this.scrollElement.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy() {
        if (this.scrollElement) {
            this.scrollElement.removeEventListener('scroll', this.scrollHandler, false)
        }
    },
    activated () {
        // this.sendToAnalytics()
    },
    fetch() {
        if (this.preview && this.profile) {
            this.client = JSON.parse(JSON.stringify(this.profile));
            this.getFrontAttributes(this.profile.attributes);
            return;
        }
        this.getData();
    },
    watch: {
        currentSection(value) {
            if (this.swiper && value) {
                const sections = document.querySelectorAll('.p-details__section');
                const section = document.querySelector('.p-details__section#' + value);
                const index = Array.prototype.indexOf.call(sections, section);
                this.swiper.slideTo(index + 1);
            }
        },
    },
    methods: {
        async verifyUser() {
            if (!this.user.confirmed) {
                this.$toast.error('You need to confirm your email.');
                this.$router.push({name: 'auth.confirm'});
                return;
            }
            if (this.user.type !== 'client') {
                await this.$dialog.confirm('Are you a client looking for biotechnology expert?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.$toast.error('You need to login using a Client account to communicate with experts.');
                        this.$router.push({name: 'auth.login'});
                        return false;
                    });
            }
            return true;
        },
        onSwiper(swiper) {
            this.swiper = swiper;
            this.currentSection = document.querySelectorAll('.p-details__section')[0]?.id
        },
        scrollHandler() {

            const elements = document.querySelectorAll('.p-details__section')
            let currentSection = elements[0];
            elements.forEach((element, index) => {
                const position = element.getBoundingClientRect().y - 40;
                if (position < 0) {
                    currentSection = element;
                }
            })
            this.currentSection = currentSection ? currentSection.id : null
            // console.log(this.currentSection)
        },
        getData () {
            this.loading.data = true;
            const username = this.profile && this.modal ? this.profile.username : this.$route.params.username;
            return this.$axios.post('/clients/details', { username })
                .then((res) => {
                    this.client = res.client
                    this.getReviews()
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
        getReviews () {
            this.loading.reviews = true;
            return this.$axios.post('/clients/reviews', { client_id: this.client.id })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.reviews = false;
                })
                .catch((error) => {
                    this.loading.reviews = false;
                });
        },
        loadMoreReviews() {
            this.loading.load_more = true;
            return this.$axios.post('/clients/reviews', {
                client_id: this.client.id,
                after_id: this.reviews[this.reviews.length -1]?.id
            })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.load_more = false;
                })
                .catch((error) => {
                    this.loading.load_more = false;
                });
        },
        getFrontAttributes (attributes) {
            this.loading.data = true;
            return this.$axios.post('/get-front-attributes', {
                attributes: attributes
            })
                .then((res) => {
                    this.client.attributes = res.attributes
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        async inviteForInterview() {
            if (!await this.verifyUser()) {
                return;
            }
            this.send_message.project = null;
            this.send_message.message = '' +
                `Hey ${this.client.firstname}\n` +
                'Just wanted to reach out and invite you to an interview. It will take place via Zoom/Teams/Google Meet, I will provide a link later.\n' +
                '\n' +
                'I\'m available on: #Day 1, interval A, B, C, D | #Day 2, interval A, B.\n' +
                '\n' +
                'Looking forward to receiving a message from you.\n' +
                '\n' +
                'Best regards,\n' +
                `${this.user.firstname}`;
            this.$modal.show('sendInterviewMessageModal');
        },
        async showSendMessage() {
            if (!await this.verifyUser()) {
                return;
            }
            this.send_message.message = '';
            this.send_message.project = null;
            this.$modal.show('sendMessageModal')
        },
        sendMessage () {
            this.loading.save = true;
            return this.$axios.post('/messaging/send-message', {
                message: this.send_message.message,
                to_user_id: this.client.id,
                project_id: this.send_message.project?.id
            })
                .then((res) => {
                    this.loading.save = false;
                    this.$modal.hide('sendMessageModal');
                    this.$modal.hide('sendInterviewMessageModal');
                    this.$router.push({name: 'account.messages', params: {ref: res.conversation.ref}})
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        async inviteToApplyToJob() {
            if (!await this.verifyUser()) {
                return;
            }
            this.invitation.project_id = null;
            this.$modal.show('sendInvitationModal');
        },
        async showConsultationModal() {
            if (!await this.verifyUser()) {
                return;
            }
            this.invitation.project_id = null;
            this.$modal.show('ConsultationModal');
        },
        sendInvitationToApplyForJob() {
            this.loading.save = true;
            this.$axios.post("/account/invitations/send", {
                type: 'project',
                user_id: this.client.id,
                project_id: this.invitation.project.id,
            })
                .then((res) => {
                    this.invitation = {
                        project: null,
                    };
                    this.$modal.hide('sendInvitationModal');
                    this.loading.save = false;
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        scrollToSection(id) {
            // console.log(document.getElementById(id).getBoundingClientRect())
            if(this.modal) {
                // document.querySelector('.modal-sidebar.vm--container.scrollable').scrollTop = document.getElementById(id).getBoundingClientRect().top;
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
            }else{
                // console.log(document.getElementById(id).offsetTop + 88)
                window.scroll({
                    top: document.getElementById(id).offsetTop + 88,
                    behavior: 'smooth'
                });
            }
        },
        getDatesDiff(date_from, date_to) {
            date_to = date_to ? dayjs(date_to) : dayjs();
            const months_diff = date_to.diff(date_from, 'month')
            if (months_diff) {
                return `${months_diff} months`;
            }
        }
    }
}
</script>



(function (Vue) {
    const prefix = '';

    const permissions = {
        'super_admin': ['edit_members', 'edit_account_status'],
        'editor_admin': []
    }

    const Auth = {
        install: function (Vue, options) {
              // this.$cookies = options[0]
              Vue.prototype.$auth = this
              Vue.$auth = this
        },
        setRole: function(role) {
            localStorage.setItem(prefix + 'role', role);
            // this.$cookies.set(prefix + 'role', role);
        },
        getRole: function() {
            return localStorage.getItem(prefix + 'role');
            // this.$cookies.get(prefix + 'role');
        },
        isRole: function (role) {
            let current_role = typeof localStorage !== 'undefined' ? localStorage.getItem(prefix + 'role') : null;
            // let token = this.$cookies.get(prefix + 'profileId');

            if (!current_role) {
                return true;
            }
            return current_role === role;
        },
        can(permission) {
            const role = this.getRole();
            if (role === 'main' || permissions[role] == 'undefined') {
                return true;
            }
            return permissions[role].indexOf(permission) > -1;
        },
        setToken: function (token) {
            localStorage.setItem(prefix + 'authToken', token);
            // this.$cookies.set(prefix + 'authToken', token);
        },
        setProfile: function (profile) {
            if (profile) {
                localStorage.setItem(prefix + 'profileId', profile.id);
            }else{
                localStorage.removeItem(prefix + 'profileId');
            }
            // this.$cookies.set(prefix + 'profileId', token);
        },
        destroyToken: function () {
            this.setRole('public')
            localStorage.removeItem(prefix + 'authToken');
            // this.$cookies.remove(prefix + 'authToken');
            localStorage.removeItem(prefix + 'profileId');
            // this.$cookies.remove(prefix + 'profileId');
        },
        getToken: function () {
            let token = typeof localStorage !== 'undefined' ? localStorage.getItem(prefix + 'authToken') : null;
            // let token = this.$cookies.get(prefix + 'authToken');

            if (!token) {
                return null;
            }
            return token;
        },
        getProfileId: function () {
            let profileId = typeof localStorage !== 'undefined' ? localStorage.getItem(prefix + 'profileId') : null;
            // let token = this.$cookies.get(prefix + 'profileId');

            if (!profileId) {
                return null;
            }
            return profileId;
        },
        setCurrentRoute(route, id) {
            if (route.name === 'login' || route.name === 'register') {
                return false;
            }
            if (typeof sessionStorage === 'undefined') {
                return;
            }
            sessionStorage.setItem(prefix + 'redirectUserId', id);
            sessionStorage.setItem(prefix + 'redirectLoginPathName', route.name);
            sessionStorage.setItem(prefix + 'redirectLoginParams', JSON.stringify(route.params));
            sessionStorage.setItem(prefix + 'redirectLoginQuery', JSON.stringify(route.query));

            // this.$cookies.set(prefix + 'redirectUserId', id);
            // this.$cookies.set(prefix + 'redirectLoginPathName', route.name);
            // this.$cookies.set(prefix + 'redirectLoginParams', JSON.stringify(route.params));
            // this.$cookies.set(prefix + 'redirectLoginQuery', JSON.stringify(route.query));
        },
        redirectToLastRoute(router, id) {
            if (typeof sessionStorage === 'undefined') {
                return;
            }
            if(sessionStorage.getItem(prefix + "redirectLoginPathName") && sessionStorage.getItem(prefix + "redirectUserId") == id){
                router.push({ name: sessionStorage.getItem(prefix + "redirectLoginPathName"),  params: JSON.parse(sessionStorage.getItem(prefix + "redirectLoginParams")),  query: JSON.parse(sessionStorage.getItem(prefix + "redirectLoginQuery"))});
                sessionStorage.removeItem(prefix + "redirectLoginPathName");
                sessionStorage.removeItem(prefix + "redirectLoginParams");
                sessionStorage.removeItem(prefix + "redirectLoginQuery");
            } else {
                router.push({name: 'home'});
            }

            // if(this.$cookies.get(prefix + "redirectLoginPathName") && this.$cookies.get(prefix + "redirectUserId") == id){
            //     router.push({ name: this.$cookies.get(prefix + "redirectLoginPathName"),  params: JSON.parse(this.$cookies.get(prefix + "redirectLoginParams")),  query: JSON.parse(this.$cookies.get(prefix + "redirectLoginQuery"))});
            //     this.$cookies.remove(prefix + "redirectLoginPathName");
            //     this.$cookies.remove(prefix + "redirectLoginParams");
            //     this.$cookies.remove(prefix + "redirectLoginQuery");
            // } else {
            //     router.push({name: 'home'});
            // }
        }
    }

    if (typeof exports == "object") {
        module.exports = Auth;
    }
})();


<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Projects List</div>
            <div class="l-account-content__toolbar">
                <ToggleNav :options="[
                        {label: 'Achieved', value: 3},
                        {label: 'Ongoing', value: 2},
                        {label: 'Posted', value: 1},
                        {label: 'Saved', value: 0}
                    ]"
                    v-model="filters.status">
                </ToggleNav>
                <NuxtLink class="btn btn-secondary mt-3" :to="{name: 'account.jobs.create'}"><PlusWhiteIcon/> Create a Project</NuxtLink>
            </div>
        </div>
        <div :class="{'loading': loading.data}">
            <div class="projects-card-list" v-if="data && data.data && data.data.length > 0">
                <JobCard class="mb-2" :key="job.id" v-for="job in data.data" :job="job">
                    <template #actions>
                        <JobDropdown @onReload="getData" @onViewDetails="viewJobDetails(job)" :job="job"/>
                    </template>
                </JobCard>
            </div>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No projects</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="JobDetailsModal" :openLink="{name: 'jobs.details', params: {slug: selected_job?.slug}}">
            <JobDetails :item="selected_job" :modal="true"/>
        </ModalSidebar>
    </div>

</template>

<script>
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import {modelListMixin} from "~/mixins/modelListMixin";
import JobCard from "@/pages/Jobs/JobCard.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import JobDetails from "@/pages/Jobs/JobDetails.vue";
import AccountJobSimpleCard from "@/components/AccountJobSimpleCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";

export default {
    name: "AccountJobs",
    mixins: [modelListMixin],
    components: {ModalSidebar, AccountJobSimpleCard, JobDetails, JobDropdown, JobCard, ToggleNav, Btn },
    data() {
        return {
            filters: {
                type: 'job',
                status: 2,
            },
            selected_job: null,
        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.data = null;
            this.getPage();
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects', this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        viewJobDetails(job) {
            if (job.status >= 2) {
                this.$router.push({name: 'account.jobs.details', params: {id: job.id}})
            }else{
                this.selected_job = job;
                this.$modal.show('JobDetailsModal')
            }
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

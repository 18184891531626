<template>
    <client-only>
        <tippy ref="dropdown"
               class="dropdown search-dropdown"
               trigger="manual"
               maxWidth="650"
               placement="bottom-center"
               theme="light"
               :inlinePositioning="true"
               :interactive="true"
               :arrow="true"
               :hideOnClick="false"
        >
            <template #trigger>
                <FormInput class="l-page-hero__search"
                           @focus="show"
                           @blur="hide"
                           :placeholder="placeholder"
                           @keypress.enter="search"
                           v-model="term"
                           style="margin-left: auto;margin-right: auto;">
                    <template v-slot:prepend><SearchIcon class="--black-icon"/></template>
                    <template v-slot:appendBtn>
                        <Btn class="btn btn-secondary" @click="search" :loading="loading">{{ search_text }}</Btn>
                    </template>
                </FormInput>
            </template>

            <div class="c-search-suggestions">
                <div class="c-search-suggestions_list" v-if="data.length">
                    <NuxtLink :key="result.id" :to="getResultUrl(result)" class="c-search-suggestions_list-item" v-for="result in data">
                        {{ result.name }}
                    </NuxtLink>
                </div>
                <div class="c-search-suggestions_list" v-else-if="!data.length && term.length && !loading_suggestions">
                    <div class="text-center">No results</div>
                </div>
                <div class="c-search-suggestions_list" v-else-if="!loading_suggestions">
                    <div class="text-center">Enter keywords to search</div>
                </div>
                <div class="c-search-suggestions_list" :class="{'loading': loading_suggestions}" v-else></div>
            </div>
        </tippy>
    </client-only>
</template>

<script>
import debounce from 'lodash/debounce'
import FormInput from "@/components/FormInput.vue";
import Btn from "@/components/Btn.vue";

export default {
    name: 'SearchInput',
    props: {
        'url': {
            default: null
        },
        'value': {
            default: null
        },
        'filters': {
            default: () => {return {}}
        },
        'placeholder': {
            default: 'Keyword(s)'
        },
        'search_text': {
            default: 'Search'
        },
        'loading': {
            default: false
        },
    },
    components: {Btn, FormInput},
    data () {
        return {
            data: [],
            loading_suggestions: false,
            term: '',
            getDataRequest: null
        }
    },
    methods: {
        show() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.show()
            }
        },
        hide() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
        search() {
            this.$emit('search');
            this.hide();
        },
        getResultUrl(result) {
            if (!result) {
                return null;
            }
            switch (result.type) {
                case 'freelancer':
                    return {name: 'freelancers.details', params: {username: result.username}};
                case 'company':
                    return {name: 'companies.details', params: {username: result.username}};
                case 'project':
                    return {name: 'projects.details', params: {slug: result.slug}};
                case 'job':
                    return {name: 'jobs.details', params: {slug: result.slug}};

            }
        },
        getData: debounce(function (query) {
            if(query === '') {
                this.data = [];
                return;
            }
            if (this.getDataRequest) {
                if (!this.getDataRequest.signal.aborted) {
                    this.getDataRequest.abort();
                }
            }
            this.getDataRequest = new AbortController();

            this.loading_suggestions = true;
            let filters = {...this.filters, ...{term: this.term}};
            return this.$axios.post(this.url, filters,
                {
                    signal: this.getDataRequest.signal
                })
                .then((res) => {
                    this.data = res.data
                    this.loading_suggestions = false;
                })
                .catch((error) => {
                    console.error({error});
                    this.loading_suggestions = false;
                });
        }, 500),
    },
    mounted() {
        if(this.value){
            this.term = this.value;
        }
        this.getData(this.term);
    },
    watch: {
        term() {
            this.getData(this.term);
            this.$emit('input', this.term);
        },
        value(a) {
            this.term = a;
        }
    },
};
</script>


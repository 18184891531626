<template>
    <div class="c-project-card" @click="onSelect">
        <div class="d-flex mb-2">
            <div class="flex-1">
                <div class="c-project-card__title">{{ project.title }}</div>
                <div class="c-job-card__stats mt-1" v-if="project.stats">
                    <template v-if="project.status == 2">
                        <span>Paid: <span class="text-secondary">{{ project.stats?.paid | toPrice }}</span></span>
                        <span>In Escrow: <span class="text-secondary">{{ project.stats?.escrow | toPrice }}</span></span>
                        <span>Started: <span class="text-secondary">{{ project.stats?.started_at | moment('DD MMM, YYYY') }}</span></span>
                    </template>
                    <template v-if="project.status == 1">
                        <span>Saved: <span class="text-secondary">{{ project.stats?.saves }}</span></span>
                        <span>Viewed: <span class="text-secondary">{{ project.stats?.views }}</span></span>
                        <span>Offers: <span class="text-secondary">{{ project.stats?.applications }}</span></span>
                    </template>
                </div>
            </div>
            <div v-if="!!$slots.actions">
                <slot name="actions"></slot>
            </div>
            <GroupToggle v-else class="ml-3" foreign_model="Modules\Projects\Entities\Project" :foreign_id="project.id" v-model="project.groups" type="project"/>
        </div>
        <div class="c-project-card__info" v-if="project.client">
            <div>
                <UserAvatar :user="project.client" style="flex: 1" :displayName="true"/>
            </div>
        </div>
        <div class="c-job-card__stats">
            <span v-if="project.attributes.service_level">{{ project.attributes.service_level.value }}</span>
            <template v-if="project.payment_method === 'hourly'">
                <span v-if="project.price[0]">Hourly Rate: {{ project.price[0] | toPrice(0) }}-{{ project.price[1] | toPrice(0) }}</span>
                <span v-if="project.hours[0]">Hours required: {{ project.hours[0] }} to {{ project.hours[1] }} h</span>
                <span v-if="project.duration[0]">Duration: {{ project.duration[0] }} to {{ project.duration[1] }} Days</span>
            </template>
            <template v-if="project.payment_method === 'fixed'">
                <span v-if="project.price[0]">Est. Budget: {{ project.price[0] | toPrice(0) }}-{{ project.price[1] | toPrice(0) }}</span>
                <span v-if="project.duration[0]">Delivery Time: {{ project.duration[0] }} to {{ project.duration[1] }} Days</span>
            </template>
        </div>
        <div class="c-project-card__content truncate-overflow-sm">
            {{ project.description }}
        </div>
        <div class="c-project-card__info">
            <div class="c-project-card__info" v-if="project.client?.attributes?.color_code_categories">
                <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in project.client.attributes.color_code_categories.slice(0, 1)" :attr="attr"/>
                <span class="badge badge-sm badge-secondary-light px-1" v-if="project.client.attributes.color_code_categories.length > 1">+{{ project.client.attributes.color_code_categories.length - 1}}</span>
            </div>
            <div v-if="project.client.address">
                <div class="c-project-card__info-icon">
                    <LocationIcon height="20" width="20"/>
                </div>
                <div class="c-project-card__info-title">{{ [project.client.address.city_name, project.client.address.country_name].join(', ') }}</div>
            </div>
            <div>
                <div class="c-project-card__info-icon">
                    <StarIcon height="16" width="16"/>
                </div>
                <div class="c-project-card__info-title">{{ project.client.avgReview }}/5 ({{ project.client.countReview }})</div>
            </div>
            <div v-if="project.client.payment_verified">
                <div class="c-project-card__info-icon">
                    <CheckCircle2Icon height="16" width="16"/>
                </div>
                <div class="c-project-card__info-title">Payment Verified</div>
            </div>
        </div>
        <div class="c-project-card__tags-wrapper" v-if="project.client?.attributes?.services?.length">
            <span class="c-project-card__tags-title">Services:</span>
            <ClientOnly>
                <ItemCounter class="c-project-card__tags">
                    <span class="badge badge-sm badge-secondary-light mr-1" v-for="service in project.client.attributes.services">{{ service.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>

        <div class="c-project-card__tags mb-2" v-if="project.client?.attributes?.expertise_level?.value">
            <span class="c-freelancer-card__tags-title">Expertise Level:</span>
            <span><img :src="project.client.attributes.expertise_level.icon"/> {{ project.client.attributes.expertise_level.value }}</span>
        </div>

        <div class="c-project-card__tags-wrapper" v-if="project.client?.attributes?.expertise_areas?.length">
            <span class="c-project-card__tags-title">Expertise Area(s):</span>
            <ClientOnly>
                <ItemCounter class="c-project-card__tags">
                    <span class="badge badge-sm badge-secondary-light mr-1" v-for="area in project.client.attributes.expertise_areas">{{ area.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>

    </div>
</template>

<script>


import UserAvatar from "../../components/UserAvatar.vue";
import GroupIcon from "../../components/GroupToggle.vue";
import ColorCodeAttribute from "../../components/ColorCodeAttribute.vue";
import CheckCircle2Icon from "~/assets/icons/check-circle2.svg?inline";
import GroupToggle from "@/components/GroupToggle.vue";
import ItemCounter from "@/components/ItemCounter.vue";

export default {
    name: 'ProjectCard',
    components: {ItemCounter, GroupToggle, ColorCodeAttribute, GroupIcon, UserAvatar, CheckCircle2Icon},
    props: ['project'],
    methods: {
        onSelect() {
            this.$emit('onSelect', this.project)
        },
    }
}
</script>

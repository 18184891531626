<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Offers ({{ data ? data.total : 0 }})</div>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :data="data.data"
                    :columns="['client', 'project', 'price', 'duration', 'hours_required', 'date']">
                    <template #client="{row}">
                        <div @click="viewOfferDetails(row)" style="cursor: pointer">
                            <UserAvatar size="medium" :user="row.project.client" :subtitle="row.project.client.username" :displayName="true" :badge="row.invited ? 'Invited for Project' : null"></UserAvatar>
                        </div>
                    </template>
                    <template #price="{row}">
                        <template v-if="row.payment_method === 'hourly'">
                            {{ row.price | toPrice }} / h
                        </template>
                        <template v-else-if="row.payment_method === 'fixed'">
                            {{ row.price | toPrice }}
                        </template>
                    </template>
                    <template #project="{row}">
                        {{ row.project.title }}
                    </template>
                    <template #duration="{row}">
                        {{ row.duration_min }} - {{ row.duration_max }} Days
                    </template>
                    <template #hours_required="{row}">
                        {{ row.hours_min }} - {{ row.hours_max }} h/{{ row.hours_type }}
                    </template>
                    <template #date="{row}">
                        {{ row.created_at | moment('DD.MM.YYYY') }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
                        <template v-if="row.status === 0">
                            <Btn @click="cancelOffer(row)" :loading="row.loading"  class="btn-secondary">Cancel</Btn>
                        </template>
                        <template v-else-if="row.status === -2">
                            <span class="mr-2"><DeclinedIcon class="mr-1"/> Withdrawn</span>
                        </template>
                        <template v-else-if="row.status === -1">
                            <span class="mr-2"><DeclinedIcon class="mr-1"/> Declined</span>
                            <Btn @click="openRejectReasonModal(row)" class="btn-outline-light">View Reason</Btn>
                        </template>
                        <template v-else-if="row.status === 1">
                            <span class="mr-2"><AcceptedIcon class="mr-1"/> Accepted</span>
                            <ProjectOfferDropdown :offer="row" @onViewDetails="viewOfferDetails(row)" @onReload="getData"/>
                        </template>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No offers</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="ProjectDetailsModal" :openLink="{name: 'projects.details', params: {slug: project?.slug}}">
            <ProjectDetails :item="project" :modal="true"/>
        </ModalSidebar>
        <ModalSidebar name="ProjectOfferDetailsModal">
            <OfferDetails :item="selected_offer" @onCancel="cancelOffer"/>
        </ModalSidebar>
        <modal name="RejectReasonModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectReasonModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Reject Reason</h4>
                    </div>
                    <div class="modal-body">
                        {{ reject_reason }}
                    </div>
                </div>
            </div>
        </modal>
    </div>

</template>

<script>
import PlusWhiteIcon from "~/assets/icons/plus.svg?inline";
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import {modelListMixin} from "~/mixins/modelListMixin";
import ProjectCard from "@/pages/Projects/ProjectCard.vue";
import ProjectDropdown from "@/components/ProjectDropdown.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import OpenBlueIcon from "~/assets/icons/open-blue.svg?inline";
import OfferDetails from "@/pages/Account/OfferDetails.vue";
import FormInput from "@/components/FormInput.vue";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import FormTextarea from "@/components/FormTextarea.vue";
import FormRadio from "@/components/FormRadio.vue";
import ProjectDetails from "@/pages/Projects/ProjectDetails.vue";
import VueTable from "@/components/VueTable.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import ProjectOfferDropdown from "@/components/ProjectOfferDropdown.vue";

export default {
    name: "AccountOffers",
    mixins: [modelListMixin],
    components: {
        ProjectOfferDropdown,
        ModalSidebar,
        VueTable,
        FormRadio, FormTextarea, FormInput, ProjectDetails, OfferDetails, UserAvatar, ProjectDropdown, ProjectCard, ToggleNav,
        PlusWhiteIcon, Btn, OpenBlueIcon,  CloseIcon },
    data() {
        return {
            project: null,
            loading: {
                info: false,
                reject: false,
            },
            selected_offer: null,
            reject_reason: '',
        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.getPage();
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects/my-offers', this.filters)
                .then((res) =>{
                    let data =  res.data
                    data.data = data.data.map(o => {
                        o.loading = false;
                        return o;
                    });
                    this.data = data
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        cancelOffer(offer) {
            this.$dialog.confirm('Are you sure you want to cancel the offer?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    offer.loading = true;
                    this.$axios.post('/account/projects/offers/cancel', {
                        id: offer.id,
                    })
                        .then((res) =>{
                            this.getData();
                            offer.loading = false;
                            this.$modal.hide('ProjectOfferDetailsModal')
                        })
                        .catch((error) => {
                            offer.loading = false;
                        });
            });
        },
        openRejectReasonModal(offer) {
            this.reject_reason = offer.reject_reason;
            this.$modal.show('RejectReasonModal')
        },
        viewOfferDetails(offer) {
            if (offer.status >= 1) {
                this.$router.push({name: 'account.offer.details', params: {id: offer.id}});
            }else{
                this.selected_offer = offer;
                this.$modal.show('ProjectOfferDetailsModal');
            }
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

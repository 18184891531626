<template functional>
  <th :width="props.width" :class="{
        'sorting': props.filters.sort !== field,
        'sorting_asc': props.filters.sort === props.field && props.filters.order === 'asc',
        'sorting_desc': props.filters.sort === props.field && props.filters.order === 'desc'
    }" v-on:click="listeners.click && listeners.click(props.field)">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: 'ThSortable',
};
</script>

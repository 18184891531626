<template>
    <div>
        <client-only>
            <div id="paypal-button-container"></div>
        </client-only>
    </div>
</template>

<script>

import {loadScript} from "@paypal/paypal-js";

export default {
    name: 'PaypalButtons',
    mounted() {
        loadScript({ 'client-id': process.env.PAYPAL_CLIENT_ID }).then((paypal) => {
            this.paypal = paypal
            .Buttons({
                createOrder: this.$emit('createOrder'),
                onApprove: this.onApprove
            })
            .render('#paypal-button-container')
        })
    },
    methods: {
        onApprove(data, actions) {
            actions.order.capture().then((details) => {
                this.$emit('onApprove', details);
            })
        }
    },
}
</script>

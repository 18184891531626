import Vue from 'vue';
import VueMq from 'vue-mq';

export default () => {
    Vue.use(VueMq, {
        breakpoints: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1274
        }
    });
};

<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Calendar</div>
        </div>
        <Tabs wrapperClass="account_tabs">
            <Tab name="Calendar View">
                <AppointmentsCalendar/>
            </Tab>
            <Tab name="Calendar Management">
                <ScheduleManagement/>
            </Tab>
        </Tabs>
    </div>
</template>

<script>

import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import AppointmentsCalendar from "@/pages/Account/AppointmentsCalendar.vue";
import ScheduleManagement from "@/pages/Account/ScheduleManagement.vue";
import {metaMixin} from "@/mixins/metaMixin";

export default {
    name: 'AccountCalendar',
    mixins: [metaMixin],
    components: {
        ScheduleManagement,
        AppointmentsCalendar,
        Tab, Tabs
    },
}
</script>

<template>
    <div class="p-project-card px-2">
        <div class="p-project-card__img-wrapper">
            <Shimmer class="p-project-card__img img-fluid" height="274px" width="204px"/>
        </div>
        <div class="p-project-card__content-wrapper">
            <div class="p-project-card__content">
                <div class="p-project-card__content-brand"><Shimmer type="text" height="20px" width="120px"/></div>
                <div class="p-project-card__content-name"><Shimmer type="text" height="26px" width="240px"/></div>
                <div class="p-project-card__content-name2"><Shimmer type="text" height="15px" width="140px"/></div>
            </div>
            <div class="text-center">
                <Shimmer type="text" height="20px" width="80px"/>
            </div>
            <div class="p-project-card__content-price">
                <Shimmer type="text" height="30px" width="80px"/>
            </div>
        </div>
        <div class="text-center mb-4">
            <Shimmer type="text" height="40px" width="180px" radius="50%"/>
        </div>
    </div>
</template>

<script>

import Shimmer from "../../components/Shimmer.vue";

export default {
    name: 'ShimmerFreelancerCard',
    components: {Shimmer},
}
</script>

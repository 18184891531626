<template>
    <div v-if="edit_object">
<!--        <button @click="AuthProvider('orcid')" class="btn btn-outline-light mb-3 btn-block ml-0">-->
<!--            <ORCIDIcon style="margin-right: 4px"/>-->
<!--            Connect with ORCID account-->
<!--        </button>-->

<!--        <div class="word-hr mb-3"><span>or fill manually</span></div>-->

        <FormInput class="mb-2" label="Publication Title" placehoder="Title" v-model="edit_object.title"/>
        <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
        <FormGroup label="Author(s)">
            <TagSelect v-model="edit_object.authors"/>
        </FormGroup>
        <FormInput class="mb-2" label="Year of publication" v-model="edit_object.year"/>
        <FormGroup label="Volume and Issue Number">
            <div class="row">
                <div class="col-md-6"><FormInput class="mb-2" placeholder="Volume Number" v-model="edit_object.volume_number"/></div>
                <div class="col-md-6"><FormInput class="mb-2" placeholder="Issue Number" v-model="edit_object.issue_number"/></div>
            </div>
        </FormGroup>
        <Btn class="btn-block btn-secondary-light" :loading="loading_save" @click="saveObject">Save</Btn>
    </div>
</template>

<script>

import Btn from "~/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import TagSelect from "@/components/TagSelect.vue";

export default {
    name: 'PublicationsForm',
    components: {
        TagSelect,
        FormTextarea,
        FormInput,
        Btn,
    },
    props: ['value', 'profile_id', 'loading_save'],
    data() {
        return {
            edit_object: null,
        }
    },
    mounted () {
        this.edit_object = this.value
    },
    watch: {
        // edit_object(value) {
        //     this.$emit('input', value)
        // },
        value(value) {
            this.edit_object = value
        }
    },
    methods: {
        saveObject() {
            this.$emit('onSave', this.edit_object)
        },
    }
}
</script>

<template functional>
    <FormGroup :help="props.help" :error="props.error" :class="[data.staticClass, data.class]" class="form-input" :label="props.label" :labelInfo="props.labelInfo">
        <div class="input-group" :style="data.staticStyle">
            <div class="input-group-prepend" v-if="!!$slots.prepend">
                <span class="input-group-text"><slot name="prepend"></slot></span>
            </div>
            <div class="input-group-prepend" v-else-if="props.prepend_text">
                <span class="input-group-text pr-1">{{ props.prepend_text }}</span>
            </div>
            <div class="input-group-prepend" v-if="!!$slots.prependBtn">
                <slot name="prependBtn"></slot>
            </div>
            <input :type="props.type ? props.type: 'text'" class="form-control"
                   :class="{'is-invalid' : props.v ? props.v.$error : null, 'no-label': !props.label}"
                   :placeholder="props.placeholder"
                   :value="props.value"
                   :name="props.name"
                   :min="props.min"
                   :max="props.max"
                   :disabled="props.disabled"
                   :readonly="props.readonly"
                   v-on:input="($event) => {
                       listeners.input && listeners.input($event.target.value);
                       if(props.v){
                           props.v.$touch();
                       }
                   }"
                   v-on:change="($event) => {
                       listeners.change && listeners.change($event.target.value);
                   }"
                   v-on:keyup="listeners.keyup && listeners.keyup($event)"
                   v-on:keypress="listeners.keypress && listeners.keypress($event)"
                   v-on:keydown="listeners.keydown && listeners.keydown($event)"
                   v-on:focus="listeners.focus && listeners.focus($event)"
                   v-on:blur="listeners.blur && listeners.blur($event)"
            >
            <div class="input-group-append" v-if="!!$slots.append">
                <span class="input-group-text pr-1"><slot name="append"></slot></span>
            </div>
            <div class="input-group-append" v-else-if="props.append_text">
                <span class="input-group-text pr-1">{{ props.append_text }}</span>
            </div>
            <div class="input-group-append" v-if="!!$slots.appendBtn">
                <slot name="appendBtn"></slot>
            </div>
        </div>
        <slot name="help"></slot>
        <div class="text-right text-muted" v-if="props.count"><small>{{ props.value ? props.value.length : 0 }}/{{ props.count }}</small></div>
    </FormGroup>
</template>

<script>

    import FormGroup from "./FormGroup.vue";

    export default {
        name: 'FormInput',
        components: {FormGroup}
    }
</script>

<template>
    <a class="c-group-toggle" @click.stop="showDropdown" :class="{'active': current_value && current_value.length}">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown" trigger="manual" placement="bottom" :interactive="true" theme="light" :arrow="true" :onShow="checkAuth" :onShown="loadData">
                <template #trigger>
                    <div class="d-flex justify-content-center"><GroupsIcon width="18" height="18"/></div>
                </template>
                <div class="c-dropdown-menu" :class="{'loading': loading.data}">
                    <div class="c-dropdown-menu__item" v-for="group in groups">
                        <FormCheckbox :fieldValue="group.id" :value="current_value" size="lg" class="mb-0" @change="toggleToGroup(group)" :id="group.id" :text="group.name"/>
                    </div>
                    <template v-if="group">
                        <div class="c-dropdown-menu__item">
                            <FormInput v-model="group.name"/>
                        </div>
                        <div class="c-dropdown-menu__item footer">
                            <Btn class="btn-secondary" :loading="loading.save" @click="saveGroup">Save</Btn>
                            <Btn class="btn-outline-danger" @click="group = null">Cancel</Btn>
                        </div>
                    </template>
                    <div v-else class="c-dropdown-menu__item text-center footer">
                        <a class="text-secondary" @click.prevent="addNewGroup"> + Create New Group</a>
                    </div>
                </div>
            </tippy>
        </ClientOnly>
    </a>
</template>

<script>

import { mapState } from 'vuex';
import FormCheckbox from "@/components/FormCheckbox.vue";
import FormInput from "@/components/FormInput.vue";
import Btn from "@/components/Btn.vue";

export default {
    name: 'GroupToggle',
    props: ['foreign_model', 'foreign_id', 'type', 'value'],
    components: {Btn, FormInput, FormCheckbox},
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            current_value: [],
            group: null
        }
    },
    mounted () {
        this.current_value = this.value
    },
    watch: {
        current_value () {
            this.value = this.current_value;
            this.$emit('input', this.current_value)
        },
    },
    computed: {
        ...mapState({
            groups (state) {
                return state.stateResource[`${this.foreign_model}_${this.type}_groups`] ? state.stateResource[`${this.foreign_model}_${this.type}_groups`] : []
            },
            user: state => state.user,
        }),
    },
    methods: {
        loadData() {
            if (!this.groups || !this.groups.length) {
                this.getData();
            }
        },
        getData: function () {
            this.$axios.post("/account/groups", {
                model_type: this.foreign_model,
                type: this.type
            })
                .then((res) =>{
                    this.$store.commit('setResource', [`${this.foreign_model}_${this.type}_groups`, res.data])
                });
        },
        checkAuth() {
            if (!this.user.id) {
                this.$toast.error('You must be signed in to be able to add to a group.')
                return false
            }
        },
        toggleToGroup (group) {
            if (!this.user.id) {
                this.$toast.error('You must be signed in to be able to add to a group.')
                return
            }
            if (this.loading.data) {
                return
            }

            this.loading.data = true;
            this.$axios.post('/account/groups/toggle', {
                group_id: group.id,
                type: this.type,
                foreign_model: this.foreign_model,
                foreign_id: this.foreign_id,
            })
                .then((res) => {
                    this.loading.data = false;
                    if (res.status) {
                        this.current_value.push(group.id);
                    }else{
                        this.current_value.splice(this.current_value.findIndex(i => i == group.id), 1);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        addNewGroup() {
            this.group = {
                id: null,
                name: '',
            }
        },
        saveGroup() {
            this.loading.save = true;
            this.$axios.post('/account/groups/save', {
                type: this.type,
                model_type: this.foreign_model,
                name: this.group.name,
            })
                .then((res) => {
                    this.loading.save = false;
                    let groups = JSON.parse(JSON.stringify(this.groups));
                    groups.push(res.group)
                    this.$store.commit('setResource', [`${this.foreign_model}_${this.type}_groups`, groups])
                    this.group = null;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.save = false;
                });
        },
        showDropdown() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.show();
            }
        },
    }
}
</script>

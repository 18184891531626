<template>
    <div class="project-card">
        <div class="project-card__image">
            <div class="project-card__status"></div>
        </div>
        <span class="project-card__title">{{project.title}}</span>
<!--        <span class="project-card__category">{{project.category}}</span>-->
        <span class="project-card__description">{{project.description}}</span>
        <div class="d-flex flex-row align-items-center project-card__counter">
            <span class="d-flex align-items-center"><MessagesIcon class="mr-1"/> 15</span>
            <div class="project-card__vertical_divider"></div>
            <span class="d-flex align-items-center"><GalleryIcon class="mr-1"/> 3</span>
            <div class="project-card__vertical_divider"></div>
            <span class="d-flex align-items-center"><AttachmentIcon class="mr-1"/> 2</span>
        </div>
        <div v-if="type === 'job'" class="mb-3 d-flex align-items-center" style="color: black">
            <ActiveIcon class="mr-1"/> Active
        </div>
        <Btn v-if="type === 'job'" class="btn btn-outline-light w-100 mb-1">Edit</Btn>
        <Btn v-else class="btn btn-secondary w-100 mb-1">Edit</Btn>
        <div v-if="type === 'job'" class="w-100">
            <Btn  class="btn btn-secondary w-100">See more details</Btn>
        </div>
        <div v-else class="d-flex flex-row w-100">
            <Btn class="btn btn-outline-light flex-1" @click="archiveProject">Archive</Btn>
            <Btn class="btn btn-outline-light flex-1" @click="deleteProject">Delete</Btn>
        </div>
    </div>
</template>

<script>

import Btn from "@/components/Btn.vue";

export default {
    name: "AccountProjectCard",
    props: ['project', 'type'],
    components: {Btn },
    data() {
        return {

        }
    },
    methods: {
        deleteProject() {
            this.$dialog.confirm('Are you sure you want to delete project ' + this.project.title + ' ?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    console.log('delete')
                });
        },
        archiveProject() {
            this.$dialog.confirm('Are you sure you want to archive project ' + this.project.title + ' ?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    console.log('archive')
                });
        }
    }
}
</script>

<style scoped>

</style>

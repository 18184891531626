<template>
    <div :class="{'loading': loading.data}">
<!--        <div class="l-account-content__header">-->
<!--            <div class="l-account-content__title">Billing History</div>-->
<!--        </div>-->
        <template v-if="data && data.data && data.data.length > 0">
            <VueTable
                :data="data.data"
                :columns="getColumns()">
                <template #documents="{row}">
                    <a href="#" :class="{'loading': row.invoice.loading}" @click.prevent="downloadInvoice(row.invoice)" v-if="row.invoice">
                        {{ row.invoice.ref }}
                    </a>
                    <a href="#" :class="{'loading': row.receipt.loading}" @click.prevent="downloadReceipt(row.receipt)" v-if="row.receipt">
                        {{ row.receipt.ref }}
                    </a>
                    <template v-for="invoice in row.invoices">
                        <a href="#" :class="{'loading': invoice.loading}" @click.prevent="downloadInvoice(invoice)">
                            {{ invoice.ref }}
                        </a><br>
                    </template>
                    <template v-for="receipt in row.receipts">
                        <a href="#" :class="{'loading': receipt.loading}" @click.prevent="downloadReceipt(receipt)">
                            {{ receipt.ref }}
                        </a><br>
                    </template>
                </template>
                <template #date="{row}">
                    {{ row.date | moment('MMM DD, YYYY') }}
                </template>
                <template #amount="{row}">
                    {{ row.total | toPrice }}
                </template>
            </VueTable>
        </template>
        <div class="card-body" v-else-if="data && !data.data.length && !loading.data">
            <h4>No data</h4>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
    </div>
</template>

<script>
import InvoiceIcon from "~/assets/icons/account/invoice.svg?inline";
import Btn from "~/components/Btn.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import UserAvatar from "@/components/UserAvatar.vue";
import VueTable from "@/components/VueTable.vue";

export default {
    name: "BillingHistory",
    mixins: [modelListMixin],
    props: ['project_id', 'application_id'],
    components: {VueTable, UserAvatar, InvoiceIcon, Btn },
    data() {
        return {
            filters: {
                sort: 'id',
                dir: 'desc',
                project_id: null,
                application_id: null,
            }
        }
    },
    methods: {
        getColumns() {
            if (this.project_id || this.application_id) {
                return ['date', 'amount', 'status', 'documents']
            }
            return ['date', 'for', 'amount', 'status', 'documents'];
        },
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/payments", {params: this.filters})
                .then((res) => {
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        downloadInvoice(invoice) {
            invoice.loading = true;
            this.$axios.get(`/account/invoices/${invoice.id}/pdf`, {responseType: 'arraybuffer'})
                .then(function (res) {
                    var file = new Blob([res], {type: 'application/pdf'});
                    let url = window.URL.createObjectURL(file);
                    window.open(url);

                    // var blob = new Blob([res], {type: 'application/pdf'});
                    // const link = document.createElement('a')
                    // link.href = URL.createObjectURL(blob)
                    // link.download = `${invoice.ref}.pdf`
                    // link.click()
                    // URL.revokeObjectURL(link.href)

                    invoice.loading = false;
                })
                .catch(function (error) {
                    console.log(error)
                    invoice.loading = false;
                });
        },
        downloadReceipt(receipt) {
            receipt.loading = true;
            this.$axios.get(`/account/receipts/${receipt.id}/pdf`, {responseType: 'arraybuffer'})
                .then(function (res) {
                    var file = new Blob([res], {type: 'application/pdf'});
                    let url = window.URL.createObjectURL(file);
                    window.open(url);

                    // var blob = new Blob([res], {type: 'application/pdf'});
                    // const link = document.createElement('a')
                    // link.href = URL.createObjectURL(blob)
                    // link.download = `${receipt.ref}.pdf`
                    // link.click()
                    // URL.revokeObjectURL(link.href)

                    receipt.loading = false;
                })
                .catch(function (error) {
                    console.log(error)
                    receipt.loading = false;
                });
        },
    },
    mounted() {
        this.filters.application_id = this.application_id
        this.filters.project_id = this.project_id
        this.getData();
    }
}
</script>

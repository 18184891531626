(function () {

    const Utils = {
        setBaseUrl(value) {
            this.base_url = value
        },
        install: function (Vue, options) {
            this.setBaseUrl(options.base_url)
            Vue.prototype.$utils = this;
            Vue.$utils = this
        },
        format () {
            return 'webp'
        },
        getPlaceholder ($params) {
            let width = $params.width ? $params.width : '';
            let height = $params.height ? $params.height : '';
            return this.base_url + '/media/placeholder' + '__w' + width + '_h' + height + ($params.bg ? '_b'.$params['bg'] : '') + '.' + this.format();
        },
        getImage (image, width, height, contain, bg, overlay) {
            let image_url = image;
            if (image && typeof image === "object") {
                if (typeof image.file !== "undefined") {
                    image_url = image.file;
                } else {
                    image_url = null;
                }
            }
            if(image_url && image_url.indexOf('http') > -1) {
                return image_url
            }
            if (!image_url) {
                return this.getPlaceholder({
                    width,
                    height,
                    bg,
                });
            }
            return this.computeImage({
                image_url,
                width,
                height,
                contain,
                bg,
                overlay,
            });
        },
        computeImage ($params) {
            let width = $params.width ? $params.width : '';
            let height = $params.height ? $params.height : '';
            let contain = $params.contain ? $params.contain : false;
            let filename = $params.image_url.split('.');
            let ext = filename.pop();
            if (ext === 'svg') {
                return this.base_url + filename.join('.') + '.' + ext;
            }
            return this.base_url + filename.join('.') + '__w' + width + '_h' + height + (contain ? '_c' : '') + ($params.bg ? '_b'.$params.bg : '') + ($params.overlay ? '_o'.$params.overlay : '') + '.' + this.format();
        },

        getYoutubeId(url) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        },

        validateEmail(email){
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    }

    if (typeof exports == "object") {
        module.exports = Utils;
    }
})();

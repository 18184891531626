<template>
    <AuthClientOnboarding v-if="user.type === 'client'"/>
    <AuthFreelancerOnboarding v-else-if="user.type === 'freelancer'"/>
    <AuthCompanyOnboarding v-else-if="user.type === 'company'"/>
</template>

<script>


import { mapState } from 'vuex'
import AuthClientOnboarding from "./AuthClientOnboarding.vue";
import AuthFreelancerOnboarding from "./AuthFreelancerOnboarding.vue";
import AuthCompanyOnboarding from "./AuthCompanyOnboarding.vue";


export default {
    name: 'AuthOnboarding',
    components: {AuthCompanyOnboarding, AuthFreelancerOnboarding, AuthClientOnboarding},
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    }
}
</script>

<template>
    <div class="p-details-wrapper" :class="{'--gray-bg' : !item}">
        <div class="p-details" :class="{'loading': loading.data, 'container pt-4 pb-4' : !this.item}">
            <div class="row" v-if="project">
                <div class="col-md-5">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="p-details__info">
                                <div class="p-details__info-actions" v-if="!preview">
                                    <GroupToggle foreign_model="Modules\Projects\Entities\Project" :foreign_id="project.id" v-model="project.groups" type="project"/>
                                </div>
                                <UserAvatar class="mb-3" size="large" :user="project.client"/>
                                <div class="p-details__info__title">{{ getClientName(project.client) }}</div>
                                <div class="p-details__info__desc">
                                    {{ project.description }}
                                </div>
                                <div class="p-details__info__stats">
                                    <span><LocationIcon/> {{ [project.client.address.city_name, project.client.address.country_name].join(', ') }}</span>
                                    <span><StarIcon/> {{ project.client.avgReview ? project.client.avgReview : '-' }}/5 <template v-if="project.client.countReview">({{ project.client.countReview }})</template></span>
                                </div>
<!--                                <Btn class="btn-secondary btn-block  mb-2">Send Message</Btn>-->
                                <Btn class="btn-secondary btn-block mb-2" :disabled="!project.can_apply" v-if="!preview" @click="showOfferModal">Make Offer</Btn>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="p-details__specs">
                                <div class="p-details__specs__label">English Language Level</div>
                                <div class="p-details__specs__content">{{ project.client.attributes.english_language_level?.value }}</div>

                                <div class="p-details__specs__label">Other Language(s)</div>
                                <div class="p-details__specs__content">
                                    <template v-if="project.client.attributes.other_languages">
                                        {{ project.client.attributes.other_languages.map(i => i.value).join(', ') }}
                                    </template>
                                    <template v-else>
                                        None
                                    </template>
                                </div>

                                <div class="p-details__specs__label">Color Code Category(s)</div>
                                <div class="p-details__specs__content" v-if="project.client.attributes.color_code_categories">
                                    <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in project.client.attributes.color_code_categories" :attr="attr"/>
                                </div>

                                <div class="p-details__specs__label">Services(s)</div>
                                <div class="p-details__specs__content" v-if="project.client.attributes.services">
                                    <div class="badge badge-secondary-light badge-lg mr-1 mb-1" v-for="service in project.client.attributes.services">{{ service.value }}</div>
                                </div>

                                <div class="p-details__specs__label">Expertise Level</div>
                                <div class="p-details__specs__content">
                                    <template v-if="project.client.attributes.expertise_level">
                                        <img :src="project.client.attributes.expertise_level.icon"/> {{ project.client.attributes.expertise_level.value }}
                                    </template>
                                </div>

                                <div class="p-details__specs__label">Expertise Area(s)</div>
                                <div class="p-details__specs__content" v-if="project.client.attributes.expertise_areas">
                                    <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="expertise_area in project.client.attributes.expertise_areas">{{ expertise_area.value }}</div>
                                </div>

                                <div class="p-details__specs__label">Total Hours of Work</div>
                                <div class="p-details__specs__content">{{ project.client.work_hours }} hours</div>

                                <div class="p-details__specs__label">Total Earnings</div>
                                <div class="p-details__specs__content">{{ project.client.attributes.total_earnings?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Number of Reviews</div>
                                <div class="p-details__specs__content">{{ project.client.countReview }}</div>

                                <div class="p-details__specs__label">Member Since</div>
                                <div class="p-details__specs__content">{{ project.client.member_since | moment('MMMM DD, YYYY') }}</div>

                                <div class="p-details__specs__label">Response Time</div>
                                <div class="p-details__specs__content">{{ project.client.attributes.response_time?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Last Online</div>
                                <div class="p-details__specs__content">{{ project.client.online_at | onlineAtFormat }}</div>

                                <template v-if="project.payment_method === 'hourly'">
                                    <div class="p-details__specs__label">Hourly Rate</div>
                                    <div class="p-details__specs__content"><DolarCircleIcon height="20"/> {{ project.price[0] | toPrice }}-{{ project.price[1] | toPrice }}</div>
                                    <div class="p-details__specs__label">Number of hours required</div>
                                    <div class="p-details__specs__content" v-if="project.hours[1]">{{ project.hours[0] }} to {{ project.hours[1] }} hours{{project.hours_type === 'month' ? '/month' : '/week'}}</div>
                                    <div class="p-details__specs__content" v-else>Any</div>
                                    <div class="p-details__specs__label">Duration of the project</div>
                                    <div class="p-details__specs__content" v-if="project.duration[1]">{{ project.duration[0] }} to {{ project.duration[1] }} Days</div>
                                    <div class="p-details__specs__content" v-else>Any</div>
                                </template>
                                <template v-if="project.payment_method === 'fixed'">
                                    <div class="p-details__specs__label">Est. Budget</div>
                                    <div class="p-details__specs__content"><DolarCircleIcon height="20"/> {{ project.price[0] | toPrice }}-{{ project.price[1] | toPrice }}</div>
                                    <div class="p-details__specs__label">Delivery Time</div>
                                    <div class="p-details__specs__content" v-if="project.duration[1]">{{ project.duration[1] }} days</div>
                                    <div class="p-details__specs__content" v-else>Any</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="card" style="padding-top: 18px;">
                        <div class="p-details__tabs">
                            <div role="tablist" class="nav">
                                <ClientOnly>
                                    <Swiper
                                            style="width: 100%"
                                            ref="tabsSlider"
                                            :navigation="true"
                                            slidesPerView="auto"
                                            :slidesOffsetBefore="32"
                                            :slidesOffsetAfter="32"
                                            :spaceBetween="16"
                                            @swiper="onSwiper"
                                    >
                                        <SwiperSlide>
                                            <span class="nav-item">
                                                <a href="#description" @click.prevent="scrollToSection('description')" class="nav-link" :class="{'active': currentSection === 'description'}">Description</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide v-if="project.documents?.length">
                                            <span class="nav-item">
                                                <a href="#documents" @click.prevent="scrollToSection('documents')" class="nav-link" :class="{'active': currentSection === 'documents'}">Documents</a>
                                            </span>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <span class="nav-item">
                                                <a href="#history" @click.prevent="scrollToSection('history')" class="nav-link" :class="{'active': currentSection === 'history'}">Expert's Recent History</a>
                                            </span>
                                        </SwiperSlide>
                                    </Swiper>
                                </ClientOnly>
                            </div>
                        </div>
                        <div class="p-details__section" id="description">
                            <div class="p-details__section-title">
                                {{ project.title }}
                            </div>
                            {{ project.description }}<br>

                        </div>
                        <div class="p-details__section" id="documents" v-if="project.documents?.length">
                            <div class="p-details__section-title">
                                Documents
                            </div>
                            <div class="p-details__docs-wrapper">
                                <DocumentCard :key="document.id" :document="document" v-for="document in project.documents" />
                            </div>
                        </div>
                        <div class="p-details__section" id="history" v-if="reviews?.length">
                            <div class="p-details__section-title">
                                Expert's Recent History
                            </div>
                            <div class="p-details__review" v-for="review in reviews">
                                <div class="p-details__review__rating">
                                    <img :src="review.icon" height="24" width="24" alt=""> {{ review.label }}
                                </div>
                                <div class="p-details__info-item__stats mb-2">
                                    <span style="white-space: nowrap"><Stars :value="review.stars"/> {{ review.stars.toFixed(1) }}</span>
                                    <span class="d-inline-flex align-items-center">Reviewed {{ review.date | momentAgo }} By <UserAvatar class="d-inline-flex ml-1" :displayName="true" :user="review.user" size="xs"/></span>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Do you recommend other People to work with this freelancer in the future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light --w48">{{ review.stats.recommend == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Will you be working with this Biotech Expert in the Future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light --w48">{{ review.stats.come_back == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>
                                <template v-if="review.project">
                                    <div class="p-details__review__title">{{ review.project.title }}</div>
                                    <div class="p-details__review__description">{{ review.project.description }}</div>
                                    <div class="p-details__info-item__stats">
                                        <span>Duration: <span>5-6 Days</span></span>
                                        <span>Total Price: <span>$400.00</span></span>
                                    </div>
                                </template>
                            </div>
                            <div class="text-center" v-if="total_reviews - reviews.length > 0">
                                <Btn class="btn-light" @click="loadMoreReviews" :loading="loading.load_more">More reviews ({{ total_reviews - reviews.length }})</Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OfferModal v-if="project" :project="project" @onSubmit="onSubmitOffer"/>
    </div>
</template>

<script>

import UserAvatar from "~/components/UserAvatar.vue";
import Btn from "~/components/Btn.vue";
import GroupToggle from "~/components/GroupToggle.vue";
import BlockToggle from "~/components/BlockToggle.vue";
import ColorCodeAttribute from "~/components/ColorCodeAttribute.vue";
import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import dayjs from "dayjs";
import Stars from "../../components/Stars.vue";
import {mapState} from "vuex";
import OfferModal from "@/components/OfferModal.vue";
import DocumentCard from "@/components/DocumentCard.vue";
import {getClientName} from "../../utils/helpers";

export default {
    name: 'ProjectDetails',
    components: {
        DocumentCard,
        OfferModal,
        Stars, Tab, Tabs, ColorCodeAttribute, BlockToggle, GroupToggle, Btn, UserAvatar,

    },
    props: ['modal', 'item', 'preview'],
    data() {
        return {
            loaded: false,
            loading: {
                data: false,
                reviews: false,
                load_mode: false,
            },
            project: null,
            reviews: [],
            total_reviews: 0,
            currentSection: null,
            scrollElement: null,
            swiper: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch();
        }
        if (this.modal) {
            this.scrollElement = document.querySelector('.modal-sidebar.vm--container.scrollable');
        } else {
            this.scrollElement = window;
        }
        this.scrollElement.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy() {
        if (this.scrollElement) {
            this.scrollElement.removeEventListener('scroll', this.scrollHandler, false)
        }
    },
    activated () {
        // this.sendToAnalytics()
    },
    fetch() {
        if (this.preview && this.item) {
            this.project = JSON.parse(JSON.stringify(this.item));
            // this.getFrontAttributes(this.item.attributes);
            return;
        }
        this.getData();
    },
    watch: {
        currentSection(value) {
            if (this.swiper && value) {
                const sections = document.querySelectorAll('.p-details__section');
                const section = document.querySelector('.p-details__section#' + value);
                const index = Array.prototype.indexOf.call(sections, section);
                this.swiper.slideTo(index + 1);
            }
        },
    },
    methods: {
        getClientName,
        onSwiper(swiper) {
            this.swiper = swiper;
            this.currentSection = document.querySelectorAll('.p-details__section')[0]?.id
        },
        scrollHandler() {

            const elements = document.querySelectorAll('.p-details__section')
            let currentSection = elements[0];
            elements.forEach((element, index) => {
                const position = element.getBoundingClientRect().y - 40;
                if (position < 0) {
                    currentSection = element;
                }
            })
            this.currentSection = currentSection ? currentSection.id : null
            // console.log(this.currentSection)
        },
        getData () {
            this.loading.data = true;
            const slug = this.item && this.modal ? this.item.slug : this.$route.params.slug;
            return this.$axios.post('/projects/details', { slug })
                .then((res) => {
                    this.project = res.project
                    this.getReviews()
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
        getReviews () {
            this.loading.reviews = true;
            return this.$axios.post('/clients/reviews', { client_id: this.project.client.id })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.reviews = false;
                })
                .catch((error) => {
                    this.loading.reviews = false;
                });
        },
        loadMoreReviews() {
            this.loading.load_more = true;
            return this.$axios.post('/clients/reviews', {
                client_id: this.client.id,
                after_id: this.reviews[this.reviews.length -1]?.id
            })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.load_more = false;
                })
                .catch((error) => {
                    this.loading.load_more = false;
                });
        },
        getFrontAttributes (attributes) {
            this.loading.data = true;
            return this.$axios.post('/get-front-attributes', {
                attributes: attributes
            })
                .then((res) => {
                    this.project.attributes = res.attributes
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        scrollToSection(id) {
            if(this.modal) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
            }else{
                window.scroll({
                    top: document.getElementById(id).offsetTop + 88,
                    behavior: 'smooth'
                });
            }
        },
        getDatesDiff(date_from, date_to) {
            date_to = date_to ? dayjs(date_to) : dayjs();
            const months_diff = date_to.diff(date_from, 'month')
            if (months_diff) {
                return `${months_diff} months`;
            }
        },
        showOfferModal() {
            if (!this.user.confirmed) {
                this.$toast.error('You need to confirm your email.');
                this.$router.push({name: 'auth.confirm'});
                return;
            }
            if (this.user.type !== 'client') {
                this.$dialog.confirm('Are you a client looking for project?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.$toast.error('You need to login using a Client account to make an offer.');
                        this.$router.push({name: 'auth.login'});
                    })
            }else{
                this.$modal.show('offerModal_' + this.project.id);
            }
        },
        onSubmitOffer() {
            this.project.can_apply = false
        },
    }
}
</script>

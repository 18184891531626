<template>
    <div class="app-wrapper" :class="{'loading': loadingInit}">
        <template v-if="inited">
            <div class="header-wrapper">
                <div class="container" style="max-width: 1420px">
                    <div class="header">
                        <div class="header__actions d-block d-lg-none">
                            <div class="header__actions-item">
                                <a class="header__menu-toggle mr-2" @click="$store.commit('toggleMainMenu')"><MenuIcon height="32px" width="32px"/></a>
                            </div>
                        </div>
                        <div class="header__logo">
                            <NuxtLink :to="{name: 'home'}">
                                <img class="img-fluid" src="/assets/logo.svg" :width="438" :height="150" alt="BOLG"/>
                            </NuxtLink>
                        </div>
                        <div class="header__center">
                            <MainMenu/>
                        </div>
                        <div class="header__actions text-right">
                            <AccountDropdown/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-wrapper" id="contentWrapper">
    <!--            <FadeInOut entry="center" exit="center" :duration="200"  mode="out-in">&ndash;&gt;-->
                        <NuxtChild :nuxt-child-key="$route.meta.routeKey ? $route.meta.routeKey : $route.path"/>
    <!--            </FadeInOut>-->
            </div>

            <section id="footer">
                <div class="c-footer">
                    <div class="container">
                        <img class="img-fluid mb-3" src="/assets/logo.svg" :width="131" alt="BOLG"/>
                        <div class="c-footer__title">Let's start working together <NuxtLink :to="{name: 'auth.user-type'}"><GreenArrow width="44" height="44"/></NuxtLink></div>
                        <div class="row" v-if="menus">
                            <div class="col-12 col-md-2" v-for="menu in menus">
                                <FooterMenu title="">
                                    <ul>
                                        <template v-for="menu_item in menu.menu_items">
                                            <li v-if="menu_item.menuable_type === 'Modules\\Cms\\Entities\\CmsContent'"><NuxtLink :to="{name: 'page.details', params: {slug: menu_item.path}}">{{ menu_item.label }}</NuxtLink></li>
                                            <li v-else><NuxtLink :to="'/' + menu_item.path">{{ menu_item.label }}</NuxtLink></li>
                                        </template>
                                    </ul>
                                </FooterMenu>
                            </div>
                            <div class="col-12 col-md-5 offset-md-1" v-if="settings.twitter_page || settings.instagram_page || settings.linkedin_page || settings.facebook_page || settings.youtube_page">
                                <div class="c-footer__social-title">Follow Us</div>
                                <div class="c-footer__social">
                                    <a target="_blank" v-if="settings.twitter_page" :href="settings.twitter_page">
                                        <TwitterIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.instagram_page" :href="settings.instagram_page">
                                        <InstagramIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.linkedin_page" :href="settings.linkedin_page">
                                        <LinkedinIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.facebook_page" :href="settings.facebook_page">
                                        <FacebookIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.youtube_page" :href="settings.youtube_page">
                                        <YoutubeIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.quora_page" :href="settings.quora_page">
                                        <QuoraIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.tiktok_page" :href="settings.tiktok_page">
                                        <TikTokIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.pinterest_page" :href="settings.pinterest_page">
                                        <PinterestIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.medium_page" :href="settings.medium_page">
                                        <MediumIcon width="32" height="32"/>
                                    </a>
                                    <a target="_blank" v-if="settings.reddit_page" :href="settings.reddit_page">
                                        <RedditIcon width="32" height="32"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-footer_bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                BOLG<sup>TM</sup>  <sup>©</sup>2024 BOLG Biotechnology Open Lab Gate Corporation. All Rights Reserved <span class="text-muted">v.{{ version }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import GreenArrow from "~/assets/icons/green_arrow.svg?inline";
    import AccountDropdown from "../components/AccountDropdown.vue";
    import MainMenu from "../components/MainMenu.vue";
    import FooterMenu from "../components/FooterMenu.vue";
    import {initMixin} from "~/mixins/initMixin";
    import {metaMixin} from "@/mixins/metaMixin";

    export default {
        name: 'layout',
        mixins: [initMixin, metaMixin],
        components: {FooterMenu, MainMenu, AccountDropdown, GreenArrow},
        data() {
            return {
                loading: {
                    settings: false,
                },
                menus: null,
                version: process.env.VERSION,
                PWA_install: null
            }
        },
        computed: {
            ...mapState({
                // meta: state => state.meta,
                settings: state => state.settings,
                pwaInstall: state => state.pwaInstall,
            })
        },
        async fetch () {
            // await Promise.all([
                // this.getMenus(),
            // ])
        },
        async mounted () {
            // let sort = localStorage.getItem('sort')
            // if (sort) {
            //     console.log(sort)
            //     this.$store.commit('setSort', JSON.parse(sort))
            // }
            this.getSettings(),
            // this.loadData()
            this.getMenus();

            try {
                let response = await this.$recaptcha.init()
                console.log({response})
            } catch (e) {
                console.error(e);
            }
            if (!this.pwaInstall) {
                window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent the mini-infobar from appearing on mobile
                    e.preventDefault();
                    // Stash the event so it can be triggered later.
                    this.$store.commit('setPwaInstall', e)
                    // Update UI notify the user they can install the PWA
                    // showInstallPromotion();
                    // Optionally, send analytics event that PWA install promo was shown.
                    if ((this.$device.isMobileOrTablet)) {
                        e.prompt();
                    }
                });
            }
        },
        beforeDestroy() {
            this.$recaptcha.destroy()
        },
        methods: {
            // async loadData () {
            //     let token = this.$auth.getToken();
            //     if (token) {
            //         await this.getUser()
            //     }else {
            //         this.$acl.change('public');
            //     }
            //     setTimeout(() => {
            //         this.$store.commit('setLoaded', 'user');
            //     }, 2000)
            // },
            getSettings () {
                return this.$axios.get('/get-settings')
                    .then((res) => {
                        this.$store.commit('setSettings', res.settings)
                    }).catch((error) => {
                        console.error(error);
                    });
            },
            getMenus () {
                this.loading.footer = true;
                return this.$axios.get('/cms/menus')
                    .then((res) => {
                        this.menus = res.menus
                        this.loading.footer = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading.footer = false;
                    });
            },
            // async getUser () {
            //     this.$store.commit('setLoading', ['user', true])
            //     return this.$axios.get('/me')
            //         .then((res) => {
            //             let user = res.data
            //             this.$store.commit('setUser', user);
            //             this.$auth.setRole('client');
            //             this.$acl.change('client');
            //             this.$store.commit('setLoading', ['user', false])
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //             this.$store.commit('setLoading', ['user', false])
            //             this.$acl.change('public');
            //         });
            // },
            // subscribe () {
            //     this.$v.subscription.$touch();
            //     if (this.$v.subscription.$invalid == true) {
            //         return false;
            //     }
            //     this.loading.subscribe = true;
            //     this.$axios.post('/cms/newsletter', this.subscription)
            //         .then((res) => {
            //             this.subscription.email = ''
            //             this.$v.subscription.$reset();
            //             this.loading.subscribe = false;
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //             this.loading.subscribe = false;
            //         });
            // },
        }
    }
</script>


<template>
    <div class="row --item-card-grid" :class="{'loading': loading.data}">
        <div class="col-lg-4 col-sm-6" v-for="group in groups">
            <GroupCard :group="group"/>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="c-group-card --white">
                <div class="c-group-card__icon-wrapper">
                    <div class="c-group-card__icon">+</div>
                </div>
                <div class="c-group-card__info">
                    <div class="c-group-card__title">Create New Group</div>

                    <FormInput label="Group Name" v-model="group.name"/>
                </div>
                <Btn class="btn btn-secondary btn-block" @click="addGroup">Create Group</Btn>
            </div>
        </div>
    </div>
</template>

<script>

import Bugsnag from "@bugsnag/js";
import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import GroupCard from "~/components/GroupCard.vue";
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";

export default {
    name: 'GroupList',
    props: ['type', 'model_type'],
    components: {FormInput, Btn, GroupCard, Tab, Tabs},
    data () {
        return {
            loading: {
                data: false,
            },
            groups: [],
            group: {
                name: ''
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.loading.data = true;
            this.$axios.post("/account/groups/list", {
                type: this.type,
                model_type: this.model_type
            })
                .then((res) => {
                    this.groups = res.data
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        addGroup () {
            this.loading.data = true;
            this.$axios.post("/account/groups/save", {
                name: this.group.name,
                model_type: this.model_type,
                type: this.type
            })
                .then((res) => {
                    this.getData()
                    this.group.name = '';
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
    }
}
</script>

<template>
    <div class="account_tabs">
        <ul role="tablist" class="nav d-inline-flex">
            <li v-for="option in options" :key="option.value" role="presentation" class="nav-item">
                <a style="cursor: pointer" class="nav-link" :class="{'active': option.value === value}" @click="$emit('input', option.value)">{{ option.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "ToggleNav",
    props: ['options', 'value'],
}
</script>

<style scoped>

</style>

var render = function render(_c,_vm){return _c('FormGroup',{staticClass:"form-input",class:[_vm.data.staticClass, _vm.data.class],attrs:{"help":_vm.props.help,"error":_vm.props.error,"label":_vm.props.label,"labelInfo":_vm.props.labelInfo}},[_c('div',{staticClass:"input-group",style:(_vm.data.staticStyle)},[(!!_vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2)]):(_vm.props.prepend_text)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text pr-1"},[_vm._v(_vm._s(_vm.props.prepend_text))])]):_vm._e(),_vm._v(" "),(!!_vm.$slots.prependBtn)?_c('div',{staticClass:"input-group-prepend"},[_vm._t("prependBtn")],2):_vm._e(),_vm._v(" "),_c('input',{staticClass:"form-control",class:{'is-invalid' : _vm.props.v ? _vm.props.v.$error : null, 'no-label': !_vm.props.label},attrs:{"type":_vm.props.type ? _vm.props.type: 'text',"placeholder":_vm.props.placeholder,"name":_vm.props.name,"min":_vm.props.min,"max":_vm.props.max,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"input":($event) => {
                   _vm.listeners.input && _vm.listeners.input($event.target.value);
                   if(_vm.props.v){
                       _vm.props.v.$touch();
                   }
               },"change":($event) => {
                   _vm.listeners.change && _vm.listeners.change($event.target.value);
               },"keyup":function($event){_vm.listeners.keyup && _vm.listeners.keyup($event)},"keypress":function($event){_vm.listeners.keypress && _vm.listeners.keypress($event)},"keydown":function($event){_vm.listeners.keydown && _vm.listeners.keydown($event)},"focus":function($event){_vm.listeners.focus && _vm.listeners.focus($event)},"blur":function($event){_vm.listeners.blur && _vm.listeners.blur($event)}}}),_vm._v(" "),(!!_vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text pr-1"},[_vm._t("append")],2)]):(_vm.props.append_text)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text pr-1"},[_vm._v(_vm._s(_vm.props.append_text))])]):_vm._e(),_vm._v(" "),(!!_vm.$slots.appendBtn)?_c('div',{staticClass:"input-group-append"},[_vm._t("appendBtn")],2):_vm._e()]),_vm._v(" "),_vm._t("help"),_vm._v(" "),(_vm.props.count)?_c('div',{staticClass:"text-right text-muted"},[_c('small',[_vm._v(_vm._s(_vm.props.value ? _vm.props.value.length : 0)+"/"+_vm._s(_vm.props.count))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <span class="o-dropdown d-inline-block">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                <template #trigger>
                    <EditDotsIcon height="24"/>
                </template>
                <div class="c-dropdown-menu">
                    <a href="#" @click.prevent="openProjectDetails" class="c-dropdown-menu__item">View Project Details</a>
                    <a href="#" @click.prevent="openOfferDetails" class="c-dropdown-menu__item">View Offer Details</a>
                    <template v-if="user.type === 'freelancer'">
                        <NuxtLink :to="{name: 'add-review', params: { username: offer.client.username, applicationId: offer.id }}" v-if="offer.status === 3 && offer.can_review" class="c-dropdown-menu__item">Leave Review</NuxtLink>
                        <div class="c-dropdown-menu__item text-danger" v-if="offer.status <= 0" @click="deleteOffer">Delete Offer</div>
                    </template>
                    <template v-else-if="user.type === 'client'">
                        <NuxtLink :to="{name: 'account.jobs.form', params: { id: offer.id }}" v-if="offer.status < 2" class="c-dropdown-menu__item">Edit Offer</NuxtLink>
                        <a href="#" class="c-dropdown-menu__item" v-if="offer.status === 1" @click.prevent="markAsAchieved()">Mark as Achieved</a>
                        <NuxtLink :to="{name: 'add-review', params: { username: offer.freelancer.username, applicationId: offer.id }}" v-if="offer.status === 3 && offer.can_review"  class="c-dropdown-menu__item">Leave Review</NuxtLink>
                    </template>

                </div>
            </tippy>
        </ClientOnly>
    </span>

</template>

<script>


import {mapState} from "vuex";

export default {
    name: 'ProjectOfferDetailsDropdown',
    props: ['offer'],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        deleteOffer() {
            this.$dialog.confirm('Are you sure you want to delete this offer?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/offers/delete', {id: this.offer.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        markAsAchieved() {
            this.$dialog.confirm('Are you sure you want to mark this offer as achieved and close it?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/offers/finish', {id: this.offer.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                            this.closeDropdown();
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        sendMessage() {
            if (this.offer.client.id === this.user.id) {
                this.$router.push({name: 'account.messages', params: {username: this.offer.project.client.username, project_id: this.offer.project.id}});
            }else{
                this.$router.push({name: 'account.messages', params: {username: this.offer.client.username, project_id: this.offer.project.id}});
            }
        },
        openProjectDetails() {
            this.$emit('onViewProjectDetails')
            this.closeDropdown();
        },
        openOfferDetails() {
            this.$emit('onViewOfferDetails')
            this.closeDropdown();
        },
        closeDropdown() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    }
}
</script>

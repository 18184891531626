<template>
    <div>
        <div class="card card-dark">
            <div class="card-header">
                <div class="card-section-title">Your availability</div>
            </div>
            <div class="card-body">
                <ExpandableSection class="mb-1" :title="week_days[day-1]" :key="day" v-for="(data, day) in schedule">
                    <form-group>
                        <toggle-button color="#0055E6" :height="22" v-model="data.active" :sync="true"/> <span class="notification-setting__label">{{ data.active ? 'I\'m available this day' : 'I\'m not available this day'}}</span>
                    </form-group>
                    <div class="text-muted mb-2">I’m available in this intervals:</div>
                    <div class="schedule-period-row" v-for="(period, index) in data.periods">
                        <span>From</span> <TimeSelect v-model="period.from"/> <span>To</span> <TimeSelect v-model="period.to"/><a class="ml-2" href="#" @click.prevent="removePeriod(day, index)"><TrashIcon/></a>
                    </div>
                    <a href="#" class="text-secondary mt-2" @click.prevent="addPeriod(day)">+ Add New Interval</a>
                </ExpandableSection>
                <div class="text-right">
                    <Btn class="btn-secondary" :loading="loading.save" @click="saveSchedule">Save</Btn>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { mapState } from 'vuex'
import ExpandableSection from "@/components/ExpandableSection.vue";
import TimeSelect from "@/components/TimeSelect.vue";
import TrashIcon from "~/assets/icons/trash.svg?inline";
import Btn from "@/components/Btn.vue";

export default {
    name: 'ScheduleManagement',
    components: {
        Btn,
        TimeSelect,
        ExpandableSection,
        TrashIcon
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            week_days: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            schedule: {
                1: {active: false, periods: []},
                2: {active: false, periods: []},
                3: {active: false, periods: []},
                4: {active: false, periods: []},
                5: {active: false, periods: []},
                6: {active: false, periods: []},
                7: {active: false, periods: []},
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/schedule")
                .then((res) => {
                    if (res.schedule) {
                        this.schedule = res.schedule.schedule;
                    }
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        saveSchedule() {
            this.loading.save = true;
            this.$axios.post("/account/schedule/save", {
                schedule: this.schedule
            })
                .then((res) => {
                    this.schedule = res.schedule.schedule;
                    this.loading.save = false;
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        addPeriod(day) {
            this.schedule[day].periods.push({
                from: '08:00',
                to: '16:00'
            })
        },
        removePeriod(day, index) {
            this.schedule[day].periods.splice(index, 1);
        },
    }
}
</script>

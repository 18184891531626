<template>
    <div :class="{'loading': loading.data}" style="max-width: 450px">
<!--        <div class="l-account-content__header">-->
<!--            <div class="l-account-content__title">Tax Information</div>-->
<!--        </div>-->
        <div>
            <form-group label="Country">
                <country-select v-model="tax.address.country" placeholder="Country"/>
            </form-group>
            <form-input label="Street Address" inputClass="form-control-lg" placeholder="E.g 653 E 9th St." v-model="tax.address.address"/>
            <div class="row">
                <form-input class="col-4" label="Zip Code" inputClass="form-control-lg" placeholder="0000" v-model="tax.address.postcode"/>
                <form-input class="col-8" label="City" inputClass="form-control-lg" placeholder="City" v-model="tax.address.city_name"/>
            </div>
            <form-group label="Province" v-if="tax.address.country && tax.address.country.id === 43">
                <county-select v-model="tax.address.county" placeholder="Province"/>
            </form-group>
            <form-input label="Province" v-else inputClass="form-control-lg" placeholder="Province" v-model="tax.address.county_name"/>
        </div>
        <div >
            <h5 style="font-weight: 700;" class="mt-5 mb-4">For Canada-based Clients, please provide the following information where applicable</h5>
        </div>
        <div>
            <form-input label="Goods and Services Tax (GST)/Harmonized Sales Tax (HST) number"
                        inputClass="form-control-lg"
                        v-model="tax.GST"
            />
            <form-input label="Quebec Sales Tax (QST) Number"
                        inputClass="form-control-lg"
                        v-model="tax.QST"
            />
            <form-input label="Provincial Sales Tax (PST) Number"
                        inputClass="form-control-lg"
                        v-model="tax.PST"
            />
            <div class="l-account-content__info-card mb-4 mt-4">
                <div class="align-self-start">
                    <InfoIcon></InfoIcon>
                </div>
                Please note that users in British Columbia (BC), Saskatchewan (SK), and Manitoba (MB) will be subject to PST regardless of their GST registration status.
            </div>
            <Btn class="btn btn-secondary w-100" @click="saveData" :loading="loading.save">Save</Btn>
        </div>
    </div>

</template>

<script>
import FormInput from "@/components/FormInput.vue";
import {required} from "vuelidate/lib/validators";
import CountrySelect from "@/components/CountrySelect.vue";
import InfoIcon from "~/assets/icons/account/info-circle.svg?inline";
import Btn from "~/components/Btn.vue";
import CountySelect from "@/components/CountySelect.vue";

export default {
    name: "TaxInformation",
    components: {CountySelect, Btn, CountrySelect, FormInput, InfoIcon},
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            tax: {
                address: {
                    address: '',
                    city_name: '',
                    county: '',
                    county_name: '',
                    postcode: '',
                    country: null,
                },
                GST: '',
                QST: '',
                PST: '',

            }
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/tax-information")
                .then((res) =>{
                    this.tax = {...this.tax, ...res.data};
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        saveData() {
            this.loading.save = true;
            this.$axios.post("/account/tax-information", this.tax)
                .then((res) =>{
                    this.loading.save = false;
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass, {'error' : props.v ? props.v.$error : null}]">
        <div class="input-group">
            <span class="input-group-text" v-if="!!$slots.prepend"><slot name="prepend"></slot></span>
            <ClientOnly>
                <flat-pickr
                    :value="props.value"
                    :disabled="props.disabled"
                    v-on:input="(value) => {
                        listeners.input && listeners.input(value);
                        if(props.v){
                            props.v.$touch();
                        }
                    }"
                    :config="{
                        minDate: props.minDate,
                        maxDate: props.maxDate,
                        enableTime: props.time,
                        allowInput: true,
                        time_24hr: true,
                        altFormat: props.altFormat ? props.altFormat : 'd.m.Y H:i',
                        dateFormat: props.dateFormat ? props.dateFormat : 'Y-m-d H:i:S',
                        altInput: true
                    }"
                    :required="props.required"
                    class="form-control">
                </flat-pickr>
            </ClientOnly>
            <span class="input-group-text" v-if="!!slots.append"><slot name="append"></slot></span>
        </div>
    </form-group>
</template>

<script>
    export default {
        name: 'FormDate',
    }
</script>

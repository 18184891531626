<template>
    <div v-if="project">
        <div class="l-account-content__header">
            <div class="l-account-content__back">
                <NuxtLink v-if="project.client.id === user.id" :to="{name: 'account.projects.offers', params: {id: project.id}}">
                    <ArrowBackIcon heigh="24"/> Back
                </NuxtLink>
                <NuxtLink v-else :to="{name: 'account.offers'}">
                    <ArrowBackIcon heigh="24"/> Back
                </NuxtLink>
            </div>
            <div class="l-account-content__info">
                <div style="flex: 1">
                    <div class="l-account-content__title">{{ project.title }}</div>
                    <div class="l-account-content__stats">
                        <span v-if="project.attributes.service_level">{{ project.attributes.service_level.value }}</span>
                        <template v-if="offer.payment_method === 'hourly'">
                            <span v-if="offer.price">Hourly Rate: {{ offer.price | toPrice }}</span>
                            <span>Hours required: {{ offer.hours_min }} to {{ offer.hours_max }} hours</span>
                            <span>Duration: {{ offer.duration_min }} to {{ offer.duration_max }} Days</span>
                        </template>
                        <template v-if="offer.payment_method === 'fixed'">
                            <span>Price: {{ offer.price_min | toPrice }}-{{ offer.price_max | toPrice }}</span>
                            <span>Delivery Time: {{ offer.duration_min }} to {{ offer.duration_max }} Days</span>
                        </template>
                    </div>
                </div>
                <ProjectOfferDetailsDropdown
                    :offer="offer"
                    @onViewProjectDetails="$modal.show('ProjectDetailsModal')"
                    @onViewOfferDetails="$modal.show('ProjectOfferDetailsModal')"
                    @onReload="getDetails"/>
            </div>
        </div>
        <div>
            <Tabs wrapperClass="account_tabs" v-if="offer">
                <Tab name="Overview">
                    <div class="l-sidebar-wrapper">
                        <div class="l-sidebar-content">
                            <div class="card card-dark mb-2">
                                <div class="card-body p-2">
                                    <UserAvatar :user="offer.client.id === user.id ? project.client : offer.client" :displayName="true"/>
                                </div>
                            </div>
                            <Btn class="btn-secondary mb-2" @click="addProjectLog" :disabled="offer.status !== 1">Add project update</Btn>

                            <div class="account-info-card mb-2">
                                <div class="account-info-card__section mt-3 mb-3">
                                    <div class="mb-2">Project Log</div>

                                    <div class="project-status-card mb-4">
                                        <div class="project-status-card__item" v-if="project.payment_method === 'hourly'">
                                            <div class="project-status-card__label">Total time invested</div>
                                            <div class="project-status-card__value">{{ stats.total_minutes | toDuration }}</div>
                                        </div>
                                        <div class="project-status-card__item">
                                            <div class="project-status-card__label">{{ offer.client.id == user.id ? 'Total money spent' : 'Total money received' }}</div>
                                            <div class="project-status-card__value">${{ stats.total_paid }} <span class="text-secondary" v-if="offer.payment_method === 'fixed'">/ ${{ stats.total_price }}</span></div>
                                        </div>
                                    </div>
                                    <div class="project-log__wrapper" :class="{'loading': loading.data}">
                                        <div class="project-log__item" @click="viewProjectLog(log)" :key="log.id" v-for="log in data">
                                            <div class="project-log__item-info">
                                                <div class="project-log__status" v-if="log.payment?.price">
                                                    <span class="project-log__status-badge --paid" v-if="log.status == 2">Paid</span>
                                                    <template v-else-if="log.status == 1">
                                                        <span class="project-log__status-badge --escrow" v-if="log.approved == 2">Approved for release</span>
                                                        <span class="project-log__status-badge" v-else-if="log.approved == -1">Requested changes</span>
                                                        <span class="project-log__status-badge --escrow" v-else>Escrow</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="project-log__status-badge --escrow" v-if="log.approved == 1">Approved</span>
                                                        <span class="project-log__status-badge" v-else-if="log.approved == -1">Requested changes</span>
                                                        <span class="project-log__status-badge" v-else>Not Paid</span>
                                                    </template>
                                                </div>
                                                <div class="project-log__action">{{ log.payment?.price ? 'Review & Pay' : 'Review' }}</div>
                                                <div class="project-log__date"> {{ log.date | moment('DD MMM YYYY') }}</div>
                                                <div class="project-log__comment">
                                                    {{ log.data?.description}}
                                                </div>
                                                <div class="project-log__file" v-for="document in log.documents">
                                                    <span>{{ document.file }}</span>
                                                </div>
                                                <div class="project-log__comment_count" v-if="log.comments?.length">
                                                    <span>+{{ log.comments?.length }} updates</span>
                                                </div>
                                            </div>
                                            <div class="project-log__item-data">
                                                <div class="project-log__data-card --white" v-if="log.minutes">
                                                    <div class="project-log__data-card__label">Time invested</div>
                                                    <div class="project-log__data-card__value">{{ log.minutes | toDuration }}</div>
                                                </div>
                                                <div class="project-log__data-card --dark" v-if="log.payment?.price">
                                                    <div class="project-log__data-card__label">Cost</div>
                                                    <div class="project-log__data-card__value">{{ log.payment.price | toPrice}}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="text-center" v-if="has_more"><Btn class="btn-secondary-light" @click="loadMore" :loating="loading.load_more">Load more</Btn></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="l-sidebar">
                            <div class="account-info-card mb-2">
                                <div class="account-info-card__section mt-3">
                                    <div>Project Status</div>
                                </div>
                                <div class="account-info-card__body">
<!--                                    <AcceptedIcon/> -->
                                    {{ offer.status_label }}
                                </div>
                            </div>
                            <div class="account-info-card mb-2" :class="{'loading': loading.stats}">
                                <div class="account-info-card__section mt-3 mb-3">
                                    <div class="mb-2">Billing</div>

                                    <table class="billing-stats">
                                        <tbody>
                                        <tr>
                                            <td>Requested</td>
                                            <td> {{ stats.total_requested | toPrice }}</td>
                                        </tr>
<!--                                        <tr>-->
<!--                                            <td>Approved for escrow</td>-->
<!--                                            <td> {{ stats.total_approved | toPrice }}</td>-->
<!--                                        </tr>-->
                                        <tr>
                                            <td>Escrow</td>
                                            <td> {{ stats.total_escrow | toPrice }}</td>
                                        </tr>
                                        <tr>
                                            <td>Approved for release</td>
                                            <td> {{ stats.total_approved_release | toPrice }}</td>
                                        </tr>
                                        <tr>
                                            <td>Paid</td>
                                            <td> {{ stats.total_paid | toPrice }}</td>
                                        </tr>
                                        <tr v-if="offer.payment_method === 'fixed'">
                                            <td>Project Price</td>
                                            <td class="text-secondary">{{ stats.total_price | toPrice }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <template v-if="user.id === offer.client.id">
                                <div>
<!--                                    <Btn :disabled="!stats.total_remaining_requested" v-if="application.payment_method === 'hourly'" @click="approvePayment" class="btn-secondary mb-2 ml-0 &#45;&#45;w250">{{ application.payment_method === 'fixed' ? 'Approve for payment' : 'Approve all' }}</Btn>-->
                                    <Btn :disabled="!stats.total_remaining_release" @click="approveRelease" class="btn-secondary mb-2 ml-0 --w250">{{ offer.payment_method === 'fixed' ? 'Approve for release' : 'Approve all for release' }}</Btn>
                                </div>
                                <div>
                                    <Btn :disabled="!stats.total_approved" @click="showBulkPayment" v-if="offer.payment_method === 'hourly'" class="btn-primary mb-2 ml-0 --w250">Pay to Escrow</Btn>
                                    <Btn :disabled="!stats.total_escrow" @click="bulkReleaseEscrow" :loading="loading.release" class="btn-primary mb-2 ml-0 --w250">Release from Escrow</Btn>
                                </div>
                            </template>
                            <template v-else-if="offer.payment_method === 'fixed'">
                                <Btn :disabled="!stats.total_escrow" :loading="loading.request_payment" @click="requestPayment" class="btn-secondary mb-2 ml-0 --w250">Request payment</Btn>
                            </template>
                        </div>
                    </div>
                </Tab>
<!--                <Tab name="Offer Details">-->
<!--                    <OfferDetails :item="offer"/>-->
<!--                </Tab>-->
                <Tab name="Billing History">
                    <BillingHistory ref="payments" :project_id="project.id" :application_id="offer.id"/>
                </Tab>
                <Tab name="Messages">
                    <ChatDiscussion :conversation="conversation" v-if="conversation" :showUserInfo="false"/>
                </Tab>
            </Tabs>
        </div>
        <modal name="addProjectLogModal" :scrollable="true" width="740px" height="auto" :pivotY="0.2" style="z-index: 99999 !important;">
            <div class="modal-dialog" style="max-width: 650px">
                <div class="modal-content" style="width: 650px" v-if="add_log">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('addProjectLogModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title" v-if="add_log.parent_id">{{ project_log && user.id === project_log.client.id ? 'Add comment' : 'Request change' }}</h4>
                        <h4 class="modal-title" v-else>Add project update log</h4>
                    </div>

                    <div class="modal-body" v-if="offer">
                        <FormTextarea class="mb-2" label="Description" v-model="add_log.data.description"/>
                        <FormGroup class="mb-2" label="You can attach a file if you want to add further details.">
                            <DocumentsUploader v-model="add_log.documents" :multiple="true"/>
                        </FormGroup>
                        <template v-if="!add_log.parent_id && offer.client.id === user.id">
                            <template v-if="offer.payment_method === 'hourly'">
                                <div class="row">
                                    <div class="col-md-6">
                                        <FormInput class="mb-2" label="Hours" type="number" :min="0" :max="can_charge" v-model="add_log.hours" append_text="h" :v="$v.add_log.hours"/>
                                    </div>
                                    <div class="col-md-6">
                                        <FormInput class="mb-2" label="Request payment" :disabled="true" v-model="add_log.price" append_text="$"/>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="can_charge > 0 && offer.payment_method === 'fixed'">
                                <FormInput class="mb-2" label="Request payment" type="number" append_text="$" :min="0" :max="can_charge" v-model="add_log.price" :v="$v.add_log.price"/>
                            </template>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.send" class="btn btn-lg btn-secondary btn-block" @click="submitAddLog()">{{ project_log && user.id === project_log.client.id ? 'Request change' : 'Submit Your Update' }}</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <ModalSidebar name="projectLogDetailsModal">
            <div class="p-details-wrapper">
                <div class="p-details" :class="{'loading': loading.details}">
                    <div class="row" v-if="project_log">
                        <div class="col-md-5">
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="p-details__info">
                                        <UserAvatar class="mb-3" size="large" :user="project_log.client"/>
                                        <template v-if="project_log.client.isAgency">
                                            <div class="p-details__info__title">{{ project_log.client.company.name }}
                                                <template v-if="project_log.client.company.website">
                                                    <br>
                                                    <div class="p-details__info__subtitle">{{ project_log.client.company.website }}</div>
                                                </template>
                                            </div>

                                        </template>
                                        <template v-else>
                                            <div class="p-details__info__title">{{ project_log.client.fullname }}</div>
                                        </template>
<!--                                        <div class="p-details__info__desc">-->
<!--                                            {{ project_log.client?.description }}-->
<!--                                        </div>-->
<!--                                        <div class="p-details__info__stats">-->
<!--                                            <span v-if="project_log.client?.address"><LocationIcon/> {{ [project_log.client.address.city_name, company.project_log.client.country_name].join(', ') }}</span>-->
<!--                                            <span><StarIcon/> {{ project_log.client?.avgReview ? project_log.client.avgReview : '-' }}/5 <template v-if="project_log.client?.countReview">({{ project_log.client.countReview }})</template></span>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="card">
                                <div class="p-details__section">
                                    <div class="p-details__section-title">
                                        {{ project_log.payment ? 'Review & Pay' : 'Review' }}
                                        <div class="p-details__section-subtitle">{{ project_log.date | moment('DD MMM YYYY') }}</div>
                                    </div>
                                    {{ project_log.data.description }}<br>

                                </div>
                                <div class="p-details__section" id="documents" v-if="project_log.documents?.length">
                                    <div class="p-details__section-title">
                                        Attached Documents
                                    </div>
                                    <div class="p-details__docs-wrapper">
                                        <DocumentCard :key="document.id" :document="document" v-for="document in project_log.documents" />
                                    </div>
                                </div>
                                <template v-if="project_log.comments?.length">
                                    <div class="p-details__section" id="documents" v-for="comment in project_log.comments">
                                        <div class="p-details__section-title">
                                            {{ comment.client.id === project.client.id ? 'Requested changes' : 'Comment' }}
                                            <div class="p-details__section-subtitle">{{ comment.date | moment('DD MMM YYYY') }}</div>
                                        </div>
                                        <div class="mb-2">{{ comment.data.description }}</div>
                                        <div class="p-details__docs-wrapper">
                                            <DocumentCard :key="document.id" :document="document" v-for="document in comment.documents" />
                                        </div>
                                    </div>
                                </template>
                                <div class="p-details__section" id="documents" v-if="project_log.payment">
                                    <div class="p-details__section-title">
                                        Payment
                                    </div>
                                    <div class="mb-2">
                                        <div class="p-details__activity"><b>Status:</b> {{ project_log.payment.status_label }}</div>
                                        <div class="p-details__activity"><b>Amount is Escrow:</b> {{ project_log.payment.total | toPrice }}</div>
                                        <div class="p-details__activity" v-for="tax in project_log.payment.taxes.taxes"><b>Tax {{ tax.name }}</b> {{ project_log.payment.price + project_log.payment.fee_value | toPrice }} x {{ tax.value | toPrice }} % = {{ tax.amount | toPrice }}</div>
                                        <div class="p-details__activity"><b>BOLG Fee:</b> {{ project_log.payment.price | toPrice }} x {{ project_log.payment.fee_percentage | toPrice }} % = {{ project_log.payment.fee_value | toPrice }}</div>
                                        <div class="p-details__activity"><b>Amount requested:</b> {{ project_log.payment.price | toPrice }}</div>
                                    </div>
                                    <div class="mb-2" v-if="user.id === project.client.id">
                                        <small class="text-muted">
<!--                                            TODO check logic with client-->
                                            You have 14 days from the submission of work to make a payment or request changes.
                                            If neither action is taken by Aug 14, the {{ project_log.payment.total | toPrice }} held in escrow for this milestone will be automatically released to {{ project_log.client.firstname }}.
                                        </small>
                                    </div>
                                    <div>
<!--                                        <Btn v-if="project_log.payment && project_log.canApprove" :disabled="show_buttons" @click="approveLog" :loading="loading.approve" class="btn-secondary mb-2">Approve & Pay to Escrow</Btn>-->
<!--                                        <Btn v-if="project_log.payment && project_log.canApprove" @click="approveWithSavedCard" :loading="loading.approve" class="btn-outline-secondary mb-2">Approve with saved card</Btn>-->
<!--                                        <Btn v-if="project_log.payment && project_log.canRelease" @click="releaseEscrow" :loading="loading.release" class="btn-secondary mb-2">Release from Escrow</Btn>-->
                                        <Btn v-if="user.id === project.client.id" :disabled="project_log.approved === 1 || project_log.status === 1" @click="approveForPayment" :loading="loading.approve" class="btn-secondary mb-2">Approve for payment</Btn>
                                        <Btn v-if="user.id === project.client.id" :disabled="project_log.approved === 2 || project_log.status !== 1" @click="approveForRelease" :loading="loading.approve" class="btn-secondary mb-2">Approve for release</Btn>
                                        <Btn v-if="user.id === project.client.id" :disabled="project_log.status > 0" @click="showRequestChanges" class="btn-outline-light mb-2">Request Changed</Btn>
                                        <div class="mt-4" id="paypal-buttons"></div>
                                    </div>
                                </div>
                                <div class="p-details__section" v-if="user.id === project_log.client.id">
                                    <Btn @click="deleteLog" :loading="loading.delete" class="btn-outline-danger" v-if="project_log.status <= 0">Delete update</Btn>
                                    <Btn @click="showRequestChanges" class="btn-outline-light">Add comment</Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalSidebar>

        <ModalSidebar name="ProjectDetailsModal">
            <ProjectDetails :item="project" :preview="true" :modal="true"/>
        </ModalSidebar>
        <ModalSidebar name="ProjectOfferDetailsModal">
            <OfferDetails :item="offer" :preview="true" :modal="true"/>
        </ModalSidebar>

        <modal name="bulkPaymentModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content" v-if="payment">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('bulkPaymentModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Pay approved requests</h4>
                    </div>
                    <div class="modal-body">
                        <p></p>
                    </div>
                    <div class="mb-2">
                        <div class="p-details__activity"><b>Amount to Escrow:</b> {{ payment.price | toPrice }}</div>
                        <div class="p-details__activity"><b>BOLG Fee:</b> {{ payment.price | toPrice }} x {{ payment.fee_percentage | toPrice }} % = {{ payment.fee_value | toPrice }}</div>
                        <div class="p-details__activity" v-for="tax in payment.taxes.taxes"><b>Tax {{ tax.name }}</b> {{ payment.price + payment.fee_value | toPrice }} x {{ tax.value | toPrice }} % = {{ tax.amount | toPrice }}</div>
                        <div class="p-details__activity"><b>Amount to be paid:</b> {{ payment.total | toPrice }}</div>
                    </div>
                    <div>
                        <Btn :disabled="show_buttons" @click="bulkPayment" :loading="loading.deposit" class="btn-secondary mb-2">Pay with PayPal</Btn>
                        <Btn @click="bulkPaymentWithSavedCard" :loading="loading.deposit" class="btn-outline-secondary mb-2">Pay with saved card</Btn>
                        <div class="mt-4" id="paypal-buttons"></div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import {modelInfiniteListMixin} from "~/mixins/modelInfiniteListMixin";
import ProjectCard from "@/pages/Projects/ProjectCard.vue";
import ProjectDropdown from "@/components/ProjectDropdown.vue";
import Tab from "@/components/Tab.vue";
import Tabs from "@/components/Tabs.vue";
import ProjectDetails from "@/pages/Projects/ProjectDetails.vue";
import OfferDetails from "@/pages/Account/OfferDetails.vue";
import InvoicesList from "@/partials/InvoicesList.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import ChatDiscussion from "@/components/Messenger/ChatDiscussion.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import DocumentCard from "@/components/DocumentCard.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import DocumentsUploader from "@/components/DocumentsUploader.vue";
import FormInput from "@/components/FormInput.vue";
import {mapState} from "vuex";
import ProjectOfferDetailsDropdown from "@/components/ProjectOfferDetailsDropdown.vue";
import BillingHistory from "@/pages/Account/BillingHistory.vue";
import {minValue, maxValue, required} from "vuelidate/lib/validators";
import {paypalMixin} from "@/mixins/paypalMixin";

export default {
    name: "ProjectOfferDetails",
    mixins: [modelInfiniteListMixin, paypalMixin],
    components: {
        ProjectOfferDetailsDropdown,
        BillingHistory,
        DocumentCard, ModalSidebar, FormInput, DocumentsUploader, FormTextarea, OfferDetails, ChatDiscussion, UserAvatar, InvoicesList, ProjectDetails, Tabs, Tab, ProjectDropdown, ProjectCard, ToggleNav, Btn},
    data() {
        return {
            loading: {
                send: false,
                stats: false,
                approve: false,
                release: false,
                request_payment: false,
            },
            filters: {
                status: 2,
            },
            project: null,
            offer: null,
            conversation: null,
            data: [],
            stats: {
                total_minutes: 0,
                total_paid: 0,
                total_requested: 0,
                total_approved: 0,
                total_remaining_requested: 0,
                total_escrow: 0,
                total_approved_release: 0,
                total_remaining_release: 0,
                total_price: 0,
            },
            payment: null,
            project_log: null,
            add_log: {
                data: {
                    description: '',
                },
                documents: [],
                price: '',
                hours: '',
                parent_id: null
            },
            show_buttons: false,
            can_charge: 0,
        }
    },
    watch: {
        'add_log.hours'(value) {
            if (this.offer.payment_method === 'hourly') {
                if (value) {
                    this.add_log.price = value * this.offer.price;
                }else {
                    this.add_log.price = 0;
                }
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    validations() {
        let rules = {
            add_log: {
                hours: {},
                price: {},
            }
        }
        if(!this.add_log.parent_id && this.can_charge > 0) {
            if (this.offer.payment_method === 'hourly') {
                rules.add_log.hours = {required, min: minValue(-1), max: maxValue(this.can_charge)}
            } else {
                rules.add_log.price = {required, min: minValue(-1), max: maxValue(this.can_charge)}
            }
        }
        return rules;
    },
    async mounted() {
        await this.getDetails();
        this.getData();
        this.getStats();
    },
    methods: {
        getDetails() {
            this.loading.details = true;
            return this.$axios.post('/account/projects/offers/ongoing-details', {
                id: this.$route.params.id
            })
                .then((res) =>{
                    this.project = res.project;
                    this.offer = res.offer;
                    this.conversation = res.conversation;
                    this.loading.details = false;
                })
                .catch((error) => {
                    this.loading.details = false;
                });
        },
        getStats() {
            this.loading.stats = true;
            this.$axios.post('/account/projects/stats', {
                project_id: this.project.id,
                application_id: this.offer.id
            })
                .then((res) =>{
                    this.stats = {...this.stats, ...res.stats};
                    this.stats.total_remaining_release = this.stats.total_escrow - this.stats.total_approved_release;
                    this.stats.total_remaining_requested = this.stats.total_requested - this.stats.total_approved;
                    this.payment = res.payment;
                    this.can_charge = res.can_charge;
                    this.loading.stats = false;
                })
                .catch((error) => {
                    this.loading.stats = false;
                });
        },
        getData() {
            this.loading.data = true;
            return this.$axios.post('/account/projects/log/list', {
                ...this.filters, ...{project_id: this.project.id, application_id: this.offer.id}
            })
                .then((res) =>{
                    if (this.filters.after_id) {
                        this.data = [...this.data, ...res.data];
                    }else{
                        this.data = res.data;
                    }
                    this.has_more = res.has_more;
                })
                .finally((error) => {
                    this.loading.data = false;
                });
        },
        async viewProjectLog(log) {
            this.project_log = log;
            this.show_buttons = false;
            await this.$modal.show('projectLogDetailsModal');
        },
        addProjectLog() {
            this.add_log = {
                data: {
                    description: '',
                },
                documents: [],
                price: '',
                hours: '',
            }
            this.$modal.show('addProjectLogModal');
        },
        submitAddLog() {
            this.$v.add_log.$touch();
            if (this.$v.add_log.$invalid === true) {
                return false;
            }
            this.loading.send = true;
            this.$axios.post("/account/projects/log/add", {...this.add_log, ...{
                    project_id: this.project.id,
                    application_id: this.offer.id
                }})
                .then((res) => {
                    this.loading.send = false;
                    if (this.add_log.parent_id && res.parent_project_log) {
                        let index = this.data.findIndex(log => log.id == res.parent_project_log.id)
                        this.data[index] = res.parent_project_log;
                    }else {
                        this.getData();
                    }
                    this.$modal.hide('addProjectLogModal');
                    this.$modal.hide('projectLogDetailsModal');
                })
                .catch((res) => {
                    this.loading.send = false;
                })
        },
        async approveLog() {
            if (this.show_buttons) {
                return;
            }
            this.show_buttons = true;
            try{
                await this.initPaypal();
                await window.paypal.Buttons({
                createOrder: async () => {
                    console.log(this)
                    return await this.$axios.post("/account/projects/log/approve", {
                        id: this.project_log.id,
                        action: 'pay'
                    })
                        .then(async (res) => {
                            return res.order.id;
                        })
                        .catch((res) => {
                            this.loading.approve = false;
                        })
                },
                onApprove: async (data, actions) => {
                    return await this.$axios.post("/account/projects/log/approve", {
                        id: this.project_log.id,
                        order_id: data.orderID,
                        action: 'pay'
                    })
                        .then(async (res) => {
                            if (res.project_log) {
                                let index = this.data.findIndex(log => log.id == res.project_log.id)
                                this.data[index] = res.project_log;
                            }else {
                                this.getData();
                            }
                            this.getStats();
                            this.$refs.payments.getData();
                            this.$modal.hide('projectLogDetailsModal');
                            this.loading.approve = false;
                        })
                        .catch((error) => {
                            console.log(error)
                            this.loading.approve = false;
                        })
                },
                onError(err) {
                    console.log(err)
                }
            }).render('#paypal-buttons')
            } catch (error) {
                this.show_buttons = false;
                alert('Paypal could not be initialized, try again later')
                console.log('Paypal error:', error)
            }
        },
        approveWithSavedCard() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(async () => {
                    this.loading.approve = true;
                    this.$axios.post("/account/projects/log/approve", {
                        id: this.project_log.id,
                        use_saved_card: true,
                        action: 'pay'
                    })
                    .then(async (res) => {
                        if (res.project_log) {
                            let index = this.data.findIndex(log => log.id == res.project_log.id)
                            this.data[index] = res.project_log;
                        }else {
                            this.getData();
                        }
                        this.getStats();
                        this.$refs.payments.getData();
                        this.$modal.hide('projectLogDetailsModal');
                        this.loading.approve = false;
                    })
                    .catch((res) => {
                        this.loading.approve = false;
                    })

            }).catch(error => {
                console.log(error)
            })
        },
        releaseEscrow() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.release = true;
                    this.$axios.post("/account/projects/log/releaseEscrow", {
                        id: this.project_log.id,
                        action: 'release',
                    })
                        .then((res) => {
                            this.loading.release = false;
                            if (res.project_log) {
                                let index = this.data.findIndex(log => log.id == res.project_log.id)
                                this.data[index] = res.project_log;
                            }else {
                                this.getData();
                            }
                            this.getStats();
                            this.$refs.payments.getData();
                            this.$modal.hide('projectLogDetailsModal');
                        })
                        .catch((res) => {
                            this.loading.release = false;
                        })
                })
        },
        showRequestChanges() {
            this.add_log = {
                data: {
                    description: '',
                },
                documents: [],
                price: '',
                hours: '',
                parent_id: this.project_log.id
            }
            this.$modal.show('addProjectLogModal');
        },
        approveForPayment() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(async () => {
                    this.loading.approve = true;
                    this.$axios.post("/account/projects/log/approve", {
                        id: this.project_log.id,
                        action: 'approve'
                    })
                        .then(async (res) => {
                            if (res.project_log) {
                                let index = this.data.findIndex(log => log.id == res.project_log.id)
                                this.data[index] = res.project_log;
                            }else {
                                this.getData();
                            }
                            this.getStats();
                            this.$modal.hide('projectLogDetailsModal');
                            this.loading.approve = false;
                        })
                        .catch((res) => {
                            this.loading.approve = false;
                        })

                }).catch(error => {
                console.log(error)
            })
        },
        approveForRelease() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(async () => {
                    this.loading.approve = true;
                    this.$axios.post("/account/projects/log/releaseEscrow", {
                        id: this.project_log.id,
                        action: 'approve-release'
                    })
                        .then(async (res) => {
                            if (res.project_log) {
                                let index = this.data.findIndex(log => log.id == res.project_log.id)
                                this.data[index] = res.project_log;
                            }else {
                                this.getData();
                            }
                            this.getStats();
                            this.$modal.hide('projectLogDetailsModal');
                            this.loading.approve = false;
                        })
                        .catch((res) => {
                            this.loading.approve = false;
                        })

                }).catch(error => {
                console.log(error)
            })
        },
        deleteLog() {
            this.$dialog.confirm('Are you sure you want to delete this update?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.delete = true;
                    this.$axios.post("/account/projects/log/delete", {
                            id: this.project_log.id
                        })
                        .then((res) => {
                            this.loading.delete = false;
                            let index = this.data.findIndex(log => log.id == this.project_log.id)
                            this.data.splice(index, 1)
                            this.$modal.hide('projectLogDetailsModal');
                        })
                        .catch((res) => {
                            this.loading.delete = false;
                        })
                })
        },
        showBulkPayment() {
            this.$modal.show('bulkPaymentModal');
        },

        async bulkPayment() {
            if (this.show_buttons) {
                return;
            }
            this.show_buttons = true;
            try {
                await this.initPaypal();
                await window.paypal.Buttons({
                    createOrder: async () => {
                        console.log(this)
                        return await this.$axios.post("/account/projects/bulk-payment", {
                            project_id: this.project.id,
                            application_id: this.offer.id,
                            action: 'pay'
                        })
                            .then(async (res) => {
                                return res.order.id;
                            })
                            .catch((res) => {
                                this.loading.deposit = false;
                            })
                    },
                    onApprove: async (data, actions) => {
                        return await this.$axios.post("/account/projects/bulk-payment", {
                            project_id: this.project.id,
                            order_id: data.orderID,
                            action: 'pay'
                        })
                            .then(async (res) => {
                                this.getData();
                                this.getStats();
                                this.$refs.payments.getData();
                                this.$modal.hide('bulkPaymentModal');
                                this.loading.deposit = false;
                            })
                            .catch((error) => {
                                console.log(error)
                                this.loading.deposit = false;
                            })
                    },
                    onError(err) {
                        console.log(err)
                    }
                }).render('#paypal-buttons')
            } catch (error) {
                this.show_buttons = false;
                alert('Paypal could not be initialized, try again later')
                console.log('Paypal error:', error)
            }
        },
        bulkPaymentWithSavedCard() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(async () => {
                    this.loading.deposit = true;
                    this.$axios.post("/account/projects/bulk-payment", {
                        project_id: this.project.id,
                        application_id: this.offer.id,
                        use_saved_card: true,
                        action: 'pay'
                    })
                        .then(async (res) => {
                            this.getData();
                            this.getStats();
                            this.$refs.payments.getData();
                            this.$modal.hide('bulkPaymentModal');
                            this.loading.deposit = false;
                        })
                        .catch((res) => {
                            this.loading.deposit = false;
                        })

                }).catch(error => {
                console.log(error)
            })
        },
        bulkReleaseEscrow() {
            this.$dialog.confirm('Are you sure? You will release all escrow now.', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.release = true;
                    this.$axios.post("/account/projects/bulk-release", {
                        project_id: this.project.id,
                        application_id: this.offer.id,
                    })
                        .then((res) => {
                            this.loading.release = false;
                            this.getData();
                            this.getStats();
                            this.$refs.payments.getData();
                        })
                        .catch((res) => {
                            this.loading.release = false;
                        })
                })
        },
        requestPayment() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.request_payment = true;
                    this.$axios.post("/account/projects/request-payment", {
                        project_id: this.project.id,
                        application_id: this.offer.id,
                    })
                        .then((res) => {
                            this.loading.request_payment = false;
                            this.getData();
                            this.getStats();
                        })
                        .catch((res) => {
                            this.loading.request_payment = false;
                        })
                })
        },
        approvePayment() {
            this.$dialog.confirm('Are you sure? All eligible requested payments will be processed on Friday.', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.request_payment = true;
                    this.$axios.post("/account/projects/approve-payment", {
                        project_id: this.project.id,
                        application_id: this.offer.id,
                    })
                        .then((res) => {
                            this.loading.request_payment = false;
                            this.getData();
                            this.getStats();
                        })
                        .catch((res) => {
                            this.loading.request_payment = false;
                        })
                })
        },
        approveRelease() {
            this.$dialog.confirm('Are you sure? All eligible escrow payments will be released when processed.', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.request_payment = true;
                    this.$axios.post("/account/projects/approve-release", {
                        project_id: this.project.id,
                        application_id: this.offer.id,
                    })
                        .then((res) => {
                            this.loading.request_payment = false;
                            this.getStats();
                            this.getData();
                        })
                        .catch((res) => {
                            this.loading.request_payment = false;
                        })
                })
        },
    }
}
</script>

<template>
   <div @drop.prevent="addImage" @dragover.prevent>
       <div class="image-uploader-crop mx-auto" :style="imageSize" :class="{'loading' : loading.upload}">
           <ClientOnly>
               <template v-if="image">
                   <cropper
                           ref="cropper"
                           :src="image.base_file"
                           @ready="setCoordinates"
                           image-restriction="stencil"
                           :canvas="false"
                           :stencil-props="{
                                handlers: {},
                                movable: false,
                                resizable: false,
                            }"
                           :minWidth="size"
                           :minHeight="size"
                           :stencilComponent="CircleStencil"
                           :stencil-size="{
                                width: size,
                                height: size
                            }"
                           :resize-image="{
                                adjustStencil: false
                           }"
                   />
               </template>
               <ImageIcon v-else width="24" height="24"/>
           </ClientOnly>
       </div>

       <label style="cursor: pointer; display: block" v-if="image">
           <input style="display: none" type="file" name="file" accept="image/*" @change="addImage" :multiple="false" :disabled="loading.upload" ref="input"/>
           <div class="btn btn-block btn-outline-light">Change Photo</div>
       </label>
       <label v-else style="cursor: pointer; display: block">
           <input style="display: none" type="file" name="file" accept="image/*" @change="addImage" :multiple="false" :disabled="loading.upload" ref="input"/>
           <div class="btn btn-block btn-outline-light">Select Photo</div>
       </label>
       <Btn :loading="loading.save" class="btn-block btn-secondary-light" @click="saveCrop" v-if="image">Save Photo Position</Btn>
   </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import {CircleStencil, Cropper} from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    import Btn from "./Btn.vue";

    export default {
        name: 'ImageUploaderCrop',
        props: ['value', 'multiple', 'target', 'target_id', 'editable', 'roles', 'width', 'height', 'size'],
        components: {Btn, draggable, Cropper},
        computed: {
            CircleStencil() {
                return CircleStencil
            },
            imageSize() {
                if (!this.height && !this.width) {
                    return {
                        height: '320px',
                        width: '320px'
                    }
                }
                const height = 200;
                const ar = this.width/this.height;
                const image_width = height * ar;

                return {height: height + 'px', width: image_width + 'px'}
            },
        },
        data () {
            return {
                loading: {
                    upload: false,
                    save: false,
                },
                id: '',
                image: null,
                hoverDropZone: false,
            }
        },
        mounted() {
            this.image = this.value;
            this.id = this._uid;
        },
        watch: {
            value(a) {
                this.image = a;
            },
            image: {
                handler(image) {
                    //console.log({image})
                    this.$emit('input', image);
                },
                deep: true,
            },
        },
        methods: {
            async addImage(e) {
                this.hoverDropZone = false;
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;

                this.uploadImage(files[0], e.target);
            },
            uploadImage(file, file_input) {
                if (['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp'].indexOf(file.type) < 0) {
                    this.$toast.error('File type not allowed');
                    this.image = null;
                    return;
                }
                var formData = new FormData();
                formData.append('file', file);
                formData.append('target', this.target);
                formData.append('id', this.target_id);
                formData.append('replace', !this.multiple);
                if (!this.multiple) {
                    this.loading.upload = true;
                }

                return this.$axios.post("/upload-image", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    emulateJSON: true,
                    files: true
                })
                    .then((res) => {
                        this.loading.upload = false;
                        this.image = res.image;
                        this.cropImage(this.image)
                        this.$forceUpdate();
                    })
                    .catch((error) => {
                        this.loading.upload = false;
                        this.image = null;
                        this.$forceUpdate();
                    });
            },
            removeImage(image) {
                if (!this.target_id) {
                    this.image = null;
                    return;
                }
                this.$dialog.confirm('Are you sure you want to delete this image?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.loading.save = true;
                        this.$forceUpdate();
                        this.$axios.post("/delete-image", {id: image.id})
                            .then((res) => {
                                this.image = null;
                                this.loading.save = false;
                            })
                            .catch((res) => {
                                this.loading.save = false;
                            })
                    });
            },
            removeImagePlaceholder() {
                this.image = null;
                this.$forceUpdate();
            },
            cropImage(image) {
                this.image = image;
                this.crop = null;
                this.$modal.show('cropImageModal_' + this.id);
            },
            setCoordinates () {
                if (this.image.crop) {
                    this.$refs.cropper.setCoordinates(this.image.crop)
                }
            },
            changeCrop({ coordinates, canvas }) {
                this.crop = coordinates
            },
            saveCrop () {
                let results = this.$refs.cropper.getResult()
                // console.log(results)
                this.loading.save = true;
                this.$axios.post("/save-image-crop", {
                    id: this.image.id,
                    crop: results.coordinates,
                    // croppedImage: results.canvas.toDataURL()
                })
                    .then((res) => {
                        let img = this.image;
                        img.crop = res.crop;
                        img.file = res.file;
                        img.original_file = res.original_file;
                        this.loading.save = false;
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading.save = false;
                    })
            }
        }
    }
</script>


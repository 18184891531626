import Vue from 'vue';
import {AclInstaller, AclCreate, AclRule} from '~/plugins/Acl/index.js';
import { router } from '~/router';

export default ({ $cookies }) => {
    // let startRole = $cookies.get('role') ? $cookies.get('role') : 'public';
    let startRole = 'client';
    console.log({startRole})
    Vue.use(AclInstaller);

    let acl = new AclCreate({
        router: router,
        initial: startRole,
        notfound: '/auth/login',
        globalRules: {
            isAny: new AclRule('public').or('client').or('freelancer').or('company').generate(),
            isPublic: new AclRule('public').generate(),
            isLogged: new AclRule('client').or('freelancer').or('company').generate()
        }
    });
    Vue.use(acl)
};

<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Payments</div>
        </div>
        <Tabs wrapperClass="account_tabs">
            <Tab name="Billing History">
                <BillingHistory/>
            </Tab>
            <Tab name="Tax Informations">
                <TaxInformation/>
            </Tab>
            <Tab name="Payment Methods">
                <PaymentMethods/>
            </Tab>
<!--            <Tab name="Payout">-->
<!--                <Payouts/>-->
<!--            </Tab>-->
            <Tab name="Subscription">
                <div class="settings-plan-card mb-3" :class="{'loading': loading.data}">
                    <div v-if="subscription?.subscription_plan?.price">
                        <div class="settings-plan-card__name">{{ subscription.subscription_plan.name }}</div>
                        <div class="settings-plan-card__cost">{{ subscription.subscription_plan.price | toPrice }}<span class="settings-plan-card__period">/ {{ subscription.subscription_plan.frequency | beautify }}</span></div>
                    </div>
                    <div v-else>
                        <div class="settings-plan-card__name">Free</div>
                    </div>
                    <div>
                        <Btn class="btn btn-white btn-white-custom" :to="{name: 'subscription-plans'}">Change Plan</Btn>
                    </div>
                    <div class="settings-circle"></div>
                    <div class="settings-background-svg">
                        <HandAndMacbook style="position: relative; z-index: 1"/>
                        <div class="settings-circle --top-circle"></div>
                    </div>
                </div>
            </Tab>
        </Tabs>
    </div>
</template>

<script>

import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import TaxInformation from "@/pages/Account/TaxInformation.vue";
import BillingHistory from "@/pages/Account/BillingHistory.vue";
import PaymentMethods from "@/pages/Account/PaymentMethods.vue";
import HandAndMacbook from "~/assets/icons/account/hands-macbook.svg?inline";
import Btn from "@/components/Btn.vue";
import Payouts from "@/pages/Account/Payouts.vue";
import {mapState} from "vuex";

export default {
    name: "Payments",
    components: {Payouts, Btn, PaymentMethods, BillingHistory, TaxInformation, Tab, Tabs, HandAndMacbook},
    data() {
        return {
            loading: {
                data: false
            },
            subscription: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/billing-data")
                .then((res) => {
                    this.subscription = res.subscription;
                    this.loading.data = false;
                })
                .catch(() => {
                    this.loading.data = false;
                });
        },
    }
}
</script>

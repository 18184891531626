<template>
    <div class="app-wrapper" style="height: 100vh;">
        <div class="header-wrapper">
            <div class="container-fluid px-3">
                <div class="header">
                    <div class="header__actions d-block d-md-none">
                        <div class="header__actions-item">
                            <a class="header__menu-toggle mr-2" @click="toggleAccountMenu()"><MenuIcon height="32px" width="32px"/></a>
                        </div>
                    </div>
                    <div class="header__logo">
                        <NuxtLink :to="{name: 'home'}">
                            <img class="img-fluid" src="/assets/logo.svg" :width="438" :height="150" alt="BOLG"/>
                        </NuxtLink>
                    </div>
                    <div class="header__actions text-right">
                        <ClientOnly>
<!--                            <NotificationsDropdown/>-->
                            <NuxtLink class="header__actions-item" :to="{name: 'account.groups'}"><GroupsIcon/></NuxtLink>
                            <AccountDropdown/>
                        </ClientOnly>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid l-account">
            <div class="l-account-sidebar" :class="{'--visible': accountMenuVisible}">
                <VuePerfectScrollbar class="scroll-area" :settings="{maxScrollbarLength: 60}">
                    <div class="l-account-sidebar__list">
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" exact :to="{name: 'account.dashboard'}"><DashboardIcon/>Dashboard</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.messages'}"><MessagesIcon/>Messaging</nuxt-link>
    <!--                    <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.notification-settings'}"><NotificationsIcon/>Notifications</nuxt-link>-->
    <!--                    <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.linked-accounts'}"><LinkedIcon/>Linked Accounts</nuxt-link>-->
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client'].includes(user.type)" :to="{name: 'account.jobs'}"><ProjectsIcon/>Projects</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client'].includes(user.type)" :to="{name: 'account.offers'}"><JobCatalogIcon/>Offers</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['freelancer'].includes(user.type)" :to="{name: 'account.calendar'}"><CalendarIcon/>Calendar</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['freelancer'].includes(user.type)" :to="{name: 'account.jobs-catalog'}"><JobCatalogIcon/>Job Catalog</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['freelancer'].includes(user.type)" :to="{name: 'account.projects'}"><ProjectsIcon/>Projects List</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client'].includes(user.type)" :to="{name: 'account.appointments'}"><AppointmentsIcon/>Appointments</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer'].includes(user.type)" :to="{name: 'account.invitations'}"><InvitationsIcon/>Invitations</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer'].includes(user.type)" :to="{name: 'account.groups'}"><GroupsIcon/>Groups</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.members'}"><MembersIcon/>Members</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['freelancer'].includes(user.type) && user.isAgency" :to="{name: 'account.employees'}"><EmployeesIcon/>Agency Members</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.payments'}"><PaymentsIcon/>Payments</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.info'}"><AccountIcon/>Profile</nuxt-link>
                        <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.settings'}"><SettingsIcon/>Account Settings</nuxt-link>
    <!--                    <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.payment-method'}"><PaymentsIcon/>Payments Method</nuxt-link>-->
    <!--                    <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.billing-history'}"><BillingIcon/>Billing History</nuxt-link>-->
    <!--                    <nuxt-link class="l-account-sidebar__list-item" v-if="['client', 'freelancer', 'company'].includes(user.type)" :to="{name: 'account.tax-info'}"><TaxingIcon/>Tax Information</nuxt-link>-->
                    </div>
                </VuePerfectScrollbar>
            </div>
            <div class="l-account-content" v-if="inited && user" :class="{'p-0': $route.name === 'account.messages', 'loading': loadingInit}">
                <NuxtChild/>
    <!--            <NuxtChild v-slot="{ Component }">-->
    <!--                <FadeInOut entry="center" exit="center" :duration="200" mode="out-in">-->
    <!--                    <component :is="Component"/>-->
    <!--                </FadeInOut>-->
    <!--            </NuxtChild>-->
    <!--            <router-view :key="$route.meta.routeKey ? $route.meta.routeKey : $route.path"></router-view>-->
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import DashboardIcon from "~/assets/icons/account/dashboard.svg?inline";
    import AccountIcon from "~/assets/icons/account/account.svg?inline";
    import MessagesIcon from "~/assets/icons/account/messages.svg?inline";
    import NotificationsIcon from "~/assets/icons/account/notifications.svg?inline";
    import LinkedIcon from "~/assets/icons/account/linked.svg?inline";
    import JobCatalogIcon from "~/assets/icons/account/job-catalog.svg?inline";
    import ProjectsIcon from "~/assets/icons/account/projects.svg?inline";
    import CalendarIcon from "~/assets/icons/account/calendar.svg?inline";
    import AppointmentsIcon from "~/assets/icons/account/appointments.svg?inline";
    import InvitationsIcon from "~/assets/icons/account/invitations.svg?inline";
    import EmployeesIcon from "~/assets/icons/account/employees.svg?inline";
    import MembersIcon from "~/assets/icons/account/members.svg?inline";
    import GroupsIcon from "~/assets/icons/account/groups.svg?inline";
    import PaymentsIcon from "~/assets/icons/account/payments.svg?inline";
    import BillingIcon from "~/assets/icons/account/billing.svg?inline";
    import TaxingIcon from "~/assets/icons/account/taxing.svg?inline";
    import SettingsIcon from "~/assets/icons/account/settings.svg?inline";
    import AccountDropdown from "~/components/AccountDropdown.vue";
    import {initMixin} from "~/mixins/initMixin";
    import NotificationsDropdown from "~/components/NotificationsDropdown.vue";
    import {metaMixin} from "@/mixins/metaMixin";

    export default {
        name: 'accountLayout',
        mixins: [initMixin, metaMixin],
        components: {
            NotificationsDropdown,
            AccountDropdown, DashboardIcon, AccountIcon, GroupsIcon, MessagesIcon, NotificationsIcon,
            LinkedIcon, JobCatalogIcon, ProjectsIcon, InvitationsIcon, MembersIcon, PaymentsIcon, BillingIcon,
            TaxingIcon, SettingsIcon, AppointmentsIcon, EmployeesIcon, CalendarIcon
        },
        data() {
            return {
                app_name: '',
                accountMenuVisible: false
            }
        },
        computed: {
            ...mapState({
                user: state => state.user,
            }),
        },
        watch: {
            '$store.state.loaded.user': function (value) {
                this.checkUser()
            },
            '$route.path': function (value) {
                this.accountMenuVisible = false
            },
            'user.id': function () {
                if (this.user.id && !this.user.confirmed) {
                    this.$router.push({name: 'auth.confirm'});
                }
            },
        },
        activated () {
            this.$forceUpdate()
        },
        created() {

        },
        mounted() {
            // this.checkUser()
            if (this.user.id && !this.user.confirmed) {
                this.$router.push({name: 'auth.confirm'});
            }
        },
        methods: {
            // checkUser () {
            //     if (!this.user.id) {
            //         this.$router.push({ name: 'auth.login' })
            //     }
            // },
            toggleAccountMenu() {
                this.accountMenuVisible = !this.accountMenuVisible
            },
            logout() {
                this.$auth.destroyToken()
                this.$acl.change('public');
                this.$store.dispatch('logout')
                this.$router.push({ name: 'home' })
            }
        },
    }
</script>


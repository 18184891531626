<template>
    <div>
<!--        <div class="l-account-content__header">-->
<!--            <div class="l-account-content__title">Notifications</div>-->
<!--        </div>-->

        <div class="l-account-content__section-title">
            <DesktopNotificationIcon/> Desktop & Mobile Notifications
        </div>
        <div class="l-account-content__section-subtitle">Show notifications for new messages on Desktop and/or Mobile</div>

        <div :class="{'loading': loading.data }">
            <div class="notification-setting" v-for="notification in notifications.push">
                <toggle-button color="#0055E6" :height="22" v-model="notification.status" :sync="true" @change="(event) => saveNotification(notification, event.value)"/> <span class="notification-setting__label">{{ notification.title }}</span>
            </div>
        </div>
        <hr>

        <div class="l-account-content__section-title">
            <EmailNotificationIcon/> Desktop & Mobile Notifications
        </div>
        <div class="l-account-content__section-subtitle">Send me email notifications for the following:</div>

        <div :class="{'loading': loading.data }">
            <div class="notification-setting" v-for="notification in notifications.email">
                <toggle-button color="#0055E6" :height="22" v-model="notification.status" :sync="true" @change="(event) => saveNotification(notification, event.value)"/> <span class="notification-setting__label">{{ notification.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import DesktopNotificationIcon from "~/assets/icons/account/desktop_notification.svg?inline";
    import EmailNotificationIcon from "~/assets/icons/account/email_notification.svg?inline";

    export default {
        name: 'Notifications',
        components: {DesktopNotificationIcon, EmailNotificationIcon},
        head: {
            title: 'Notifications',
        },
        data () {
            return {
                loading: {
                    data: false
                },
                notifications: {
                    push: [],
                    email: []
                },

            }
        },
        methods: {
            getData() {
                this.loading.data = true;
                this.$axios.get("/account/notification-settings")
                    .then((res) =>{
                        this.notifications.push = res.notifications.push ?? [];
                        this.notifications.email = res.notifications.email ?? [];
                        this.loading.data = false;
                    })
                    .catch((error) => {
                        this.loading.data = false;
                    });
            },
            saveNotification(notification, value) {
                notification.loading = true;
                this.$axios.post("/account/notification-settings/save", {
                    notification_type_id: notification.id,
                    status: value,
                })
                    .then((res) =>{
                        notification.loading = false;
                    })
                    .catch((error) => {
                        notification.loading = false;
                        notification.status = !value
                    });
            },
        },
        mounted() {
            this.getData();
        }
    };
</script>

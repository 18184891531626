<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Appointments</div>
<!--            <div class="d-flex justify-content-between mt-3">-->
<!--                <NuxtLink :to="{name: 'account.new-appointment'}">-->
<!--                    <Btn class="btn btn-secondary"><PlusWhiteIcon/> Set Up New Appointment</Btn>-->
<!--                </NuxtLink>-->
<!--                <div>-->
<!--                    <Btn class="btn btn-outline-light"><SortIcon/> Sort By Newest</Btn>-->
<!--                    <Btn class="btn btn-outline-light"><FiltersIcon/> Filters</Btn>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <template v-if="data && data.data && data.data.length > 0">
            <div class="table-responsive">
                <table class="table table-vcentered">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Duration</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="(group, date) in grouped_data">
                            <tr>
                                <td class="table-date-divider" colspan="5">
                                    {{ date | moment('DD dddd, MMM YYYY') }}
                                </td>
                            </tr>
                            <tr v-for="appointment in group" v-bind:key="appointment.id">
                                <td>
                                    <UserAvatar size="medium" :user="appointment.with_client" :displayName="true"></UserAvatar>
                                </td>
                                <td>
                                    {{ appointment.date_from | moment('MMM DD, YYYY') }}
                                </td>
                                <td>
                                    {{ appointment.date_from | moment('HH:mm') }} - {{ appointment.date_to | moment('HH:mm') }}
                                </td>
                                <td>
                                    {{ appointment.duration }} Min
                                </td>
                                <td class="text-right">
                                    <div class="d-inline-flex align-items-center justify-content-end mr-2">
                                        <AcceptedIcon v-if="appointment.status == 1" class="mr-1"/>
                                        <DeclinedIcon v-else-if="appointment.status == -1" class="mr-1"/>
                                        {{ appointment.status_label }}
                                        <template v-if="new Date(appointment.date_to) > new Date()">
                                            <Btn v-if="appointment.status == 0" @click="cancelAppointment(appointment)" :loading="appointment.loading" class="ml-2 btn btn-outline-light">Cancel</Btn>
                                            <template v-if="appointment.status == 1">
                                                <a v-if="appointment.meeting_url" target="_blank" :href="appointment.meeting_url" class="ml-2 btn btn-outline-secondary">Join Meeting</a>
                                                <Btn v-else @click="startAppointment(appointment)" :loading="appointment.loading" class="ml-2 btn btn-outline-light">Start Meeting</Btn>
                                            </template>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </template>
        <div class="card-body" v-else-if="data && !data.data.length">
            <h4>No appointments</h4>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
    </div>
</template>

<script>
import SortIcon from "~/assets/icons/sort.svg?inline";
import FiltersIcon from "~/assets/icons/filters.svg?inline";
import Btn from "~/components/Btn.vue";
import UserAvatar from "~/components/UserAvatar.vue";
import {groupBy} from "lodash";
import ToggleNav from "~/components/ToggleNav.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import dayjs from "dayjs";

export default {
    name: "Appointments",
    mixins: [modelListMixin],
    components: {ToggleNav, UserAvatar, Btn, SortIcon, FiltersIcon },
    computed: {
        grouped_data() {
            if (!this.data) {
                return null
            }
            return groupBy(this.data.data, (appointment) => {
                return dayjs(appointment.date_from).format('YYYY-MM-DD')
            });
        }
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
                start: false,
            },
            data: null,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/appointments")
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        cancelAppointment (appointment) {
            this.$dialog.confirm('Are your sure you want to cancel this appointment?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    appointment.loading = true
                    this.$axios.post('/account/appointments/change-status', {
                        appointment_id: appointment.id,
                        status: 'canceled',
                    }).then((res) => {
                        appointment.loading = false
                        this.getData()
                    }).catch(() => {
                        appointment.loading = false
                    })
                })
        },
        startAppointment (appointment) {
            this.loading.start = true
            this.$axios.post('/account/appointments/start', {
                appointment_id: appointment.id,
            }).then((res) => {
                this.getData();
                window.open(res.data.join_url, '_blank', 'noreferrer');
                this.loading.start = false
            }).catch(() => {
                this.loading.start = false
            })
        },
    }
}
</script>

<style scoped>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"l-page-content"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"how-it-works__path"},[_c('div',{staticClass:"how-it-works__path-row"},[_c('div',{staticClass:"how-it-works__path-col --small"},[_c('Works1',{staticClass:"img-fluid"})],1),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-row"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-col --small"},[_c('Works2',{staticClass:"img-fluid"})],1)]),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-row"},[_c('div',{staticClass:"how-it-works__path-col --small"},[_c('Works3',{staticClass:"img-fluid"})],1),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-row"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-col --small"},[_c('Works4',{staticClass:"img-fluid"})],1)]),_vm._v(" "),_c('div',{staticClass:"how-it-works__path-row"},[_c('div',{staticClass:"how-it-works__path-col --small"},[_c('Works5',{staticClass:"img-fluid"})],1),_vm._v(" "),_vm._m(5)]),_vm._v(" "),_c('div',{staticClass:"how-it-works__finish"},[_c('PatternGreen',{staticClass:"how-it-works__finish-pattern"}),_vm._v("\n                    The Expert will be paid once you approve that the work is complete within one week of payment.\n                ")],1)]),_vm._v(" "),_c('Newsletter')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-page-title"},[_vm._v("\n                How It "),_c('span',{staticClass:"--underlined"},[_vm._v("Works")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works__path-col --large"},[_c('div',{staticClass:"how-it-works__step"},[_vm._v("1")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-title"},[_vm._v("Search")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-desc"},[_c('div',[_c('b',[_vm._v("User Action:")]),_vm._v(" Clients search for biotech freelancers or specific biotech services on\n                                the platform.")]),_vm._v(" "),_c('div',[_c('b',[_vm._v("Platform Function:")]),_vm._v(" Filters and search tools help clients find suitable biotech\n                                freelancers or services based on skills, ratings, availability, and other criteria.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works__path-col --large --right"},[_c('div',{staticClass:"how-it-works__step"},[_vm._v("2")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-title"},[_vm._v("Decide")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-desc"},[_c('div',[_c('b',[_vm._v("User Action:")]),_vm._v(" Clients review profiles, portfolios, and feedback to select the best\n                                biotech freelancer for their project.")]),_vm._v(" "),_c('div',[_c('b',[_vm._v("Platform Function:")]),_vm._v(" Provides detailed profiles, reviews, and ratings for biotech\n                                freelancers to help clients make informed decisions.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works__path-col --large"},[_c('div',{staticClass:"how-it-works__step"},[_vm._v("3")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-title"},[_vm._v("Purchase")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-desc"},[_c('div',[_c('b',[_vm._v("User Action:")]),_vm._v(" Clients agree on project terms with the biotech freelancer and make\n                                an upfront payment for the agreed-upon hours or fixed price.")]),_vm._v(" "),_c('div',[_c('b',[_vm._v("Platform Function:")]),_vm._v(" Securely holds the payment in escrow until the work is\n                                completed and approved.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works__path-col --large --right"},[_c('div',{staticClass:"how-it-works__step"},[_vm._v("4")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-title"},[_vm._v("Execution")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-desc"},[_c('div',[_c('b',[_vm._v("User Action:")]),_vm._v(" Biotech freelancers complete the work according to the agreed-\n                                upon terms and submit it for review.\n                            ")]),_vm._v(" "),_c('div',[_c('b',[_vm._v("Platform Function:")]),_vm._v(" Allows biotech freelancers to log hours worked, update\n                                project status, and communicate with clients during the project.\n                            ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works__path-col --large"},[_c('div',{staticClass:"how-it-works__step"},[_vm._v("5")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-title"},[_vm._v("Approval")]),_vm._v(" "),_c('div',{staticClass:"how-it-works__step-desc"},[_c('div',[_c('b',[_vm._v("User Action:")]),_vm._v(" Clients review the submitted work within a stipulated time frame\n                                (e.g., 5 days) and either approve it or request revisions.")]),_vm._v(" "),_c('div',[_c('b',[_vm._v("Platform Function:")]),_vm._v(" Releases the payment to the biotech freelancer after the\n                                client’s approval or a holding period if no disputes are raised.")])])])
}]

export { render, staticRenderFns }
<template>
    <div>
        <div id="stripeConnect" class="card mb-2 mt-2" :class="{'loading': loading.data}">
            <div class="card-body">
                <div class="row" style="color: white">
                    <div class="col-sm-8">
                        <h3 class="card-title">Connect with <b> Stripe</b></h3>
                        <div class="card-subtitle ">To be able to receive and make payments first connect to Stripe.</div>
<!--                        <div class="mt-2" v-if="stripe_connect.stripe_id">Stripe Id : {{ stripe_connect.stripe_id }}</div>-->
                    </div>
                    <div class="col-sm-4 d-flex flex-row align-items-center justify-content-lg-end">
<!--                        <template v-if="stripe_connect.stripe_id && stripe_connect.stripe_status === 1">-->
<!--                            <a href="#" class="btn btn-outline-warning btn-sm mt-2 &#45;&#45;w220" :style="{ cursor: 'pointer'}" @click="disconnectFromStripe()">Disconnect</a>-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            <a :href="stripe_oauth_link" target="_blank" class="btn btn-primary btn-sm  mt-2&#45;&#45;w220" style="white-space: nowrap" :style="{ cursor: 'pointer'}">Connect with <b> Stripe</b></a>-->
<!--                        </template>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Payment Method</div>
        </div>
        <div style="max-width: 100%; width: 566px" :class="{'loading': loading.data}">
            <div class="payment-add-row">
                <span>Your Bank Cards</span>
                <div class="add-payment-btn" @click="addCreditCard"><PlusWhiteIcon class="mr-1"/>Add New Bank Card</div>
            </div>
            <div class="payment-card mb-2" v-for="card in cards" @click="setCard(card)">
                <div class="d-flex flex-row align-items-center" style="flex: 1">
                    <span>
                        <form-payment-radio :hideText="true" :id="card.id" :text="card.type" :fieldValue="card.id" name="card" v-model="selected_card_id"></form-payment-radio>
                    </span>
                    <VisaIcon v-if="card.brand === 'visa'" width="71" height="50" class="mr-2"/>
                    <MastercardIcon v-else-if="card.brand === 'mastercard'" width="71" height="50" class="mr-2"/>
                    <AmexIcon v-else-if="card.brand === 'amex'" width="71" height="50" class="mr-2"/>
                    <CardIcon v-else class="mr-2" width="71" height="50"/>
                    <span>{{ card.name }}</span>
                </div>
                <span>{{ card.hash }}</span>
                <div><TrashIcon width="70" @click="deleteCard(card)"/></div>
            </div>
            <Btn v-if="selected_card_id" class="btn-secondary" :loading="loading.select" @click="setActiveCard">Set as Active</Btn>
        </div>
        <modal name="addCardModal" height="auto" :scrollable="true" style="border-radius: 10px">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('addCardModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Add a New Bank Card</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2" label="Cardholder" v-model="newCard.cardholder"/>
                        <ClientOnly>
                            <stripe-element-card
                                ref="newCard"
                                :pk="stripe_public_key"
                                @token="saveCard"
                            />
                        </ClientOnly>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="submitSaveCard()">Add Bank Card</Btn>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import VisaIcon from "~/assets/icons/account/visa-logo.svg?inline";
import MastercardIcon from "~/assets/icons/account/mastercard-logo.svg?inline";
import AmexIcon from "~/assets/icons/account/amex-logo.svg?inline";
import CardIcon from "~/assets/icons/account/card.svg?inline";
import FormPaymentRadio from "@/components/FormPaymentRadio.vue";
import Btn from "@/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";

export default {
    name: "Payouts",
    components: {FormInput, Btn, FormPaymentRadio, VisaIcon, MastercardIcon, AmexIcon, CardIcon, CloseIcon },
    data() {
        return {
            loading: {
                data: false,
                save: false,
                select: false,
                stripe_connect: false,
            },
            cards: [],
            selected_card_id: null,
            newCard: {
                cardholder: '',
            },
        }
    },
    created() {
        this.stripe_public_key = process.env.STRIPE_PK
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/credit-cards")
                .then((res) => {
                    this.cards = res.data;
                    this.loading.data = false;
                    this.selected_card_id = res.data.find(i => i.is_default)?.id
                })
                .catch(() => {
                    this.loading.data = false;
                });
        },
        setCard(card) {
            this.selected_card_id = card.id;
            console.log('set card')
        },
        addCreditCard() {
            this.newCard.cardholder = '';
            this.client_secret = ''
            this.$modal.show('addCardModal');
            this.setupIntent()
        },
        submitSaveCard () {
            this.$refs.newCard.submit();
        },
        setupIntent() {
            this.loading.client_secret = true
            this.$axios.post("/account/credit-cards/setup-intent")
                .then((res) =>{
                    this.client_secret = res.intent.client_secret
                    this.loading.client_secret = false;
                })
                .catch(() => {
                    this.loading.client_secret = false;
                });
        },
        saveCard(response) {
            console.log({response})
            this.loading.save = true;
            this.$axios.post("/account/credit-cards/add", {
                name: this.newCard.cardholder,
                card_id: response.card.id,
                payment_token: response.id,
            })
                .then((res) => {
                    if (res.status === 'requires_action') {
                        this.handleCardAction(res.stripe_account, res.client_secret)
                    }else if (res.status === 'requires_confirmation' || res.status === 'incomplete') {
                        this.completeCardPayment(res.stripe_account, res.client_secret)
                    }else {
                        this.$modal.hide('addCardModal');
                        this.loading.save = false;
                        this.getData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading.save = false;
                });
        },
        completeCardPayment (stripe_account, client_secret) {
            let stripe = Stripe(this.$settings.getGlobalValue('stripe', 'key'), {
                stripeAccount: stripe_account
            })
            stripe.confirmCardPayment(client_secret).then((result) => this.handleStripeJsResult(result)).catch(e => console.log(e))
        },
        handleStripeJsResult (result) {
            if (result.error) {
                this.$toast.error(result.error)
                this.loading.save = false;
            } else {
                this.$axios.post("/account/credit-cards/confirm-intent", { payment_intent_id: result.paymentIntent.id })
                    .then((res) =>{
                        this.loading.save = false;
                    })
                    .catch(()=> {
                        this.loading.save = false;
                    });
            }
        },
        deleteCard(credit_card) {
            this.$dialog.confirm('Are you sure you want to delete the payment card?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.delete = true;
                    this.$axios.post("/account/credit-cards/delete", {credit_card_id: credit_card.id})
                        .then(() =>{
                            this.getData();
                            this.loading.delete = false;
                        })
                        .catch(()=> {
                            this.loading.delete = false;
                        });
                });
        },
        setActiveCard() {
            this.$dialog.confirm('Are you sure you want to set this card as you active one?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.select = true;
                    this.$axios.post("/account/credit-cards/set-default", {credit_card_id: this.selected_card_id})
                        .then(() =>{
                            this.getData();
                            this.loading.select = false;
                        })
                        .catch(()=> {
                            this.loading.select = false;
                        });
                });
        },
    }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import VueSocialAuth from 'vue-social-auth'

export default (obj, inject) => {
  // aviable only in client side
  if (!process.client) {
    return
  }

  //initialize plugin with options
  const pluginOptions = [{"property":"$socialAuth","providers":{"google":{"clientId":"720536592512-c26pr0irr546mapf5fsfs1veou5cimbu.apps.googleusercontent.com","redirectUri":"https:\u002F\u002Fbolg.synergo.ro\u002Fsocial-login\u002Fgoogle\u002Fcallback"},"linkedin":{"clientId":"77h64l86yoxtas","redirectUri":"https:\u002F\u002Fbolg.synergo.ro\u002Fsocial-login\u002Flinkedin\u002Fcallback"},"paypal":{"name":"paypal","url":"\u002Fauth\u002Ffacebook","authorizationEndpoint":"https:\u002F\u002Fwww.sandbox.paypal.com\u002Fconnect\u002F","redirectUri":"https:\u002F\u002Fbolg.synergo.ro\u002Fsocial-login\u002Fpaypal\u002Fcallback","requiredUrlParams":["flowEntry","scope"],"scope":["openid","profile","email","address","https:\u002F\u002Furi.paypal.com\u002Fservices\u002Fpaypalattributes"],"scopeDelimiter":" ","display":"popup","oauthType":"2.0","popupOptions":{"width":580,"height":400},"clientId":"Ae-rgnXessiZFd-6aDhJu3dNTo_KKQeORVC4voOeLs7r4lNcuLyeFOQuLJR9UJZuNlX-_vJuX0Qc6Naw","flowEntry":"static"}}}][0] || {}
  Vue.use(VueSocialAuth, pluginOptions)
}

<template>
    <div class="d-flex flex-column" style="flex: 1;">
        <div class="l-auth__onboarding" :class="{'loading': loading.data, '--white-bg': finished}">
            <Bubbles class="l-auth__onboarding-bubbles"/>

            <OnboardingCard
                    v-if="finished"
                    title="Success! Your profile is ready"
                    style="max-width: 680px"
                    icon="/images/success_profile.svg"
            >
                <div class="mx-auto" style="max-width: 488px">
                    <div class="l-auth__onboarding__success-card mb-4">
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> You can only have one account with BOLG</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Only Your Country Will Be Visible to Biotech Expert</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Your Phone Number Will Not Be Visible</div>
                    </div>
                    <div class="text-center">
                        <Btn class="btn-outline-light --w380" :to="{name: 'account.info'}">View My Profile</Btn>
                    </div>
                </div>
            </OnboardingCard>
            <Steps :index="step" v-else @stepsChange="stepsChange">
                <Step name="Prefix">
                    <OnboardingCard
                        title="Add the Title if You Prefer"
                        icon="/images/send-email.svg"
                    >
                        <div class="text-center mb-4">
                            <BadgeSelect :value="user_data.prefix" v-model="user_data.prefix" :options="['Mr.', 'Mrs.', 'Miss', 'Ms.', 'Dr.', {label: 'Don\'t Add Title', value: null}]"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ProfilePhoto">
                    <OnboardingCard
                        title="Upload profile photo"
                        subtitle="Drag the photo to move and perfectly fit"
                        style="width: 508px;"
                    >
                        <div style="display: flex; align-items: center; flex-direction: column;">
                            <div style="position: relative;">
                                <ImagesUploaderCrop :size="320" :value="user_data.image" @input="(value) => user_data.image = value" :multiple="false"/>
                                <SwirleIcon class="l-auth__onboarding_crop-icon"/>
                            </div>
                        </div>
        <!--                <Btn class="btn-block btn-secondary-light" @click="user_data.image = null">Save Photo</Btn>-->
                    </OnboardingCard>
                </Step>
                <Step name="Company">
                    <OnboardingCard
                        title="Fill Your Company Profile if You Are Registering a Business Account"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormInput class="mb-2" label="Company Name" placehoder="" :value="user_data.company.name" @input="(value) => user_data.company.name = value"/>
                            <FormInput class="mb-2" label="Website" placehoder="" :value="user_data.company.website" @input="(value) => user_data.company.website = value"/>
                            <FormInput class="mb-2" label="Street Address" placehoder="" :value="user_data.company.address.address" @input="(value) => user_data.company.address.address = value"/>
                            <FormInput class="mb-2" label="City" placehoder="" :value="user_data.company.address.city_name" @input="(value) => user_data.company.address.city_name = value"/>
                            <FormInput class="mb-2" label="Province" placehoder="" :value="user_data.company.address.county_name" @input="(value) => user_data.company.address.county_name = value"/>
                            <FormInput class="mb-2" label="ZIP code" placehoder="" :value="user_data.company.address.postcode" @input="(value) => user_data.company.address.postcode = value"/>
                            <FormGroup label="Country">
                                <CountrySelect :value="user_data.company.address.country" @input="(value) => user_data.company.address.country = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Employees" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Number of Employees"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" fieldValue="<15" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Less Than 15 Employees"/>
                            <FormRadio :bordered="true" fieldValue="15-50" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Between 15-50"/>
                            <FormRadio :bordered="true" fieldValue="50-100" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="50-100 Employees"/>
                            <FormRadio :bordered="true" fieldValue="100-500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="100-500 Employees"/>
                            <FormRadio :bordered="true" fieldValue=">500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="More than 500 Employees"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Additionalinfo" key="Additionalinfo" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Additional information"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormGroup label="The company Logo" class="text-center">
                                <ImagesUploaderCrop class="mt-3" :size="200" :value="user_data.company.image" @input="(value) => user_data.company.image = value" :multiple="false"/>
                            </FormGroup>
                            <FormInput class="mb-2" label="Your Position in the Company" placehoder="e.g. Reasearcher" :value="user_data.company.position" @input="(value) => user_data.company.position = value"/>
                            <FormDate :time="false" altFormat="m/d/Y" placeholder="mm/dd/yyyy" class="mb-2" label="Established Date" :value="user_data.company.established_date" @input="(value) => user_data.company.established_date = value"/>
                            <FormGroup label="Upload a Proof of Business">
                                <DocumentsUploader class="mb-2" :value="user_data.company.documents" @input="(value) => user_data.company.documents = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Visibility" key="Visibility">
                    <OnboardingCard
                        title="Profile Visibility"
                        subtitle="Who do you want to see your profile? Simply, select an option to control your visibiity. You can change this later in your profile data"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="width: 488px; max-width: 100%;">
<!--                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'public'}" @click="user_data.privacy = 'public'">-->
<!--                                <div class="o-privacy-select__icon"><PrivacyPublicIcon/></div>-->
<!--                                <div class="o-privacy-select__info">-->
<!--                                    <div class="o-privacy-select__info-title">Public</div>-->
<!--                                    <div class="o-privacy-select__info-description">Anyone can see it, it will be indexed in search engines</div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'registered'}" @click="user_data.privacy = 'registered'">
                                <div class="o-privacy-select__icon"><PrivacyRegisteredIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">BOLG Users only</div>
                                    <div class="o-privacy-select__info-description">Only users logged in to BOLG can find it</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'private'}" @click="user_data.privacy = 'private'">
                                <div class="o-privacy-select__icon"><PrivacyPrivateIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Private*</div>
                                    <div class="o-privacy-select__info-description">Only BOLG admins can see it.</div>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
            </Steps>
        </div>
        <div class="l-auth__footer" v-if="!finished">
            <div class="l-auth__footer-progress d-flex d-md-none">
                <div class="progress mr-2" style="width: 55px">
                    <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                </div>
                {{ completed }}% Profile Completed
            </div>
            <div class="container l-auth__footer-container">
                <Btn class="btn-outline-light --w120" :disabled="step == 0" @click="goToPrevStep">Back</Btn>
                <div class="l-auth__footer-progress d-none d-md-flex">
                    <div class="progress mr-2" style="width: 55px">
                        <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                    </div>
                    {{ completed }}% Profile Completed
                </div>
                <div style="white-space: nowrap">
                    <Btn class="text-secondary" @click="skip">Skip for now</Btn>
                    <Btn class="btn-secondary --w120" v-if="step < stepsCount - 1" @click="goToNextStep">Next</Btn>
                    <Btn class="btn-secondary --w120" v-else @click="saveUserData" :loading="loading.save">Save</Btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { mapState } from 'vuex'
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";
import BadgeSelect from "~/components/BadgeSelect.vue";
import ImagesUploaderCrop from "~/components/ImageUploaderCrop.vue";
import FormRadio from "~/components/FormRadio.vue";
import FormDate from "~/components/FormDate.vue";
import DocumentsUploader from "~/components/DocumentsUploader.vue";

import SwirleIcon from "~/assets/icons/swirle.svg?inline";
import Steps from "@/components/Steps.vue";
import Step from "@/components/Step.vue";


export default {
    name: 'AuthClientOnboarding',
    components: {
        Step,
        Steps, DocumentsUploader, FormDate, FormRadio, CountrySelect,
        FormInput, ImagesUploaderCrop, BadgeSelect, OnboardingCard, Btn,
        Bubbles, SwirleIcon
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            stepsCount: 0,
            step: 0,
            finished: false,
            user_data: {
                prefix: "Mr.",
                image: null,
                company: {
                    name: '',
                    description: '',
                    website: '',
                    employees: null,
                    image: null,
                    position:null,
                    established_date:null,
                    address: {
                        address: '',
                        city_name: '',
                        county_name: '',
                        postcode: '',
                        country: null,
                    },
                    documents: [],
                },
                attributes: {},
                privacy: null,
            },
        }
    },
    validations: {

    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        completed() {
            return (this.step / (this.stepsCount - 1) * 100).toFixed(0);
        },
    },
    created: function () {
        this.getData()
    },
    methods: {
        goToPrevStep() {
            if (this.loading.data) {
                return;
            }
            if (this.edit_object) {
                this.edit_index = null;
                this.edit_type = null;
                this.edit_object = null;
                return;
            }
            if (this.step >= 1) {
                this.step--;
            }
        },
        goToNextStep() {
            if (this.loading.data) {
                return;
            }
            if (this.edit_object) {
                return;
            }
            if (this.step <= this.stepsCount) {
                this.step++;
            }
        },
        skip() {
            this.$router.push({'name': 'account.dashboard'})
        },
        getData() {
            this.$axios.get("/account/get-info")
                .then((res) =>{
                    this.user_data = {...this.user_data, ...res.data}
                    if (!this.user.company) {
                        this.user_data.company = {
                            name: '',
                            description: '',
                            website: '',
                            employees: null,
                            image: null,
                            position:null,
                            established_date:null,
                            address: {
                                address: '',
                                city_name: '',
                                county_name: '',
                                postcode: '',
                                country: null,
                            },
                            documents: [],
                        }
                    }
                    this.loading.data = false;
                });
        },
        saveUserData() {
            this.loading.save = true;
            this.$axios.post("/account/save-onboarding", this.user_data)
                .then((res) =>{
                    this.loading.save = false;
                    this.finished = true;
                })
                .catch((res) =>{
                    this.loading.save = false;
                })
        },
        stepsChange(count) {
            this.stepsCount = count
        },
    },
}
</script>

<template>
   <modal name="paymentMethodModal" :scrollable="true" width="740px" height="auto" :adaptive="true" @before-open="getData" :pivotY="0.2">
       <div class="modal-dialog" style="max-width: 650px">
           <div class="modal-content" style="width: 650px">
               <a style="cursor: pointer" class="close" @click="$modal.hide('paymentMethodModal')">
                   <CloseIcon class="close"/>
               </a>
               <div class="modal-header">
                   <h4 class="modal-title">Select your payment method</h4>
               </div>
               <div class="modal-body">
                   <div style="max-width: 100%; width: 566px" :class="{'loading': loading.data}">
                       <div class="payment-add-row">
                           <span>Your Bank Cards</span>
                           <div class="add-payment-btn" @click="addCreditCard"><PlusWhiteIcon class="mr-1"/>Add New Bank Card</div>
                       </div>
                       <div class="payment-card mb-2" v-for="card in cards" @click="setCard(card)">
                           <div class="d-flex flex-row align-items-center" style="flex: 1">
                                <span>
                                    <form-payment-radio :hideText="true" :id="card.id" :text="card.type" :fieldValue="card.id" name="card" v-model="selected_card_id"></form-payment-radio>
                                </span>
                               <VisaIcon v-if="card.brand === 'visa'" width="71" height="50" class="mr-2"/>
                               <MastercardIcon v-else-if="card.brand === 'mastercard'" width="71" height="50" class="mr-2"/>
                               <AmexIcon v-else-if="card.brand === 'amex'" width="71" height="50" class="mr-2"/>
                               <CardIcon v-else class="mr-2" width="71" height="50"/>
                               <span>{{ card.name }}</span>
                           </div>
                           <span>{{ card.hash }}</span>
                           <div><TrashIcon width="70" @click="deleteCard(card)"/></div>
                       </div>
                   </div>


                   <div v-show-slide="addCard">
                       <div class="modal-header">
                           <h4 class="modal-title">Add a New Bank Card</h4>
                       </div>
                       <div class="modal-body">
                           <FormInput class="mb-2" label="Cardholder" v-model="newCard.cardholder"/>
                           <ClientOnly>
                               <stripe-element-card
                                   ref="newCard"
                                   :pk="stripe_public_key"
                                   @token="saveCard"
                               />
                           </ClientOnly>
                       </div>
                       <div class="modal-footer">
                           <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="submitSaveCard()">Add Bank Card</Btn>
                       </div>
                   </div>

               </div>
               <div class="modal-footer" v-if="cards.length">
                   <Btn :disabled="!selected_card_id" class="btn-secondary" :loading="loading.select" @click="setActiveCard">Set as Active</Btn>
               </div>
           </div>
       </div>
   </modal>
</template>

<script>
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import VisaIcon from "~/assets/icons/account/visa-logo.svg?inline";
import MastercardIcon from "~/assets/icons/account/mastercard-logo.svg?inline";
import AmexIcon from "~/assets/icons/account/amex-logo.svg?inline";
import CardIcon from "~/assets/icons/account/card.svg?inline";
import FormPaymentRadio from "@/components/FormPaymentRadio.vue";
import Btn from "@/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";

export default {
    name: 'PaymentMethodCard',
    components: {FormInput, Btn, FormPaymentRadio, VisaIcon, MastercardIcon, AmexIcon, CardIcon, CloseIcon },
    props: ['project'],
    data () {
        return {
            loading: {
                data: false,
                save: false,
                select: false,
            },
            addCard: false,
            cards: [],
            selected_card_id: null,
            newCard: {
                cardholder: '',
            },
            stripe_public_key: null
        }
    },
    created() {
        this.stripe_public_key = process.env.STRIPE_PK
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/credit-cards")
                .then((res) => {
                    this.cards = res.data;
                    this.loading.data = false;
                    this.selected_card_id = res.data.find(i => i.is_default)?.id
                })
                .catch(() => {
                    this.loading.data = false;
                });
        },
        setCard(card) {
            this.selected_card_id = card.id;
            console.log('set card')
        },
        addCreditCard() {
            this.newCard.cardholder = '';
            this.client_secret = ''
            this.addCard = true;
            this.setupIntent()
        },
        submitSaveCard () {
            this.$refs.newCard.submit();
        },
        setupIntent() {
            this.loading.client_secret = true
            this.$axios.post("/account/credit-cards/stripe/setup-intent")
                .then((res) =>{
                    this.client_secret = res.intent.client_secret
                    this.loading.client_secret = false;
                })
                .catch(() => {
                    this.loading.client_secret = false;
                });
        },
        saveCard(response) {
            this.loading.save = true;
            this.$axios.post("/account/credit-cards/stripe/add", {
                name: this.newCard.cardholder,
                card_id: response.card.id,
                payment_token: response.id,
            })
                .then((res) => {
                    if (res.payment_method.status === 'requires_action') {
                        this.handleCardAction(res.payment_method.stripe_account, res.payment_method.client_secret)
                    }else if (res.payment_method.status === 'requires_confirmation' || res.payment_method.status === 'incomplete') {
                        this.completeCardPayment(res.payment_method.stripe_account, res.payment_method.client_secret)
                    }else {
                        this.$modal.hide('paymentMethodModal')
                        this.loading.save = false;
                        this.addCard = false
                        this.getData();
                    }
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        completeCardPayment (stripe_account, client_secret) {
            let stripe = Stripe(this.$settings.getGlobalValue('stripe', 'key'), {
                stripeAccount: stripe_account
            })
            stripe.confirmCardPayment(client_secret).then((result) => this.handleStripeJsResult(result)).catch(e => console.log(e))
        },
        handleStripeJsResult (result) {
            if (result.error) {
                this.$toast.error(result.error)
                this.loading.save = false;
            } else {
                this.$axios.post("/account/credit-cards/stripe/confirm-intent", { payment_intent_id: result.paymentIntent.id })
                    .then((res) =>{
                        this.loading.save = false;
                    })
                    .catch(()=> {
                        this.loading.save = false;
                    });
            }
        },
        deleteCard(credit_card) {
            this.$dialog.confirm('Are you sure you want to delete the payment card?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.delete = true;
                    this.$axios.post("/account/credit-cards/delete", {credit_card_id: credit_card.id})
                        .then(() =>{
                            this.getData();
                            this.loading.delete = false;
                        })
                        .catch(()=> {
                            this.loading.delete = false;
                        });
                });
        },
        setActiveCard() {
            this.$dialog.confirm('Are you sure you want to set this card as you active one?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.select = true;
                    this.$axios.post("/account/credit-cards/stripe/set-default", {credit_card_id: this.selected_card_id})
                        .then(() =>{
                            this.getData();
                            this.loading.select = false;
                            this.$modal.hide('paymentMethodModal')
                        })
                        .catch(()=> {
                            this.loading.select = false;
                        });
                });
        }
    }
};
</script>


<template functional>
    <form-group :label="props.label" :help="props.help" v-bind:class="[data.staticClass, data.class, {'error' : props.v ? props.v.$error : null}]" class="form-input">
        <select class="form-control"
                :class="[props.inputClass, {'no-label': !props.label}]"
                :placeholder="props.label"
                :value="props.value"
                :name="props.name"
                :disabled="props.disabled"
                :readonly="props.readonly"
                v-on:change="($event) => {
                    listeners.input && listeners.input($event.target.value);
                    listeners.change && listeners.change($event.target.value);
                    if (props.v) {
                       props.v.$touch();
                    }
                }"
            >
            <option disabled v-if="props.label">{{ props.label }}</option>
            <template v-for="n in 24">
                <option :value="$options.filters.pad((n - 1), 2) + ':00'">{{ $options.filters.pad((n - 1), 2) + ':00:00' | momentTime('HH:mm') }}</option>
                <option v-if="props.interval === 15" :value="$options.filters.pad((n - 1), 2) + ':15'">{{ $options.filters.pad((n - 1), 2) + ':15:00' | momentTime('HH:mm') }}</option>
                <option :value="$options.filters.pad((n - 1), 2) + ':30'">{{ $options.filters.pad((n - 1), 2) + ':30:00' | momentTime('HH:mm') }}</option>
                <option v-if="props.interval === 15" :value="$options.filters.pad((n - 1), 2) + ':45'">{{ $options.filters.pad((n - 1), 2) + ':45:00' | momentTime('HH:mm') }}</option>
<!--                <option v-if="props.min_time && $utils.diffTime(props.min_time, n - 1 + ':00') >= 0" :value="(n - 1) + ':00'">{{ (n - 1) + ':00:00' | momentTime('H:mm A') }}</option>-->
<!--                <option v-if="props.min_time && $utils.diffTime(props.min_time, n - 1 + ':30') <= 0" :value="(n - 1) + ':30'">{{ (n - 1) + ':30:00' | momentTime('H:mm A') }}</option>-->
            </template>
        </select>
    </form-group>
</template>

<script>
    export default {
        name: 'FormTimeSelect',
    }
</script>

import {loadScript} from "@paypal/paypal-js";
import PaypalModal from "@/components/PaypalModal.vue";

export const paypalMixin = {
    data() {
        return {
            paypal: false,
        };
    },
    methods: {
        initPaypal: async (params = {}) => {
            if (!window.paypal ) {
                await loadScript({ 'client-id': process.env.PAYPAL_CLIENT_ID, currency: 'USD', ...params });
            }
            console.log('paypal initialized', params, window.paypal)
            return window.paypal;
        },
        paypalPopup(link) {
            window.open(`${link}&displayMode=minibrowser`)
        },
        openPaypalPopup() {
            this.loading.select = false;

            this.$modal.show(PaypalModal,
  {
                    name: 'paypalModal'
                },
     {
                    name: 'paypalModal',
                    height: "auto" ,
                    scrollable: true,
                    clickToClose: false
                },
    {
                'opened': () => {
                    this.showPaypal()
                }
            });
        },
        // initPaypalLogin(elementId) {
        //     console.log(window.paypal)
        //     window.paypal.use( ['login'], function (login) {
        //         login.render ({
        //             "appid":"Ae-rgnXessiZFd-6aDhJu3dNTo_KKQeORVC4voOeLs7r4lNcuLyeFOQuLJR9UJZuNlX-_vJuX0Qc6Naw",
        //             "authend":"sandbox",
        //             "containerid": elementId,
        //             "responseType":"code",
        //             "locale":"en-us",
        //             "theme":"neutral",
        //             "buttonType":"LWP",
        //             "buttonShape":"pill",
        //             "buttonSize":"lg",
        //             "fullPage":"true",
        //             "returnurl":"http://localhost:3000/account/login/apple/callback"
        //         });
        //     });
        // },
    }
}


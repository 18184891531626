
(function (Vue) {
    const Analytics = {
        ids: {},
        options: {},
        enabled: false,
        install: function (Vue, options) {
            Vue.prototype.$analytics = this
            Vue.$analytics = this
            this.options = options
            if (typeof options.enabled != 'undefined') {
                this.enabled = options.enabled
            }
            if (this.options.router && this.options.trackPages) {
                this.options.router.afterEach((to, from) => {
                    this.sendPageView(to)
                })
            }
        },
        setEnable (value) {
            this.enabled = value
        },
        setId (type, id) {
            if (typeof this.ids[type] === 'undefined') {
                this.ids[type] = id
                this.loadScript(type)
            }else{

            }
        },
        hasService (type) {
            if (!this.enabled) {
                return false;
            }
            return this.ids[type] !== undefined
        },
        sendPageView (url) {
            if(this.hasService('gtm')){

            }
            if(this.hasService('ga')){
                ga('send', 'pageview');
            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){
                fbq('track', 'PageView');
            }
        },
        addToCart (project, quantity = 1, actionField = null) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "add": {
                            "actionField": {
                                "list": actionField
                            },
                            "projects": [{
                                "id": project.projectID,
                                "name": project.info.project,
                                "price": project.price,
                                "brand": project.brand,
                                "category": project.category,
                                "quantity": quantity
                            }]
                        }
                    }
                });
            }
        },
        changeCartitemQty (cartitem, diff) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "add": {
                            "actionField": {
                                "list": 'Cart update'
                            },
                            "projects": [{
                                "id": cartitem.projectid,
                                "name": cartitem.project,
                                "price": cartitem.price,
                                "brand": cartitem.brand,
                                "category": cartitem.category,
                                "quantity": diff
                            }]
                        }
                    }
                });
            }
        },
        removeCartitem (cartitem, quantity = null) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "EEremoveFromCart",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "actionField": {
                            "list": 'Cart update'
                        },
                        "remove": {
                            "projects": [{
                                "id": cartitem.projectid,
                                "name": cartitem.project,
                                "price": cartitem.price,
                                "brand": cartitem.brand,
                                "category": cartitem.category,
                                "quantity": quantity ? quantity : cartitem.quantity
                            }]
                        }
                    }
                });
            }
        },
        projectView (project) {
            if(this.hasService('gtm')){
                dataLayer.push({
                    "event": "projectView",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "details": {
                            "projects": [{
                                "id": project.projectID,
                                "name": project.info.project,
                                "price": project.price,
                                "brand": project.brand,
                                "category": project.category,
                                "quantity": 1
                            }]
                        }
                    }
                });
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        projectClick (project, actionField = null) {
            if(this.hasService('gtm')){
                dataLayer.push({
                    "event": 'projectClick',
                    "ecommerce": {
                        "currencyCode": "RON",
                        "click": {
                            "actionField": actionField ? {
                                "list": actionField
                            } : null,
                            "projects": [{
                                "id": project.projectID,
                                "name": project.info.project,
                                "price": project.price,
                                "brand": project.brand,
                                "category": project.category,
                                "quantity": 1
                            }]
                        }
                    }
                });
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        sendImpressions (projects, actionField = null) {
            if(this.hasService('gtm')) {
                let object = {
                    "event": "ecc.impression.View",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "impressions": {
                            'projects': projects.map(project => {
                                return {
                                    "id": project.projectID,
                                    "name": project.info.project,
                                    "price": project.price,
                                    "brand": project.brand,
                                    "category": project.category,
                                }
                            })
                        }
                    }
                }
                if (actionField) {
                    object.ecommerce.impressions['actionField'] = {
                        "list": actionField
                    }
                }
                dataLayer.push(object);
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        sendCheckout(cartitems, step = 1) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "checkout",
                    "ecommerce": {
                        "checkout": {
                            "actionField": {
                                "step": step,
                                "options": ''
                            },
                            'projects': cartitems.map(cartitem => {
                                return {
                                    "id": cartitem.projectid,
                                    "name": cartitem.project,
                                    "price": cartitem.price,
                                    "brand": cartitem.brand,
                                    "category": cartitem.category,
                                    "quantity": cartitem.quantity
                                }
                            })
                        }
                    }
                });
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        sendPromotions(promotions = []) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "promotionView",
                    "ecommerce": {
                        "promoView": {
                            'promotions': promotions.map(promo => {
                                return {
                                    name: promo.name,
                                    creative: promo.url,
                                    position: promo.position,
                                }
                            })
                        }
                    }
                });
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        transactionComplete(order) {
            if(this.hasService('gtm')) {
                dataLayer.push({
                    "event": "transactionComplete",
                    "ecommerce": {
                        "currencyCode": "RON",
                        "purchase": {
                            "actionField": {
                                "id": order.randomID,
                                "revenue": order.total,
                                // "coupon": order.total,
                                "shipping": order.delivery_price,
                                "tax": order.total_vat
                            },
                            'projects': order.projects.map(project => {
                                return {
                                    "id": project.projectID,
                                    "name": project.info.project,
                                    "price": project.price,
                                    // "brand": project.brand,
                                    // "category": project.category,
                                    "quantity": project.quantity,
                                    "coupon": '',
                                }
                            })
                        }
                    }
                });
            }
            if(this.hasService('ga')){

            }
            if(this.hasService('gtag')){

            }
            if(this.hasService('fb')){

            }
        },
        loadScript (type) {
            switch (type) {
                case 'gtm':
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            'gtm.start': new Date().getTime(), event: 'gtm.js'
                        });
                        var f = d.getElementsByTagName(s)[0],
                          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                        j.src =
                          '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', this.ids[type]);
                break;
                case 'ga':
                    (function (i, s, o, g, r, a, m) {
                        i['GoogleAnalyticsObject'] = r;
                        i[r] = i[r] || function () {
                            (i[r].q = i[r].q || []).push(arguments)
                        }, i[r].l = 1 * new Date();
                        a = s.createElement(o);
                        m = s.getElementsByTagName(o)[0];
                        a.src = g;
                        m.parentNode.insertBefore(a, m)
                    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

                    ga('create', this.ids[type], 'auto');
                    ga('require', 'ec');
                break;
                case 'gtag':
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        var f = d.getElementsByTagName(s)[0],
                          j = d.createElement(s);
                        j.src = '//www.googletagmanager.com/gtag/js?id=' + i;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', this.ids[type]);

                    function gtag(){dataLayer.push(arguments);}
                    window.dataLayer = window.dataLayer || [];
                    gtag('js', new Date());
                    gtag('config', this.ids[type]);
                break;
                case 'fb':
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', this.ids[type]);
                break;
            }
        },
    }

    if (typeof exports == "object") {
        module.exports = Analytics;
    }
})();


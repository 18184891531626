export const modelInfiniteListMixin = {
    data() {
        return {
            loading: {
                data: false,
                search: false,
                load_more: false
            },
            data: null,
            filters: {
                limit : 10,
                sort: '',
                dir: 'asc',
                after_id: null,
            },
            has_more: null,
        };
    },
    methods: {
        async search () {
            this.loading.search = true;
            this.filters.has_more = null;
            await this.getData();
            this.loading.search = false;
        },
        async loadMore() {
            if (!this.data.length) {
                this.has_more = false;
                return;
            }
            this.filters.after_id = this.data[this.data.length -1].id;
            this.loading.load_more = true;
            await this.getData();
            this.loading.load_more = false;
            this.filters.after_id = null
        },
        setSort (field) {
            this.filters.page = 1;
            if (this.filters.sort === field) {
                this.filters.dir = this.filters.dir === 'asc' ? 'desc' : 'asc';
            }else{
                this.filters.sort = field;
            }

            this.getData();
        },
    }
}


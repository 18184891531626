<template>
    <div class="p-details-wrapper" :class="{'--gray-bg' : !this.item}">
        <div class="p-details" :class="{'loading': loading.data || application_loading, 'container pt-4 pb-4' : !this.item}">
            <div class="row" v-if="application">
                <div class="col-md-12" v-if="application.status === -1">
                    <div class="alert alert-danger">
                        <div class="alert-title">Blocked/Declined</div>
                        <div class="alert-body">
                            <div class="mb-1"><b>Reason: </b>{{ application.reject_reason }}</div>
                            <div><b>Details: </b>{{ application.reject_reason_details }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="p-details__info">
                                <UserAvatar class="mb-3" size="large" :user="application.client"/>
                                <div class="p-details__info__title">{{ getClientName(application.client) }}</div>
                                <div class="p-details__info__desc">
                                    {{ application.client.description }}
                                </div>
                                <div class="badge badge-secondary-light badge-lg mb-3" v-if="application.client.attributes.availability">Availability: {{ application.client.attributes.availability?.value }}</div>
                                <div class="p-details__info__stats" v-if="application.client">
                                    <span v-if="application.client.address"><LocationIcon/> {{ [application.client.address.city_name, application.client.address.country_name].join(', ') }}</span>
                                    <span><StarIcon/> {{ application.client.avgReview ? application.client.avgReview : '-' }}/5 <template v-if="application.client.countReview">({{ application.client.countReview }})</template></span>
                                </div>
                                <div class="row" style="width: 100%;" v-if="application.canApprove">
                                    <div class="col-6">
                                        <Btn class="btn-outline-light btn-block mb-2" @click="$emit('onOpenRejectModal', application)">Block</Btn>
                                    </div>
                                    <div class="col-6">
                                        <Btn class="btn-secondary btn-block mb-2" @click="$emit('onAcceptApplication', application)">Accept</Btn>
                                    </div>
                                </div>
<!--                                <div class="row" style="width: 100%;" v-if="application.canWithdraw">-->
<!--                                    <Btn class="btn-outline-light btn-block mb-2" @click="$emit('onWithdrawApplication', application)">Withdraw</Btn>-->
<!--                                </div>-->
<!--                                <div class="row" style="width: 100%;" v-if="application.canDelete">-->
<!--                                    <Btn class="btn-outline-light btn-block mb-2" @click="$emit('onWithdrawApplication', application)">Withdraw</Btn>-->
<!--                                </div>-->
<!--                                <div class="row" style="width: 100%;" v-if="application.canEdit">-->
<!--                                    <Btn class="btn-outline-light btn-block mb-2" @click="$emit('onWithdrawApplication', application)">Edit</Btn>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2" v-if="application.team">
                        <div class="card-header">
                            <div class="card-title">Working With</div>
                        </div>
                        <div class="card-body">
                            <UserAvatar :display-name="true" :user="application"/>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="p-details__specs">
                                <div class="p-details__specs__label">Color Code Category(s)</div>
                                <div class="p-details__specs__content" v-if="application.client.attributes.color_code_categories?.length">
                                    <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in application.client.attributes.color_code_categories" :attr="attr"/>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    -'
                                </div>

                                <div class="p-details__specs__label">Services(s)</div>
                                <div class="p-details__specs__content" v-if="application.client.attributes.services?.langth">
                                    <div class="badge badge-secondary-light badge-lg mr-1 mb-1" v-for="service in application.client.attributes.services">{{ service.value }}</div>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    -
                                </div>

                                <div class="p-details__specs__label">Expertise Level</div>
                                <div class="p-details__specs__content">
                                    <template v-if="application.client.attributes.expertise_level">
                                        <img :src="application.client.attributes.expertise_level.icon"/> {{ application.client.attributes.expertise_level.value }}
                                    </template>
                                    <template v-else>-</template>
                                </div>

                                <div class="p-details__specs__label">Expertise Area(s)</div>
                                <div class="p-details__specs__content" v-if="application.client.attributes.expertise_areas?.length">
                                    <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="expertise_area in application.client.attributes.expertise_areas">{{ expertise_area.value }}</div>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    -
                                </div>

                                <div class="p-details__specs__label">Total Hours of Work</div>
                                <div class="p-details__specs__content">{{ application.client.work_hours ?? '0'}} hours</div>

                                <div class="p-details__specs__label">Total Earnings</div>
                                <div class="p-details__specs__content">{{ application.client.attributes.total_earnings?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Number Or Reviews</div>
                                <div class="p-details__specs__content">{{ application.client.countReview ?? '-' }}</div>

                                <div class="p-details__specs__label">Member Since</div>
                                <div class="p-details__specs__content">{{ application.client.member_since | moment('MMMM DD, YYYY') }}</div>

                                <div class="p-details__specs__label">Response Time</div>
                                <div class="p-details__specs__content">{{ application.client.attributes.response_time?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Last Online</div>
                                <div class="p-details__specs__content">{{ application.client.online_at | onlineAtFormat }}</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="card" style="padding-top: 18px;">
                        <div class="p-details__section" id="description">
                            <div class="p-details__section-title">
                                Additional details
                            </div>
                            <div class="p-details__activity"><b>Will you do revisions?:</b> {{ application.revisions }}</div>
                            <template v-if="application.payment_method === 'hourly'">
                                <div class="p-details__activity"><b>Hourly Rate:</b> {{ application.price | toPrice }} / h</div>
                                <div class="p-details__activity"><b>Duration of the Project:</b> {{ application.duration_min }} - {{ application.duration_max }} Days</div>
                                <div class="p-details__activity"><b>Number of Hours Required:</b> {{ application.hours_min }} - {{ application.hours_max }} Hours / {{ application.hours_type | beautify }}</div>
                            </template>
                            <template v-if="application.payment_method === 'fixed'">
                                <div class="p-details__activity"><b>Fixed Price:</b> {{ application.price | toPrice }}</div>
                                <div class="p-details__activity"><b>Delivery Time:</b> {{ application.duration_min }} - {{ application.duration_max }} Days</div>
                            </template>
                        </div>
                        <div class="p-details__section" id="skills">
                            <div class="p-details__section-title">
                                Description of your skills and experience.
                            </div>
                            <p>
                                {{ application.skills }}
                            </p>
                        </div>
                        <div class="p-details__section" id="skills">
                            <div class="p-details__section-title">
                                Why your skills and experience are relevant to the Project?
                            </div>
                            <p>
                                {{ application.skills_relevance }}
                            </p>
                        </div>
                        <div class="p-details__section" id="skills">
                            <div class="p-details__section-title">
                                Describe relevant projects you worked on before.
                            </div>
                            <p>
                                {{ application.relevant_projects }}
                            </p>
                        </div>
                        <div class="p-details__section" id="skills">
                            <div class="p-details__section-title">
                                Describe your accomplishment strategy.
                            </div>
                            <p>
                                {{ application.accomplishment_strategy }}
                            </p>
                        </div>
                        <div class="p-details__section" id="documents" v-if="application.documents?.length">
                            <div class="p-details__section-title">
                               Attached Documents
                            </div>
                            <div class="p-details__docs-wrapper">
                                <DocumentCard :key="document.id" :document="document" v-for="document in application.documents" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserAvatar from "@/components/UserAvatar.vue";
import Btn from "@/components/Btn.vue";
import ColorCodeAttribute from "@/components/ColorCodeAttribute.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import {mapState} from "vuex";
import DocumentCard from "@/components/DocumentCard.vue";
import {getClientName} from "../../utils/helpers";

export default {
    name: 'ApplicationDetails',
    components: {
        DocumentCard,
        Tab, Tabs, ColorCodeAttribute, Btn, UserAvatar
    },
    props: ['item', 'application_loading'],
    data() {
        return {
            loaded: false,
            loading: {
                data: false,
                reviews: false,
                load_mode: false,
            },
            application: null,
            reviews: [],
            total_reviews: 0,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch();
        }
        // this.scrollElement = document.querySelector('.modal-sidebar.vm--container.scrollable');
    },
    activated () {
        // this.sendToAnalytics()
    },
    fetch() {
        if (this.preview && this.item) {
            this.application = JSON.parse(JSON.stringify(this.item));
            return;
        }
        this.getData();
    },
    watch: {
        currentSection(value) {
            if (this.swiper && value) {
                const sections = document.querySelectorAll('.p-details__section');
                const section = document.querySelector('.p-details__section#' + value);
                const index = Array.prototype.indexOf.call(sections, section);
                this.swiper.slideTo(index + 1);
            }
        },
    },
    methods: {
        getClientName,
        onSwiper(swiper) {
            this.swiper = swiper;
            this.currentSection = document.querySelectorAll('.p-details__section')[0]?.id
        },
        scrollHandler() {

            const elements = document.querySelectorAll('.p-details__section')
            let currentSection = elements[0];
            elements.forEach((element, index) => {
                const position = element.getBoundingClientRect().y - 40;
                if (position < 0) {
                    currentSection = element;
                }
            })
            this.currentSection = currentSection ? currentSection.id : null
        },
        getData () {
            if (!this.item) {
                return;
            }
            this.loading.data = true;
            return this.$axios.post('/account/projects/applications/details', { application_id: this.item.id })
                .then((res) => {
                    this.application = res.application
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
    }
}
</script>

export default () => {
    return {
       "search": {
            "results": {
                "results": "{n} Result | {n} Results",
                "no_results": "No Results found",
            },
            "freelancers": {
                "results": "{n} Expert | {n} Experts",
                "no_results": "No Experts found",
            },
            "projects": {
                "results": "{n} Project | {n} Projects",
                "no_results": "No Projects found",
            },
            "jobs": {
                "results": "{n} Job | {n} Jobs",
                "no_results": "No Jobs found",
            },
            "companies": {
                "results": "{n} Company | {n} Companies",
                "no_results": "No Companies found",
            }
       }
    }
}

<template>
    <div v-if="edit_object">
        <FormInput class="mb-2" label="Job title" placehoder="Jon Title" v-model="edit_object.name"/>
        <BadgeSelect :options="['Full Time', 'Part Time', 'Self Employed', 'Freelancer', 'Internship']" v-model="edit_object.type"/>
        <FormTextarea class="mb-2" label="Description" :count="250" placehoder="Description" v-model="edit_object.description"/>
        <FormInput class="mb-2" label="Company Name if Applicable" placeholder="Company Name" v-model="edit_object.company"/>
        <FormInput class="mb-2" label="City" v-model="edit_object.city_name"/>
        <FormInput class="mb-2" label="Province" v-model="edit_object.county_name"/>
        <FormGroup label="Country">
            <CountrySelect class="mb-2" v-model="edit_object.country"/>
        </FormGroup>
        <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" :maxDate="new Date()" label="From" :time="false" v-model="edit_object.period.from"/>
        <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" :minDate="edit_object.period.from" :maxDate="new Date()" label="To" :time="false" v-model="edit_object.period.to" v-if="!edit_object.period.present"/>
        <FormCheckbox text="I still work here" size="lg" v-model="edit_object.period.present" @change="(value) => value ? edit_object.period.to = null : null"/>
        <FormGroup label="Upload Document if Applicable">
            <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="employment_history" target="Modules\Clients\Entities\ClientProfile" :target_id="profile_id"/>
        </FormGroup>
        <Btn class="btn-block btn-secondary-light" :loading="loading_save" @click="saveObject">Save</Btn>
    </div>
</template>

<script>

import Btn from "~/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import BadgeSelect from "@/components/BadgeSelect.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import CountrySelect from "@/components/CountrySelect.vue";
import DocumentsUploader from "@/components/DocumentsUploader.vue";
import FormCheckbox from "@/components/FormCheckbox.vue";
import FormDate from "@/components/FormDate.vue";
import FormRadio from "@/components/FormRadio.vue";

export default {
    name: 'EmploymentHistoryForm',
    components: {
        FormRadio,
        FormDate,
        FormCheckbox,
        DocumentsUploader,
        CountrySelect,
        FormTextarea,
        BadgeSelect,
        FormInput,
        Btn,
    },
    props: ['value', 'profile_id', 'loading_save'],
    data() {
        return {
            edit_object: null,
        }
    },
    mounted () {
        this.edit_object = this.value
    },
    watch: {
        // edit_object(value) {
        //     this.$emit('input', value)
        // },
        value(value) {
            this.edit_object = value
        }
    },
    methods: {
        saveObject() {
            this.$emit('onSave', this.edit_object)
        },
    }
}
</script>

<template>
    <div class="c-job-card --sm" @click="onSelect">
        <div class="d-flex align-items-center mb-1">
            <div class="c-job-card__title">{{ project.title }}</div>
            <div v-if="!!$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="c-job-card__stats mb-1">
            <span v-if="project.attributes.service_level">{{ project.attributes.service_level.value }}</span>
            <template v-if="project.payment_method === 'hourly'">
                <span v-if="project.price[0]">Hourly Rate: {{ project.price[0] | toPrice }}-{{ project.price[1] | toPrice }}</span>
                <span v-if="project.hours[0]">Hours required: {{ project.hours[0] }} to {{ project.hours[1] }} h</span>
                <span v-if="project.duration[0]">Duration: {{ project.duration[0] }} to {{ project.duration[1] }} Days</span>
            </template>
            <template v-if="project.payment_method === 'fixed'">
                <span v-if="project.price[0]">Est. Budget: {{ project.price[0] | toPrice }}-{{ project.price[1] | toPrice }}</span>
                <span v-if="project.duration[0]">Delivery Time: {{ project.duration[0] }} to {{ project.duration[1] }} Days</span>
            </template>
        </div>
        <div class="c-job-card__info-wrapper">
            <div></div>
            <div class="c-job-card__stats" v-if="project.stats">
                <template v-if="project.status == 2">
                    <span>Paid: <span class="text-secondary">{{ project.stats?.paid | toPrice }}</span></span>
                    <span>In Escrow: <span class="text-secondary">{{ project.stats?.escrow | toPrice }}</span></span>
                    <span>Started: <span class="text-secondary">{{ project.stats?.started_at | moment('DD MMM, YYYY') }}</span></span>
                </template>
                <template v-if="project.status == 1">
                    <span>Saved: <span class="text-secondary">{{ project.stats?.saves }}</span></span>
                    <span>Viewed: <span class="text-secondary">{{ project.stats?.views }}</span></span>
                    <span>Offers: <span class="text-secondary">{{ project.stats?.applications }}</span></span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>


import UserAvatar from "./UserAvatar.vue";
import GroupIcon from "./GroupToggle.vue";
import ColorCodeAttribute from "./ColorCodeAttribute.vue";
import GroupToggle from "@/components/GroupToggle.vue";

export default {
    name: 'AccountProjectSimpleCard',
    components: {GroupToggle, ColorCodeAttribute, GroupIcon, UserAvatar},
    props: ['project'],
    methods: {
        onSelect() {
            this.$emit('onSelect', this.project)
        },
    }
}
</script>

<template>
    <img :loading="lazy ? lazy : 'lazy' " :src="url" :alt="alt" :width="width" :height="height" :style="style">
</template>

<script>

export default {
    name: 'XImgset',
    props: ['src', 'alt', 'width', 'height', 'lazy'],
    computed: {
        url () {
            if (!this.height && !this.width) {
                return this.src
            }
            return this.$utils.getImage(this.src, this.width, this.height)
        },
        style() {
            let style = {}

            if (this.height && this.width) {
                // style.aspectRatio = this.width/this.height
            }

            return style;
        }
    }
}
</script>

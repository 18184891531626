<template>
    <div class="expandable-card">
        <div class="expandable-card-title" @click.prevent="expanded = !expanded">{{ title }}
            <div class="expandable-card-chevron" :class="{'active': expanded}"><ChevronDownIcon/></div>
        </div>
        <div v-show-slide="expanded">
            <div class="expandable-card-body">
                <slot/>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'ExpandableSection',
    props: ['initial', 'title'],
    data() {
        return {
            expanded: false
        }
    },
    created() {
        if (typeof this.initial != 'undefined') {
            this.expanded = this.initial
        }
    }
}
</script>

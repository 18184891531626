<template functional>
    <div>
        <template v-for="(option, index) in props.options">
            <a href="#" v-if="typeof option === 'object'" class="badge badge-primary-light mb-2 mr-1 badge-lg"  :class="{'selected active': props.value == option.value}"
               @click.prevent="($event) => {
                    listeners.input && listeners.input(option.value);
                    listeners.change && listeners.change(option.value);
                    if(props.v){
                       props.v.$touch();
                    }
                }">{{ option.label }}</a>
            <a href="#" v-else class="badge badge-primary-light mb-2 mr-1 badge-lg" :class="{'selected active': props.value == option}"
               @click.prevent="($event) => {
                    listeners.input && listeners.input(option);
                    listeners.change && listeners.change(option);
                    if(props.v){
                       props.v.$touch();
                    }
                }">{{ option }}</a>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'BadgeSelect',
    }
</script>

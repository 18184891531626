<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__back">
                <NuxtLink :to="{name: 'account.jobs'}">
                    <ArrowBackIcon heigh="24"/> Back
                </NuxtLink>
            </div>
            <div class="l-account-content__title">Applications ({{ data ? data.total : 0 }})</div>
            <div v-if="job && job.title">
                <a href="#" @click.prevent="openJobDetails" class="l-account-content__subtitle text-secondary">For {{ job.title }} <OpenBlueIcon height="16" width="16"/></a>
            </div>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :data="data.data"
                    :columns="['expert', 'price', 'duration', 'hours_required', 'date']">
                    <template #expert="{row}">
                        <div @click="viewApplicationDetails(row)" style="cursor: pointer">
                            <UserAvatar size="medium" :user="row.client" :subtitle="row.client.username" :displayName="true" :badge="row.invited ? 'Invited for Project' : null"></UserAvatar>
                        </div>
                    </template>
                    <template #price="{row}">
                        <template v-if="row.payment_method === 'hourly'">
                            {{ row.price | toPrice }} / h
                        </template>
                        <template v-else-if="row.payment_method === 'fixed'">
                            {{ row.price | toPrice }}
                        </template>
                    </template>
                    <template #hours_required="{row}">
                        {{ row.hours_min }} - {{ row.hours_max }} h/{{ row.hours_type }}
                    </template>
                    <template #duration="{row}">
                        {{ row.duration_min }} - {{ row.duration_max }} Days
                    </template>
                    <template #date="{row}">
                        {{ row.created_at | moment('DD.MM.YYYY') }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
                        <template v-if="row.status === 0">
                            <Btn @click="viewApplicationDetails(row)" class="btn-outline-light">Details</Btn>
                            <!--                            <Btn @click="openRejectModal(row)" class="btn-outline-light">Block</Btn>-->
<!--                            <Btn @click="acceptApplication(row)" class="btn-secondary">Accept</Btn>-->
                        </template>
                        <template v-else-if="row.status === -1">
                            <span class="mr-2"><DeclinedIcon class="mr-1"/> Declined</span>
                            <Btn @click="openRejectReasonModal(row)" class="btn-outline-light">View Reason</Btn>
                        </template>
                        <template v-else-if="row.status === 1">
                            <span class="mr-2"><AcceptedIcon class="mr-1"/> Accepted</span>
                            <JobApplicationDropdown :application="row" @onViewDetails="viewApplicationDetails(row)" @onReload="getData"/>
                        </template>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No applications</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="JobDetailsModal" :openLink="{name: 'jobs.details', params: {slug: job?.slug}}">
            <JobDetails :item="job" :modal="true"/>
        </ModalSidebar>

        <ModalSidebar name="JobApplicationDetailsModal" :openLink="{name: 'freelancers.details', params: {username: selected_application?.client?.username}}">
            <ApplicationDetails
                :application_loading="selected_application?.loading"
                :item="selected_application"
                @onOpenRejectModal="openRejectModal"
                @onAcceptApplication="acceptApplication"
            />
        </ModalSidebar>

        <modal name="RejectModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Why are you Decline this expert?</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Person is requesting payment outside of BOLG" name="reason" :value="reject_application.reason" @input="(value) => reject_application.reason = value" text="Person is requesting payment outside of BOLG"/>
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Profile looks fake" name="reason" :value="reject_application.reason" @input="(value) => reject_application.reason = value" text="Profile looks fake"/>
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Other Reasons" name="reason" :value="reject_application.reason" @input="(value) => reject_application.reason = value" text="Other Reasons"/>
                            <FormTextarea class="mb-2" label="Please Tell Us More" v-model="reject_application.reject_reason_details"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn :loading="loading.reject" class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('RejectModal')">Cancel</Btn>
                            <Btn :loading="loading.reject" class="btn btn-lg btn-secondary --w150" @click="rejectApplication()">Reject</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="RejectReasonModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectReasonModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Reject Reason</h4>
                    </div>
                    <div class="modal-body">
                        {{ reject_reason }}
                    </div>
                </div>
            </div>
        </modal>
        <modal name="depositModel" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content" v-if="selected_application">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('depositModel')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Accept application</h4>
                    </div>
                    <div class="modal-body">
                        <p>To accept a fixed priced application you must deposit the amount before beginning</p>
                    </div>
                    <div class="mb-2">
                        <div class="p-details__activity"><b>Amount to Escrow:</b> {{ selected_application.payment.price | toPrice }}</div>
                        <div class="p-details__activity" v-for="tax in selected_application.payment.taxes.taxes"><b>Tax {{ tax.name }}</b> {{ selected_application.payment.price + selected_application.payment.fee_value | toPrice }} x {{ tax.value | toPrice }} % = {{ tax.amount | toPrice }}</div>
                        <div class="p-details__activity"><b>BOLG Fee:</b> {{ selected_application.payment.price | toPrice }} x {{ selected_application.payment.fee_percentage | toPrice }} % = {{ selected_application.payment.fee_value | toPrice }}</div>
                        <div class="p-details__activity"><b>Amount to be paid:</b> {{ selected_application.payment.total | toPrice }}</div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn :disabled="show_buttons" @click="depositToEscrow" :loading="loading.deposit" class="btn-secondary mb-2">Deposit using PayPal</Btn>
                            <Btn @click="depositToEscrowWithSavedCard" :loading="loading.deposit" class="btn-outline-secondary mb-2">Deposit with saved card</Btn>
                            <Btn class="btn btn-lg btn-outline-light mb-2 --w150" @click="$modal.hide('depositModel')">Cancel</Btn>
                            <div class="mt-4" id="paypal-buttons"></div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>

</template>

<script>
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import {modelListMixin} from "~/mixins/modelListMixin";
import JobCard from "@/pages/Jobs/JobCard.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import JobApplicationDropdown from "@/components/JobApplicationDropdown.vue";
import OpenBlueIcon from "~/assets/icons/open-blue.svg?inline";
import JobDetails from "@/pages/Jobs/JobDetails.vue";
import ApplicationDetails from "@/pages/Account/ApplicationDetails.vue";
import FormInput from "@/components/FormInput.vue";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import FormTextarea from "@/components/FormTextarea.vue";
import FormRadio from "@/components/FormRadio.vue";
import VueTable from "@/components/VueTable.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import {paypalMixin} from "@/mixins/paypalMixin";

export default {
    name: "JobApplications",
    mixins: [modelListMixin, paypalMixin],
    components: {
        ModalSidebar,
        VueTable,
        FormRadio, FormTextarea, FormInput, JobDetails, ApplicationDetails, JobApplicationDropdown, UserAvatar, JobDropdown, JobCard, ToggleNav,
        Btn, OpenBlueIcon, CloseIcon },
    data() {
        return {
            job: null,
            loading: {
                info: false,
                reject: false,
                deposit: false,
            },
            filters: {
                project_id: null,
            },
            selected_application: null,
            reject_application: {
                id: null,
                reason: '',
                reject_reason_details: '',
            },
            reject_reason: '',
            show_buttons: false
        }
    },
    created() {
        this.filters.project_id = this.$route.params.id
        if (this.$route.params.job) {
            this.job = this.$route.params.job;
        }else{
            this.getDetails();
        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.getPage();
        }
    },
    methods: {
        getDetails() {
            this.loading.info = true;
            return this.$axios.post('/account/projects/project-info', { id: this.$route.params.id })
                .then((res) => {
                    this.job = res.project
                    this.loading.info = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.info = false;
                });
        },
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects/applications/list', this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        acceptApplication(application) {
            if (application.payment_method === 'fixed') {
                this.$modal.show('depositModel')
                return;
            }
            this.$dialog.confirm('Are you sure you want to approve the application?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    application.loading = true;
                    this.$axios.post('/account/projects/applications/approve', {id: application.id})
                        .then((res) =>{
                            this.getData();
                            application.loading = false;
                            this.$modal.hide('JobApplicationDetailsModal')
                        })
                        .catch((error) => {
                            application.loading = false;
                            if (res.no_payment_methods) {
                                this.$router.push({'name': 'account.payments'});
                            }
                        });
                });
        },
        rejectApplication() {
            this.loading.reject = true;
            this.$axios.post('/account/projects/applications/reject', {
                id: this.reject_application.id,
                reason: this.reject_application.reason,
                reject_reason_details: this.reject_application.reason === 'Other Reasons' ? this.reject_application.reject_reason_details : '',
            })
                .then((res) =>{
                    this.getData();
                    this.$modal.hide('RejectModal')
                    this.loading.reject = false;
                    this.$modal.hide('JobApplicationDetailsModal')
                })
                .catch((error) => {
                    this.loading.reject = false;
                });
        },
        openRejectModal(application) {
            this.reject_application.id = application.id;
            this.$modal.show('RejectModal')
        },
        openRejectReasonModal(application) {
            this.reject_reason = application.reject_reason;
            this.$modal.show('RejectReasonModal')
        },
        openJobDetails() {
            this.$modal.show('JobDetailsModal')
        },
        viewApplicationDetails(application) {
            this.selected_application = application;
            this.$modal.show('JobApplicationDetailsModal')
        },
        depositToEscrowWithSavedCard() {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(async () => {
                    this.loading.deposit = true;
                    this.$axios.post("/account/projects/applications/approve", {
                        id: this.selected_application.id,
                        use_saved_card: true,
                    })
                        .then(async (res) => {
                            this.getData();
                            this.$modal.hide('depositModel');
                            this.loading.deposit = false;
                            this.$modal.hide('JobApplicationDetailsModal')
                        })
                        .catch((res) => {
                            this.loading.deposit = false;
                            if (res.no_payment_methods) {
                                this.$router.push({'name': 'account.payments'});
                            }
                        })

                }).catch(error => {
                console.log(error)
            })
        },

        async depositToEscrow() {
            if (this.show_buttons) {
                return;
            }
            this.show_buttons = true;
            try {
                await this.initPaypal();
                await window.paypal.Buttons({
                    createOrder: async () => {
                        console.log(this)
                        return await this.$axios.post("/account/projects/applications/approve", {
                            id: this.selected_application.id
                        })
                            .then(async (res) => {
                                return res.order.id;
                            })
                            .catch((res) => {
                                this.loading.deposit = false;
                            })
                    },
                    onApprove: async (data, actions) => {
                        return await this.$axios.post("/account/projects/applications/approve", {
                            id: this.selected_application.id,
                            order_id: data.orderID
                        })
                            .then(async (res) => {
                                this.getData();
                                this.$modal.hide('depositModel');
                                this.loading.deposit = false;
                                this.$modal.hide('JobApplicationDetailsModal')
                            })
                            .catch((error) => {
                                console.log(error)
                                this.loading.deposit = false;
                            })
                    },
                    onError(err) {
                        console.log(err)
                    }
                }).render('#paypal-buttons')
            } catch (error) {
                this.show_buttons = false;
                alert('Paypal could not be initialized, try again later')
                console.log('Paypal error:', error)
            }
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

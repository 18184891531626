<template>
    <FormSelect placeholder="Status" class="mb-0" inputClass="form-control" v-model="selected_value" :options="options" :placehoder="label"/>
</template>

<script>

    import FormSelect from "@/components/FormSelect.vue";

    export default {
        name: 'TimeSelect',
        components: {FormSelect},
        props: ['value', 'label', 'interval'],
        data () {
            return {
                options: [],
                selected_value : null,
            }
        },
        created (){
            const x = this.interval ? this.interval : 30;
            let tt = 0;

            for (var i=0;tt<24*60; i++) {
                const hh = ("0" + (Math.floor(tt / 60))).slice(-2);
                const mm = ("0" + (tt % 60)).slice(-2);
                const value = `${hh}:${mm}`

                this.options.push({
                    value: value,
                    label: this.$options.filters.momentTime(value + ':00', 'HH:mm')
                })
                tt = tt + x;
            }

            this.selected_value = this.value;
        },
        watch: {
            value(a) {
                this.selected_value = a;
            },
            selected_value(option) {
                this.$emit('input', option);
            },
        }
    };
</script>

<template>
    <div class="p-filters">
        <template v-if="loading">
            <Shimmer v-for="i in 6" :key="i" class="mb-2" width="100%" height="49px"/>
        </template>
        <template v-else>
            <GridFilter :filter="filters[index]"
                        @setFilterValue="(payload) => $emit('setFilterValue', payload)"
                        @clearFilterValue="(payload) => $emit('clearFilterValue', payload)"
                        v-for="(filter, index) in filters" :key="filter.type + (filter.id ? filter.id : index)"/>
        </template>
    </div>
</template>

<script>

import Shimmer from "./Shimmer.vue";
import GridFilter from "./GridFilter.vue";

export default {
    name: 'GridFilters',
    components: {GridFilter, Shimmer},
    props: {
        filters: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    }
}
</script>

<template>
   <div v-if="project?.id">
       <modal class="application-modal" :name="'applicationModal_' + project.id" :scrollable="true" width="740px" height="auto" :adaptive="true" :pivotY="0.2">
           <div class="modal-dialog" style="max-width: 650px">
               <div class="modal-content" style="width: 650px">
                   <a style="cursor: pointer" class="close" @click="$modal.hide('applicationModal_' + project.id)">
                       <CloseIcon class="close"/>
                   </a>
                   <div class="modal-header">
                       <ApplicationImage height="130" width="240" class="mb-3"/>
                       <h4 class="modal-title">You are applying for the project</h4>
                       <div class="application-project">
                           {{ project.title }}
                       </div>
                   </div>
                   <div class="modal-body">
                       <FormTextarea class="mb-2" label="Description of your skills and experience.*" v-model="application.skills" :v="$v.application.skills"/>
                       <FormTextarea class="mb-2" label="Why your skills and experience are relevant to the Project?*" v-model="application.skills_relevance" :v="$v.application.skills_relevance"/>
                       <FormTextarea class="mb-2" label="Describe relevant projects you worked on before." v-model="application.relevant_projects"/>
                       <FormTextarea class="mb-2" label="Describe your accomplishment accomplishment_strategy*" v-model="application.accomplishment_strategy" :v="$v.application.accomplishment_strategy"/>
                       <FormInput type="number" class="mb-2" label="Will you do revisions (how many)?*" v-model="application.revisions" min="0" :v="$v.application.revisions"/>
                       <FormGroup class="mb-2" label="You can attach a file if you want to add further details.">
                           <DocumentsUploader v-model="application.documents" :multiple="true"/>
                       </FormGroup>

                       <template v-if="project.payment_method === 'hourly'">
                           <FormInput class="mb-2" label="Hourly rate*" v-model="application.price" :v="$v.application.price"/>
                           <FormGroup label="Duration of the Project:">
                               <div class="form-range-input">
                                   <FormInput style="width: 170px" v-model="application.duration_min"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 170px" v-model="application.duration_max"/>
                                   <span class="ml-2">Days</span>
                               </div>
                           </FormGroup>
                           <FormGroup label="Number of Hours Required">
                               <div class="form-range-input">
                                   <FormRadio v-model="application.hours_type" class="mr-2" fieldValue="week" text="Per Week"/>
                                   <FormRadio v-model="application.hours_type" class="mr-2" fieldValue="month" text="Per Month"/>
                                   <FormInput style="width: 120px" v-model="application.hours_min" append_text="h"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 120px" v-model="application.hours_max" append_text="h"/>
                               </div>
                           </FormGroup>
                       </template>

                       <template v-if="project.payment_method === 'fixed'">
                           <FormInput class="mb-2" label="Fixed Price*" v-model="application.price"/>
                           <div class="form-group">
                               <label for="">Delivery Time:</label>
                               <div class="form-range-input">
                                   <FormInput style="width: 170px" v-model="application.duration_min" append_text="days"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 170px" v-model="application.duration_max" append_text="days"/>
                               </div>
                           </div>
                       </template>

                   </div>
                   <div class="modal-footer">
                       <Btn :loading="loading.send" class="btn btn-lg btn-secondary btn-block" @click="submitApplication()">Submit Your Proposal</Btn>
                   </div>
               </div>
           </div>
       </modal>
       <modal :name="'applicationDoneModal_' + project.id" :scrollable="true" width="599px" height="auto" :adaptive="true" :pivotY="0.2">
           <div class="modal-dialog" style="max-width: 537px">
               <div class="modal-content" style="width: 537px">
                   <a style="cursor: pointer" class="close" @click="$modal.hide('applicationDoneModal_' + project.id)">
                       <CloseIcon class="close"/>
                   </a>
                   <div class="modal-header">
                       <ApplicationDoneImage height="190" width="125" class="mb-3"/>
                       <h4 class="modal-title" style="max-width: 350px;">Proposal Under consideration</h4>
                   </div>
                   <div class="modal-body">
                       <div class="modal-subtitle" style="max-width: 415px">We will notify you if The Client choose you. You will get an email from us.</div>
                   </div>
                   <div class="modal-footer">
                       <Btn :loading="loading.send" class="btn btn-lg btn-secondary btn-block --w380" @click="goBack">Back to projects List</Btn>
                   </div>
               </div>
           </div>
       </modal>
   </div>
</template>

<script>
    import FormInput from "@/components/FormInput.vue";
    import Btn from "@/components/Btn.vue";
    import FormTextarea from "@/components/FormTextarea.vue";
    import DocumentsUploader from "@/components/DocumentsUploader.vue";
    import FormRadio from "@/components/FormRadio.vue";
    const { required, minValue } = require('vuelidate/lib/validators');

    export default {
        name: 'ApplicationModal',
        components: {FormRadio, DocumentsUploader, FormTextarea, Btn, FormInput},
        props: ['project'],
        data () {
            return {
                loading: {
                    send: false,
                },
                application: {
                    skills: '',
                    skills_relevance: '',
                    relevant_projects: '',
                    accomplishment_strategy: '',
                    revisions: '',
                    documents: [],
                    price: '',
                    duration_min: 1,
                    duration_max: 25,
                    hours_type: 'week',
                    hours_min: 1,
                    hours_max: 25,
                    payment_method: 'fixed',
                },
            }
        },
        validations: {
            application: {
                skills: { required },
                skills_relevance: { required },
                accomplishment_strategy: { required },
                revisions: { required, minValue: minValue(0) },
                price: { required },
            }
        },
        created() {
            if (this.project.payment_method === 'fixed') {
                this.application.price = this.project.price[0];
                this.application.duration_min = this.project.duration[0];
                this.application.duration_max = this.project.duration[1];
            }
            if (this.project.payment_method === 'hourly') {
                this.application.price = this.project.price[0];
                this.application.hours_type = this.project.hours_type;
                this.application.duration_min = this.project.duration[0];
                this.application.duration_max = this.project.duration[1];
                this.application.hours_min = this.project.hours[0];
                this.application.hours_max = this.project.hours[1];
            }
        },
        methods: {
            submitApplication() {
                this.$v.application.$touch();
                if (this.$v.application.$invalid == true) {
                    return false;
                }
                this.loading.send = true;
                this.$axios.post("/jobs/add-application", {...this.application, ...{
                    project_id: this.project.id
                }})
                    .then((res) => {
                        this.loading.send = false;
                        this.$modal.show('applicationDoneModal_' + this.project.id);
                        this.$emit('onSubmit', res)
                    })
                    .catch((res) => {
                        this.loading.send = false;
                    })
            },
            goBack() {
                this.$modal.hide('applicationModal_' + this.project.id)
                this.$modal.hide('applicationDoneModal_' + this.project.id)
                this.$modal.hide('JobDetailsModal')
            },
        }
    };
</script>


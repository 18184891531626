<template>
    <div style="height: 100%">
        <div :class="{'--with-sidebar': sidebarExpanded}">
            <div style="flex: 1">
                <div class="fullcalendar" :class="{'loading-big': loading.data}">
                    <FullCalendar
                        ref="calendar"
                        :options="calendarOptions"
                    />
                </div>
            </div>
            <div class="sidebar" v-if="sidebarExpanded">
                <div class="sidebar-content">
                    <div class="sidebar-calendar">
                        <CalendarPicker v-model="date" @change="selectDate"/>
                    </div>
                </div>
            </div>
        </div>
        <modal name="previewAppointmentModal" :scrollable="true" width="740px" height="auto" :adaptive="true" :pivotY="0.2">
            <div class="modal-dialog" style="max-width: 650px">
                <div class="modal-content" style="width: 650px" v-if="selected_event">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('previewAppointmentModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Appointment #{{ selected_event.extendedProps.object.ref }}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 330px;">
                            <table class="table table-sm">
                                <tbody>
                                <tr>
                                    <td>Ref</td>
                                    <td>{{ selected_event.extendedProps.object.ref }}</td>
                                </tr>
<!--                                <tr>-->
<!--                                    <td>Status</td>-->
<!--                                    <td-->
<!--                                        v-bind:class="[selected_event.extendedProps.object.status === -1 ? 'text-danger' :-->
<!--                                                        selected_event.extendedProps.object.status === 1 ? 'text-success' :-->
<!--                                                        selected_event.extendedProps.object.status === 0 ? 'text-warning' : 'text-info']">-->
<!--                                        {{ selected_event.extendedProps.object.status_label }}-->

<!--                                    </td>-->
<!--                                </tr>-->
                                <tr>
                                    <td>Date</td>
                                    <td>{{ selected_event.extendedProps.object.date_from | moment('DD MMM YYYY HH:mm') }}</td>
                                </tr>
                                <tr>
                                    <td>Duration</td>
                                    <td>{{ selected_event.extendedProps.object.duration | toDuration }}</td>
                                </tr>
                                <tr>
                                    <td>Made by</td>
                                    <td>{{ getClientName(selected_event.extendedProps.object.client) }}</td>
                                </tr>
                                <tr>
                                    <td>With</td>
                                    <td>{{ getClientName(selected_event.extendedProps.object.with_client) }}</td>
                                </tr>
    <!--                            <tr>-->
    <!--                                <td>{{ $t('email') }}</td>-->
    <!--                                <td><a :href="'mail:' + selected_event.extendedProps.object.email" v-if="selected_event.extendedProps.object.email">{{ selected_event.extendedProps.object.email }}</a></td>-->
    <!--                            </tr>-->
    <!--                            <tr>-->
    <!--                                <td>{{ $t('phone') }}</td>-->
    <!--                                <td><a :href="'tel:' + selected_event.extendedProps.object.phone" v-if="selected_event.extendedProps.object.phone">{{ selected_event.extendedProps.object.phone }}</a></td>-->
    <!--                            </tr>-->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer flex-row" v-if="new Date(selected_event.extendedProps.object.date_to) > new Date()">
<!--                        <a href="#" @click.prevent="editBooking(selected_event)" class="btn btn-light" style="width:150px">Edit</a>-->
                        <Btn v-if="(selected_event.extendedProps.object.status == 0)" @click="approveAppointment(selected_event)" :loading="loading.approve" class="btn-primary --w150">Approve</Btn>
<!--                        <a v-if="selected_event.extendedProps.object.status === 1" href="#" @click.prevent="finishBooking(selected_event)" :class="{'loading': loading.finish}" class="btn btn-sm btn-primary">FINALIZEAZA</a>-->
                        <Btn v-if="(selected_event.extendedProps.object.status >= 0)" @click="cancelAppointment(selected_event)" :loading="loading.cancel" class="btn-outline-danger --w150">Reject</Btn>
                        <template v-if="selected_event.extendedProps.object.status == 1">
                            <a v-if="selected_event.extendedProps.object.meeting_url" target="_blank" :href="selected_event.extendedProps.object.meeting_url" class="ml-2 btn btn-outline-secondary">Join Meeting</a>
                            <Btn v-else @click="startAppointment(selected_event)" :loading="loading.start" class="btn-primary --w150">Start</Btn>
                        </template>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="createAppointmentModal" :scrollable="true" width="740px" height="auto" :adaptive="true" :pivotY="0.2">
            <div class="modal-dialog" style="max-width: 650px">
                <div class="modal-content" style="width: 650px">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('createAppointmentModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Creating new Appointment</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 330px;">
                            <FormInput :v="$v.appointment.with_user.email" label="Email Address" v-model="appointment.with_user.email"/>
                            <FormInput :v="$v.appointment.with_user.firstname" label="First name" v-model="appointment.with_user.firstname"/>
                            <FormInput :v="$v.appointment.with_user.lastname" label="Last name" v-model="appointment.with_user.lastname"/>
                            <CalendarPicker v-model="appointment.date" :v="$v.appointment.date"/>
                            <div class="row mt-2">
                                <div class="col"><FormTimeSelect label="Time-From" v-model="appointment.time_from" :v="$v.appointment.time_from"/></div>
                                <div class="col"><FormTimeSelect label="Time-To" v-model="appointment.time_to" :v="$v.appointment.time_to"/></div>
                            </div>
                            <Btn @click="save()" :loading="loading.save" class="btn-secondary btn-block">Create</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import scrollgridPlugin from '@fullcalendar/scrollgrid'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import CalendarPicker from "@/components/CalendarPicker.vue";
import {mapState} from "vuex";
import Btn from "@/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import FormTimeSelect from "@/components/FormTimeSelect.vue";
import {required} from "vuelidate/lib/validators";
import {getClientName} from "../../utils/helpers";

dayjs.extend(isToday)

export default {
    name: 'AppointmentsCalendar',
    components: {FormTimeSelect, FormInput, Btn, CalendarPicker, FullCalendar },
    data () {
        return {
            sidebarExpanded: true,
            calendarOptions: {
                plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin, scrollgridPlugin],
                initialView: 'timeGridDay',
                dayMaxEventRows: true,
                showNonCurrentDates: false,
                timeZone: 'local',
                firstDay: 1,
                dayMinWidth: 260,
                snapDuration: '00:30',
                stickyFooterScrollbar: true,
                views: {
                    dayGrid: {
                        dayMaxEventRows: 4
                    }
                },
                headerToolbar: {
                    start: 'title',
                    center: '',
                    end: 'prev,today,next'
                },
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false,
                    // hour12: false,
                    hourCycle: 'h23'
                },
                editable: false,
                selectable: true,
                allDaySlot: false,
                slotMinTime: '00:00:00',
                slogMaxTime: '24:00:00',
                slotDuration: '00:30:00',
                slotLabelInterval: '00:30',
                eventMinHeight: 69,
                expandRows: true,
                dayMaxEvents: false,
                height: 'auto',
                // dayHeaders: false,
                events: [],
                // dayCellContent: function (arg) {
                //     if(!arg.view.type === '') {
                //         return null
                //     }
                //
                //     return `<div class="day-summary"></div>`
                // },
                eventContent: this.eventContent,
                // eventDrop: this.eventDrop,
                // eventResize: this.eventResize,
                eventClick: this.previewAppointment,
                select: this.showAddAppointment,
                datesSet: this.onDatesSet,
            },
            date: null,
            selected_event: null,
            view: 'day',
            loading: {
                data: false,
                save: false,
                cancel: false,
                approve: false,
                start: false,
            },
            appointment: {
                with_user: {
                    email: '',
                    firstname: '',
                    lastname: '',
                },
                date: null,
                time_from: null,
                time_to: null,
            },
            filters: {
                date_from: null,
                date_to: null,
                type: null,
            },
        }
    },
    validations: {
        appointment: {
            with_user: {
                email: {required},
                firstname: {required},
                lastname: {required},
            },
            date: {required},
            time_from: {required},
            time_to: {required},
        },
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    created () {
        this.date = dayjs().format('YYYY-MM-DD')
    },
    watch: {
        filters: {
            deep: true,
            handler (a) {
                this.getData()
                // this.$refs.calendar.getApi().gotoDate(this.date)
            }
        },
    },
    methods: {
        getClientName,
        eventContent(arg) {
            const start_date = dayjs(arg.event.start).format('HH:mm')
            const end_date = dayjs(arg.event.end).format('HH:mm')
            if (arg.view.type === 'dayGridMonth') {
                return {
                    html: `
                                <div class="fc-daygrid-event-dot" style="border-color: ${arg.event.extendedProps.backgroundColor};"></div>
                                <div class="fc-event-time">${start_date}</div>
                                <div class="fc-event-title">${arg.event.title}</div>
                            `
                }
            }
            const name = arg.event.extendedProps.object.client.id === this.user.id ? getClientName(arg.event.extendedProps.object.with_client) : getClientName(arg.event.extendedProps.object.client);
            return {
                html: `<div class="custom-event">
                                <div class="custom-event__avatar" style="background-color: ${arg.event.extendedProps.backgroundColor}">
                                    ${name?.substring(0, 2)}
                                </div>
                                <div class="custom-event__info">
                                    <div class="custom-event__title">${arg.event.title}</div>
                                    <div class="custom-event__name">${name}</div>
                                    <div class="custom-event__date">${start_date} - ${end_date}</div>
                                </div>
                            </div>`
            }
        },
        getData () {
            this.loading.data = true
            this.$axios.get('/account/appointments/calendar', { params: this.filters })
                .then((res) => {
                    this.calendarOptions.events = res.data
                    this.refreshEvents()
                    this.loading.data = false
                })
                .catch((error) => {
                    this.loading.data = false
                })
        },
        save () {
            this.$v.appointment.$touch()
            if (this.$v.appointment.$invalid == true) {
                return false
            }
            this.loading.save = true
            let data = {...this.appointment};
            data.date_from = dayjs(this.appointment.date + ' ' + this.appointment.time_from)
            data.date_to = dayjs(this.appointment.date + ' ' + this.appointment.time_to)
            this.$axios.post('/account/appointments/save', data)
                .then(() => {
                    this.getData()
                    this.$modal.hide('createAppointmentModal')
                    this.loading.save = false
                })
                .catch((error) => {
                    this.loading.save = false
                })
        },
        editAppointment (appointment) {
            this.$modal.hide('previewAppointmentModal')
            this.$modal.show('editAppointmentModal')
            this.appointment = JSON.parse(JSON.stringify(appointment.extendedProps.object))
        },
        cancelEdit () {
            this.$modal.hide('editAppointmentModal')
        },
        cancelAppointment (event) {
            this.$dialog.confirm('Are your sure you want to cancel this appointment?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.cancel = true
                    this.$axios.post('/account/appointments/change-status', {
                        appointment_id: event.id,
                        status: 'reject',
                    }).then((res) => {
                        this.loading.cancel = false
                        this.$modal.hide('previewAppointmentModal')
                        this.getData()
                    }).catch(() => {
                        this.loading.cancel = false
                    })
                })
        },
        approveAppointment (event) {
            this.$dialog.confirm('Are you sure you want to approve this appointment?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.approve = true
                    this.$axios.post('/account/appointments/change-status', {
                        appointment_id: event.id,
                        status: 'approve',
                    }).then((res) => {
                        this.loading.approve = false
                        this.$modal.hide('previewAppointmentModal')
                        this.getData()
                    }).catch(() => {
                        this.loading.approve = false
                    })
                })
        },
        startAppointment (event) {
            this.loading.start = true
            this.$axios.post('/account/appointments/start', {
                appointment_id: event.id,
            }).then((res) => {
                this.getData();
                window.open(res.meetings.data.join_url, '_blank', 'noreferrer');
                this.selected_event.extendedProps.object.meeting_url = res.meetings.data.join_url
                this.loading.start = false
            }).catch((error) => {
                console.log(error)
                this.loading.start = false
            })
        },
        showAddAppointment (selectionInfo) {
            this.appointment = {
                with_user: {
                    email: '',
                    firstname: '',
                    lastname: '',
                },
                date_from: null,
                date_to: null,
            }
            this.$v.appointment.$reset()

            this.appointment.date = dayjs(selectionInfo.start).format('YYYY-MM-DD')
            this.appointment.time_from = dayjs(selectionInfo.start).format('HH:mm')
            this.appointment.time_to = dayjs(selectionInfo.end).format('HH:mm')
            this.$modal.show('createAppointmentModal')
        },
        previewAppointment ({ event }) {
            this.selected_event = event
            this.$modal.show('previewAppointmentModal')
        },
        cancel () {
            this.$modal.hide('addAppointmentModal')
        },
        refreshEvents () {
            this.$refs.calendar.getApi().refetchEvents()
        },
        removeEvent () {
            this.$refs.calendar.getApi().removeEvents([this.selected.id])
            this.selected = {}
        },
        eventDrop ({ event, delta, revert, jsEvent, ui, view }) {
            if (event.startEditable) {
                this.$dialog.confirm(this.$t('alert_reschedule_event') + event.extendedProps.object.id + '?', {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no')
                })
                    .then(() => {
                        this.$axios.post('/account/appointments/change-date', {
                            appointment_id: event.extendedProps.object.id,
                            event_start: dayjs(event.start).format('YYYY-MM-DD HH:mm:ss'),
                            event_end: dayjs(event.end).format('YYYY-MM-DD HH:mm:ss'),
                        }).then(() => {
                            // event.extendedProps.object.appointment_date = event.start.format('YYYY-MM-DD HH:mm:ss');
                        })
                    })
                    .catch((error) => {
                        revert()
                    })
            } else if(this.view === 'day') {
                // this.$dialog.confirm('You cannot drag and drop appointments in day view.', {
                //     okText: this.$t('ok'),
                // })
            }
        },
        eventResize ({ event, delta, revert, jsEvent, ui, view }) {
            if(event.durationEditable) {
                this.$dialog.confirm(this.$t('alert_change_appointment_duration') + event.extendedProps.object.id + '?', {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no')
                })
                    .then(() => {
                        this.$axios.post('/account/appointments/change-date', {
                            appointment_id: event.extendedProps.object.id,
                            event_start: event.start,
                            event_end: event.end
                        })
                    })
                    .catch((error) => {
                        revert()
                    })
            }
        },
        onDatesSet ({ view, start, end }) {
            switch (view.type) {
                case 'timeGridDay' :
                    this.view = 'day'
                    if (!dayjs(this.date).isSame(dayjs(start), 'day', null, '[]')) {
                        this.date = dayjs(start).format('YYYY-MM-DD')
                    }
                    this.filters.date_from = dayjs(start).toISOString()
                    //when only one day is selected the date_to needs to be the same as date_from, otherwise we get also appointments from the next day
                    this.filters.date_to = dayjs(end).toISOString()
                    break
            }
        },
        selectDate (date, showDay = false) {
            this.date = date
            this.$refs.calendar.getApi().gotoDate(date)
        },
    }
}
</script>


<template>
    <div>
        <div v-if="finished">
            <div class="card c-onboarding-card">
                <div class="card-header">
                    <div class="card-icon"><inline-svg src="/images/success_consultation.svg"/></div>
                    <div class="card-title">Your Consultation Session Booking With {{ getClientName(client) }} Has Been Confirmed</div>
                    <div class="card-subtitle">We have sent you a confirmation email as well</div>
                </div>
                <div class="card-body">
                    <div class="mx-auto" style="max-width: 488px">
                        <div class="text-center">
                            <Btn class="btn-outline-secondary --w380 mb-2" @click="finished = false">Make Another Booking</Btn>
                        </div>
                        <div class="text-center">
                            <Btn class="btn-secondary --w380" @click="$modal.hide('ConsultationModal')">Back to Profile</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else-if="client">
            <div class="col-lg-4 mb-2">
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="p-details__info">
                            <UserAvatar class="mb-3" size="large" :user="client"/>
                            <template v-if="client.isAgency">
                                <div class="p-details__info__title">{{ client.company.name }}
                                    <template v-if="client.company.website">
                                        <br>
                                        <div class="p-details__info__subtitle">{{ client.company.website }}</div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="p-details__info__title">{{ client.fullname }}</div>
                            </template>

                            <div class="p-details__info__desc">
                                {{ client.description }}
                            </div>
                            <div class="badge badge-secondary-light badge-lg mb-3" v-if="client.attributes.availability">Availability: {{ client.attributes.availability?.value }}</div>
                            <div class="p-details__info__stats">
                                <span><LocationIcon/> {{ [client.address.city_name, client.address.country_name].join(', ') }}</span>
                                <span><StarIcon/> {{ client.avgReview ? client.avgReview : '-' }}/5 <template v-if="client.countReview">({{ client.countReview }})</template></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 mb-2">
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="consultation-duration__card --blue" @click="booking.duration = 30">
                                    <div class="consultation-duration__icon">
                                        <Duration30/>
                                    </div>
                                    <div class="consultation-duration__info">
                                        <div class="consultation-duration__info-title">30 minute</div>
                                        <div class="consultation-duration__info-description">One-On-One Meeting</div>
                                    </div>
                                    <div class="consultation-duration__check">
                                        <FormCheckbox id="duration30" :value="booking.duration === 30" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="consultation-duration__card --green"  @click="booking.duration = 60">
                                    <div class="consultation-duration__icon">
                                        <Duration60/>
                                    </div>
                                    <div class="consultation-duration__info">
                                        <div class="consultation-duration__info-title">60 minute</div>
                                        <div class="consultation-duration__info-description">One-On-One Meeting</div>
                                    </div>
                                    <div class="consultation-duration__check">
                                        <FormCheckbox id="duration60" :value="booking.duration === 60"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="text-muted" v-if="!booking.duration">
                            Please select session duration to continue
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-7">
                                <label class="consultation__section-label" for="">Please select day</label>
                                <CalendarPicker class="consultation__calendar" @change="selectDate" @changeMonth="getMonthAvailability" :minDate="new Date()" :availability="month_availability" :loading="loading.month_availability"/>
                            </div>
                            <div class="col-md-5 d-flex flex-column">
                                <label class="consultation__section-label" for="">Please select the hours interval</label>
                                <div class="text-muted" v-if="!booking.date">
                                    Please select a day
                                </div>
                                <div class="card card-dark" style="flex: 1" v-else>
                                    <div class="card-body consultation__timeslots">
                                        <div class="consultation__timeslot" :class="{'selected': slot === booking.time}" v-for="slot in date_availability" @click="selectSlot(slot)">
                                            {{ slot }}-{{addDuration(slot)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body consultation__selected-card">
                        <div class="consultation__selected-info">
                            <div class="consultation__selected-title" v-if="booking.time">{{ booking.date | moment('dddd, DD MMMM, YYYY') }}</div>
                            <div class="consultation__selected-timeslot" v-if="booking.time">{{ booking.time }}-{{addDuration(booking.time)}}</div>
                        </div>
                        <Btn @click="confirmBooking" :disabled="!booking.time" :loading="loading.save" class="btn-secondary">Confirm Booking</Btn>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import UserAvatar from "@/components/UserAvatar.vue";
import FormCheckbox from "@/components/FormCheckbox.vue";
import CalendarPicker from "@/components/CalendarPicker.vue";
import Btn from "@/components/Btn.vue";
import dayjs from "dayjs";
import OnboardingCard from "@/components/OnboardingCard.vue";
import {getClientName} from "../utils/helpers";

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
    name: 'BookConsultation',
    components: {OnboardingCard, Btn, CalendarPicker, FormCheckbox, UserAvatar},
    props: ['client'],
    data() {
        return {
            loading: {
                save: false,
                data: false,
                month_availability: false,
            },
            date_availability: [],
            month_availability: {},
            booking: {
                duration: null,
                month: null,
                year: null,
                date: null,
                time: null
            },
            finished: false,
        }
    },
    watch: {
        'booking.duration'() {
            this.getMonthAvailability();
        }
    },
    methods: {
        getClientName,
        getMonthAvailability(month = null) {
            if (!this.client) {
                return;
            }
            this.date_availability = [];
            this.month_availability = {};
            this.loading.month_availability = true;
            if (month) {
                this.booking.month = month.month;
                this.booking.year = month.year;
            }
            if (!this.booking.month) {
                return;
            }
            return this.$axios.post('/clients/month-availability', {
                user_id: this.client.id,
                month: this.booking.month,
                year: this.booking.year,
                duration: this.booking.duration,
                timezone: dayjs.tz.guess(),
            })
                .then((res) => {
                    this.month_availability = res.dates
                    if (this.booking.date && this.month_availability[this.booking.date]) {
                        this.date_availability = this.month_availability[this.booking.date]
                        if (this.booking.time && this.date_availability.indexOf(this.booking.time) <= -1) {
                            this.booking.time = null;
                        }
                    }

                    this.loading.month_availability = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.month_availability = false;
                });
        },
        selectDate(date) {
            this.booking.date = date;
            this.date_availability = this.month_availability[this.booking.date]
        },
        selectSlot(slot) {
            this.booking.time = slot;
        },
        addDuration(slot) {
            return dayjs(slot, 'HH:mm').add(this.booking.duration, 'minute').format('HH:mm')
        },
        confirmBooking () {
            if (!this.client) {
                return;
            }
            this.loading.save = true;
            let data = {
                user_id: this.client.id,
                date_from: dayjs(this.booking.date + ' ' + this.booking.time),
                duration: this.booking.duration,
                timezone: dayjs.tz.guess()
            };
            return this.$axios.post('/clients/save-appointment', data)
                .then((res) => {
                    this.booking = {
                        duration: null,
                        month: null,
                        year: null,
                        date: null,
                        time: null
                    }
                    this.loading.save = false;
                    this.finished = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.save = false;
                });
        },
        backToProfile() {
            this.$modal.hide('ConsultationModal')
            setTimeout(() => {
                this.finished = false
            }, 5000)
        },
    }
}
</script>

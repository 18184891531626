<template>
    <div class="chat-room" :class="{'loading': loading.conversations}">
        <div class="chat-room__conversation">
            <ChatDiscussion :conversation="selected_conversation"
                            v-if="selected_conversation"
                            v-on:messages-loaded="markConversationAsRead(selected_conversation)"
                            @onInitConversation="setInitedConversation"
                            @deselectConversation="selected_conversation = null"
            />
            <div v-else class="text-center my-4 mx-4 d-none d-md-block">Select conversation</div>
        </div>
        <transition :name="$mq === 'xs' ? 'slide-up' : 'slide'">
            <div class="chat-room__sidebar" :class="{'--selected-conversation': selected_conversation}">
                <div class="l-account-content__header">
                    <div class="l-account-content__title">Messages</div>
                </div>
                <transition name="fade" mode="out-in" v-if="conversations?.length">
                    <div class="chat-list">
                        <transition-group
                            name="chat-list"
                            tag="div"
                        >
                            <a :key="conversation.ref ? conversation.ref : conversation.username" href="#"
                               v-for="conversation in conversations"
                               class="chat-list__item"
                               @click.prevent="selectConversation(conversation)"
                               :class="{'--active': selected_conversation && conversation.id === selected_conversation.id}">
                                <div class="chat-list__item__image-wrapper">
                                    <div style="position: relative">
                                        <UserAvatar :user="conversation.user" class="chat-list__item__image"/>
<!--                                        <img :src="$utils.getImage(conversation.user.image, 52, 52, false)" class="chat-list__item__image" :class="{'active': conversation.unread_count > 0}"/>-->
                                        <span v-if="conversation.is_online" class="chat-list__item__online-dot"></span>
                                    </div>
                                </div>
                                <div class="chat-list__item__content">
                                    <div class="chat-list__item__info">
                                        <div class="chat-list__item__name">{{ getClientName(conversation.user) }}</div>
                                        <div class="chat-list__item__time">{{ conversation.last_message_at | momentAgo }}</div>
                                    </div>
                                    <div class="chat-list__item__content__text-wrapper">
                                        <div class="chat-list__item__text"><template v-if="conversation.project"><b>Project: </b>{{ conversation.project.title }}</template><template v-else>{{ conversation.last_message }}</template></div>
                                    </div>
                                </div>
                            </a>
                        </transition-group>
                    </div>
                </transition>
                <div v-else>No conversations</div>
            </div>
        </transition>
    </div>
</template>

<script>
import ChatDiscussion from "@/components/Messenger/ChatDiscussion.vue";
import find from "lodash/find";
import UserAvatar from "@/components/UserAvatar.vue";
import {getClientName} from "../../utils/helpers";
import {mapState} from "vuex";
import {orderBy} from "lodash";

export default {
    name: 'Chat',
    components: {
        UserAvatar,
        ChatDiscussion,
    },
    data () {
        return {
            loading: {
                conversations: false,
            },
            last_updated_at: null,
            sidebar: null,
            conversations: [],
            selected_conversation: null,
            inited_conversation: null,
            pollingConversationsRequest: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    beforeDestroy() {
        this.$echo.leave(`Conversations.${this.user.id}`);
        if (this.pollingConversationsRequest) {
            this.pollingConversationsRequest.abort();
        }
    },
    async mounted() {
        await this.getConversations();
        if (this.$route.params.username) {
            await this.initConversationWithUser(this.$route.params.username)
        }else if (this.conversations.length) {
            let conversation = this.conversations[0];
            if (this.$route.params.ref) {
                conversation = find(this.conversations, {'ref': this.$route.params.ref});
            }
            this.selectConversation(conversation);
        }
        // if (this.conversations.length) {
            // this.startPollingConversations();
        // }
        this.startListeners();
    },
    methods: {
        getClientName,
        startListeners() {
            console.log(`Conversations.${this.user.id}`)
            this.$echo.private(`Conversations.${this.user.id}`)
                .listen('.ConversationUpdated', (e) => {
                    let index = this.conversations.findIndex(m => m.id === e.conversation.id)
                    console.log({index})
                    if (index > -1) {
                        this.conversations[index].last_message = e.conversation.last_message;
                        this.conversations[index].last_message_at = e.conversation.last_message_at;
                    }
                    console.log(this.conversations[index])
                    this.conversations = orderBy(this.conversations, ['last_message_at'], ['desc'])
                })
                .listen('.NewConversation', (e) => {
                    this.conversations.push(e.conversation)
                    orderBy(this.conversations, ['updated_at'], ['desc'])
                })
                .listen('.ConversationDeleted', (e) => {
                    let index = this.conversations.findIndex(m => m.id === e.id)
                    if (index > -1) {
                        this.conversations.splice(index, 1);
                    }
                });
            console.log(this.$echo)
        },
        startPollingConversations() {
            if (!this.last_updated_at) {
                return false;
            }
            if (this.pollingConversationsRequest) {
                if (!this.pollingConversationsRequest.signal.aborted) {
                    this.pollingConversationsRequest.abort();
                }
            }
            this.pollingConversationsRequest = new AbortController();
            this.$axios.post("/messaging/poll-conversations", {
                'last_updated_at': this.last_updated_at,
            }, {
                signal: this.pollingConversationsRequest.signal
            })
                .then((res) => {
                    if (res.not_changed === undefined) {
                        this.conversations = res.conversations;
                        this.last_updated_at = res.last_updated_at;

                        if(this.inited_conversation){
                            let conversation = find(this.conversations, {'ref': this.inited_conversation.ref});
                            this.inited_conversation = null;
                            this.selectConversation(conversation);
                        }
                    }
                    this.startPollingConversations();
                })
                .catch((error) => {
                    console.log({error})

                });
        },
        getConversations () {
            this.loading.conversations = true;
            return this.$axios.get("/messaging/conversations")
                .then((res) => {
                    this.conversations = res.conversations;
                    this.loading.conversations = false;
                    this.last_updated_at = res.last_updated_at;
                    // document.getElementById('#div1').scrollTop(document.getElementById('#div1').scrollHeight);
                })
                .catch((error) => {
                    console.log(error);
                    this.loading.conversations = false;
                });
        },
        initConversationWithUser (username) {
            return this.$axios.post("/messaging/conversations/init", {username: username, project_id: this.$route.params.project_id})
                .then((res) => {
                    if (res.conversation.ref) {
                        let index = this.conversations.findIndex((c) => c.ref === res.conversation.ref)
                        if (index > -1) {
                            this.selectConversation(this.conversations[index]);
                        }else{
                            this.conversations.unshift(res.conversation);
                            this.selectConversation(this.conversations[0]);
                        }
                    }else{
                        // this.conversations.unshift(res.conversation);
                        this.selectConversation(res.conversation);
                    }
                    setTimeout(() => {
                        // document.getElementById('#div1').scrollTop(document.getElementById('#div1').scrollHeight);
                    }, 100);
                })
                .catch(() => {

                });
        },
        selectConversation(conversation) {
            this.selected_conversation = conversation;
        },
        markConversationAsRead(conversation) {
            conversation.unread_count = 0;
        },
        setInitedConversation(conversation) {
            this.inited_conversation = conversation;
            let index = this.conversations.findIndex((c) => c.ref === conversation.ref)
            if (index > -1) {
                this.selectConversation(this.conversations[index]);
                this.inited_conversation = null;
            }else {
                if (!this.last_updated_at) {
                    this.last_updated_at = 1
                }
                // this.startPollingConversations();
            }
        }
    }
}

</script>

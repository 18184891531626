<template>
    <div class="p-filters__item" v-if="filter_data">
        <div class="p-filters__item-title" :class="{'expanded' : expanded}" @click="expanded = !expanded"> {{ filter_data.name }}
            <CaretIcon class="p-filters__item-caret" v-if="filter_data.field_type !== 'dropdown-select'"/>
        </div>
        <div v-show-slide="expanded" v-if="filter_data.field_type === 'range' || filter_data.field_type === 'hours'">
            <div class="p-filters__item-slider">
                <ClientOnly>
<!--                    <template v-if="filter_data.field_type === 'hours'">-->
<!--                        <a-->
<!--                            :key="-1"-->
<!--                            @click.prevent="selectFilterValue('')"-->
<!--                            class="p-filters__item-list-item">-->
<!--                            <div>Any</div>-->
<!--                        </a>-->
<!--                    </template>-->
                    <vue-slider v-model="filter_data.value"
                                :min="parseFloat(filter_data.min.toFixed(0))"
                                :max="parseFloat(filter_data.max.toFixed(0))"
                                v-on:drag-end="setRangeFilterValue"
                                tooltip="always"
                                :tooltipFormatter="val => (filter_data.prefix ? filter_data.prefix : '') + val + (filter_data.suffix ? filter_data.suffix : '')"
                                tooltipPlacement="top"
                                :marks="[
                                    parseFloat(filter_data.min.toFixed(0)),
                                    parseFloat(filter_data.max.toFixed(0))
                                ]"
                    ></vue-slider>
                </ClientOnly>
            </div>
        </div>
<!--        <div v-show-slide="expanded" class="p-filters__item-list-wrapper" v-else-if="filter_data.field_type === 'boolean'">-->
<!--            <div class="p-filters__item-list">-->
<!--                <a-->
<!--                    href="yes&#45;&#45;1"-->
<!--                    @click.prevent="selectFilterValue(1)"-->
<!--                    class="p-filters__item-list-item" :class="{'active': value.is_selected}">-->
<!--                    <div>Yes</div>-->
<!--                </a>-->
<!--                <a-->
<!--                    href="no&#45;&#45;0"-->
<!--                    @click.prevent="selectFilterValue(0)"-->
<!--                    class="p-filters__item-list-item" :class="{'active': value.is_selected}">-->
<!--                    <div>No</div>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
        <div class="p-filters__item-list-wrapper" v-else-if="filter_data.field_type === 'dropdown-select'">
            <div class="p-filters__item-search">
                <div class="vue-multiselect__input-group">
                    <FilterSelect :options="filteredValues" @select="selectFilterValue" :searchOnly="true"/>
                    <div class="input-group-append">
                        <span class="input-group-text"><SearchIcon/></span>
                    </div>
                </div>
            </div>
            <div class="pb-2">
                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="(value, index) in selectedValues">{{ value.name }} <a href="#" @click.prevent="() => selectFilterValue(value)">X</a></div>
            </div>
        </div>
        <div v-show-slide="expanded" class="p-filters__item-list-wrapper" v-else>
            <div class="p-filters__item-search" v-if="filter_data.options.length >= 20">
                <div class="input-group">
                    <input type="search" v-model="search" class="form-control" placeholder="Search">
                    <div class="input-group-append">
                        <span class="input-group-text"><SearchIcon/></span>
                    </div>
                </div>
            </div>
            <div class="p-filters__item-list">
                <a
                    :key="-1"
                    @click.prevent="clearFilterValue()"
                    class="p-filters__item-list-item" :class="{'active': !hasSelected}">
                    <div>Any</div>
                </a>
                <template v-for="(value, index) in filteredValues">
                    <a
                        :href="value.url"
                        :key="index"
                        @click.prevent="selectFilterValue(value)"
                        class="p-filters__item-list-item" :class="{'active': value.is_selected}">
                        <img height="20" v-if="value.icon" :src="value.icon"/> <div>{{ value.name }} <div class="p-filters__item-list-item-subtitle" v-if="value.subtitle">{{ value.subtitle}}</div></div> <template v-if="value.count">( {{ value.count }} )</template>
                    </a>
                </template>
            </div>
        </div>

    </div>
</template>

<script>


import FilterSelect from "@/components/FilterSelect.vue";

export default {
    name: 'GridFilter',
    components: {FilterSelect},
    props: ['filter'],
    computed: {
        filteredValues () {
            if (this.filter_data.field_type === 'dropdown-select') {
                return this.filter_data.options.filter(value => !value.is_selected)
            }
            if(!this.search) {
                return this.filter_data.options;
            }
            const term = this.search.toLowerCase();
            return this.filter_data.options.filter(value => value.name.toLowerCase().indexOf(term) > -1)
        },
        hasSelected () {
            return this.filter_data.options.some(value => value.is_selected)
        },
        selectedValues () {
            console.log(this.filter_data.options)
            return this.filter_data.options.filter(value => value.is_selected)
        },
    },
    data() {
        return {
            search: '',
            filter_data: null,
            expanded: true
        }
    },
    created () {
        let filter = this.filter;
        if (filter.field_type === 'range') {
            filter = this.setRangeFilter(filter)
        } else {
            // if (filter.values.some(value => value.selected)) {
            //     this.expanded = true
            // }
        }
        this.filter_data = filter

    },
    watch: {
        filter () {
            let filter = this.filter;
            if (filter.field_type === 'range') {
                filter = this.setRangeFilter(filter)
            }
            this.filter_data = filter
        },
    },
    methods: {
        setRangeFilter (filter) {
            if (!filter.value) {
                filter.value = [
                    parseFloat(filter.from.toFixed(0)),
                    parseFloat(filter.to.toFixed(0))
                ]
            }
            return filter;
        },
        selectFilterValue(value) {
            value.is_selected = !value.is_selected
            this.$emit('setFilterValue', {
                id: this.filter_data.id,
                value: value.value,
                type: this.filter_data.type,
                multiple_values: this.filter_data.allow_filter_multiple,
                url: value.url,
            })
        },
        clearFilterValue() {
            this.filter_data.options.map(o => {
                o.is_selected = false;
            })
            this.$emit('clearFilterValue', {
                id: this.filter_data.id,
                type: this.filter_data.type,
            })
        },
        setRangeFilterValue() {
            this.$emit('setFilterValue', {
                id: this.filter_data.id,
                value: this.filter_data.value,
                type: this.filter_data.type,
                multiple_values: this.filter_data.multiple_values,
                url: this.filter_data.url.replace('{min}', this.filter_data.value[0]).replace('{max}', this.filter_data.value[1]),
            })
        },
    }
}
</script>

<template functional>
    <nuxt-link v-if="props.to"
                 :id="props.id"
                 :to="props.to"
                 :class="[data.staticClass, data.class, {'loading': props.loading, 'disabled': props.disabled || props.loading}]"
                 class="btn"
                 v-tooltip.top="props.tooltip"
                 :disabled="props.disabled || props.loading"
                 :readonly="props.readonly"
    ><slot></slot></nuxt-link>
    <button v-else
            :id="props.id"
            :class="[data.staticClass, data.class, {'loading': props.loading, 'disabled': props.disabled || props.loading}]"
            class="btn"
            v-tooltip.top="props.tooltip"
            :disabled="props.disabled || props.loading"
            :readonly="props.readonly"
            v-on:click="listeners.click && listeners.click()"
    ><slot></slot></button>
</template>

<script>
    export default {
        name: 'Btn',
    }
</script>

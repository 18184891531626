<template>
    <div style="position: relative">
        <div class="container">
            <div class="c-newsletter">
                <div class="c-newsletter__body">
                    <div class="c-newsletter__title">Sign Up to Our <span class="--underlined">Newsletter</span></div>
                    <div class="c-newsletter__subtitle">We will send you best offers and promotions just once a month</div>
                    <FormInput class="c-newsletter__input" placeholder="Your e-mail address" v-model="email" @keyup.enter="subscribe">
                        <template v-slot:append>
                            <Btn :loading="loading" @click="subscribe" class="btn btn-white btn-sm">Subscribe</Btn>
                        </template>
                    </FormInput>
                </div>
                <PatternIcon class="c-newsletter__pattern"/>
                <img class="c-newsletter__img" src="/images/newsletter_full.png"/>
            </div>
        </div>
    </div>
</template>

<script>

import FormInput from "../components/FormInput.vue";
import Btn from "../components/Btn.vue";
import PatternIcon from "~/assets/assets/pattern-blue.svg?inline";
const { required, email } = require('vuelidate/lib/validators');

export default {
    name: 'Newsletter',
    components: {Btn, FormInput, PatternIcon},
    data() {
        return {
            loading: false,
            email: ''
        }
    },
    validations: {
        email: { required, email }
    },
    methods: {
        subscribe() {
            this.$v.email.$touch()
            if (this.$v.email.$invalid == true) {
                return false
            }
            this.loading = true;
            this.$axios.post("/clients/subscribe", {
                email: this.email
            })
                .then((res) => {
                    this.email = '';
                    this.$v.email.$reset()
                })
                .finally((res) => {
                    this.loading = false;
                })
        },
    }
}
</script>

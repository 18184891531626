<template>
    <div style="position: relative; max-width: 100%">
        <PatternGreen class="c-onboarding-card-pattern1"/>
        <PatternGreen2 class="c-onboarding-card-pattern2"/>
        <div class="card c-onboarding-card">
            <div class="card-header">
                <div class="card-icon" v-if="icon"><inline-svg :src="icon"/></div>
                <div class="card-title" v-if="title">{{ title }}</div>
                <div class="card-subtitle" v-if="subtitle">{{ subtitle }}</div>
            </div>
            <div class="card-body">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>

import PatternGreen from "~/assets/assets/pattern-green.svg?inline";
import PatternGreen2 from "~/assets/assets/pattern-green2.svg?inline";

export default {
    name: 'OnboardingCard',
    props: ['icon', 'title', 'subtitle'],
    components: {PatternGreen, PatternGreen2}
}
</script>

<template>
    <div class="chat-item"
         :class="{'--received': !message.me, '--sent': message.me, '--image': message.type === 'image', '--show-time': date_visible || user_visible}"
         v-tooltip.top="($mq == 'xs' || $mq == 'sm') ? null : tooltipDate(message.date)">
        <div class="chat-item-time" v-show="date_visible" >
            {{ message.date | moment("dddd HH:mm") }}
        </div>
        <div class="chat-item-name" v-show="user_visible">
            {{ message.user.id === user.id ? 'You' : message.user.fullname }}
        </div>
        <div class="chat-item-message" :class="{'--dropdown': message.user.id === user.id}">
            <div v-if="message.type === 'txt'" v-html="message.message"></div>
            <div v-else-if="message.type === 'image'" class="chat-item__image-wrapper" @click="$modal.show('image' + message.id)">
                <XImgset loading="eager" :src="message.file" :width="200" :height="200" :contain="true"/>
            </div>
            <div v-else-if="message.type === 'file'" class="chat-item__file-wrapper"><a :href="message.file" target="_blank">{{message.filename}}</a> <i class="fa fa-download"></i></div>
            <span class="o-dropdown" v-if="message.user.id === user.id">
                <ClientOnly>
                    <tippy ref="dropdown" class="dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                        <template #trigger>
                            <EditSimpleIcon height="24"/>
                        </template>
                        <div class="c-dropdown-menu">
                            <div class="c-dropdown-menu__item" v-if="message.type === 'txt'" @click="editMessage">Edit</div>
                            <div class="c-dropdown-menu__item text-danger" @click="deleteMessage">Delete</div>
                        </div>
                    </tippy>
                </ClientOnly>
            </span>
        </div>
        <modal v-if="message.type === 'image'" :name="'image' + message.id" class="chat-modal" height="auto" :click-to-close="false" :scrollable="true" :width="$mq === 'xs' ? '100%' : '80%'"  :pivotY="0.2">
            <div class="modal-content">
                <button class="close" @click.prevent="$modal.hide('image' + message.id)">
                    <CloseCircleIcon/>
                </button>
                <div class="chat-modal-image">
                    <XImgset loading="eager" :src="message.file" :width="1600" :height="1080" :contain="true"/>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import {mapState} from "vuex";
    import XImgset from "@/components/XImgSet.vue";

    export default {
        name: 'ChatMessage',
        components: {XImgset},
        props: {
            'message': {
                default: {}
            },
            'date_visible': {
                default: false
            },
            'user_visible': {
                default: false
            }
        },
        computed: {
            ...mapState({
                user: state => state.user,
            })
        },
        data () {
            return {
                loading: false
            }
        },
        methods: {
            tooltipDate(date) {
                return dayjs(date).format('HH:mm');
            },
            zoomImage() {

            },
            downloadFile() {

            },
            editMessage() {
                this.$emit('onEdit', this.message);
                this.closeDropdown();
            },
            deleteMessage() {
                this.$dialog.confirm('Are you sure you want to delete this message?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.loading = true;
                        this.$forceUpdate();
                        this.$axios.post("/messaging/delete-message", {id: this.message.id})
                            .then(() => {
                                this.loading = false;
                                this.$emit('onDelete', this.message);
                                this.closeDropdown();
                            })
                            .catch(() => {
                                this.loading = false;
                            })
                    });
            },
            closeDropdown() {
                if (this.$refs['dropdown']) {
                    this.$refs['dropdown'].tip.hide()
                }
            },
        }
    }
</script>

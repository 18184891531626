var render = function render(_c,_vm){return _c('form-group',{class:[_vm.data.staticClass],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('div',{staticClass:"custom-radio",staticStyle:{"flex":"1"}},[_c('input',{staticClass:"custom-control-input",class:{'is-invalid' : _vm.props.v ? _vm.props.v.$error : null},attrs:{"id":_vm.props.id ? _vm.props.id : _vm.props.text ? _vm.props.text.replace(/[^\w-]+/g,'') : _vm.props.label.replace(/[^\w-]+/g,''),"type":"radio","name":_vm.props.name,"readonly":_vm.props.readonly,"disabled":_vm.props.disabled},domProps:{"checked":_vm.props.value == _vm.props.fieldValue,"value":_vm.props.fieldValue},on:{"change":($event) => {
                  _vm.listeners.input && _vm.listeners.input($event.target.value);
                  _vm.listeners.change && _vm.listeners.change($event.target.value);
                  if(_vm.props.v){
                       _vm.props.v.$touch();
                  }
              }}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",class:{'--bordered': _vm.props.bordered},attrs:{"for":_vm.props.id ? _vm.props.id : _vm.props.text ? _vm.props.text.replace(/[^\w-]+/g,'') : _vm.props.label.replace(/[^\w-]+/g,'')}},[_vm._v(_vm._s(_vm.props.text)),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
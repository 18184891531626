<template>
    <nuxt-link class="c-project-info-card" :to="{name: 'projects.details', params: {slug: project.slug}}">
        <div class="c-project-info-card__img-wrapper" v-if="project.image">
            <XImgset class="c-project-info-card__img img-fluid" :src="project.image" :width="204" :height="274" :alt="project.title"/>
        </div>
        <div class="c-project-info-card__content-wrapper">
            <div class="c-project-info-card__content">
                <div class="c-project-info-card__content-title">{{ project.title }}</div>
                <div class="c-project-info-card__content-desc">{{ project.description }}</div>
            </div>

            <div class="c-project-info-card__stats" v-if="project.attributes.service_level">Service Level of the Project: <div class="c-project-info-card__stats-value text-secondary">{{ project.attributes.service_level.value }}</div></div>
            <div class="c-project-info-card__stats">
                <span v-if="project.attributes.number_of_revisions">Revisions: <span>{{ project.attributes.number_of_revisions.value }}</span></span>
                <template v-if="project.payment_method === 'hourly'">
                    <span v-if="project.price[0]">Hourly Rate: <span>{{ project.price[0] | toPrice(0) }}-{{ project.price[1] | toPrice(0) }}</span></span>
                    <span v-if="project.hours[0]">Hours required: <span>{{ project.hours[0] }} to {{ project.hours[1] }} h</span></span>
                    <span v-if="project.duration[0]">Duration: <span>{{ project.duration[0] }} to {{ project.duration[1] }} Days</span></span>
                </template>
                <template v-if="project.payment_method === 'fixed'">
                    <span v-if="project.price[0]">Est. Budget: <span>{{ project.price[0] | toPrice(0) }}-{{ project.price[1] | toPrice(0) }}</span></span>
                    <span v-if="project.duration[0]">Delivery Time: <span>{{ project.duration[0] }} to {{ project.duration[1] }} Days</span></span>
                </template>
            </div>
        </div>
    </nuxt-link>
</template>

<script>

import XImgset from "./XImgSet.vue";

export default {
    name: 'ProjectInfoCard',
    components: {XImgset},
    props: ['project'],
    data() {
        return {
            loading: {

            },
        }
    },
    methods: {
        projectClick () {

        },
    },
}
</script>

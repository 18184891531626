<template functional>
    <div class="form-group form-input" v-bind:class="[data.staticClass, data.class]">
        <label v-if="props.label" :for="props.for" style="min-height: 16px; display: block;">
            {{ props.label }}
            <span class="text-muted" v-if="props.labelInfo">{{ props.labelInfo }}</span>
            <i v-if="props.info" style="vertical-align: middle;" class="bx bx-info-circle" v-tooltip="props.info"></i>
        </label>
        <slot></slot>
        <p v-if="props.help"><small class="text-muted" v-html="props.help"></small></p>
        <p v-if="props.error"><small class="text-danger" v-html="props.error"></small></p>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
}
</script>

<template>
    <div class="c-group-card" :class="{'--danger': group.type === 'blocked'}">
        <PatternGreen class="c-group-card__pattern"/>
        <div class="c-group-card__icon-wrapper">
            <div class="c-group-card__icon">
                <img v-if="group.icon" src="" alt="">
                <BlockIcon v-else-if="group.type === 'blocked'" />
                <GroupsIcon v-else />
            </div>
        </div>
        <div class="c-group-card__info">
            <div class="c-group-card__title">{{ group.name }}</div>
            <div class="c-group-card__count">{{ group.items_count }} {{  group.type === 'blocked' ? 'Blocked' : 'Saved' }}</div>
        </div>
        <NuxtLink :disabled="!group.items_count" :to="{name: 'account.group.details', params: {id: group.id}}" class="btn btn-white btn-block" :class="{'blocked': !group.items_count}">
            <span>View List</span>
        </NuxtLink>
    </div>
</template>

<script>

import PatternGreen from "~/assets/assets/pattern-green.svg?inline";

export default {
    name: 'GroupCard',
    components: {PatternGreen},
    props: ['group'],
}
</script>

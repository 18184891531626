var render = function render(_c,_vm){return _c('div',[_vm._l((_vm.props.options),function(option,index){return [(typeof option === 'object')?_c('a',{staticClass:"badge badge-primary-light mb-2 mr-1 badge-lg",class:{'selected active': _vm.props.value == option.value},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (($event) => {
                _vm.listeners.input && _vm.listeners.input(option.value);
                _vm.listeners.change && _vm.listeners.change(option.value);
                if(_vm.props.v){
                   _vm.props.v.$touch();
                }
            }).apply(null, arguments)}}},[_vm._v(_vm._s(option.label))]):_c('a',{staticClass:"badge badge-primary-light mb-2 mr-1 badge-lg",class:{'selected active': _vm.props.value == option},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (($event) => {
                _vm.listeners.input && _vm.listeners.input(option);
                _vm.listeners.change && _vm.listeners.change(option);
                if(_vm.props.v){
                   _vm.props.v.$touch();
                }
            }).apply(null, arguments)}}},[_vm._v(_vm._s(option))])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
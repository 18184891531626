<template>
    <div class="user-avatar" v-if="company">
        <img v-if="company.image" :src="$utils.getImage(company.image, 52, 52, false)"
             :class="{'xs': size === 'xs', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}">
        <img v-else
             id="user_avatar"
             :class="{'xs': size === 'xs', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}"
             src="/images/avatar.png">
        <div v-if="displayName"
             class="user-avatar-info"
             :class="{'xs': size === 'xs', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}"
        >
            <div style="font-weight: 500">{{ company.name }}</div>
            <div v-if="subtitle" v-html="subtitle" class="user-avatar-info__subtitle"></div>
            <div class="user-avatar-info__subtitle" v-if="!!$slots.subtitle">
                <slot name="subtitle"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CompanyAvatar',
    props: ['company', 'subtitle', 'size', 'displayName']
};

</script>

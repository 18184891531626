<template>
    <div>
        <div class="l-page-hero" style="background-image: url('/images/bg.png')">
            <div>
                <div class="l-page-hero__title">Hire a Freelance Biotechnology <span class="--underlined-teal pb-2">Expert</span></div>
                <div class="text-center">
                    <Btn class="btn-xl btn-secondary mb-2" @click="goToExperts"><b>Find Biotech Expert</b></Btn>
                    <Btn class="btn-xl btn-white mb-2" @click="goToJobs"><b>Find Biotech Job</b></Btn>
                </div>
            </div>
            <ScrollIcon class="l-page-hero__scrollicon" height="42"/>
        </div>
        <div class="container" :class="{'loading-big': loading.data}">
            <div class="l-page-content">

                <div class="l-section" v-if="freelancer_groups">
                    <div class="l-section_title">
                        Find Biotech <span class="--underlined">Expert</span> by
                    </div>

                    <section class="c-projects-tabs mt-2">
                        <Tabs nav-class="nav">
                            <Tab v-if="freelancer_groups.color_code_categories" :name="freelancer_groups.color_code_categories.name">
                                <div class="row --item-card-grid">
                                    <div class="col-lg-4 col-sm-6" :key="item.id" v-for="item in freelancer_groups.color_code_categories.items">
                                        <a class="c-attribute-card" @click.prevent="searchAttribute(item.id, item.attribute_id)">
                                            <div class="c-attribute-card__icon" v-if="item.icon">
                                                <XImgset class="img-fluid" :src="item.icon" :width="42" :height="42" :alt="item.name"/>
                                            </div>
                                            <div class="c-attribute-card__title">{{ item.name }}</div>
                                            <div class="c-attribute-card__desc" v-if="item.subtitle">{{ item.subtitle }}</div>
                                            <div class="c-attribute-card__count">{{ item.count }} Biotech Experts</div>
                                        </a>
                                    </div>
                                </div>
                            </Tab>
                            <Tab v-if="freelancer_groups.services" :name="freelancer_groups.services.name">
                                <div class="row --item-card-grid">
                                    <div class="col-lg-3 col-sm-6" :key="item.id" v-for="item in freelancer_groups.services.items">
                                        <a class="c-attribute-card" @click.prevent="searchAttribute(item.id, item.attribute_id)">
                                            <div class="c-attribute-card__icon" v-if="item.icon">
                                                <XImgset class="img-fluid" :src="item.icon" :width="42" :height="42" :alt="item.name"/>
                                            </div>
                                            <div class="c-attribute-card__title --small">{{ item.name }}</div>
                                            <div class="c-attribute-card__desc --small" v-if="item.subtitle">{{ item.subtitle }}</div>
                                            <div class="c-attribute-card__count --small">{{ item.count }} Biotech Experts</div>
                                        </a>
                                    </div>
                                </div>
                            </Tab>
                            <Tab v-if="freelancer_groups.expertise_level" :name="freelancer_groups.expertise_level.name">
                                <div class="row --item-card-grid">
                                    <div class="col-sm-6" v-for="item in freelancer_groups.expertise_level.items">
                                        <a class="c-attribute-card" :key="item.id" @click.prevent="searchAttribute(item.id, item.attribute_id)">
                                            <div class="c-attribute-card__icon p-2" v-if="item.icon">
                                                <XImgset class="img-fluid" :src="item.icon" :width="42" :height="42" :alt="item.name"/>
                                            </div>
                                            <div class="c-attribute-card__title">{{ item.name }}</div>
                                            <div class="c-attribute-card__desc" v-if="item.subtitle">{{ item.subtitle }}</div>
                                            <div class="c-attribute-card__count">{{ item.count }} Biotech Experts</div>
                                        </a>
                                    </div>
                                </div>
                            </Tab>
                            <Tab v-if="freelancer_groups.location" :name="freelancer_groups.location.name">
                                <div class="row --item-card-grid">
                                    <div class="col-sm-6" v-for="item in freelancer_groups.location.items">
                                        <a class="c-attribute-card" :key="item.id" @click.prevent="searchAttribute(item.id, item.attribute_id)">
                                            <div class="c-attribute-card__icon --circle" v-if="item.icon">
                                                <XImgset class="img-fluid" :src="item.icon" :width="42" :height="42" :alt="item.name"/>
                                            </div>
                                            <div class="c-attribute-card__title">{{ item.name }}</div>
                                            <div class="c-attribute-card__desc" v-if="item.subtitle">{{ item.subtitle }}</div>
                                            <div class="c-attribute-card__count">{{ item.count }} Biotech Experts</div>
                                        </a>
                                    </div>
                                </div>
                            </Tab>
                            <Tab name="Expertise Area">
                                <div class="vue-multiselect__input-group">
                                    <AttributeSelect
                                        attribute_code="expertise_areas"
                                        :multiple="true"
                                        v-model="expertise_areas"
                                        placeholder="Enter Expertise Area(s)"/>
                                    <div class="input-group-append">
                                        <Btn class="btn btn-primary btn-sm btn-square" @click="searchExpertiseAreas"><SearchIcon class="mr-0" width="26" height="24"/></Btn>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </section>
                </div>

                <div class="l-section" v-if="companies.length">
                    <div class="l-section_title" style="max-width: 550px">
                        Browse Biotech <span class="--underlined">Companies</span> Directory
                    </div>

                    <div class="row --item-card-grid">
                        <div class="col-lg-4 col-sm-6" :key="client.id" v-for="client in companies" v-if="client.company?.name">
                            <div class="c-company-card">
                                <div class="c-company-card__header">
                                    <div class="c-company-card__image">
                                        <XImgset :src="client.company?.image" :width="32" :height="32"/>
                                    </div>
                                    <div class="c-company-card__title">{{ client.company?.name }}</div>
                                </div>
                                <div class="c-company-card__info" v-if="client.career_page">
                                    <JobIcon width="24" class="mr-2"/> Jobs Available
                                </div>
                                <div class="c-company-card__desc">{{ client.company.description }}</div>
<!--                                <div class="c-company-card__tags">-->
<!--                                    <div class="badge badge-secondary" v-for="tag in company.tags">{{tag}}</div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <Btn class="btn-primary btn-xl" :to="{name: 'companies.list'}"><b>Browse More Biotech Companies</b></Btn>
                    </div>
                </div>

                <div class="l-section">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="l-section_title" style="max-width: 435px">
                                Why should you use <span class="--underlined">BOLG?</span>
                            </div>

                            <div class="c-obj-list-item">
                                <div class="c-obj-list-item__icon"><QualityIcon width="56" height="56"/></div>
                                <div class="c-obj-list-item__body">
                                    <div class="c-obj-list-item__title">Proof of Quality</div>
                                    <div class="c-obj-list-item__desc">
                                        <ul>
                                            <li>Verified biotech experts.</li>
                                            <li>View portfolios and reviews.</li>
                                            <li>Regular quality checks.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="c-obj-list-item">
                                <div class="c-obj-list-item__icon"><CostIcon width="56" height="56"/></div>
                                <div class="c-obj-list-item__body">
                                    <div class="c-obj-list-item__title">No Cost Until You Hire</div>
                                    <div class="c-obj-list-item__desc">
                                        <ul>
                                            <li>Browse profiles and proposals for free.</li>
                                            <li>Pay only upon approval.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="c-obj-list-item">
                                <div class="c-obj-list-item__icon"><SafeIcon width="56" height="56"/></div>
                                <div class="c-obj-list-item__body">
                                    <div class="c-obj-list-item__title">Safe & Secure</div>
                                    <div class="c-obj-list-item__desc">
                                        <ul>
                                            <li>Funds held in escrow.</li>
                                            <li>Strong data protection.</li>
                                            <li>Fair dispute resolution.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src="/images/why_bolg.png" alt="Why should you use BOLG?" class="img-fluid">
                        </div>
                    </div>
                </div>

                <div class="l-section" v-if="articles.length" style="max-width: 780px">
                    <div class="l-section_title">
                        Read More About Biotechnology Freelance Work <span class="--underlined">on Our Blog</span>
                    </div>

                    <div class="row --item-card-grid">
                        <div class="col-lg-4 col-sm-6" :key="article.id" v-for="article in articles">
                            <ArticleCard :post="article"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { metaMixin } from '~/mixins/metaMixin'

import ScrollIcon from "~/assets/icons/scroll.svg?inline";

import CostIcon from "~/assets/icons/home/cost_icon.svg?inline";
import QualityIcon from "~/assets/icons/home/quality_icon.svg?inline";
import SafeIcon from "~/assets/icons/home/safe_icon.svg?inline";
import SearchIcon from "~/assets/icons/search_white.svg?inline";

import JobIcon from "~/assets/icons/job.svg?inline";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import ArticleCard from "../components/ArticleCard.vue";
import FormInput from "../components/FormInput.vue";
import XImgset from "../components/XImgSet.vue";
import AttributeSelect from "../components/AttributeSelect.vue";
import Btn from "../components/Btn.vue";
import {mapState} from "vuex";

export default {
    name: 'HomeView',
    components: {Btn, AttributeSelect, XImgset, FormInput, ArticleCard, Tab, Tabs, ScrollIcon, CostIcon, QualityIcon, SafeIcon, JobIcon, SearchIcon},
    mixins: [metaMixin],
    data () {
        return {
            loading: {
                data: false,
            },
            freelancer_groups: null,
            companies: [],
            articles: [],
            expertise_areas: [],
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    async fetch () {
        return this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    activated () {
        // this.sendToAnalytics()
    },
    methods: {
        sendToAnalytics () {
            if (!this.$analytics) {
                return
            }
            let projects = []
            this.promos.forEach(promo => {
                projects = projects.concat(promo.projects)
            })
            this.$analytics.sendImpressions(projects, 'Homepage')
            this.$analytics.sendPromotions(this.herobanners.map((banner, index) => {
                return {
                    name: banner.promotitle,
                    url: banner.url,
                    position: index
                }
            }))
        },
        getData () {
            this.loading.data = true;
            return this.$axios.get('/cms/homepage')
                .then((res) => {
                    this.freelancer_groups = res.freelancer_groups;
                    this.companies = res.companies;
                    this.articles = res.articles;
                    this.loading.data = false;
                    this.meta = res.seo
                    // this.sendToAnalytics()
                })
                .catch((error) => {
                    console.log(error);
                    this.loading.data = false;
                });
        },
        searchExpertiseAreas() {
            this.$router.push({name: 'freelancers.list', query: {
                filters: JSON.stringify({
                    7: this.expertise_areas.map(i => i.id)
                })
            }})
        },
        searchAttribute(value_id, attr_id) {
            let filters = {
                attributes: {}
            };
            filters['attributes'][attr_id] = [value_id]
            this.$router.push({name: 'freelancers.list', query: {
                filters: JSON.stringify(filters)
            }})
        },
        goToExperts() {
            if(this.user.type !== 'client') {
                this.$dialog.confirm('Are you a client looking for biotechnology expert?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.$toast.error('You need to login using a Client account to communicate with experts.');
                        this.$router.push({name: 'auth.login'});
                    }).catch(() => {
                    this.$router.push({name: 'freelancers.list'})
                })
                return;
            }
            this.$router.push({name: 'freelancers.list'})
        },
        goToJobs() {
            if(this.user.type !== 'freelancer') {
                this.$dialog.confirm('Are you a biotechnology freelancer looking for job?', {
                    okText: 'Yes',
                    cancelText: 'No'
                })
                    .then(() => {
                        this.$toast.error('You need to login using an approved Expert account to access the jobs list.');
                        this.$router.push({name: 'auth.login'});
                    }).catch(() => {

                })
            }else if (this.user.status !== 1) {
                this.$toast.error('Your account needs to be approved by BOLG admins which might take up to 48 hours to be able to access the jobs list.');
            }else {
                this.$router.push({name: 'jobs.list'})
            }
        },
    }
}
</script>

<template>
    <multiselect
        v-bind:class="{'error' : v ? v.$error : null}"
        v-model="selected_value"
        :options="options"
        :searchable="true"
        :placeholder="placeholder ? placeholder : 'Search'"
        selectLabel=""
        deselectLabel=""
        selectedLabel=""
        label="name"
        trackBy="id"
        :loading="loading"
        :multiple="multiple"
        @search-change="getData"
        :closeOnSelect="!multiple && !searchOnly"
    >
        <template slot="noResult"> {{ 'not found' }}</template>
        <template slot="noOptions"> {{ placeholder ? placeholder : 'Search' }}</template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
    name: 'AttributeSelect',
    props: ['value', 'placeholder', 'v', 'multiple', 'attribute_code', 'searchOnly', 'closeOnSelect'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: null,
            options: []
        }
    },
    methods: {
        getData: debounce(function (query) {
            if(query !== '') {
                this.loading = true;
                this.$axios.post("/get-attribute-options", { query: query, attribute_code: this.attribute_code })
                    .then((res) =>{
                        this.options = res;
                        this.loading = false;
                    });
            }
        }, 500),
    },
    created() {
        this.selected_value = this.multiple ? [] : null;
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
        this.getData();
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (this.searchOnly) {
                if (option) {
                    console.log({option})
                    this.$emit('select', JSON.parse(JSON.stringify(option)));
                }
                this.selected_value = this.multiple ? [] : null;
                return;
            }
            if (!option) {
                this.$emit('input', null);
                this.$emit('setId', null);
            }else {
                this.$emit('input', option);
                this.$emit('setId', option ? option.id : null);
            }
        }
    },
};
</script>


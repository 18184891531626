<template>
    <div>
        <Chat v-if="active"/>
    </div>
</template>

<script>

import {mapState} from "vuex";
import Chat from "@/components/Messenger/Chat.vue";

export default {
    name: 'Messaging',
    components: {Chat},
    data () {
        return {
            active: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted () {
        this.active = true;
    },
    beforeDestroy () {
        this.active = false;
    },
    activated () {
        this.active = true;
    },
    deactivated () {
        this.active = false;
    },
    methods: {

    }
}
</script>

<template>
    <div>
        <Tabs wrapperClass="account_tabs">
            <Tab name="For an Interview">
                <InvitationsIntrerview/>
            </Tab>
            <Tab name="For a project">
                <InvitationsProject/>
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import Tab from "@/components/Tab.vue";
import Tabs from "@/components/Tabs.vue";
import InvitationsIntrerview from "@/pages/Account/InvitationsIntrerview.vue";
import InvitationsProject from "@/pages/Account/InvitationsProject.vue";

export default {
    name: "Invitations",
    components: {InvitationsProject, InvitationsIntrerview, Tabs, Tab},
}
</script>

import Vue from 'vue';

import ArrowBackIcon from "~/assets/icons/arrow-back.svg?inline";
import OpenIcon from "~/assets/icons/open.svg?inline";
import UserIcon from "~/assets/icons/user.svg?inline";
import CaretIcon from "~/assets/icons/caret.svg?inline";
import LogOutIcon from "~/assets/icons/logout.svg?inline";
import MessagesIcon from "~/assets/icons/account/message-3.svg?inline";
import GalleryIcon from "~/assets/icons/account/gallery.svg?inline";
import AttachmentIcon from "~/assets/icons/account/attachment.svg?inline";
import ActiveIcon from "~/assets/icons/account/active.svg?inline";
import LocationIcon from "~/assets/icons/location.svg?inline";
import StarIcon from "~/assets/icons/star.svg?inline";
import CheckCircle2Icon from "~/assets/icons/check-circle2.svg?inline";
import TeamIcon from "~/assets/icons/team.svg?inline";
import DolarCircleIcon from "~/assets/icons/dolar_circle.svg?inline";
import PlayIcon from "~/assets/icons/play.svg?inline";
import CloseIcon from "~/assets/icons/close-square.svg?inline";
import ApplicationImage from "~/assets/assets/application.svg?inline";
import ApplicationDoneImage from "~/assets/assets/application_done.svg?inline";
import ClockIcon from "~/assets/icons/clock.svg?inline";
import BlockIcon from "../assets/icons/block.svg?inline";
import PreviousIcon from "~/assets/icons/previous.svg?inline";
import NextIcon from "~/assets/icons/next.svg?inline";
import ImageIcon from "~/assets/icons/image.svg?inline";
import PdfFileIcon from "~/assets/icons/files/pdf.svg?inline";
import ImageFileIcon from "~/assets/icons/files/image.svg?inline";
import ChevronDownIcon from "~/assets/icons/chevron-down.svg?inline";
import MinusIcon from "~/assets/icons/minus-circle.svg?inline";
import PlusIcon from "~/assets/icons/plus-circle.svg?inline";
import PlusWhiteIcon from "~/assets/icons/plus.svg?inline";
import SearchIcon from "~/assets/icons/search.svg?inline";
import GroupsIcon from "~/assets/icons/account/groups.svg?inline";
import EditDotsIcon from "~/assets/icons/edit_dots.svg?inline";
import NotificationsIcon from "~/assets/icons/account/notifications.svg?inline";
import ChevronLeftIcon from "~/assets/icons/chevron-left.svg?inline";
import AttachIcon from "~/assets/icons/attach.svg?inline";
import CloseCircleIcon from "~/assets/icons/close-circle.svg?inline";
import PrivacyPublicIcon from "~/assets/icons/privacy_public.svg?inline";
import PrivacyRegisteredIcon from "~/assets/icons/privacy_registered.svg?inline";
import PrivacyPrivateIcon from "~/assets/icons/privacy_private.svg?inline";
import CheckIcon from "~/assets/icons/check.svg?inline";
import FacebookIcon from "~/assets/icons/facebook.svg?inline";
import InstagramIcon from "~/assets/icons/instagram.svg?inline";
import YoutubeIcon from "~/assets/icons/youtube.svg?inline";
import LinkedinIcon from "~/assets/icons/linkedin.svg?inline";
import TwitterIcon from "~/assets/icons/twitter_x.svg?inline";
import PinterestIcon from "~/assets/icons/pinterest.svg?inline";
import QuoraIcon from "~/assets/icons/quora.svg?inline";
import TikTokIcon from "~/assets/icons/tiktok.svg?inline";
import MediumIcon from "~/assets/icons/medium.svg?inline";
import RedditIcon from "~/assets/icons/reddit.svg?inline";
import MenuIcon from "~/assets/icons/menu.svg?inline";
import EditIcon from "~/assets/icons/account/edit.svg?inline";
import EditSimpleIcon from "~/assets/icons/edit-simple.svg?inline";
import EmploymentIcon from "~/assets/icons/employment.svg?inline";
import EducationIcon from "~/assets/icons/education.svg?inline";
import TrainingIcon from "~/assets/icons/training.svg?inline";
import PublicationIcon from "~/assets/icons/publication.svg?inline";
import DownloadIcon from "~/assets/icons/download.svg?inline";
import DownloadGreenIcon from "~/assets/icons/download-green.svg?inline";
import AcceptedIcon from "~/assets/icons/account/accepted-icon.svg?inline";
import PendingIcon from "~/assets/icons/account/pending-icon.svg?inline";
import DeclinedIcon from "~/assets/icons/account/declined-icon.svg?inline";
import CheckCircleIcon from "~/assets/icons/check-circle.svg?inline";
import Duration30 from "~/assets/icons/duration30.svg?inline";
import Duration60 from "~/assets/icons/duration60.svg?inline";
import Filters2 from "~/assets/icons/filters2.svg?inline";
import TrashIcon from "~/assets/icons/trash.svg?inline";
import EyeIcon from "~/assets/icons/eye.svg?inline";
import EyeSlashIcon from "~/assets/icons/eyeSlash.svg?inline";


export default ({app}) => {
    Vue.component('EyeIcon', EyeIcon);
    Vue.component('EyeSlashIcon', EyeSlashIcon);
    Vue.component('CheckCircleIcon', CheckCircleIcon);
    Vue.component('DeclinedIcon', DeclinedIcon);
    Vue.component('PendingIcon', PendingIcon);
    Vue.component('AcceptedIcon', AcceptedIcon);
    Vue.component('DownloadIcon', DownloadIcon);
    Vue.component('DownloadGreenIcon', DownloadGreenIcon);
    Vue.component('PublicationIcon', PublicationIcon);
    Vue.component('TrainingIcon', TrainingIcon);
    Vue.component('EducationIcon', EducationIcon);
    Vue.component('EmploymentIcon', EmploymentIcon);
    Vue.component('EditIcon', EditIcon);
    Vue.component('EditSimpleIcon', EditSimpleIcon);
    Vue.component('MenuIcon', MenuIcon);
    Vue.component('FacebookIcon', FacebookIcon);
    Vue.component('InstagramIcon', InstagramIcon);
    Vue.component('YoutubeIcon', YoutubeIcon);
    Vue.component('PinterestIcon', PinterestIcon);
    Vue.component('MediumIcon', MediumIcon);
    Vue.component('QuoraIcon', QuoraIcon);
    Vue.component('TikTokIcon', TikTokIcon);
    Vue.component('RedditIcon', RedditIcon);
    Vue.component('CheckIcon', CheckIcon);
    Vue.component('PrivacyPublicIcon', PrivacyPublicIcon);
    Vue.component('PrivacyRegisteredIcon', PrivacyRegisteredIcon);
    Vue.component('PrivacyPrivateIcon', PrivacyPrivateIcon);
    Vue.component('ArrowBackIcon', ArrowBackIcon);
    Vue.component('OpenIcon', OpenIcon);
    Vue.component('UserIcon', UserIcon);
    Vue.component('CaretIcon', CaretIcon);
    Vue.component('LogOutIcon', LogOutIcon);
    Vue.component('MessagesIcon', MessagesIcon);
    Vue.component('GalleryIcon', GalleryIcon);
    Vue.component('AttachmentIcon', AttachmentIcon);
    Vue.component('ActiveIcon', ActiveIcon);
    Vue.component('LocationIcon', LocationIcon);
    Vue.component('StarIcon', StarIcon);
    Vue.component('CheckCircle2Icon', CheckCircle2Icon);
    Vue.component('TeamIcon', TeamIcon);
    Vue.component('DolarCircleIcon', DolarCircleIcon);
    Vue.component('PlayIcon', PlayIcon);
    Vue.component('CloseIcon', CloseIcon);
    Vue.component('ApplicationImage', ApplicationImage);
    Vue.component('ApplicationDoneImage', ApplicationDoneImage);
    Vue.component('ClockIcon', ClockIcon);
    Vue.component('BlockIcon', BlockIcon);
    Vue.component('PreviousIcon', PreviousIcon);
    Vue.component('NextIcon', NextIcon);
    Vue.component('ImageIcon', ImageIcon);
    Vue.component('PdfFileIcon', PdfFileIcon);
    Vue.component('ImageFileIcon', ImageFileIcon);
    Vue.component('ChevronDownIcon', ChevronDownIcon);
    Vue.component('ChevronLeftIcon', ChevronLeftIcon);
    Vue.component('MinusIcon', MinusIcon);
    Vue.component('PlusIcon', PlusIcon);
    Vue.component('PlusWhiteIcon', PlusWhiteIcon);
    Vue.component('SearchIcon', SearchIcon);
    Vue.component('GroupsIcon', GroupsIcon);
    Vue.component('EditDotsIcon', EditDotsIcon);
    Vue.component('NotificationsIcon', NotificationsIcon);
    Vue.component('AttachIcon', AttachIcon);
    Vue.component('CloseCircleIcon', CloseCircleIcon);
    Vue.component('LinkedinIcon', LinkedinIcon);
    Vue.component('TwitterIcon', TwitterIcon);
    Vue.component('Duration30', Duration30);
    Vue.component('Duration60', Duration60);
    Vue.component('Filters2', Filters2);
    Vue.component('TrashIcon', TrashIcon);

};

<template>
    <div>
<!--        <div @click="expanded = !expanded" class="c-footer__list-title expand-next expand-mobile" :class="{'in': expanded}">-->
<!--            {{ title }}-->
<!--        </div>-->
        <div v-show-slide="expanded || ($mq === 'md' || $mq === 'lg' || $mq === 'xl')" class="c-footer__list-link">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FooterMenu',
    props: ['title'],
    data () {
        return {
            expanded: true
        }
    },
}
</script>

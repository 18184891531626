<template>
    <div>
        <div class="l-account-content__header" v-if="$auth.can('edit_members')">
            <div class="l-account-content__title">Members</div>
            <Btn class="btn btn-secondary mt-3" @click="addMember"><PlusWhiteIcon/> Add a member</Btn>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :centered="true"
                    :data="data.data"
                    :columns="['member', 'invite_date', 'role', 'status']">
                    <template #member="{row}">
                        <template v-if="row.user.id !== user.id">
                            <UserAvatar size="medium" :user="row.user" :displayName="true"></UserAvatar>
                        </template>
                        <template v-else>
                            <UserAvatar v-if="row.member" size="medium" :user="row.member" :displayName="true"></UserAvatar>
                            <div v-else-if="row.email">{{ row.email }}</div>
                        </template>

                    </template>
                    <template #invite_date="{row}">
                        {{ row.invite_date | moment('DD.MM.YYYY') }}
                    </template>
                    <template #role="{row}">
                        {{ row.role_label }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
                        <template v-if="$auth.can('edit_members')">
                            <template v-if="row.can_edit">
                                <EditIcon class="edit-btn" @click="editMember(row)"/>
                            </template>
                            <template v-if="row.status === '0' && row.user.id !== user.id">
                                <Btn class="btn-outline-light" @click="acceptMember(row)">Accept</Btn>
                            </template>
                            <Btn class="btn-outline-light" @click="removeMember(row)">Remove</Btn>
                        </template>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && data.data && !data.data.length && !loading.data">
                <h4>No members added</h4>
            </div>
            <div class="d-flex mt-2" v-if="data && data.data && data.data.total > 1">
                <pagination :limit="10" :data="data.data" v-on:pagination-change-page="getPage"></pagination>
            </div>
        </div>
        <modal name="addMemberModal" height="auto" :scrollable="true" style="border-radius: 10px">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button class="close" @click="$modal.hide('addMemberModal')">
                        <CloseIcon class="close"/>
                    </button>
                    <div class="modal-header">
                        <h4 class="modal-title">Add a member</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2" label="Email" type="email" v-model="member.email"/>
                        <FormSelect label="Role" :options="roleOptions" :v-model="member.role"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveMember()">Send invitation</Btn>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="editMemberModal" height="auto" :scrollable="true" style="border-radius: 10px">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button class="close" @click="$modal.hide('editMemberModal')">
                        <CloseIcon class="close"/>
                    </button>
                    <div class="modal-header">
                        <h4 class="modal-title">Edit member</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2" label="Email" type="email" v-model="member.email"/>
                        <FormSelect :options="roleOptions" v-model="member.role"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveMember(true)">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import UserAvatar from "~/components/UserAvatar.vue";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import VueTable from "@/components/VueTable.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import {mapState} from "vuex";

export default {
    name: "Members",
    mixins: [modelListMixin],
    components: {
        VueTable,
        FormSelect, FormInput, Btn, UserAvatar, CloseIcon},
    head: {
        title: 'Members',
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            member: {
                id: null,
                email: '',
                role: 'editor_admin'
            },
            roleOptions: [
                {label: 'Super Admin', value: 'super_admin'},
                {label: 'Editor Admin', value: 'editor_admin'}
            ]
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/members/get")
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        addMember() {
            this.member = {
                id: null,
                email: '',
                role: 'editor_admin'
            };
            this.$modal.show('addMemberModal')
        },
        editMember(member) {
            this.member = JSON.parse(JSON.stringify(member));
            this.$modal.show('editMemberModal')
        },
        saveMember(edit) {
            this.loading.save = true;
            this.member.edited = edit;
            this.$axios.post("/account/members/save", this.member)
                .then((res) =>{
                    this.getData();
                    this.loading.save = false;
                    this.$modal.hide('addMemberModal')
                    this.$modal.hide('editMemberModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        removeMember(member) {
            this.$dialog.confirm('Are you sure you want to delete this member?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    member.loading = true;
                    this.$axios.post("/account/members/delete", {
                        id: member.id
                    })
                        .then((res) =>{
                            this.getData();
                            member.loading = false;
                        })
                        .catch((error) => {
                            member.loading = false;
                        });
                    });
        },
        acceptMember(member) {
            this.$dialog.confirm('Are you sure you want to accept this invitation?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    member.loading = true;
                    this.$axios.post("/account/members/accept", {
                        id: member.id
                    })
                        .then((res) =>{
                            this.getData();
                            member.loading = false;
                        })
                        .catch((error) => {
                            member.loading = false;
                        });
                    });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<template>
    <div class="auth-company-card">
        <div class="auth-company-card__header">
            <UserAvatar/>
        </div>
        <div class="auth-company-card__info">
            <div><b>Unilever</b></div>
            <div>
                <div class="auth-company-card__info-icon">
                    <DolarCircleIcon height="20"/>
                </div>
                <div class="auth-company-card__info-title">100k-120k</div>
            </div>
            <div>
                <div class="auth-company-card__info-icon">
                    <TeamIcon height="20"/>
                </div>
                <div class="auth-company-card__info-title">100k-120k</div>
            </div>
        </div>
        <div class="auth-company-card__title"></div>
        <div class="auth-company-card__description">
            Lorem ipsum dolor sit amet, scing elit.
            Condimentum quam morbi fermentum...
        </div>
        <div class="auth-company-card__tags" >
            <span class="badge badge-sm badge-secondary-light">Part-Time</span>
            <span class="badge badge-sm badge-secondary-light">Expert</span>
        </div>
    </div>
</template>

<script>

import UserAvatar from "../../components/UserAvatar.vue";


export default {
    name: 'AuthCompanyJobCard',
    props: ['job'],
    components: {UserAvatar},
}
</script>

<template>
    <div class="o-dropdown-select">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown o-dropdown-select-dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                <template #trigger>
                    <span class="o-dropdown-select-value">{{ value.label }}</span> <CaretIcon class="o-dropdown-select-caret" height="20"/>
                </template>
                <div class="c-dropdown-menu">
                    <div class="c-dropdown-menu__item" v-for="value in values" @click="setSorting(value)">{{ value.label }}</div>
                </div>
            </tippy>
        </ClientOnly>
    </div>

</template>

<script>

import { mapState } from 'vuex'
export default {
    name: 'SortingDropdown',
    computed: {
        ...mapState({
            value: state => state.itemsSort,
            values: state => state.itemsSortOptions,
        })
    },
    methods: {
        setSorting(value){
            this.$refs['dropdown'].tip.hide()
            localStorage.setItem('sort', JSON.stringify(value))
            this.$store.commit('setSort', value)
        }
    }
}
</script>

<template>
    <div class="user-avatar" v-if="user">
        <img v-if="user.company?.image" :src="$utils.getImage(user.company.image, 52, 52, false)"
             :class="{'xs': size === 'xs', 'xsmall': size === 'xsmall', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}">
        <img v-else-if="!user.company && user.image" :src="$utils.getImage(user.image, 52, 52, false)"
             :class="{'xs': size === 'xs', 'xsmall': size === 'xsmall', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}">
        <img v-else
             id="user_avatar"
             :class="{'xs': size === 'xs', 'xsmall': size === 'xsmall', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}"
             src="/images/avatar.png">
        <div v-if="displayName"
             class="user-avatar-info"
             :class="{'xs': size === 'xs', 'xsmall': size === 'xsmall', 'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}"
        >
            <div style="font-weight: 500;">{{ getClientName(user) }} <span class="badge badge-xs badge-secondary-light">{{ badge }}</span></div>
            <div v-if="subtitle" v-html="subtitle" class="user-avatar-info__subtitle"></div>
            <div v-if="displayEmail" class="user-avatar-info__subtitle mt-1">{{ user.email }}</div>
            <div class="user-avatar-info__subtitle" v-if="!!$slots.subtitle">
                <slot name="subtitle"></slot>
            </div>
        </div>
    </div>
</template>

<script>

import {getClientName} from "../utils/helpers";

export default {
    name: 'UserAvatar',
    methods: {getClientName},
    props: ['user', 'subtitle', 'size', 'displayName', 'displayEmail', 'badge']
};

</script>

<template>
    <div class="container">
        <div class="l-page-content mb-4">
            <div class="row">
                <div class="col-md-7">
                    <div class="l-page-title">
                        <span class="--underlined">Frequent</span> Asked Questions
                    </div>
                </div>
                <div class="col-md-5">
                    <img class="img-fluid" src="/images/faq.svg">
                </div>
            </div>
        </div>

        <div class="mb-5" :class="{'loading': loading.data}">
            <template v-if="posts">
                <FAQitem v-for="post in posts.data" :key="post.id" :item="post"/>
            </template>
        </div>

        <Newsletter/>
    </div>
</template>

<script>

import { metaMixin } from '~/mixins/metaMixin'
import Bugsnag from "@bugsnag/js";
import Newsletter from "../partials/Newsletter.vue";
import FAQitem from "../components/FAQitem.vue";

export default {
    name: 'FAQ',
    components: {FAQitem, Newsletter},
    props: ['data'],
    mixins: [metaMixin],
    data() {
        return {
            loading: {
                data: false,
            },
            posts: null,
            filters: {
                full_content: true,
                content_type: 'faq',
                sort: 'id',
                dir: 'asc',
                limit: 1500,
                page: 1
            }
        }
    },
    async fetch () {
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        getData () {
            this.loading.data = true;
            return this.$axios.get('/cms/posts', { params: this.filters })
                .then((res) => {
                    this.posts = res.posts
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        getPage(pageNo) {
            this.filters.page = pageNo;
            this.getData()
        },
    }
}
</script>

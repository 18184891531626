<template>
    <div class="container">
        <div class="l-page-content" :class="{'loading-big': loading.data}">
            <h1 class="l-page-title --small">{{ page.name }}</h1>
            <div class="l-article__text clearfix" v-html="page.content"></div>
        </div>
    </div>
</template>

<script>

import { metaMixin } from '~/mixins/metaMixin'
import Bugsnag from "@bugsnag/js";

export default {
    name: 'InfoPage',
    props: ['data'],
    mixins: [metaMixin],
    data() {
        return {
            loading: {
                data: false,
            },
            page: {
                id: null,
                title: '',
                content: '',
            },
        }
    },
    watch: {
        '$route'() {
            this.getData()
        }
    },
    async fetch () {
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        async getData () {
            this.loading.data = true;
            await this.$axios.get(`/cms/pages/${this.$route.params.slug}`)
                .then((res) => {
                    this.page = res.page
                    if (res.page.seo) {
                        this.meta = res.page.seo;
                    }
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        getPage(pageNo) {
            this.filters.page = pageNo;
            this.getData()
        },
    }
}
</script>

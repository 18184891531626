import {mapState} from "vuex";
import debounce from 'lodash/debounce'
import {isEqual} from "lodash";

export const searchMixin = {
    data() {
        return {
            loading: {
                data: false,
                filters: false
            },
            data: null,
            filters: {
                filters: {},
                sort: 'new__desc',
                limit: 18,
                term: '',
            },
            available_filters: [],
            getDataRequest: null,
        };
    },
    computed: {
        ...mapState(['itemsSort'])
    },
    watch: {
        itemsSort (value) {
            this.setSort(value)
            this.setFiltersParams()
        },
        'filters.filters' () {
            this.resetPage()
            this.setFiltersParams()
        },
        'filters.page' () {
            this.setFiltersParams()
        },
        'filters.sortorder' () {
            this.setFiltersParams()
        },
    },
    methods: {
        setFiltersParams: debounce(function () {
            let query = {};
            if (this.filters.filters) {
                query.filters = JSON.stringify(this.filters.filters)
            }
            if (this.itemsSort.value !== 0) {
                query.sort = String(this.itemsSort.value)
            }
            if (this.filters.page > 1) {
                query.page = String(this.filters.page)
            }
            if(!isEqual(this.$route.query, query)) {
                this.$router.replace({ path: this.$route.path, query })
            }
        }, 300, {
            'leading': false,
            'trailing': true
        }),
        setFilterValue (payload) {
            if (!this.filters.filters[payload.type]) {
                this.filters.filters[payload.type] = {};
            }

            if (payload.multiple_values) {
                if (!this.filters.filters[payload.type][payload.id]) {
                    this.filters.filters[payload.type][payload.id] = [];
                }
                let index = this.filters.filters[payload.type][payload.id].indexOf(payload.value);
                if(index > -1) {
                    this.filters.filters[payload.type][payload.id].splice(index, 1)
                } else {
                    this.filters.filters[payload.type][payload.id].push(payload.value)
                }
                if (this.filters.filters[payload.type][payload.id].length === 0) {
                    delete this.filters.filters[payload.type][payload.id];
                }
            }else{
                if (payload.id) {
                    if (this.filters.filters[payload.type][payload.id] === payload.value) {
                        delete this.filters.filters[payload.type][payload.id];
                    } else {
                        this.filters.filters[payload.type][payload.id] = payload.value;
                    }
                } else {
                    if (this.filters.filters[payload.type] === payload.value) {
                        delete this.filters.filters[payload.type];
                    } else {
                        this.filters.filters[payload.type] = payload.value;
                    }
                }
            }
            this.debounceGetData();
            // this.getFilters()
        },
        debounceGetData: debounce(function () {
            this.getData()
        }, 1500),
        clearFilterValue (payload) {
            if (this.filters.filters[payload.type][payload.id]) {
                this.filters.filters[payload.type][payload.id] = [];
            }
            this.debounceGetData();

            // this.getFilters()
        },
        getPage(page) {
            this.filters.page = page;
            this.getData();
        },
        search () {
            this.loading.search = true
            this.filters.page = 1;
            this.getData();
        },
        setSort (payload) {
            this.filters.sort = payload ? payload.value : null;
            this.getData()
        },
        resetPage() {
            this.filters.page = 1
        },
    }
}


<template>
    <div class="header__actions-item --with-separator" :class="{'active': count > 0, 'loading-icon': loading.data}">
        <client-only>
            <tippy ref="notificationsDropdown"
                   class="dropdown"
                   trigger="click"
                   placement="bottom-end"
                   theme="light"
                   :interactive="true"
                   :arrow="true"
                   @onShow="getData"
            >
                <template #trigger>
                    <NotificationsIcon/>
                </template>
                <div class="c-dropdown-menu" v-if="data && data.length">
                    <a class="c-dropdown-menu__item" v-for="notification in data">
                        <div>
                            {{ notification.text }}
                        </div>
                        <div>
                            {{ notification.date | momentAgo }}
                        </div>
                    </a>
                </div>
                <div class="c-dropdown-menu" v-else-if="data">
                    <div class="text-center">No notifications</div>
                </div>
            </tippy>

        </client-only>
    </div>
</template>

<script>

import {searchMixin} from "@/mixins/searchMixin";

export default {
    name: 'NotificationsDropdown',
    mixins: [searchMixin],
    data() {
        return {
            loading: {
                data: false,
            },
            count: 0,
            data: null
        }
    },
    watch: {
        '$route': function () {
            if (this.$refs['notificationsDropdown']) {
                this.$refs['notificationsDropdown'].tip.hide()
            }
        },
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            return this.$axios.get('/account/notifications')
                .then((res) => {
                    this.data = res.data.data
                    this.count = res.data.total
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
    },
}
</script>

<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass]">
        <div class="custom-radio" style="flex: 1">
            <input :id="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
                   :class="{'is-invalid' : props.v ? props.v.$error : null}"
                   class="custom-control-input"
                   type="radio"
                   :name="props.name"
                   :checked="props.value == props.fieldValue"
                   :value="props.fieldValue"
                   :readonly="props.readonly"
                   :disabled="props.disabled"
                   v-on:change="($event) => {
                      listeners.input && listeners.input($event.target.value);
                      listeners.change && listeners.change($event.target.value);
                      if(props.v){
                           props.v.$touch();
                      }
                  }"
            >
            <label :for="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
                   class="custom-control-label"
                   :class="{'--bordered': props.bordered}"
            >{{ props.text }}<slot/></label>
        </div>
    </form-group>
</template>

<script>
    export default {
        name: 'FormRadio',
    }
</script>

<template>
    <div v-if="edit_object">
        <FormInput class="mb-2" label="Certificate title" placehoder="Certificate title" v-model="edit_object.title"/>
        <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
        <FormInput class="mb-2" label="Issued By" v-model="edit_object.issued_by"/>
        <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" :maxDate="new Date()" label="From" :time="false" v-model="edit_object.period.from"/>
        <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" :minDate="edit_object.period.from" :maxDate="new Date()" label="To" :time="false" v-model="edit_object.period.to" v-if="!edit_object.period.present"/>
        <FormGroup label="Upload Training Certificate if Applicable">
            <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="certificates" target="Modules\Clients\Entities\ClientProfile" :target_id="profile_id"/>
        </FormGroup>
        <Btn class="btn-block btn-secondary-light" :loading="loading_save" @click="saveObject">Save</Btn>
    </div>
</template>

<script>

import Btn from "~/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import DocumentsUploader from "@/components/DocumentsUploader.vue";
import FormDate from "@/components/FormDate.vue";

export default {
    name: 'CertificatesForm',
    components: {
        FormDate,
        DocumentsUploader,
        FormTextarea,
        FormInput,
        Btn,
    },
    props: ['value', 'profile_id', 'loading_save'],
    data() {
        return {
            edit_object: null,
        }
    },
    mounted () {
        this.edit_object = this.value
    },
    watch: {
        // edit_object(value) {
        //     this.$emit('input', value)
        // },
        value(value) {
            this.edit_object = value
        }
    },
    methods: {
        saveObject() {
            this.$emit('onSave', this.edit_object)
        },
    }
}
</script>

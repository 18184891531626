<template>
    <div v-bind:class="{'error' : v ? v.$error : null}">
        <multiselect
            v-model="selected_value"
            :options="options"
            :searchable="true"
            :placeholder="placeholder ? placeholder : 'Search country'"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            label="name"
            trackBy="id"
            :loading="loading"
            @search-change="getData"
        >
            <template v-slot:option="props">
                <img class="multiselect__image" :src="props.option.img">
                <span>{{ props.option.name }}</span>
            </template>
            <template v-slot:singleLabel="props">
                <img class="multiselect__image" v-if="props.option" :src="props.option.img">
                <span>{{ props.option?.name }}</span>
            </template>
            <template slot="noResult"> Not found</template>
            <template slot="noOptions"> {{ placeholder ? placeholder : 'Search country' }}</template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
    name: 'CountrySelect',
    props: ['value', 'placeholder', 'v'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: null,
            options: []
        }
    },
    methods: {
        getData: debounce(function (query) {
            if(query !== '') {
                this.loading = true;
                this.$axios.post("/get-countries", { query: query })
                    .then((res) =>{
                        this.options = res;
                        this.loading = false;
                    });
            }
        }, 500),
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
        this.getData();
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (!option) {
                this.$emit('input', null);
                this.$emit('setId', null);
            }else {
                this.$emit('input', option);
                this.$emit('setId', option ? option.id : null);
            }
        }
    },
};
</script>


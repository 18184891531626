import Bugsnag from "@bugsnag/js";


const default_plan = {
    limit_messages: false,
    limit_invitations: false,
    limit_job_applications: false,
    limit_projects: false,
    bid_rate: false,
    background: "white",
    display_logo: false,
    limit_addresses: 1,
    limit_description: false,
    logo_link: false,
    promote: false,
}
export const state = () => ({
    user: {
        id: null,
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone_prefix: '',
        phone: '',
        privacy: '',
        type: '',
        online_at: '',
        image: null,
        address: null,
        company: null,
        isAgency: false,
        profiles: [],
        confirmed: false,
    },
    plan_settings: default_plan,
    profiles: [],
    meta: {
        title: null,
        description: '',
        breadcrumbs: []
    },
    loaded: {
        user: false
    },
    loading: {
        user: false,
    },
    itemsSort: {
        value: 'new__desc',
        label: 'Newest'
    },
    itemsSortOptions: [
        // { label: 'Newest', value: 'new__desc'},
        // { label: 'Oldest', value: 'new__asc'},
        // { label: 'Highest Hourly Rate', value: 'price__desc'},
        // { label: 'Lowest Hourly Rate', value: 'price__asc'},
        // { label: 'Relevance', value: 'relevance'},
        // { label: 'Star Rating', value: 'rating__desc'},
    ],
    showMainMenu: false,
    auth: {

    },
    stateResource: {},
    settings: {},
    pwaInstall: null
})

export const mutations = {
    setUser: (state, payload) => {
        state.user = {...state.user, ...payload};
        if (Bugsnag.isStarted()) {
            Bugsnag.setUser(payload.id, payload.email, payload.username);
        }
    },
    setPlan: (state, payload) => {
        state.plan_settings = {...default_plan, ...payload};
    },
    setProfiles: (state, payload) => {
        state.profiles = payload;
    },
    setLoaded: (state, value) => {
        state.loaded[value] = true
    },
    setMeta: (state, payload) => {
        state.meta.title = payload.title ? payload.title : null;
        state.meta.description = payload.description ? payload.description : '';
        state.meta.breadcrumbs = payload.breadcrumbs ? payload.breadcrumbs : [];
    },
    setResource: (state, payload) => {
        state.stateResource  = {...state.stateResource, [payload[0]]: payload[1] };
    },
    deleteResource: (state, resource) => {
        state.stateResource[resource] = [];
    },
    toggleMainMenu: (state, value = null) => {
        if (value !== null) {
            state.showMainMenu = value
        }else{
            state.showMainMenu = !state.showMainMenu
        }
        if (state.showMainMenu) {
            document.body.classList.add("header-menu-visible");
        }else{
            document.body.classList.remove("header-menu-visible");
        }
    },
    setSettings: (state, value) => {
        state.settings = value
    },
    setLoading: (state, payload) => {
        state.loading[payload[0]] = payload[1]
    },
    setSortOptions: (state, value) => {
        state.itemsSortOptions = value ? value : []
    },
    setSort: (state, value) => {
        if (value) {
            state.itemsSort = value;
        }
    },
    setPwaInstall: (state, value) => {
        if (!state.pwaInstall) {
            state.pwaInstall = value;
        }
    },
}


export const actions = {
    logout () {
        this.commit('setUser', {
            id: null,
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone_prefix: '',
            phone: '',
            privacy: '',
            type: '',
            online_at: '',
            image: null,
            address: null,
            company: null,
            isAgency: false,
        })
        this.commit('setPlan', null);
        this.commit('setProfiles', []);
    },
}



<template functional>
    <div class="custom-radio --payment-radio mb-0" style="flex: 1">
        <input :id="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
               :class="{'is-invalid' : props.v ? props.v.$error : null}"
               class="custom-control-input"
               type="radio"
               :name="props.name"
               :value="props.fieldValue"
               :readonly="props.readonly"
               :disabled="props.disabled"
               :checked="props.value === props.fieldValue"
               v-on:change="($event) => {
                  listeners.input && listeners.input($event.target.value);
                  if (props.v) {
                       props.v.$touch();
                    }
                }"
        >
        <label :for="props.id ? props.id : props.text ? props.text.replace(/[^\w-]+/g,'') : props.label.replace(/[^\w-]+/g,'')"
               class="custom-control-label"
        >
            {{ props.hideText ? '' : props.text }}
        </label>
    </div>
</template>

<script>
    export default {
        name: 'FormPaymentRadio',
    }
</script>

<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Dashboard</div>
        </div>
        <div class="l-sidebar-wrapper">
            <div class="l-sidebar-content">
                <template v-if="['client'].includes(user.type)">
                    <div class="l-account-content__section-title justify-content-between">Your Latest Projects
                        <Btn :to="{name: 'account.jobs'}" class="btn-outline-light" style="justify-self: flex-end">See All Projects</Btn>
                    </div>
                    <div :class="{'loading': loading.data}">
                        <template v-if="jobs && jobs.length > 0">
                            <div class="projects-card-list">
                                <AccountJobSimpleCard class="mb-2" :key="job.id" v-for="job in jobs" :job="job">
                                    <template #actions>
                                        <JobDropdown @onViewDetails="viewJobDetails(job)" :job="job"/>
                                    </template>
                                </AccountJobSimpleCard>
                            </div>
                        </template>
                        <div class="card-body" v-else-if="jobs && !jobs.length">
                            <h4 class="text-center mb-4">No projects</h4>
                        </div>
                    </div>
                </template>
                <template v-else-if="['freelancer'].includes(user.type)">
                    <div class="l-account-content__section-title justify-content-between">Your Latest Projects
                        <Btn :to="{name: 'account.projects'}" class="btn-outline-light" style="justify-self: flex-end">See All Projects</Btn>
                    </div>
                    <div :class="{'loading': loading.data}">
                        <template v-if="projects && projects.length > 0">
                            <div class="projects-card-list">
                                <AccountProjectSimpleCard class="mb-2" :key="project.id" v-for="project in projects" :project="project">
                                    <template #actions>
                                        <ProjectDropdown @onViewDetails="viewProjectDetails(project)" :project="project"/>
                                    </template>
                                </AccountProjectSimpleCard>
                            </div>
                        </template>
                        <div class="card-body" v-else-if="projects && !projects.length">
                            <h4 class="text-center mb-4">No projects</h4>
                        </div>
                    </div>
                </template>

                <div class="account-info-card">
                    <div class="account-info-card__section mt-3">
                        <div class="l-account-content__section-title justify-content-between">Last Messages
                            <Btn :to="{name: 'account.messages'}" class="btn-outline-light" style="justify-self: flex-end">See All Messages</Btn>
                        </div>
                        <div :class="{'loading': loading.data}">
                            <div v-if="conversations && conversations.length">
                                <a :key="conversation.ref" href="#"
                                   v-for="conversation in conversations"
                                   class="chat-list__item"
                                   @click.prevent="selectConversation(conversation)"
                                >
                                    <div class="chat-list__item__image-wrapper">
                                        <div style="position: relative">
                                            <img :src="$utils.getImage(conversation.user.image, 52, 52, false)" class="chat-list__item__image" :class="{'active': conversation.unread_count > 0}"/>
                                            <span v-if="conversation.is_online" class="chat-list__item__online-dot"></span>
                                        </div>
                                    </div>
                                    <div class="chat-list__item__content">
                                        <div class="chat-list__item__info">
                                            <div class="chat-list__item__name">{{ conversation.user.fullname }}</div>
                                            <div class="chat-list__item__time">{{ conversation.last_message_at | momentAgo }}</div>
                                        </div>
                                        <div class="chat-list__item__content__text-wrapper">
                                            <div class="chat-list__item__text">{{ conversation.last_message }}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div v-else-if="conversations && !conversations.length">
                                <div class="text-center mb-4">No messages</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter class="--contain-bg"/>
            </div>
            <div class="l-sidebar">
                <div class="c-dashboard-tip --white mb-2">
                    <div class="c-dashboard-tip__title">How It Works</div>
                    <NuxtLink to="/how-it-works" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>
                <div class="c-dashboard-tip --green mb-2">
                    <div class="c-dashboard-tip__title">Success Stories</div>
                    <NuxtLink to="/info/success-stories" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>
                <div class="c-dashboard-tip --yellow mb-2">
                    <div class="c-dashboard-tip__title">Events</div>
                    <NuxtLink to="/info/events" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>
                <div class="c-dashboard-tip --blue mb-2" v-if="user.type === 'client'">
                    <div class="c-dashboard-tip__title">How to find Biotech Experts</div>
                    <NuxtLink to="/info/how-to-find-biotech-expert" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>
                <div class="c-dashboard-tip --blue mb-2" v-if="user.type === 'freelancer'">
                    <div class="c-dashboard-tip__title">How to find a Job</div>
                    <NuxtLink to="/info/how-to-find-biotech-job" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>
                <div class="c-dashboard-tip --blue mb-2" v-if="user.type === 'company'">
                    <div class="c-dashboard-tip__title">Partnership</div>
                    <NuxtLink to="/establish-partnership" class="c-dashboard-tip__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                            <path d="M116 29C116 34.5228 111.523 39 106 39L19.5187 39C16.7438 39 14.0937 37.847 12.2021 35.8167L3.35108 26.3167C-0.22685 22.4765 -0.226849 16.5235 3.35109 12.6832L12.2021 3.18325C14.0937 1.153 16.7438 2.16054e-06 19.5187 2.31343e-06L106 7.07841e-06C111.523 7.38271e-06 116 4.47716 116 10L116 29Z" fill="white"/>
                        </svg>
                        <span class="c-dashboard-tip__btn-text">See More</span>
                    </NuxtLink>
                </div>

                <div class="account-info-card">
                    <div class="account-info-card__section mt-3 mb-3">
                        <div class="l-account-content__section-title">We’d love to hear from you!</div>
                        <div class="l-account-content__section-subtitle">Email us if you have any questions or feedback</div>
                        <Btn to="/contact-us" class="btn-block btn-outline-light mb-2">Send Us Feedback</Btn>
                        <inline-svg src="/images/dashboard_feedback.svg"/>
                    </div>
                </div>
            </div>
        </div>
        <ModalSidebar name="JobDetailsModal" :openLink="{name: 'jobs.details', params: {slug: selected_job?.slug}}">
            <JobDetails :item="selected_job" :modal="true"/>
        </ModalSidebar>
        <ModalSidebar name="ProjectDetailsModal" :openLink="{name: 'projects.details', params: {slug: selected_project?.slug}}">
            <ProjectDetails :item="selected_project" :modal="true"/>
        </ModalSidebar>
    </div>
</template>

<script>

import Newsletter from "@/partials/Newsletter.vue";
import Btn from "@/components/Btn.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import JobDetails from "@/pages/Jobs/JobDetails.vue";
import {mapState} from "vuex";
import AccountJobSimpleCard from "@/components/AccountJobSimpleCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import AccountProjectSimpleCard from "@/components/AccountProjectSimpleCard.vue";
import ProjectDropdown from "@/components/ProjectDropdown.vue";
import ProjectDetails from "@/pages/Projects/ProjectDetails.vue";

export default {
    name: 'Dashboard',
    components: {ProjectDetails, ProjectDropdown, AccountProjectSimpleCard, ModalSidebar, AccountJobSimpleCard, JobDetails, JobDropdown, Btn, Newsletter},
    data() {
        return {
            loading: {
                data: false,
            },
            projects: null,
            jobs: null,
            conversations: null,
            selected_job: null,
            selected_project: null,
        }
    },
    mounted() {
        this.getProjects()
    },
    activated() {
        this.getProjects()
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    methods: {
        getProjects() {
            this.loading.data = true;
            this.$axios.get("/account/dashboard-data")
                .then((res) => {
                    this.jobs = res.jobs
                    this.projects = res.projects
                    this.conversations = res.conversations
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        viewJobDetails(job) {
            if (job.status >= 2) {
                this.$router.push({name: 'account.jobs.details', params: {id: job.id}})
            } else {
                this.selected_job = job;
                this.$modal.show('JobDetailsModal')
            }
        },
        viewProjectDetails(project) {
            if (project.status >= 2) {
                this.$router.push({name: 'account.projects.details', params: {id: project.id}})
            }else{
                this.selected_project = project;
                this.$modal.show('ProjectDetailsModal')
            }
        },
        selectConversation(conversation) {
            this.$router.push({name: 'messages', params: {conversation: conversation}})
        },
    }
}
</script>

<template>
    <div class="d-flex flex-column" style="flex: 1;">
        <div class="l-auth__onboarding" :class="{'loading': loading.data, '--white-bg': finished, '--gray-bg': preview}">
            <Bubbles class="l-auth__onboarding-bubbles"/>
            <div class="container" v-if="preview">
                <CompanyDetails :profile="user_data" :preview="true"/>
            </div>
            <OnboardingCard
                v-else-if="finished"
                title="Success! Your profile is ready"
                style="width: 680px"
                icon="/images/success_profile.svg"
            >
                <div class="mx-auto" style="max-width: 488px">
                    <div class="l-auth__onboarding__success-card mb-4">
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> You can only have one account with BOLG</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Only your country and city will be visible to Users</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Links to your company Website, LinkedIn URL and Career Page Will be visible to users</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Your phone number will not be visible to Users</div>
                    </div>
                    <div class="alert-outline mb-4">
                        <p>We will review your profile within 24 or 48 hours to approve it and accept it.</p>
                        <p>You may have a quick skype or zoom call to verify that you are the person who filled the profile. We will ask you about your name, your Company Name, full address, phone number, Your business details, etc. </p>
                    </div>
                    <div class="text-center">
                        <Btn class="btn-outline-light --w380" :to="{name: 'account.info'}">View My Profile</Btn>
                    </div>
                </div>
            </OnboardingCard>
            <Steps :index="step" v-else @stepsChange="stepsChange">
<!--                <Step name="Profile">-->
<!--                    <OnboardingCard-->
<!--                        title="Fill your profile"-->
<!--                        subtitle="You can fill your profile automatically from your LinkedIn account or fill it manually"-->
<!--                    >-->
<!--                        <div class="mx-auto" style="max-width: 380px">-->
<!--                            <div>-->
<!--                                <Btn class="btn-outline-light btn-block mb-2"><LinkedInIcon height="30" width="30"/> Sign in With LinkedIn</Btn>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <Btn class="btn-outline-light btn-block mb-2" @click="goToNextStep"><EditIcon height="30" width="30"/> Fill it Manually</Btn>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </OnboardingCard>-->
<!--                </Step>-->
                <Step name="ProfilePhoto">
                    <OnboardingCard
                        title="Upload profile photo"
                        subtitle="Drag the photo to move and perfectly fit"
                        style="width: 508px;"
                    >
                        <div style="display: flex; align-items: center; flex-direction: column;">
                            <div style="position: relative;">
                                <ImagesUploaderCrop :size="320" :value="user_data.image" @input="(value) => user_data.image = value" :multiple="false"/>
                                <SwirleIcon class="l-auth__onboarding_crop-icon"/>
                            </div>
                        </div>
                        <!--                <Btn class="btn-block btn-secondary-light" @click="user_data.image = null">Save Photo</Btn>-->
                    </OnboardingCard>
                </Step>
                <Step name="Company">
                    <OnboardingCard
                        title="Fill Your Company Profile if You Are Registering a Business Account"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormInput class="mb-2" label="Company Name" placehoder="" :value="user_data.company.name" @input="(value) => user_data.company.name = value"/>
                            <FormInput class="mb-2" label="Website" placehoder="" :value="user_data.company.website" @input="(value) => user_data.company.website = value"/>
                            <FormInput class="mb-2" label="Street Address" placehoder="" :value="user_data.company.address.address" @input="(value) => user_data.company.address.address = value"/>
                            <FormInput class="mb-2" label="City" placehoder="" :value="user_data.company.address.city_name" @input="(value) => user_data.company.address.city_name = value"/>
                            <FormInput class="mb-2" label="Province" placehoder="" :value="user_data.company.address.county_name" @input="(value) => user_data.company.address.county_name = value"/>
                            <FormInput class="mb-2" label="ZIP code" placehoder="" :value="user_data.company.address.postcode" @input="(value) => user_data.company.address.postcode = value"/>
                            <FormGroup label="Country">
                                <CountrySelect :value="user_data.company.address.country" @input="(value) => user_data.company.address.country = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="CompanyDescription">
                    <OnboardingCard
                        title="Describe the company"
                        style="width: 710px"
                    >
                        <div class="mx-auto">
                            <FormTextarea class="mb-2" placehoder="" :value="user_data.company.description" @input="(value) => user_data.company.description = value"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="companyInfo" key="companyInfo" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Number of Employees"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" fieldValue="<15" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Less Than 15 Employees"/>
                            <FormRadio :bordered="true" fieldValue="15-50" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Between 15-50"/>
                            <FormRadio :bordered="true" fieldValue="50-100" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="50-100 Employees"/>
                            <FormRadio :bordered="true" fieldValue="100-500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="100-500 Employees"/>
                            <FormRadio :bordered="true" fieldValue=">500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="More than 500 Employees"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Additionalinfo" key="Additionalinfo" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Additional information"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormGroup label="The company Logo" class="text-center">
                                <ImagesUploaderCrop class="mt-3" :size="200" :value="user_data.company.image" @input="(value) => user_data.company.image = value" :multiple="false"/>
                            </FormGroup>
                            <FormInput class="mb-2" label="Your Position in the Company" placehoder="e.g. Reasearcher" :value="user_data.company.position" @input="(value) => user_data.company.position = value"/>
                            <FormDate :time="false" altFormat="Y-m-d" class="mb-2" label="Established Date" :value="user_data.company.established_date" @input="(value) => user_data.company.established_date = value"/>
                            <FormGroup label="Upload a Proof of Business">
                                <DocumentsUploader class="mb-2" :value="user_data.company.documents" @input="(value) => user_data.company.documents = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ColorCodeCategory" key="ColorCodeCategory">
                    <OnboardingCard
                        title="Color Code Category(s)"
                        style="width: 710px"
                    >
                        <div class="text-center">
                            <div class="mx-auto" style="display: inline-block">
                                <FormCheckbox v-for="option in attributes.color_code_categories"
                                              :fieldValue="option"
                                              :key="option.id"
                                              :id="'color_code_categories' + option.id"
                                              name="color_code_categories"
                                              v-model="user_data.attributes.color_code_categories"
                                >
                                    <template v-slot:labelslot>
                                        <div class="o-attribute-option" >
                                            <div class="o-attribute-option__icon" v-if="option.icon">
                                                <XImgset class="img-fluid" :src="option.icon" :width="20" :height="20" :alt="option.name"/>
                                            </div>
                                            <div>
                                                <div class="o-attribute-option__title">{{ option.name }}</div>
                                                <div class="o-attribute-option__desc" v-if="option.subtitle">{{ option.subtitle }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </FormCheckbox>

                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="BiotechnologySectors" key="BiotechnologySectors">
                    <OnboardingCard
                        title="Biotech Sector(s) Covered"
                        subtitle="Maximum 30 Biotech Sectors can be added"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <div class="pb-2">
                                <div class="badge badge-primary mr-1 mb-1" v-for="(biotech_sector, index) in user_data.attributes.biotech_sectors">{{ biotech_sector.name }} <a @click.prevent="removeBiotechSector(index)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="biotech_sectors" class="mb-1" :searchOnly="true" @select="(option) => user_data.attributes.biotech_sectors.push(option)"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="OfferingJobs" key="OfferingJobs">
                    <OnboardingCard
                        title="Offering Jobs"
                        style="width: 780px"
                    >
                        <div>
                            <div class="d-flex flex-row">
                                <FormRadio class="mr-2" name="offering_jobs" text="Yes" size="lg" :fieldValue="true" v-model="user_data.attributes.offering_jobs"/>
                                <FormRadio class="mr-2" name="offering_jobs" text="No" size="lg" :fieldValue="false" v-model="user_data.attributes.offering_jobs"/>
                            </div>
                            <FormInput class="mb-2" label="Link to career Page" placehoder="Https://" v-model="user_data.career_page"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Visibility" key="Visibility">
                    <OnboardingCard
                        title="Profile Visibility"
                        subtitle="Who do you want to see your profile? Simply, select an option to control your visibiity. You can change this later in your profile data"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="width: 488px; max-width: 100%;">
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'public'}" @click="user_data.privacy = 'public'">
                                <div class="o-privacy-select__icon"><PrivacyPublicIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Public</div>
                                    <div class="o-privacy-select__info-description">Anyone can see it, it will be indexed in search engines</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'registered'}" @click="user_data.privacy = 'registered'">
                                <div class="o-privacy-select__icon"><PrivacyRegisteredIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">BOLG Users only</div>
                                    <div class="o-privacy-select__info-description">Only users logged in to BOLG can find it</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'private'}" @click="user_data.privacy = 'private'">
                                <div class="o-privacy-select__icon"><PrivacyPrivateIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Private*</div>
                                    <div class="o-privacy-select__info-description">Only BOLG admins can see it.</div>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>

            </Steps>
        </div>
        <div class="l-auth__footer" v-if="!finished">
            <div class="container align-items-center justify-content-center d-flex flex-row" v-if="preview">
                <Btn class="btn-outline-light --w280" @click="preview = false">Edit Profile</Btn>
                <Btn class="btn-secondary --w280" @click="saveUserData" :loading="loading.save">Create Profile</Btn>
            </div>
            <template v-else>
                <div class="l-auth__footer-progress d-flex d-md-none">
                    <div class="progress mr-2" style="width: 55px">
                        <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                    </div>
                    {{ completed }}% Profile Completed
                </div>
                <div class="container l-auth__footer-container">
                    <Btn class="btn-outline-light --w120" :disabled="step == 0" @click="goToPrevStep">Back</Btn>
                    <div class="l-auth__footer-progress d-none d-md-flex">
                        <div class="progress mr-2" style="width: 55px">
                            <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                        </div>
                        {{ completed }}% Profile Completed
                    </div>
                    <div style="white-space: nowrap">
                        <Btn class="text-secondary" v-if="step < stepsCount - 1" @click="skip">Skip for now</Btn>
                        <Btn class="btn-outline-light --w120" v-else @click="preview = true">Preview</Btn>
                        <Btn class="btn-secondary --w120" v-if="step < stepsCount - 1" @click="goToNextStep">Next</Btn>
                        <Btn class="btn-secondary --w120" v-else @click="saveUserData" :loading="loading.save">Submit</Btn>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script>


import { mapState } from 'vuex'
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";
import BadgeSelect from "~/components/BadgeSelect.vue";
import ImagesUploaderCrop from "~/components/ImageUploaderCrop.vue";
import FormRadio from "~/components/FormRadio.vue";
import FormDate from "~/components/FormDate.vue";
import DocumentsUploader from "~/components/DocumentsUploader.vue";
import LinkedInIcon from "~/assets/icons/auth/linkedin.svg?inline";

import SwirleIcon from "~/assets/icons/swirle.svg?inline";
import Steps from "~/components/Steps.vue";
import Step from "~/components/Step.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";
import XImgset from "~/components/XImgSet.vue";
import AttributeSelect from "~/components/AttributeSelect.vue";
import FormTextarea from "~/components/FormTextarea.vue";
import FreelancerDetails from "@/pages/Freelancers/FreelancerDetails.vue";
import CompanyDetails from "@/pages/Companies/CompanyDetails.vue";
import {maxLength} from "vuelidate/lib/validators";


export default {
    name: 'AuthCompanyOnboarding',
    components: {
        CompanyDetails,
        FreelancerDetails,
        FormTextarea,
        AttributeSelect,
        XImgset,
        FormCheckbox,
        Step,
        Steps, DocumentsUploader, FormDate, FormRadio, CountrySelect,
        FormInput, ImagesUploaderCrop, BadgeSelect, OnboardingCard, Btn,
        Bubbles,
        SwirleIcon, LinkedInIcon
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            preview: false,
            stepsCount: 0,
            step: 0,
            finished: false,
            user_data: {
                image: null,
                company: {
                    name: '',
                    description: '',
                    website: '',
                    employees: null,
                    image: null,
                    position:null,
                    established_date:null,
                    address: {
                        address: '',
                        city_name: '',
                        county_name: '',
                        postcode: '',
                        country: null,
                    },
                    documents: [],
                },
                attributes: {
                    color_code_categories: [],
                    biotech_sectors: [],
                    offering_jobs: null,
                },
                career_page: '',
            },
            attributes: {},
            privacy: null,
        }
    },
    validations: {
        user_data: {
            attributes :{
                biotech_sectors: {maxLength: maxLength(30)}
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        completed() {
            return (this.step / (this.stepsCount - 1) * 100).toFixed(0);
        },
    },
    created: function () {
        this.getData()
    },
    methods: {
        goToPrevStep() {
            if (this.loading.data) {
                return;
            }
            if (this.step > 0) {
                this.step--;
            }
        },
        goToNextStep() {
            if (this.loading.data) {
                return;
            }
            if (this.edit_object) {
                return;
            }
            if (this.step <= this.stepsCount) {
                this.step++;
            }
        },
        skip() {
            this.$router.push({'name': 'account.dashboard'})
        },
        async getData() {
            this.loading.data = true;
            await this.$axios.post("/api/get-attributes-options", {
                attribute_code: ['color_code_categories', 'biotech_sectors', 'offering_jobs']
            })
                .then((res) =>{
                    this.attributes = res;
                })

            await this.$axios.get("/account/get-info")
                .then((res) =>{
                    this.user_data = {...this.user_data, ...res.data}
                    if (!this.user.company) {
                        this.user_data.company = {
                            name: '',
                            description: '',
                            website: '',
                            employees: null,
                            image: null,
                            position:null,
                            established_date:null,
                            address: {
                                address: '',
                                city_name: '',
                                county_name: '',
                                postcode: '',
                                country: null,
                            },
                            documents: [],
                        }
                    }
                });
            this.loading.data = false;
        },
        saveUserData() {
            this.loading.save = true;
            this.$axios.post("/account/save-onboarding", this.user_data)
                .then((res) =>{
                    this.loading.save = false;
                    this.finished = true;
                    this.preview = false;
                })
                .catch((res) =>{
                    this.loading.save = false;
                })
        },
        stepsChange(count) {
            this.stepsCount = count
        },
        removeBiotechSector(index) {
            this.user_data.attributes.biotech_sectors.splice(index, 1)
        },
    },
}
</script>

<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Job Catalog</div>
        </div>
        <Tabs wrapperClass="account_tabs">
            <Tab name="Ongoing">
                <AccountJobsList status="2"/>
            </Tab>
            <Tab name="Achieved">
                <AccountJobsList status="3"/>
            </Tab>
            <Tab name="Applications">
                <AccountJobApplicationsList/>
            </Tab>
            <Tab name="Saved">
                <GroupList model_type="Modules\Projects\Entities\Project" type="job"/>
            </Tab>
        </Tabs>
    </div>

</template>

<script>
import {modelListMixin} from "~/mixins/modelListMixin";
import Tab from "@/components/Tab.vue";
import Tabs from "@/components/Tabs.vue";
import AccountJobsList from "@/partials/AccountJobsList.vue";
import GroupList from "@/pages/Account/GroupList.vue";
import AccountJobApplicationsList from "@/partials/AccountJobApplicationsList.vue";

export default {
    name: "JobsCatalog",
    mixins: [modelListMixin],
    components: {AccountJobApplicationsList, GroupList, AccountJobsList, Tabs, Tab },
}
</script>


<template>
    <span class="stars">
        <StarIcon :class="hover_value ? (i <= hover_value ? 'active' : 'inactive') : i <= selected_value ? 'active' : 'inactive'"
            :height="size ? size : '24'"
            :width="size ? size : '24'"
            @click="editable ? selected_value = i : null"
            @mouseenter="editable ? hover_value = i : null"
            @mouseleave="editable ? hover_value = null : null"
            v-for="i in 5"
            :key="i"
        />
    </span>
</template>

<script>
    export default {
        name: 'Stars',
        props: ['editable', 'value', 'size'],
        data () {
            return {
                selected_value: null,
                hover_value: null
            }
        },
        mounted() {
            if(this.value){
                this.selected_value = this.value;
            }
        },
        watch: {
            value(a) {
                this.selected_value = a;
            },
            selected_value(option) {
                if (!this.editable) {
                    return false;
                }
                if (!option) {
                    this.$emit('input', null);
                }else {
                    this.$emit('input', option);
                }
            },
        },
    };
</script>


<template>
    <div class="l-articles-list__content" :class="{'loading' : loading.posts}">
        <div style="max-width: 344px;">
            <FormInput placeholder="Keyword(s)" class="mt-3 mb-5">
                <template v-slot:append><SearchIcon/></template>
            </FormInput>
        </div>
        <div class="row --item-card-grid" v-if="posts.data">
            <template v-if="filters.content_type === 'video'">
                <div class="col-lg-4 col-sm-6" v-for="article in posts.data">
                    <VideoCard :post="article" @showVideo="showVideo"/>
                </div>
            </template>
            <template v-else-if="filters.content_type === 'animation'">
                <div class="col-lg-4 col-sm-6" v-for="article in posts.data">
                    <AnimationCard :post="article" @showAnimation="showVideo"/>
                </div>
            </template>
            <template v-else>
                <div class="col-lg-4 col-sm-6" v-for="article in posts.data">
                    <ArticleCard :post="article"/>
                </div>
            </template>
        </div>
        <div class="text-xs-center" v-if="pagination">
            <laravel-vue-pagination :limit="10" :data="posts" v-on:pagination-change-page="getPage"/>
        </div>
        <modal name="videoModal" @before-close="stopVideo" width="700" height="auto">
            <div class="youtubeVideo">
                <iframe ref="video" v-if="video" :src="'https://www.youtube.com/embed/' + $utils.getYoutubeId(video.attributes.video)" frameborder="0" allowfullscreen id="productVideo"></iframe>
            </div>
        </modal>
    </div>
</template>

<script>


// import { metaMixin } from '~/mixins/metaMixin'

import ArticleCard from "~/components/ArticleCard.vue";
import FormInput from "~/components/FormInput.vue";
import VideoCard from "~/components/VideoCard.vue";
import AnimationCard from "~/components/AnimationCard.vue";

export default {
    name: 'ArticlesList',
    components: {AnimationCard, VideoCard, FormInput, ArticleCard},
    props: ['data'],
    // mixins: [metaMixin],
    // setup() {
    //     const data = useApiMeta('Blog');
    //     return data;
    // },
    data() {
        return {
            loading: {
                posts: false,
            },
            posts: [],
            pagination: null,
            filters: {
                perPage: 15,
                page: 1,
                content_type: 'post'
            },
            video: null,
        }
    },
    async fetch () {
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        getData () {
            if(this.$route.meta.type) {
                this.filters.content_type = this.$route.meta.type;
            }
            this.loading.posts = true;
            return this.$axios.get('/cms/posts', { params: this.filters })
                .then((res) => {
                    this.posts = res.posts
                    this.loading.posts = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.posts = false;
                });
        },
        getPage(pageNo) {
            this.filters.page = pageNo;
            this.getData()
        },
        showVideo(video) {
            this.video = video
            this.$modal.show('videoModal')
        },
        stopVideo() {
            // console.log(this.$refs)
            // if (this.$refs["video"]) {
            //     this.$refs["video"]['playVideo']();
            // }
        },
    }
}
</script>

import Vue from 'vue';
import VueSocialauth from 'vue-social-auth'

export default () => {
    const baseUrl = window.location.origin

    Vue.use(VueSocialauth, {
        property: '$socialAuth',
    })
};

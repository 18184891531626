<template>
    <modal class="modal-sidebar" transition="slide" :name="name" height="auto" width="100%" :scrollable="true">
        <div class="modal-sidebar__content">
            <div class="modal-sidebar__content-header">
                <div>
                    <a href="#" @click.prevent="$modal.hide(name)">
                        <ArrowBackIcon/> {{ backLabel ? backLabel : 'Back' }}
                    </a>
                </div>
                <div v-if="openLink">
                    <NuxtLink target="_black" :to="openLink">
                        <OpenIcon/> Open In a New Tab
                    </NuxtLink>
                </div>
            </div>
            <slot/>
        </div>
    </modal>
</template>

<script>
    export default {
        name: 'ModalSidebar',
        props: ['name', 'openLink', 'backLabel']
    };
</script>


export const getUserUrl = (user) => {
    if (user.type === 'freelancer') {
        return {name: 'freelancers.details', params: {username: user.username}}
    }
    if (user.type === 'client') {
        return {name: 'clients.details', params: {username: user.username}}
    }
    if (user.type === 'company') {
        return {name: 'companies.details', params: {username: user.username}}
    }
}

export const getClientName = (client) => {
    if (client.isAgency) {
        return client.company.name;
    }
    if (client.fullname) {
        return client.fullname;
    }
    return client.firstname + ' ' + client.lastname
}

<template>
    <div>
        <div id="PayPalConnect" v-if="user.type === 'freelancer'" class="card card-dark mb-2 mt-2" style="max-width: 100%; width: 666px" :class="{'loading': loading.data}">
            <div class="card-body">
                <div class="">
                    <h3 class="card-title">Connect with <b> PayPal</b></h3>
                    <div class="card-subtitle ">To be able to receive payments first connect to PayPal.</div>
                    <div class="card-subtitle mt-1" v-if="paypal_wallet">Paypal Wallet Connected</div>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <template v-if="paypal_wallet">
                        <a href="#" class="btn btn-outline-danger btn-sm mt-2 --w220" :style="{ cursor: 'pointer'}" @click="disconnectPaypalWallet()">Disconnect</a>
                    </template>
                    <template v-else>
                        <a class="btn btn-secondary" @click="AuthProvider('paypal')">Sign up for PayPal</a>
                    </template>
                </div>
            </div>
        </div>

        <template v-if="user.type === 'client'">
            <div class="l-account-content__header">
                <div class="l-account-content__title">Payment Methods</div>
            </div>
            <div style="max-width: 100%; width: 766px" :class="{'loading': loading.data}">
                <div class="payment-add-row">
                    <span>Your Payment Methods</span>
<!--                    <div class="add-payment-btn" @click="addPayPal"><PlusWhiteIcon class="mr-1"/>Add PayPal</div>-->
                    <div class="add-payment-btn" @click="addCreditCard"><PlusWhiteIcon class="mr-1"/>Add New Bank Card</div>
                </div>
                <div class="payment-card mb-2" v-for="card in cards" @click="setCard(card)">
                    <div class="d-flex flex-row align-items-center" style="flex: 1">
                        <span>
                            <form-payment-radio :hideText="true" :id="card.id" :text="card.type" :fieldValue="card.id" name="card" v-model="selected_card_id"></form-payment-radio>
                        </span>
                        <VisaIcon v-if="card.brand === 'visa'" width="71" height="50" class="mr-2"/>
                        <MastercardIcon v-else-if="card.brand === 'mastercard'" width="71" height="50" class="mr-2"/>
                        <AmexIcon v-else-if="card.brand === 'amex'" width="71" height="50" class="mr-2"/>
                        <CardIcon v-else class="mr-2" width="71" height="50"/>
                        <span>{{ card.name }}</span>
                    </div>
                    <span>{{ card.hash }}</span>
                    <div><TrashIcon width="70" @click="deleteCard(card)"/></div>
                </div>
                <Btn v-if="selected_card_id" class="btn-secondary" :loading="loading.select" @click="setActiveCard">Set as Active</Btn>
            </div>
            <modal name="addCardModal" height="auto" :scrollable="true" style="border-radius: 10px">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <a style="cursor: pointer" class="close" @click="$modal.hide('addCardModal')">
                            <CloseIcon class="close"/>
                        </a>
                        <div class="modal-header">
                            <h4 class="modal-title">Add a New Bank Card</h4>
                        </div>
                        <div class="modal-body" :class="{'loading': loading.paypal}">
                            <div id='card-holder-name'></div>
                            <div id="paypal-button-container"></div>
                        </div>
                        <div class="modal-footer">
                            <Btn :loading="loading.save" id='submit' class="btn btn-lg btn-secondary btn-block" @click="submitSaveCard()">Add Bank Card</Btn>
                        </div>
                    </div>
                </div>
            </modal>
        </template>

    </div>
</template>

<script>
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import VisaIcon from "~/assets/icons/account/visa-logo.svg?inline";
import MastercardIcon from "~/assets/icons/account/mastercard-logo.svg?inline";
import AmexIcon from "~/assets/icons/account/amex-logo.svg?inline";
import CardIcon from "~/assets/icons/account/card.svg?inline";
import FormPaymentRadio from "@/components/FormPaymentRadio.vue";
import Btn from "@/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import {mapState} from "vuex";
import PaypalButtons from "@/components/PaypalButtons.vue";
import {paypalMixin} from "@/mixins/paypalMixin";
import OAuthPopup from "vue-social-auth/src/oauth/popup";

export default {
    name: "PaymentMethods",
    mixins: [paypalMixin],
    components: {PaypalButtons, FormInput, Btn, FormPaymentRadio, VisaIcon, MastercardIcon, AmexIcon, CardIcon, CloseIcon },
    data() {
        return {
            loading: {
                data: false,
                save: false,
                select: false,
                paypal: false,
            },
            cards: [],
            selected_card_id: null,
            newCard: {
                cardholder: '',
            },
            paypal_wallet: null,
            paypal_auth_url: null,
            cardFields: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    created() {
        this.getData();
        this.checkPaypal();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.get("/account/credit-cards")
                .then((res) => {
                    this.cards = res.data;
                    this.loading.data = false;
                    this.selected_card_id = res.data.find(i => i.is_default)?.id
                })
                .catch(() => {
                    this.loading.data = false;
                });
        },
        setCard(card) {
            this.selected_card_id = card.id;
        },
        addCreditCard() {
            this.newCard.cardholder = '';
            this.setupCreditCardTokens()
        },
        addPayPal() {
            this.newCard.cardholder = '';
            this.setupPaypalTokens()
        },
        submitSaveCard () {
            // console.log(this.cardFields);
            this.loading.save = true;
            this.cardFields
                .submit()
                .then(() => {
                    this.loading.save = false;
                    console.log("submit was successful");
                })
                .catch((error) => {
                    this.loading.save = false;
                    console.error("submit erred:", error);
                });
        },
        async setupCreditCardTokens() {
            this.loading.paypal = true
            await this.$modal.show('addCardModal');
            await this.initPaypal({components: ['card-fields', 'buttons']});
            this.loading.paypal = false

            this.cardFields = window.paypal.CardFields({
                createVaultSetupToken: async () => {
                    return await this.$axios.post("/account/credit-cards/paypal/setup-token", {
                        type: 'card'
                    })
                        .then((res) =>{
                            this.loading.save = false;
                            return res.data.id;
                        })
                        .catch(() => {
                            this.loading.save = false;
                        });
                },
                onApprove: async (data) => {
                    if(data.liabilityShift){
                        // Handle liability shift
                    }
                    const result = await this.$axios.post("/account/credit-cards/paypal/add", {
                        payment_token: data.vaultSetupToken,
                        type: 'creditCard'
                    })
                        .then((res) =>{
                            this.loading.paypal = false;
                            this.$modal.hide('addCardModal');
                            this.loading.save = false;
                            this.getData();
                            return res.data.id;
                        })
                        .catch(() => {
                            this.loading.save = false;
                        });
                },
                onError: (error) => {
                    console.log({error})
                    this.$toast.error('Could not save card. ' + error)
                    // Capture and log errors from the SDK
                }
            })

            if (this.cardFields.isEligible()) {
                this.cardFields.NameField().render("#card-holder-name");
                this.cardFields.render("#paypal-button-container");
                // this.cardFields.NumberField().render("#card-number");
                // this.cardFields.ExpiryField().render("#expiration-date");
                // this.cardFields.CVVField().render("#cvv");
            } else {
                this.$toast.error('Could not initiate PayPal')
                // Handle the workflow when credit and debit cards are not available
            }
        },
        async setupPaypalTokens() {
            this.loading.paypal = true
            // await this.$modal.show('addCardModal');
            const results = await this.$axios.post("/account/credit-cards/paypal/setup-token", {
                type: 'paypal'
            })
                .then((res) =>{
                    this.loading.paypal = false;
                    if (res.data.links) {
                        const approve_link = res.data.links.find(i => i.rel === 'approve')
                        if (approve_link) {
                            window.open(approve_link);
                        }
                    }
                    return res.data.id;
                })
                .catch(() => {
                    this.loading.paypal = false;
                });
            window.open(results.data.url)
            await this.initPaypal({components: ['card-fields', 'buttons']});

            this.cardFields = window.paypal.Buttons({
                createVaultSetupToken: async () => {

                },
                onApprove: async (data) => {
                    console.log({data})
                    if(data.liabilityShift){
                        // Handle liability shift
                    }
                    const result = await this.$axios.post("/account/credit-cards/paypal/add", {
                        payment_token: data.vaultSetupToken,
                        type: 'paypal'
                    })
                        .then((res) =>{
                            this.loading.paypal = false;
                            this.$modal.hide('addCardModal');
                            this.loading.save = false;
                            this.getData();
                            return res.data.id;
                        })
                        .catch(() => {
                            this.loading.paypal = false;
                        });
                },
                onError: (error) => {
                    console.log({error})
                    this.$toast.error('Could not save card. ' + error)
                    // Capture and log errors from the SDK
                }
            })

            if (this.cardFields.isEligible()) {
                this.cardFields.NameField().render("#card-holder-name");
                this.cardFields.render("#paypal-button-container");
                // this.cardFields.NumberField().render("#card-number");
                // this.cardFields.ExpiryField().render("#expiration-date");
                // this.cardFields.CVVField().render("#cvv");
            } else {
                this.$toast.error('Could not initiate PayPal')
                // Handle the workflow when credit and debit cards are not available
            }
        },
        deleteCard(credit_card) {
            this.$dialog.confirm('Are you sure you want to delete the payment card?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.delete = true;
                    this.$axios.post("/account/credit-cards/delete", {credit_card_id: credit_card.id})
                        .then(() =>{
                            this.getData();
                            this.loading.delete = false;
                        })
                        .catch(()=> {
                            this.loading.delete = false;
                        });
                });
        },
        setActiveCard() {
            this.$dialog.confirm('Are you sure you want to set this card as you active one?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.select = true;
                    this.$axios.post("/account/credit-cards/paypal/set-default", {credit_card_id: this.selected_card_id})
                        .then(() =>{
                            this.getData();
                            this.loading.select = false;
                        })
                        .catch(()=> {
                            this.loading.select = false;
                        });
                });
        },
        getPaypalUrl () {
            this.loading.paypal = true
            this.$axios.post("/account/connect-paypal-account")
                .then((res) => {
                    this.paypal_auth_url = res.url
                    // window.open(res.body.link, '_blank');
                    this.loading.paypal = false;
                })
                .catch(() =>{
                    this.loading.paypal = false;
                });
        },
        disconnectPaypalWallet () {
            this.$dialog.confirm('Are you sure you want to disconnect the wallet?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.paypal = true
                    this.$axios.post("/account/disconnect-paypal-account")
                        .then(() => {
                            this.loading.paypal = false;
                        })
                        .catch(() => {
                            this.loading.paypal = false;
                        });
                });
        },
        checkPaypal () {
            this.loading.paypal = true;
            this.$axios.post("/account/check-paypal-connection")
                .then(async (res) =>{
                    console.log(res);
                    if (res.paypal_wallet) {
                        this.paypal_wallet = res.paypal_wallet;
                    }else{
                        // this.getPaypalUrl();
                    }
                    this.loading.paypal = false;
                })
                .catch((error) =>{
                    console.log(error)
                    this.loading.paypal = false;
                });
        },
        AuthProvider(provider) {
            this.$socialAuth.authenticate(provider).then(response =>{
                this.SetPaypalWallet(provider, response)
            }).catch(err => {
                console.log({err:err})
            })
        },
        SetPaypalWallet(provider, response){
            this.$axios.post("/account/connect-paypal-account", {...response})
                .then((res) => {
                    this.paypal_wallet = res.paypal_wallet;
                    this.loading.paypal = false;
                })
                .catch(() =>{
                    this.loading.paypal = false;
                });
        },
    }
}
</script>

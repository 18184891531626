<template>
    <div class="c-job-card" @click="onSelect">
        <div class="d-flex align-items-center mb-2">
            <div style="flex: 1">
                <div class="c-job-card__title">{{ job.title }}</div>
                <div class="c-job-card__stats mt-1" v-if="job.stats">
                    <template v-if="job.status == 3">
                        <span>Paid: <span class="text-secondary">{{ job.stats?.paid | toPrice }}</span></span>
                        <span>In Escrow: <span class="text-secondary">{{ job.stats?.escrow | toPrice }}</span></span>
                        <span>Started: <span class="text-secondary">{{ job.stats?.started_at | moment('DD MMM, YYYY') }}</span></span>
                    </template>
                    <template v-if="job.status == 2">
                        <span>Paid: <span class="text-secondary">{{ job.stats?.paid | toPrice }}</span></span>
                        <span>In Escrow: <span class="text-secondary">{{ job.stats?.escrow | toPrice }}</span></span>
                        <span>Started: <span class="text-secondary">{{ job.stats?.started_at | moment('DD MMM, YYYY') }}</span></span>
                    </template>
                    <template v-if="job.status == 1">
                        <span>Saved: <span class="text-secondary">{{ job.stats?.saves }}</span></span>
                        <span>Viewed: <span class="text-secondary">{{ job.stats?.views }}</span></span>
                        <span>Applications: <span class="text-secondary">{{ job.stats?.applications }}</span></span>
                    </template>
                </div>
            </div>
            <div v-if="!!$slots.actions">
                <slot name="actions"></slot>
            </div>
            <GroupToggle v-else class="ml-3" foreign_model="Modules\Projects\Entities\Project" :foreign_id="job.id" v-model="job.groups" type="job"/>
        </div>
        <div class="c-job-card__info" v-if="job.client">
            <div>
                <UserAvatar :user="job.client" style="flex: 1" :displayName="true"/>
            </div>
            <div v-if="job.client.address">
                <div class="c-job-card__info-icon">
                    <LocationIcon height="20" width="20"/>
                </div>
                <div class="c-job-card__info-title">{{ [job.client.address.city_name, job.client.address.country_name].join(', ') }}</div>
            </div>
            <div>
                <div class="c-job-card__info-icon">
                    <StarIcon height="16" width="16"/>
                </div>
                <div class="c-job-card__info-title">{{ job.client.avgReview }}/5 ({{ job.client.countReview }})</div>
            </div>
            <div v-if="job.client.payment_verified">
                <div class="c-job-card__info-icon">
                    <CheckCircle2Icon height="16" width="16"/>
                </div>
                <div class="c-job-card__info-title">Payment Verified</div>
            </div>
        </div>
        <div class="c-job-card__stats">
            <span v-if="job.attributes.service_level">{{ job.attributes.service_level.value }}</span>
            <template v-if="job.payment_method === 'hourly'">
                <span v-if="job.price[0]">Hourly Rate: {{ job.price[0] | toPrice(0) }}-{{ job.price[1] | toPrice(0) }}</span>
                <span v-if="job.hours[0]">Hours required: {{ job.hours[0] }} to {{ job.hours[1] }} h</span>
                <span v-if="job.duration[0]">Duration: {{ job.duration[0] }} to {{ job.duration[1] }} Days</span>
            </template>
            <template v-if="job.payment_method === 'fixed'">
                <span v-if="job.price[0]">Est. Budget: {{ job.price[0] | toPrice(0) }}-{{ job.price[1] | toPrice(0) }}</span>
                <span v-if="job.duration[0]">Delivery Time: {{ job.duration[0] }} to {{ job.duration[1] }} Days</span>
            </template>
        </div>
        <div class="c-job-card__content truncate-overflow-sm">
            {{ job.description }}
        </div>
        <div class="c-job-card__info" v-if="job.attributes.color_code_categories">
            <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in job.attributes.color_code_categories.slice(0, 1)" :attr="attr"/>
            <span class="badge badge-sm badge-secondary-light px-1" v-if="job.attributes.color_code_categories.length > 1">+{{ job.attributes.color_code_categories.length - 1}}</span>
        </div>
        <div class="c-job-card__tags-wrapper" v-if="job.attributes?.services">
            <span class="c-project-card__tags-title">Services:</span>
            <ClientOnly>
                <ItemCounter class="c-project-card__tags">
                    <span class="badge badge-sm badge-secondary-light mr-1" v-for="service in job.attributes.services">{{ service.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>
        <div class="c-job-card__tags mb-2" v-if="job.attributes.expertise_level?.value">
            <span class="c-freelancer-card__tags-title">Expertise Level:</span>
            <span><img :src="job.attributes.expertise_level.icon"/> {{ job.attributes.expertise_level.value }}</span>
        </div>
        <div class="c-job-card__tags-wrapper" v-if="job.attributes?.expertise_areas">
            <span class="c-project-card__tags-title">Expertise Area(s):</span>
            <ClientOnly>
                <ItemCounter class="c-project-card__tags">
                    <span class="badge badge-sm badge-secondary-light mr-1" v-for="area in job.attributes.expertise_areas">{{ area.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>
    </div>
</template>

<script>


import UserAvatar from "../../components/UserAvatar.vue";
import GroupIcon from "../../components/GroupToggle.vue";
import CheckCircle2Icon from "~/assets/icons/check-circle2.svg?inline";
import ColorCodeAttribute from "../../components/ColorCodeAttribute.vue";
import GroupToggle from "@/components/GroupToggle.vue";
import ItemCounter from "@/components/ItemCounter.vue";

export default {
    name: 'JobCard',
    components: {ItemCounter, GroupToggle, ColorCodeAttribute, GroupIcon, UserAvatar, CheckCircle2Icon},
    props: ['job'],
    methods: {
        onSelect() {
            this.$emit('onSelect', this.job)
        },
    }
}
</script>

<template>
    <div v-bind:class="{'error' : v ? v.$error : null}">
        <multiselect
            v-model="selected_value"
            :options="options"
            :searchable="true"
            :placeholder="placeholder ? placeholder : 'Search'"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            label="name"
            trackBy="id"
            :loading="loading"
            @search-change="getData"
        >
            <template v-slot:option="props">
                <img class="multiselect__image" v-if="props.option.image" :src="$utils.getImage(props.option.image, 32, 32, false)"/>
                <span class="multiselect__text" v-if="props.option.email"><span class="text-muted">Send Invitation To:</span> <b>{{ props.option.email }}</b></span>
                <span class="multiselect__text" v-else>{{ props.option.name }}</span>
                <span class="multiselect__action" v-if="props.option.action">{{props.option.action }}</span>
            </template>
            <template v-slot:singleLabel="props">
                <img class="multiselect__image" v-if="props.option?.image" :src="$utils.getImage(props.option.image, 32, 32, false)"/>
                <span class="multiselect__text" v-if="props.option.email"><span class="text-muted">Send Invitation To:</span> <b>{{ props.option.email }}</b></span>
                <span class="multiselect__text" v-else>{{ props.option.name }}</span>
                <span class="multiselect__action" v-if="props.option?.action">{{ props.option.action }}</span>
            </template>
            <template slot="noResult"> Not found</template>
            <template slot="noOptions"> {{ placeholder ? placeholder : 'Search' }}</template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
    name: 'InvitationSelect',
    props: ['value', 'placeholder', 'v'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: null,
            options: []
        }
    },
    methods: {
        getData: debounce(function (query) {
            if(query !== '') {
                this.loading = true;
                this.$axios.get("/account/invitations/search", { params: {query: query} })
                    .then((res) =>{
                        if(res.data.length) {
                            this.options = res.data.map(option => {
                                option.action = 'Invite'
                                if (option.company && option.company.name) {
                                    option.name = option.company.name;
                                    option.image = option.company.image;
                                }else{
                                    option.name = option.fullname;
                                }
                                return option;
                            });
                        }else if (this.$utils.validateEmail(query)) {
                            this.options = [{
                                id: 0,
                                name: query,
                                email: query,
                                action: 'Send'
                            }]
                        }
                        console.log(res.data.length, this.options)
                        this.loading = false;
                    }).catch(error => {
                        console.log({error})
                });
            }
        }, 500),
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (!option) {
                this.$emit('input', null);
                // this.$emit('setId', null);
            }else {
                this.$emit('input', option);
                // this.$emit('setId', option ? option.id : null);
            }
        }
    },
};
</script>


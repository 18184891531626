<template>
    <div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :data="data.data"
                    :columns="['project', 'price', 'duration', 'hours_required', 'date']">
                    <template #project="{row}">
                        {{ row.project?.title }}
                    </template>
                    <template #price="{row}">
                        <template v-if="row.payment_method === 'hourly'">
                            {{ row.price | toPrice }} / h
                        </template>
                        <template v-else-if="row.payment_method === 'fixed'">
                            {{ row.price | toPrice }}
                        </template>
                    </template>
                    <template #hours_required="{row}">
                        {{ row.hours_min }} - {{ row.hours_max }} h/{{ row.hours_type }}
                    </template>
                    <template #duration="{row}">
                        {{ row.duration_min }} - {{ row.duration_max }} Days
                    </template>
                    <template #date="{row}">
                        {{ row.created_at | moment('DD.MM.YYYY') }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
<!--                        <template v-if="row.canWithdraw0">-->
<!--                            <Btn @click="withdrawApplication(row)" class="btn-outline-light">Withdraw</Btn>-->
<!--                        </template>-->
                        <template v-if="row.status === -1">
                            <span class="mr-2"><DeclinedIcon class="mr-1"/> Declined</span>
                            <Btn @click="openRejectReasonModal(row)" class="btn-outline-light">View Reason</Btn>
                        </template>
                        <template v-else-if="row.status === 1">
                            <span class="mr-2"><AcceptedIcon class="mr-1"/> Accepted</span>
                        </template>
                        <JobApplicationDropdown :application="row" @onViewDetails="viewApplicationDetails(row)" @onReload="getData"/>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No job applications</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="JobDetailsModal" :openLink="{name: 'jobs.details', params: {slug: selected_job?.slug}}">
            <JobDetails :item="selected_job" :modal="true"/>
        </ModalSidebar>
        <ModalSidebar name="JobApplicationDetailsModal">
            <ApplicationDetails
                :item="selected_application"
                @onWithdrawApplication="withdrawApplication(selected_application)"
            />
        </ModalSidebar>
        <modal name="RejectReasonModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectReasonModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Reject Reason</h4>
                    </div>
                    <div class="modal-body">
                        {{ reject_reason }}
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import {modelListMixin} from "@/mixins/modelListMixin";
import AccountJobSimpleCard from "@/components/AccountJobSimpleCard.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import JobDetails from "@/pages/Jobs/JobDetails.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import ApplicationDetails from "@/pages/Account/ApplicationDetails.vue";
import VueTable from "@/components/VueTable.vue";
import Btn from "@/components/Btn.vue";
import JobApplicationDropdown from "@/components/JobApplicationDropdown.vue";

export default {
    name: "AccountJobApplicationsList",
    props: ['status'],
    mixins: [modelListMixin],
    components: {JobApplicationDropdown, Btn, VueTable, ApplicationDetails, ModalSidebar, JobDetails, JobDropdown, AccountJobSimpleCard },
    data() {
        return {
            filters: {
                type: 'job',
            },
            selected_job: null,
            selected_application: null,
            reject_reason: '',

        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.getPage();
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects/applications/list', this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        viewJobDetails(job) {
            if (job.status >= 2) {
                this.$router.push({name: 'account.jobs.details', params: {id: job.id}})
            }else{
                this.selected_job = job;
                this.$modal.show('JobDetailsModal')
            }
        },
        withdrawApplication() {
            this.loading.withdraw = true;
            this.$axios.post('/account/projects/applications/withdraw', {
                id: this.reject_application.id,
            })
                .then((res) =>{
                    this.getData();
                    this.$modal.hide('JobApplicationDetailsModal')
                })
                .finally(() => {
                    this.loading.withdraw = false;
                });
        },
        viewApplicationDetails(application) {
            this.selected_application = application;
            this.$modal.show('JobApplicationDetailsModal')
        },
        openRejectReasonModal(application) {
            this.reject_reason = application.reject_reason;
            this.$modal.show('RejectReasonModal')
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

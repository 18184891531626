<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title d-flex flex-row align-items-center">
                <a class="d-flex align-items-center mr-2" @click="$router.back()" href="#"><BackIcon/></a> Creating New Appointment
            </div>
        </div>
        <div style="width: 400px; max-width: 100%">
            <form-input v-model="appointment.userdata.email" label="Email Address" placeholder="address@gmail.com"></form-input>
            <form-input v-model="appointment.userdata.firstname" label="First Name" placeholder="Melanie"></form-input>
            <form-input v-model="appointment.userdata.lastname" label="Last Name" placeholder="Collins"></form-input>
            <calendar-picker class="mb-3" v-model="appointment.date"></calendar-picker>
            <div class="d-flex flex-row mb-1">
                <form-time-select label="Time-From" v-model="appointment.time_from" class="flex-1 mr-1"></form-time-select>
                <form-time-select label="Time-To" v-model="appointment.time_to" class="flex-1"></form-time-select>
            </div>
            <Btn class="btn btn-secondary w-100">Create</Btn>
        </div>
    </div>
</template>

<script>
import BackIcon from "~/assets/icons/arrow-left.svg?inline";
import Btn from "~/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import CalendarPicker from "@/components/CalendarPicker.vue";
import FormTimeSelect from "@/components/FormTimeSelect.vue";

export default {
    name: "AppointmentForm",
    components: {FormTimeSelect, CalendarPicker, FormInput, BackIcon, Btn },
    data() {
        return {
            appointment: {
                userdata: {
                    email: '',
                    firstname: '',
                    lastname: '',
                },
                date: '',
                time_from: '08:00',
                time_to: '08:30'
            }
        }
    }
}
</script>

<style scoped>

</style>

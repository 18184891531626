<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__back">
                <NuxtLink :to="{name: group && group.type === 'job' ? 'account.jobs-catalog' : 'account.groups'}">
                    <ArrowBackIcon heigh="24"/> Back
                </NuxtLink>
            </div>
            <div class="l-account-content__title">Group: {{ group ? group.name : '' }}</div>
            <Btn class="btn btn-outline-danger mt-2" @click="deleteGroup">Delete</Btn>
        </div>

        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <div>
                    <template v-if="group && group.model_type === 'Modules\\Clients\\Entities\\Client'">
                        <template v-if="group.type === 'freelancer'">
                            <FreelancerCard class="mb-2" :client="item" :key="item.id" v-for="item in data.data"/>
                        </template>
                        <template v-else-if="group.type === 'company'">
                            <CompanyCard class="mb-2" :client="item" :key="item.id" v-for="item in data.data"/>
                        </template>
                    </template>
                    <template v-if="group && group.model_type === 'Modules\\Projects\\Entities\\Project'">
                        <template v-if="group.type === 'job'">
                            <JobCard @click="$router.push({name: 'account.jobs.details', params: {id: job.id}})" class="mb-2" :key="job.id" v-for="job in data.data" :job="job">
                                <template #actions>
                                    <JobDropdown  @onReload="getData" @onViewDetails="viewJobDetails(job)" :job="job"/>
                                </template>
                            </JobCard>
                        </template>
                    </template>
                </div>
            </template>

            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>Group is empty</h4>
            </div>
            <div class="d-flex mt-2" v-if="data && data.total > 1">
                <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
            </div>
        </div>

        <ModalSidebar name="JobDetailsModal">
            <JobDetails :item="selected_job" :preview="true"/>
        </ModalSidebar>
    </div>
</template>

<script>

import Btn from "~/components/Btn.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import FreelancerCard from "@/pages/Freelancers/FreelancerCard.vue";
import CompanyCard from "@/components/CompanyCard.vue";
import JobCard from "@/pages/Jobs/JobCard.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import JobDetails from "@/pages/Jobs/JobDetails.vue";

export default {
    name: 'GroupDetails',
    components: {JobDetails, ModalSidebar, JobDropdown, JobCard, CompanyCard, Btn, FreelancerCard},
    mixins: [modelListMixin],
    data () {
        return {
            loading: {
                data: false,
                delete: false,
            },
            group: null,
            selected_job: null,
            filters: {
                group_id: null
            },
        }
    },
    created() {
        this.filters.group_id = this.$route.params.id;
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.loading.data = true;
            this.$axios.post("/account/groups/items", this.filters)
                .then((res) => {
                    this.group = res.group;
                    this.data = res.items
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
        deleteGroup () {
            this.loading.delete = true;
            this.$axios.post("/account/groups/delete", {
                id: this.$route.params.id
            })
                .then((res) => {
                    this.$router.push({name: 'account.groups'})
                    this.loading.delete = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.delete = false;
                });
        },
        viewJobDetails(job) {
            if (job.status >= 2) {
                this.$router.push({name: 'account.jobs.details', params: {id: job.id}})
            }else{
                this.selected_job = job;
                this.$modal.show('JobDetailsModal')
            }
        },
    }
}
</script>

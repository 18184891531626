<template>
    <div>
        <div class="calendar-picker">
            <div class="calendar-picker__month-select">
                <select type="select" class="form-select date-selector" v-model="month">
                    <option :key="index" :value="index" v-for="(month, index) in months">{{ month }}</option>
                </select>
                <select type="select" class="form-select date-selector" v-model="year">
                    <option :key="index" :value="year" v-for="(year, index) in years">{{ year }}</option>
                </select>
<!--                <div class="ml-auto text-nowrap">-->
<!--                    <PreviousIcon style="cursor: pointer" @click="setPrevMonth"/>-->
<!--                    <NextIcon class="ml-2" style="cursor: pointer" @click="setNextMonth"/>-->
<!--                </div>-->
            </div>
            <div class="calendar-picker-month" :class="{'loading': loading}">
                <div class="calendar-picker-week">
                    <div class="calendar-picker-day --weekday" v-for="week_day in week_days">{{ week_day }}</div>
                </div>
                <div class="calendar-picker-week" v-for="week in days">
                    <a href="#" @click.prevent="selectDate(date)"
                       class="calendar-picker-day"
                       v-for="date in week"
                       :class="{'active': date && date.date == selected_date,
                                'today': date && date.date == today,
                                'disabled': date && !date.enabled,
                                '--has-bookings': date && date_bookings && date_bookings[date.date] && date_bookings[date.date] > 0,
                                '--booked': date && availability && availability[date.date] && !availability[date.date].length,
                                '--closed': date && availability && availability[date.date] === null
                        }">
                        {{ date ? date.day : '' }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)


export default {
    name: 'CalendarPicker',
    props: ['value', 'date_bookings', 'minDate', 'maxDate', 'availability', 'loading'],
    data() {
        return {
            calendar: null,
            today: null,
            selected_date: null,
            month: null,
            year: null,
            days: [],
            week_days: [ 'M', 'T', 'W', 'T', 'F', 'S', 'S'],
            months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
            months_options:[
                {'label': 'January', 'value': 0},
                {'label': 'February', 'value': 1},
                {'label': 'March', 'value': 2},
                {'label': 'April', 'value': 3},
                {'label': 'May', 'value': 4},
                {'label': 'June', 'value': 5},
                {'label': 'July', 'value': 6},
                {'label': 'August', 'value': 7},
                {'label': 'September', 'value': 8},
                {'label': 'October', 'value': 9},
                {'label': 'November', 'value': 10},
                {'label': 'December', 'value': 11},
            ]
        }
    },
    created () {
        let today = dayjs();
        this.today = today.format('YYYY-MM-DD')

        if (this.value) {
            let date = dayjs(this.value);
            this.month = date.month();
            this.year = date.year();
            this.selected_date = date.format('YYYY-MM-DD')
        }else{
            this.month = today.month();
            this.year = today.year();
            this.selected_date = today.format('YYYY-MM-DD')
        }


        this.generateCalendar()
    },
    computed: {
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: (year + 10) - 2020}, (value, index) => 2021 + index)
        }
    },
    watch: {
        month() {
            this.generateCalendar()
            this.$emit('changeMonth', {
                year: this.year,
                month: this.month + 1
            });
        },
        selected_date() {
            this.$emit('change', this.selected_date)
        },
        value() {
            if (this.value != this.selected_date) {
                this.selected_date = this.value
            }
        }
    },
    methods: {
        selectDate(date) {
             if (!date.enabled) {
                return false;
            }
            this.selected_date = date.date;
        },
        setNextMonth() {
            if (this.month === 11) {
                this.month = 0;
                this.year += 1;
            } else {
                this.month += 1;
            }
        },
        setPrevMonth() {
            if (this.month === 1) {
                this.month = 11;
                this.year -= 1;
            } else {
                this.month -= 1;
            }
        },
        generateCalendar () {
            this.days = this.getDays()
        },
        getDays() {
            let firstDay = (new Date(this.year, this.month)).getDay() - 1;
            if (firstDay < 0) {
                firstDay = 6
            }

            let day = 1;
            let results = [];
            let lastDay = new Date(this.year, this.month + 1, 0).getDate()
            let minDate = this.minDate ? dayjs(this.minDate) : null;
            let maxDate = this.maxDate ? dayjs(this.maxDate) : null;

            let month = this.$options.filters.pad(this.month + 1, 2)
            weekloop:
            for (let i = 0; i < 6; i++) {
                results.push([])
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDay) {
                        results[i].push(null);
                    } else if (day >= lastDay && j === 6) {
                        results[i].push(null);
                        break weekloop;
                    } else if (day > lastDay) {
                        results[i].push(null);
                    } else {
                        let enabled = (!minDate || dayjs(`${this.year}-${month}-${this.$options.filters.pad(day, 2)}`).isSameOrAfter(minDate, 'date')) && (!maxDate || dayjs(`${this.year}-${month}-${this.$options.filters.pad(day, 2)}`).isSameOrBefore(maxDate, 'date'))
                        results[i].push({
                            day: day,
                            date: `${this.year}-${month}-${this.$options.filters.pad(day, 2)}`,
                            enabled: enabled
                        });
                        day++;
                    }
                }
            }

            return results
        }
    }
}
</script>

<template>
    <div class="p-details-wrapper" :class="{'--gray-bg' : !this.profile}">
        <div class="p-details" :class="{'loading': loading.data, 'container pt-4 pb-4' : !this.profile}">
            <div class="row" v-if="company && client">
                <div class="col-md-5">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="p-details__info">
                                <div class="p-details__info-actions">
                                    <BlockToggle foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.blocked" type="company" class="mr-2"/>
                                    <GroupToggle foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.groups" type="company"/>
                                </div>
                                <a target="_blank" :href="company.website" v-if="company.website">
                                    <CompanyAvatar class="mb-3" size="large" :company="company"/>
                                </a>
                                <CompanyAvatar v-else class="mb-3" size="large" :company="company"/>
                                <div class="p-details__info__title">{{ company.name }}</div>
                                <div class="p-details__info__desc">
                                    {{ company.description }}
                                </div>
                                <div class="p-details__info__stats">
                                    <span v-if="company.address"><LocationIcon/> {{ [company.address.city_name, company.address.country_name].join(', ') }}</span>
                                    <span><StarIcon/> {{ company.avgReview ? company.avgReview : '-' }}/5 <template v-if="company.countReview">({{ company.countReview }})</template></span>
                                </div>
                                <Btn :to="{name: 'add-review', params: {username: client.username}}" class="btn-secondary btn-block mb-1 ml-0" v-if="client.canReview">Review This Company</Btn>
                                <Btn class="btn-secondary btn-block mb-1 ml-0" @click="showSendMessage">Send Message</Btn>
                                <a :href="client.career_page" target="_blank" class="btn btn-outline-light btn-block mb-1 ml-0" v-if="client.attributes.offering_jobs?.value && client.career_page">View Available jobs</a>
                            </div>
                        </div>
                    </div>

<!--                    <div class="card mb-2" v-if="company.employees">-->
<!--                        <div class="card-header">-->
<!--                            <div class="card-title">Employees</div>-->
<!--                        </div>-->
<!--                        <div class="card-body">-->
<!--                            <UserAvatar :display-name="true" :user="client"/>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="p-details__specs">

                                <div class="p-details__specs__label">Color Code Category(s)</div>
                                <div class="p-details__specs__content" v-if="client.attributes.color_code_categories">
                                    <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in client.attributes.color_code_categories" :attr="attr"/>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    None
                                </div>
<!--                                <div class="p-details__specs__label">Services(s)</div>-->
<!--                                <div class="p-details__specs__content" v-if="client.attributes.services">-->
<!--                                    <div class="badge badge-secondary-light badge-lg mr-1 mb-1" v-for="service in client.attributes.services">{{ service.value }}</div>-->
<!--                                </div>-->

                                <div class="p-details__specs__label">Biotech Sector(s) Covered</div>
                                <div class="p-details__specs__content" v-if="client.attributes.biotech_sectors?.length">
                                    <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="biotech_sector in client.attributes.biotech_sectors">{{ biotech_sector.value }}</div>
                                </div>
                                <div class="p-details__specs__content" v-else>
                                    None
                                </div>

<!--                                <div class="p-details__specs__label">Number of Reviews</div>-->
<!--                                <div class="p-details__specs__content">{{ company.countReview }}</div>-->

                                <div class="p-details__specs__label">Established date</div>
                                <div class="p-details__specs__content">{{ company.established_date | moment('MMMM DD, YYYY') }}</div>

<!--                                <div class="p-details__specs__label">Response Time</div>-->
<!--                                <div class="p-details__specs__content">{{ client.attributes.response_time?.value ?? '-' }}</div>-->

<!--                                <div class="p-details__specs__label">Last Online</div>-->
<!--                                <div class="p-details__specs__content">{{ company.online_at | onlineAtFormat }}</div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="card" style="padding-top: 18px;">
                        <div class="p-details__section" id="bio" v-if="company.description?.length">
                            <div class="p-details__section-title">
                                About The Company
                            </div>
                            {{ company.description }}
                        </div>
                        <div class="p-details__section" id="history" v-if="reviews?.length">
                            <div class="p-details__section-title">
                                Reviews
                            </div>
                            <div class="p-details__review" v-for="review in reviews">
                                <div class="p-details__review__rating">
                                    <img :src="review.icon" height="24" width="24" alt=""> {{ review.label }}
                                </div>
                                <div class="p-details__info-item__stats mb-2">
                                    <span style="white-space: nowrap"><Stars :value="review.stars"/> {{ review.stars.toFixed(1) }}</span>
                                    <span class="d-inline-flex align-items-center">Reviewed {{ review.date | momentAgo }} By <UserAvatar class="d-inline-flex ml-1" :displayName="true" :user="review.user" size="xs"/></span>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Do you recommend other People to work with this freelancer in the future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light">{{ review.stats.recommend == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>

                                <div class="p-details__review__question">
                                    <div class="p-details__review__question__label">Will you be working with this Biotech Expert in the Future?</div>
                                    <div class="p-details__review__question__answer">
                                        <Btn class="badge badge-lg badge-secondary-light">{{ review.stats.come_back == 1 ? 'Yes' : 'No' }}</Btn>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-if="total_reviews - reviews.length > 0">
                                <Btn class="btn-light" @click="loadMoreReviews" :loading="loading.load_more">More reviews ({{ total_reviews - reviews.length }})</Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="sendMessageModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('sendMessageModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Send message</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 480px">
                            <FormTextarea class="mb-2" label="Message" v-model="send_message.message"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('sendMessageModal')">Cancel</Btn>
                            <Btn :loading="loading.save" class="btn btn-lg btn-secondary --w150" @click="sendMessage()">Send</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import UserAvatar from "~/components/UserAvatar.vue";
import Btn from "~/components/Btn.vue";
import ProjectInfoCard from "~/components/ProjectInfoCard.vue";
import GroupToggle from "~/components/GroupToggle.vue";
import BlockToggle from "~/components/BlockToggle.vue";
import ColorCodeAttribute from "~/components/ColorCodeAttribute.vue";
import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import dayjs from "dayjs";
import Stars from "../../components/Stars.vue";
import CompanyAvatar from "@/components/CompanyAvatar.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import {mapState} from "vuex";

export default {
    name: 'CompanyDetails',
    components: {
        FormTextarea,
        CompanyAvatar,
        Stars, Tab, Tabs, ColorCodeAttribute, BlockToggle, GroupToggle, ProjectInfoCard, Btn, UserAvatar,
    },
    props: ['modal', 'profile', 'preview'],
    data() {
        return {
            loaded: false,
            loading: {
                data: false,
                reviews: false,
                load_more: false,
                save: false,
            },
            client: null,
            company: null,
            reviews: [],
            total_reviews: 0,
            send_message: {
                message: '',
                project: null
            },
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch();
        }
    },
    activated () {
        // this.sendToAnalytics()
    },
    fetch() {
        if (this.preview && this.profile) {
            this.client = JSON.parse(JSON.stringify(this.profile));
            this.company = JSON.parse(JSON.stringify(this.profile.company));
            this.getFrontAttributes(this.profile.attributes);
            return;
        }
        this.getData();
    },
    methods: {
        getData () {
            this.loading.data = true;
            const username = this.profile && this.modal ? this.profile.username : this.$route.params.username;
            return this.$axios.post('/clients/details', { username })
                .then((res) => {
                    this.client = res.client
                    this.company = res.client.company
                    this.getReviews()
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
        getReviews () {
            this.loading.reviews = true;
            return this.$axios.post('/clients/reviews', { client_id: this.client.id })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.reviews = false;
                })
                .catch((error) => {
                    this.loading.reviews = false;
                });
        },
        loadMoreReviews() {
            this.loading.load_more = true;
            return this.$axios.post('/clients/reviews', {
                client_id: this.company.id,
                after_id: this.reviews[this.reviews.length -1]?.id
            })
                .then((res) => {
                    this.reviews = [...this.reviews, ...res.reviews]
                    this.total_reviews = res.total
                    this.loading.load_more = false;
                })
                .catch((error) => {
                    this.loading.load_more = false;
                });
        },
        getFrontAttributes (attributes) {
            this.loading.data = true;
            return this.$axios.post('/get-front-attributes', {
                attributes: attributes
            })
                .then((res) => {
                    this.client.attributes = res.attributes
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        getDatesDiff(date_from, date_to) {
            date_to = date_to ? dayjs(date_to) : dayjs();
            const months_diff = date_to.diff(date_from, 'month')
            if (months_diff) {
                return `${months_diff} months`;
            }
        },
        showSendMessage() {
            if (!this.user.id) {
                this.$router.push({name: 'auth.login'})
                return;
            }
            this.send_message.message = '';
            this.send_message.project = null;
            this.$modal.show('sendMessageModal')
        },
        sendMessage () {
            this.loading.save = true;
            return this.$axios.post('/messaging/send-message', {
                message: this.send_message.message,
                to_user_id: this.client.id,
                project_id: this.send_message.project?.id
            })
                .then((res) => {
                    this.loading.save = false;
                    this.$modal.hide('sendMessageModal');
                    this.$modal.hide('sendInterviewMessageModal');
                    this.$router.push({name: 'account.messages', params: {ref: res.conversation.ref}})
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
    }
}
</script>

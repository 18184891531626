<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Account Settings</div>
        </div>
        <Tabs wrapperClass="account_tabs">
            <Tab name="General">
                <div class="account-info-card mb-3">
                    <div class="account-info-card__header">
                        <div>Login Details</div>
                    </div>
                    <div class="account-info-card__section">
                        <div class="account-info-card__row">
                            <div class="account-info-card__row-info">
                                <div class="account-info-card__label">Username</div>
                                <div class="account-info-card__value">{{ user.username }}</div>
                            </div>
                            <div class="account-info-card__row-action">
                                <EditIcon class="edit-btn" @click="editUsername()"/>
                            </div>
                        </div>
                        <div class="account-info-card__row" v-if="$auth.can('edit_account')">
                            <div class="account-info-card__row-info">
                                <div class="account-info-card__label">Email</div>
                                <div class="account-info-card__value">{{ user.email }}</div>
                            </div>
                            <div class="account-info-card__row-action">
                                <EditIcon class="edit-btn" @click="editEmail()"/>
                            </div>
                        </div>
                        <div class="account-info-card__row" v-if="$auth.can('edit_account')">
                            <div class="account-info-card__row-info">
                                <div class="account-info-card__label">Password</div>
                                <div class="account-info-card__value">*************</div>
                            </div>
                            <div class="account-info-card__row-action">
                                <EditIcon class="edit-btn" @click="editPassword()"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="settings-card mb-3" v-if="$auth.can('edit_account_status')">
                    <span class="settings-card__title">Deactivate My Account</span>
                    <span class="settings-card__subtitle mt-1 mb-2">
                        This will temporary deactivate your account and you can activate it again.
                    </span>
                    <div class="d-flex align-items-center">
                        <toggle-button class="m-0"
                                       color="#0055E6"
                                       :height="22" :width="40"
                                       v-model="account.status"
                                       :sync="true" @change="(event) => saveAccountStatus(event.value)"
                        />
                        <span class="settings-card__label">Account is {{account.status ? 'Active' : 'Inactive'}} <span class="ml-2" :class="{'loading': loading.status}"></span></span>
                    </div>
                </div>
                <div class="settings-card" v-if="$auth.can('delete_account')">
                    <span class="settings-card__title">Close My account</span>
                    <span class="settings-card__subtitle mt-1 mb-2">
                        This will permanently delete your account and all of its data. You will not be able to reactivate this account.
                    </span>
                    <Btn class="btn btn-sm btn-outline-danger" :loading="loading.deleting" @click="closeAccount">Close My Account</Btn>
                </div>
            </Tab>
            <Tab name="Notifications">
                <Notifications/>
            </Tab>
        </Tabs>

        <modal name="changePasswordModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('changePasswordModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Change your password</h4>
                    </div>
                    <div class="modal-body">
                        <FormInputPassword class="mb-2" type="password" label="Current password" v-model="change_password.old_password" :v="$v.change_password.old_password"/>
                        <FormInputPassword class="mb-2" type="password" label="New password" v-model="change_password.new_password" :v="$v.change_password.new_password"/>
                        <FormInputPassword class="mb-2" type="password" label="Confirm password" v-model="change_password.confirm_new_password" :v="$v.change_password.confirm_new_password"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="changePassword()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="changeEmailModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('changeEmailModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Change your email</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2" type="email"
                                   label="New Email"
                                   v-model="change_email.email"
                                   :v="$v.change_email.email"/>
                        <FormInputPassword class="mb-2" type="password" label="Password" v-model="change_email.password" :v="$v.change_email.password"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="changeEmail()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="changeUsernameModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('changeUsernameModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Change your username</h4>
                    </div>
                    <div class="modal-body">
                        <FormInput class="mb-2"
                                   type="email"
                                   label="New Username"
                                   v-model="change_username.username"
                                   :v="$v.change_username.username"
                        />
                        <FormInputPassword class="mb-2" type="password" label="Password" v-model="change_username.password" :v="$v.change_username.password"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" :disabled="change_username.check_username" @click="changeUsername()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
import HandAndMacbook from "~/assets/icons/account/hands-macbook.svg?inline";
import Btn from "@/components/Btn.vue";
import {mapState} from "vuex";
import Tab from "@/components/Tab.vue";
import Tabs from "@/components/Tabs.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import FormInput from "@/components/FormInput.vue";
import {email, required, sameAs} from "vuelidate/lib/validators";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import Notifications from "@/pages/Account/Notifications.vue";
import FormInputPassword from "@/components/FormInputPassword.vue";

export default {
    name: "Settings",
    components: {FormInputPassword, Notifications, FormInput, UserAvatar, Tabs, Tab, Btn, HandAndMacbook, CloseIcon},
    data() {
        return {
            loading: {
                save: false,
                deleting: false,
                status: false,
                check_username: false,
            },
            account: {
                status: true,
            },
            change_password: {
                old_password: '',
                new_password: '',
                confirm_new_password: '',
            },
            change_email: {
                password: '',
                email: '',
            },
            change_username: {
                password: '',
                username: '',
                check_username: null
            },
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    validations: {
        change_password: {
            old_password: {required},
            new_password: {required},
            confirm_new_password: {required, sameAs: sameAs('new_password')},
        },
        change_email: {
            password: {required},
            email: {required},
        },
        change_username: {
            password: {required},
            username: {required},
        },
    },
    mounted() {
        this.account.status = this.user.status == 1;
    },
    methods: {
        saveAccountStatus(newValue) {
            this.account.status = newValue;
            this.loading.status = true
            this.$axios.post("/account/save-account-status", {status: newValue ? 1 : 0})
                .then((res) => {
                    this.loading.status = false

                })
                .catch((res) => {
                    this.loading.status = false
                    this.account.status = !newValue;
                })
        },
        closeAccount() {
            this.$dialog.confirm('Are you sure you want to delete your account?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading.deleting = true
                    this.$axios.post("/account/delete-account")
                        .then((res) => {
                            this.$auth.destroyToken()
                            this.$acl.change('public');
                            this.$store.dispatch('logout')
                            this.$router.push({ name: 'home' })
                            this.loading.deleting = false
                        })
                        .catch((res) => {
                            this.loading.deleting = false
                        })
                });
        },
        editPassword() {
            this.$modal.show('changePasswordModal')
        },
        changePassword () {
            this.$v.change_password.$touch();
            if (this.$v.change_password.$invalid == true) {
                return false;
            }
            this.loading.save = true;
            this.$axios.post("/account/change-password", this.change_password)
                .then((res) => {
                    this.loading.save = false;
                    this.$modal.hide('changePasswordModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        editEmail() {
            this.$modal.show('changeEmailModal')
        },
        changeEmail () {
            this.$v.change_email.$touch();
            if (this.$v.change_email.$invalid == true) {
                return false;
            }
            this.loading.save = true;
            this.$axios.post("/account/change-email", this.change_email)
                .then((res) => {
                    this.loading.save = false;
                    this.$store.commit('setUser', {email: this.change_email.email})
                    this.$modal.hide('changeEmailModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        editUsername() {
            this.$modal.show('changeUsernameModal')
        },
        changeUsername () {
            this.$v.change_username.$touch();
            if (this.$v.change_username.$invalid == true) {
                return false;
            }
            this.loading.save = true;
            this.$axios.post("/account/change-username", this.change_username)
                .then((res) => {
                    this.loading.save = false;
                    this.$store.commit('setUser', {username: res.username})
                    this.$modal.hide('changeUsernameModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        // checkUsername() {
        //     this.loading.check_username = true;
        //     this.$axios.post("/auth/check-username", {
        //         username: this.register.username,
        //     })
        //         .then((res) =>{
        //             this.change_username.check_username = res.exists;
        //             this.loading.check_username = false;
        //         })
        //         .catch((res) =>{
        //             this.loading.check_username = false;
        //         })
        // }
    }
}
</script>

<style scoped>

</style>

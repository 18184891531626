<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Groups</div>
        </div>

        <Tabs wrapperClass="account_tabs">
<!--            <Tab name="Clients" v-if="['freelancer', 'company'].includes(user.type)">-->
<!--                <GroupList model_type="Modules\Clients\Entities\Client" type="client"/>-->
<!--            </Tab>-->
            <Tab name="Biotech Experts" v-if="['client', 'company'].includes(user.type)">
                <GroupList model_type="Modules\Clients\Entities\Client" type="freelancer"/>
            </Tab>
            <Tab name="Biotech Companies" v-if="['client', 'freelancer'].includes(user.type)">
                <GroupList model_type="Modules\Clients\Entities\Client" type="company"/>
            </Tab>
        </Tabs>
    </div>
</template>

<script>

import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import GroupList from "./GroupList.vue";
import {mapState} from "vuex";

export default {
    name: 'Groups',
    components: {GroupList, Tab, Tabs},
    data () {
        return {

        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    activated () {

    },
    methods: {

    }
}
</script>

<template functional>
    <FormGroup :help="props.help" :label="props.label" :class="[data.staticClass, {'error' : props.v ? props.v.$error : null}]">
        <textarea :rows="props.rows ? props.rows : 5" :type="props.type ? props.type: 'text'" class="form-control"
                  :class="{'is-invalid' : props.v ? props.v.$error : null}"
                  :placeholder="props.placeholder"
                  :value="props.value"
                  :name="props.name"
                  :disabled="props.disabled"
                  :readonly="props.readonly"
                  v-on:input="($event) => {
                      listeners.input && listeners.input($event.target.value);
                      if(props.v){
                           props.v.$touch();
                      }
                  }"
        />
        <div class="text-right text-warning" v-if="props.count_error && props.count < props.value?.length"><small>{{ props.count_error }}</small></div>
        <div class="text-right text-muted" v-if="props.count"><small>{{ props.value ? props.value.length : 0 }}/{{ props.count }}</small></div>
    </FormGroup>
</template>

<script>
    import FormGroup from "./FormGroup.vue";

    export default {
        name: 'FormTextarea',
        components: {FormGroup},
    }
</script>

<template>
    <div v-if="edit_object">
        <FormInput class="mb-2" label="Award Title" placehoder="Title" v-model="edit_object.title"/>
        <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
        <FormInput class="mb-2" label="Awarded by" placehoder="" v-model="edit_object.awarded_by"/>
        <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="Date" :time="false" v-model="edit_object.date"/>
        <FormGroup label="Upload Award Certificate if Applicable">
            <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="awards" target="Modules\Clients\Entities\ClientProfile" :target_id="profile_id"/>
        </FormGroup>
        <Btn class="btn-block btn-secondary-light" :loading="loading_save" @click="saveObject">Save</Btn>
    </div>
</template>

<script>

import Btn from "~/components/Btn.vue";
import FormInput from "@/components/FormInput.vue";
import BadgeSelect from "@/components/BadgeSelect.vue";
import FormTextarea from "@/components/FormTextarea.vue";
import CountrySelect from "@/components/CountrySelect.vue";
import DocumentsUploader from "@/components/DocumentsUploader.vue";
import FormCheckbox from "@/components/FormCheckbox.vue";
import FormDate from "@/components/FormDate.vue";
import FormRadio from "@/components/FormRadio.vue";

export default {
    name: 'AwardsForm',
    components: {
        FormRadio,
        FormDate,
        FormCheckbox,
        DocumentsUploader,
        CountrySelect,
        FormTextarea,
        BadgeSelect,
        FormInput,
        Btn,
    },
    props: ['value', 'profile_id', 'loading_save'],
    data() {
        return {
            edit_object: null,
        }
    },
    mounted () {
        this.edit_object = this.value
    },
    watch: {
        // edit_object(value) {
        //     this.$emit('input', value)
        // },
        value(value) {
            this.edit_object = value
        }
    },
    methods: {
        saveObject() {
            this.$emit('onSave', this.edit_object)
        },
    }
}
</script>

var render = function render(_c,_vm){return _c('form-group',{class:[_vm.data.staticClass, {'error' : _vm.props.v ? _vm.props.v.$error : null}],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('div',{staticClass:"input-group"},[(!!_vm.$slots.prepend)?_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2):_vm._e(),_vm._v(" "),_c('ClientOnly',[_c('flat-pickr',{staticClass:"form-control",attrs:{"value":_vm.props.value,"disabled":_vm.props.disabled,"config":{
                    minDate: _vm.props.minDate,
                    maxDate: _vm.props.maxDate,
                    enableTime: _vm.props.time,
                    allowInput: true,
                    time_24hr: true,
                    altFormat: _vm.props.altFormat ? _vm.props.altFormat : 'd.m.Y H:i',
                    dateFormat: _vm.props.dateFormat ? _vm.props.dateFormat : 'Y-m-d H:i:S',
                    altInput: true
                },"required":_vm.props.required},on:{"input":(value) => {
                    _vm.listeners.input && _vm.listeners.input(value);
                    if(_vm.props.v){
                        _vm.props.v.$touch();
                    }
                }}})],1),_vm._v(" "),(!!_vm.slots.append)?_c('span',{staticClass:"input-group-text"},[_vm._t("append")],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
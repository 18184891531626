<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__title">Projects List</div>
            <div class="l-account-content__toolbar">
                <ToggleNav :options="[
                        {label: 'Active', value: 1},
                        {label: 'Saved', value: 0},
                        {label: 'Archived', value: -3}
                    ]"
                    v-model="filters.status">
                </ToggleNav>
                <Btn :disabled="user.status !== 1" :tooltip="user.status !== 1 ? 'Your accounts is not approved' : null" class="btn btn-secondary mt-3" :to="{name: 'account.projects.create'}"><PlusWhiteIcon stroke="#fff"/> Create a Project</Btn>
            </div>
        </div>
        <div :class="{'loading': loading.data}">
            <div class="projects-card-list" v-if="data && data.data && data.data.length > 0">
                <ProjectCard :project="item" :key="item.id" v-for="item in data.data" class="mb-2">
                    <template #actions>
                        <ProjectDropdown @onViewDetails="viewProjectDetails(item)" @onReload="getData()" :project="item"/>
                    </template>
                </ProjectCard>
            </div>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No projects</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="ProjectDetailsModal" :openLink="{name: 'projects.details', params: {slug: selected_project?.slug}}">
            <ProjectDetails :item="selected_project" :modal="true"/>
        </ModalSidebar>
    </div>

</template>

<script>
import AccountProjectCard from "@/components/AccountProjectCard.vue";
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import ProjectDropdown from "@/components/ProjectDropdown.vue";
import {modelListMixin} from "@/mixins/modelListMixin";
import ProjectDetails from "@/pages/Projects/ProjectDetails.vue";
import AccountProjectSimpleCard from "@/components/AccountProjectSimpleCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import ProjectCard from "@/pages/Projects/ProjectCard.vue";
import {mapState} from "vuex";

export default {
    name: "AccountProjects",
    mixins: [modelListMixin],
    components: {ProjectCard, ModalSidebar, AccountProjectSimpleCard, ProjectDetails, ProjectDropdown, ToggleNav, AccountProjectCard, Btn },
    data() {
        return {
            filters: {
                type: 'project',
                status: 1,
            },
            selected_project: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.data = null;
            this.getPage();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects', this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        viewProjectDetails(project) {
            if (project.status >= 2) {
                this.$router.push({name: 'account.projects.details', params: {id: project.id}})
            }else{
                this.selected_project = project;
                this.$modal.show('ProjectDetailsModal')
            }
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="l-account-content__header">
            <div class="l-account-content__back">
                <NuxtLink :to="{name: 'account.projects'}">
                    <ArrowBackIcon heigh="24"/> Back
                </NuxtLink>
            </div>
            <div class="l-account-content__title">Offers ({{ data ? data.total : 0 }})</div>
            <div v-if="project && project.title">
                <a href="#" @click.prevent="openProjectDetails" class="l-account-content__subtitle text-secondary">For {{ project.title }} <OpenBlueIcon height="16" width="16"/></a>
            </div>
        </div>
        <div :class="{'loading': loading.data}">
            <template v-if="data && data.data && data.data.length > 0">
                <VueTable
                    :data="data.data"
                    :columns="['client', 'price', 'duration', 'hours_required', 'date']">
                    <template #client="{row}">
                        <div @click="viewOfferDetails(row)" style="cursor: pointer">
                            <UserAvatar size="medium" :user="row.client" :subtitle="row.client.username" :displayName="true" :badge="row.invited ? 'Invited for Project' : null"></UserAvatar>
                        </div>
                    </template>
                    <template #price="{row}">
                        <template v-if="row.payment_method === 'hourly'">
                            {{ row.price | toPrice }} / h
                        </template>
                        <template v-else-if="row.payment_method === 'fixed'">
                            {{ row.price | toPrice }}
                        </template>
                    </template>
                    <template #duration="{row}">
                        {{ row.duration_min }} - {{ row.duration_max }} Days
                    </template>
                    <template #hours_required="{row}">
                        {{ row.hours_min }} - {{ row.hours_max }} h/{{ row.hours_type }}
                    </template>
                    <template #date="{row}">
                        {{ row.created_at | moment('DD.MM.YYYY') }}
                    </template>
                    <template #status="{row}">
                        <AcceptedIcon v-if="row.status === '1'" class="mr-1"/>
                        <PendingIcon v-else-if="row.status === '0'" class="mr-1"/>
                        <DeclinedIcon v-else class="mr-1"/>
                        {{ row.status_label }}
                    </template>
                    <template #actions="{row}">
                        <template v-if="row.status === 0">
                            <Btn @click="openRejectModal(row)" :loading="row.loading" class="btn-outline-light">Block</Btn>
                            <Btn @click="acceptOffer(row)" :loading="row.loading"  class="btn-secondary">Accept</Btn>
                        </template>
                        <template v-else-if="row.status === -1">
                            <span class="mr-2"><DeclinedIcon class="mr-1"/> Declined</span>
                            <Btn @click="openRejectReasonModal(row)" class="btn-outline-light">View Reason</Btn>
                        </template>
                        <template v-else-if="row.status === 1">
                            <span class="mr-2"><AcceptedIcon class="mr-1"/> Accepted</span>
                            <ProjectOfferDropdown :offer="row" @onViewDetails="viewOfferDetails(row)" @onReload="getData"/>
                        </template>
                    </template>
                </VueTable>
            </template>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No offers</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="ProjectDetailsModal" :openLink="{name: 'projects.details', params: {slug: project?.slug}}">
            <ProjectDetails :item="project" :modal="true"/>
        </ModalSidebar>
        <ModalSidebar name="ProjectOfferDetailsModal">
            <OfferDetails
                :item="selected_offer"
                @onOpenRejectModal="openRejectModal"
                @onAcceptOffer="acceptOffer"
            />
        </ModalSidebar>

        <modal name="RejectModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Why are you Decline this expert?</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Person is requesting payment outside of BOLG" name="reason" :value="reject_offer.reason" @input="(value) => reject_offer.reason = value" text="Person is requesting payment outside of BOLG"/>
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Profile looks fake" name="reason" :value="reject_offer.reason" @input="(value) => reject_offer.reason = value" text="Profile looks fake"/>
                            <FormRadio class="mb-2" :bordered="true" fieldValue="Other Reasons" name="reason" :value="reject_offer.reason" @input="(value) => reject_offer.reason = value" text="Other Reasons"/>
                            <FormTextarea class="mb-2" label="Please Tell Us More" v-model="reject_offer.reject_reason_details"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <Btn :loading="loading.reject" class="btn btn-lg btn-outline-light --w150" @click="$modal.hide('RejectModal')">Cancel</Btn>
                            <Btn :loading="loading.reject" class="btn btn-lg btn-secondary --w150" @click="rejectOffer()">Reject</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="RejectReasonModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('RejectReasonModal')">
                        <CloseIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Reject Reason</h4>
                    </div>
                    <div class="modal-body">
                        {{ reject_reason }}
                    </div>
                </div>
            </div>
        </modal>
    </div>

</template>

<script>
import PlusWhiteIcon from "~/assets/icons/plus.svg?inline";
import Btn from "~/components/Btn.vue";
import ToggleNav from "@/components/ToggleNav.vue";
import {modelListMixin} from "~/mixins/modelListMixin";
import ProjectCard from "@/pages/Projects/ProjectCard.vue";
import ProjectDropdown from "@/components/ProjectDropdown.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import OpenBlueIcon from "~/assets/icons/open-blue.svg?inline";
import OfferDetails from "@/pages/Account/OfferDetails.vue";
import FormInput from "@/components/FormInput.vue";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import FormTextarea from "@/components/FormTextarea.vue";
import FormRadio from "@/components/FormRadio.vue";
import ProjectDetails from "@/pages/Projects/ProjectDetails.vue";
import VueTable from "@/components/VueTable.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import ProjectOfferDropdown from "@/components/ProjectOfferDropdown.vue";

export default {
    name: "ProjectOffers",
    mixins: [modelListMixin],
    components: {
        ProjectOfferDropdown,
        ModalSidebar,
        VueTable,
        ProjectDetails,
        FormRadio, FormTextarea, FormInput, OfferDetails, UserAvatar, ProjectDropdown, ProjectCard, ToggleNav,
        PlusWhiteIcon, Btn, OpenBlueIcon,  CloseIcon },
    data() {
        return {
            project: null,
            loading: {
                info: false,
                reject: false,
            },
            filters: {
                project_id: null,
            },
            selected_offer: null,
            reject_offer: {
                id: null,
                reason: '',
                reject_reason_details: '',
            },
            reject_reason: '',
        }
    },
    created() {
        this.filters.project_id = this.$route.params.id
        if (this.$route.params.project) {
            this.project = this.$route.params.project;
        }else{
            this.getDetails();
        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.getPage();
        }
    },
    methods: {
        getDetails() {
            this.loading.info = true;
            return this.$axios.post('/account/projects/project-info', { id: this.$route.params.id })
                .then((res) => {
                    this.project = res.project
                    this.loading.info = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.info = false;
                });
        },
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/projects/offers/list', this.filters)
                .then((res) =>{
                    let data =  res.data
                    data.data = data.data.map(o => {
                        o.loading = false;
                        return o;
                    });
                    this.data = data
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        acceptOffer(offer) {
            this.$dialog.confirm('Are you sure you want to approve the offer?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    offer.loading = true;
                    this.$axios.post('/account/projects/offers/approve', {id: offer.id})
                        .then((res) =>{
                            this.getData();
                            offer.loading = false;
                            this.$modal.hide('ProjectOfferDetailsModal')
                        })
                        .catch((error) => {
                            offer.loading = false;
                            if (res.no_payment_methods) {
                                this.$router.push({'name': 'account.payments'});
                            }
                        });
                });
        },
        rejectOffer() {
            this.loading.reject = true;
            this.$axios.post('/account/projects/offers/reject', {
                id: this.reject_offer.id,
                reason: this.reject_offer.reason,
                reject_reason_details: this.reject_offer.reason === 'Other Reasons' ? this.reject_offer.reject_reason_details : '',
            })
                .then((res) =>{
                    this.getData();
                    this.$modal.hide('RejectModal')
                    this.loading.reject = false;
                    this.$modal.hide('ProjectOfferDetailsModal')
                })
                .catch((error) => {
                    this.loading.reject = false;
                });
        },
        openRejectModal(offer) {
            this.reject_offer.id = offer.id;
            this.$modal.show('RejectModal')
        },
        openRejectReasonModal(offer) {
            this.reject_reason = offer.reject_reason;
            this.$modal.show('RejectReasonModal')
        },
        openProjectDetails() {
            this.$modal.show('ProjectDetailsModal')
        },
        viewOfferDetails(offer) {
            this.selected_offer = offer;
            this.$modal.show('ProjectOfferDetailsModal')
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

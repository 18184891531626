<template>
    <multiselect
        v-bind:class="{'error' : v ? v.$error : null}"
        v-model="selected_value"
        :options="options"
        placeholder=""
        selectLabel=""
        deselectLabel=""
        selectedLabel=""
        tag-placeholder=""
        :multiple="true"
        :taggable="true"
        :closeOnSelect="false"
        @tag="addTag"
    >
        <template slot="noResult"> </template>
        <template slot="noOptions"> </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'TagSelect',
    props: ['value', 'v'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: [],
            options: []
        }
    },
    methods: {
        addTag (newTag) {
            this.options.push(newTag)
            this.value.push(newTag)
        }
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            this.$emit('input', option);
        }
    },
};
</script>


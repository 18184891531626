<template>
    <div class="d-flex flex-column" style="flex: 1;">
        <div class="l-auth__onboarding" :class="{'loading': loading.data, '--white-bg': finished || initial}">
            <Bubbles class="l-auth__onboarding-bubbles"/>
            <div v-if="initial">
                <div class="text-center l-auth__onboarding__image">
                    <inline-svg src="/images/new_job.svg"/>
                </div>
                <div class="l-auth__onboarding-title">
                    Post a Project
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="onboarding__project-step">
                            <ArrowCUpIcon style="top: 0" class="onboarding__project-step__arrow"/>
                            <div class="onboarding__project-step__icon">
                                <MessageIcon/>
                            </div>
                            <div class="onboarding__project-step__title">Post a Project</div>
                            <div class="onboarding__project-step__subtitle">Describe your project requirments choose budget and the expertise you need.</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="onboarding__project-step">
                            <ArrowCDownIcon style="top: 74px" class="onboarding__project-step__arrow"/>
                            <div class="onboarding__project-step__icon">
                                <FreelancersIcon/>
                            </div>
                            <div class="onboarding__project-step__title">Connect with biotech experts</div>
                            <div class="onboarding__project-step__subtitle">Receive bids from relevant experts and interact freely with them before hiring.</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="onboarding__project-step" style="margin-bottom: 0">
                            <div class="onboarding__project-step__icon">
                                <SuccessIcon/>
                            </div>
                            <div class="onboarding__project-step__title">Success</div>
                            <div class="onboarding__project-step__subtitle">Verify the project is complete to your satisfaction before paying the expert.</div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <div class="mb-2 mt-2" style="color: #797EA4;"><ClockIcon height="24"/> It takes about 8 minutes</div>
                    <Btn class="btn-secondary" @click="initial = false">Start Posting Project</Btn>
                </div>

            </div>
            <OnboardingCard
                v-else-if="finished"
                title="Great! You’ve successfully posted your project."
                style="width: 680px"
                icon="/images/success_project.svg"
            >
                <div class="mx-auto" style="max-width: 488px">
                    <div class="l-auth__onboarding__success-card mb-4">
                        <div class="l-auth__onboarding__success-card__item">
                            <CheckIcon/>
                            Now you can receive quotes/bids from different Biotech Experts.
                        </div>
                        <div class="l-auth__onboarding__success-card__item">
                            <CheckIcon/>
                            You can invite Biotech Experts for interviews.
                        </div>
                        <div class="l-auth__onboarding__success-card__item">
                            <CheckIcon/>
                            After Completion of the Project:
                            <ul>
                                <li>
                                    You Can Request Revisions
                                </li>
                                <li>
                                    Mark the Work as Complete and Approve the Work So the Money Deposited In Escrow Will Be Released to the Biotech Expert or
                                </li>
                                <li>
                                    Mark the Work as Incomplete.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-center">
                        <Btn class="btn-secondary --w380" @click="goToHome">Go To Home Page</Btn>
                    </div>
                </div>
            </OnboardingCard>
            <Steps :index="step" v-else @stepsChange="stepsChange">

                <Step name="About">
                    <OnboardingCard
                        style="width: 680px"
                        title="About Project"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormInput :count="50" class="mb-2" label="Project Title" placehoder="" v-model="project.title" :v="$v.project.title"/>
                            <FormTextarea :count="500" class="mb-2" label="Description" labelInfo="Optional" placehoder="" v-model="project.description" :v="$v.project.description"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Documents">
                    <OnboardingCard
                        style="width: 932px"
                        title="Additional File(s) Optional"
                        subtitle="You Can Also Attach a File Describing Project Details"
                    >
                        <DocumentsUploader v-model="project.documents" :multiple="true"/>
                    </OnboardingCard>
                </Step>
                <Step name="Skills">
                    <OnboardingCard
                        title="Required Skills and Experience "
                        style="width: 728px"
                    >
                        <div class="mx-auto" style="max-width: 600px">
                            <FormTextarea :count="500" class="mb-2" placehoder="" v-model="project.skills"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="EnglishLevel">
                    <OnboardingCard
                        title="English Language Level"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="education" v-model="project.attributes.english_language_level" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.english_language_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="english_language_level"
                                       v-model="project.attributes.english_language_level"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="PrevExperience">
                    <OnboardingCard
                        title="Education"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="education" v-model="project.attributes.education" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.education"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="education"
                                       v-model="project.attributes.education"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ColorCode">
                    <OnboardingCard
                        title="Color Code Category(s)"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormCheckbox @click="project.attributes.color_code_categories = []" text="Any"/>
                            <FormCheckbox v-for="option in attributes.color_code_categories"
                                          :fieldValue="option"
                                          :key="option.id"
                                          :id="'color_code_categories' + option.id"
                                          name="color_code_categories"
                                          v-model="project.attributes.color_code_categories"
                            >
                                <template v-slot:labelslot>
                                    <div class="o-attribute-option" >
                                        <div class="o-attribute-option__icon" v-if="option.icon">
                                            <XImgset class="img-fluid" :src="option.icon" :width="20" :height="20" :alt="option.name"/>
                                        </div>
                                        <div>
                                            <div class="o-attribute-option__title">{{ option.name }}</div>
                                            <div class="o-attribute-option__desc" v-if="option.subtitle">{{ option.subtitle }}</div>
                                        </div>
                                    </div>
                                </template>
                            </FormCheckbox>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Services">
                    <OnboardingCard
                        title="Service(s)"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <div class="row">
                                <div class="col-md-6">
                                    <FormCheckbox @click="project.attributes.services = []" text="Any"/>
                                </div>
                                <div class="col-md-6" v-for="option in attributes.services">
                                    <FormCheckbox
                                        :id="'services' + option.id"
                                        :fieldValue="option"
                                        :key="option.id"
                                        name="services"
                                        size="lg"
                                        v-model="project.attributes.services"
                                        :text="option.name"/>
                                </div>
                            </div>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ExpertiseLevel">
                    <OnboardingCard
                        title="Expertise Level"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="expertise_level" v-model="project.attributes.expertise_level" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.expertise_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="expertise_level"
                                       v-model="project.attributes.expertise_level"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ExpertiseArea">
                    <OnboardingCard
                        title="Expertise Area(s)"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 580px">
                            <div class="pb-2">
                                <div class="badge badge-xl badge-bordered badge-primary-light mr-1 mb-1" v-for="(expertise_area, index) in project.attributes.expertise_areas">{{ expertise_area.name }} <a @click.prevent="() => project.attributes.expertise_areas.splice(index, 1)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="expertise_areas" class="mb-1" :closeOnSelect="false" :searchOnly="true" @select="(option) => addAttributeOption(option, 'expertise_areas')"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Country">
                    <OnboardingCard
                        title="Choose Expert’s Country(s)"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 580px">
                            <div class="pb-2">
                                <div class="badge badge-xl badge-bordered badge-primary-light mr-1 mb-1" v-for="(location, index) in project.attributes.location">{{ location?.name }} <a @click.prevent="() => project.attributes.location.splice(index, 1)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="location" class="mb-1" :searchOnly="true" @select="(option) => addAttributeOption(option, 'location')"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="HoursWork">
                    <OnboardingCard
                        title="Total Hours of Work"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="hours_of_work" v-model="project.attributes.hours_of_work" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.hours_of_work"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="hours_of_work"
                                       v-model="project.attributes.hours_of_work"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="TotalEarnings">
                    <OnboardingCard
                        title="Total earnings"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="total_earnings" v-model="project.attributes.total_earnings" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.total_earnings"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="total_earnings"
                                       v-model="project.attributes.total_earnings"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="StarRating">
                    <OnboardingCard
                        title="Star Rating"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="rating" v-model="project.attributes.rating" text="Any"/>
                            <FormRadio :bordered="true" :fieldValue="0" name="rating" v-model="project.attributes.rating" text="Not Yet Rated"/>
                            <FormRadio :bordered="true" :fieldValue="i" name="rating" v-model="project.attributes.rating" :id="`rating${i}`" :key="i" v-for="i in 5">
                                <StarIcon :key="n" height="24" width="24" v-for="n in i"/>
                            </FormRadio>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Reviews">
                    <OnboardingCard
                        title="Number of Reviews "
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="reviews" v-model="project.attributes.reviews" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.reviews"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="reviews"
                                       v-model="project.attributes.reviews"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="MemberSince">
                    <OnboardingCard
                        title="Member Since"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="null" name="member_since" v-model="project.attributes.member_since" text="Any"/>
                            <FormRadio :bordered="true" v-for="option in attributes.member_since"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="member_since"
                                       v-model="project.attributes.member_since"
                                       :text="option.name"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Revisions">
                    <OnboardingCard
                        title="Number of Revisions"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" :fieldValue="n" v-for="n in 4" :id="`number_of_revisions${n}`" name="number_of_revisions" v-model="project.attributes.number_of_revisions" :key="n" :text="n"/>
                            <FormInput type="number" class="mb-2" label="Specify" v-model="project.attributes.number_of_revisions"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ServiceLevel">
                    <OnboardingCard
                        title="Service Level of the Project"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 500px">
                            <div class="row">
                                <div class="col-sm-4" v-for="option in attributes.service_level">
                                    <FormRadio :bordered="true"
                                               :fieldValue="option.id"
                                               :key="option.id"
                                               name="service_level"
                                               v-model="project.attributes.service_level"
                                               :text="option.name"/>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="PaymentMethod">
                    <OnboardingCard
                        title="Payment Method"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 700px">
                            <div class="text-center mb-4">
                                <div class="o-price-type-select"
                                     :class="{'active': project.payment_method === 'fixed'}"
                                     @click="project.payment_method = 'fixed'"
                                >
                                    <div class="o-price-type-select__icon">
                                        <LockIcon width="32" height="32"/>
                                    </div>
                                    <div class="o-price-type-select__label">Fixed Price Project</div>
                                </div>
                                <div class="o-price-type-select"
                                     :class="{'active': project.payment_method === 'hourly'}"
                                     @click="project.payment_method = 'hourly'"
                                >
                                    <div class="o-price-type-select__icon">
                                        <Clock2Icon width="32" height="32"/>
                                    </div>
                                    <div class="o-price-type-select__label">Hourly Rate-Based Project</div>
                                </div>
                            </div>

                            <template v-if="project.payment_method === 'fixed'">
                                <div class="form-group mb-5">
<!--                                    <label for="">Minimum and Maximum Budget: <span class="text-primary">{{ project.price_min | toPrice }} - {{ project.price_max | toPrice }}</span></label>-->
                                    <label for="">Minimum and Maximum Budget:</label>
                                    <div class="form-range-input">
                                        <FormInput style="width: 170px" append_text="$" v-model="project.price_min"/>
                                        <span class="form-range-input__separator"> - </span>
                                        <FormInput style="width: 170px" append_text="$" v-model="project.price_max"/>
                                    </div>
                                </div>
                                <div class="form-group">
<!--                                    <label for="">Delivery Time: <span class="text-primary">{{ project.duration_min }}-{{ project.duration_max }} Days</span></label>-->
                                    <label for="">Delivery Time:</label>
                                    <div class="form-range-input">
                                        <FormInput style="width: 170px" v-model="project.duration_min" append_text="days"/>
                                        <span class="form-range-input__separator"> - </span>
                                        <FormInput style="width: 170px" v-model="project.duration_max" append_text="days"/>
                                    </div>
                                </div>
                            </template>

                            <template v-if="project.payment_method === 'hourly'">
                                <div class="form-group mb-5">
                                    <label for="">Minimum and Maximum Hourly Rate:</label>
                                    <div class="form-range-input">
                                        <FormInput style="width: 170px" append_text="$/hr" v-model="project.price_min"/>
                                        <span class="form-range-input__separator"> - </span>
                                        <FormInput style="width: 170px" append_text="$/hr" v-model="project.price_max"/>
                                    </div>
                                </div>
                                <div class="form-group mb-5">
<!--                                    <label for="">Duration of the Project: <span class="text-primary">1-25 Days</span></label>-->
                                    <label for="">Duration of the Project:</label>
                                    <div class="form-range-input">
                                        <FormInput style="width: 170px" v-model="project.duration_min" append_text="days"/>
                                        <span class="form-range-input__separator"> - </span>
                                        <FormInput style="width: 170px" v-model="project.duration_max" append_text="days"/>
                                    </div>
                                </div>
                                <FormGroup label="Number of Hours Required">
                                    <div class="form-range-input">
                                        <FormRadio v-model="project.hours_type" class="mr-2" fieldValue="week" text="Per Week"/>
                                        <FormRadio v-model="project.hours_type" class="mr-2" fieldValue="month" text="Per Month"/>
                                        <FormInput style="width: 120px" v-model="project.hours_min" append_text="h"/>
                                        <span class="form-range-input__separator"> - </span>
                                        <FormInput style="width: 120px" v-model="project.hours_max" append_text="h"/>
                                    </div>
                                </FormGroup>
                            </template>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Visibility">
                    <OnboardingCard
                        title="Project Visibility"
                        subtitle="Please specify who can see your project"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 700px">
                            <div class="o-privacy-select mb-2" :class="{'active': project.privacy === 'public'}" @click="project.privacy = 'public'">
                                <PrivacyInviteIcon/>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Invite All</div>
                                    <div class="o-privacy-select__info-description">Your project is public, and every Biotech Expert can see it and apply to it</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': project.privacy === 'private'}" @click="project.privacy = 'private'">
                                <PrivacyInviteLockIcon/>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Invite certain Biotech Expert</div>
                                    <div class="o-privacy-select__info-description">Only Biotech Experts who I invited</div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
            </Steps>
            <div class="l-auth__footer" v-if="!finished && !initial">
                <div class="l-auth__footer-progress d-flex d-md-none">
                    <div class="progress mr-2" style="width: 55px">
                        <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                    </div>
                    {{ completed }}% Profile Completed
                </div>
                <div class="container l-auth__footer-container">
                    <Btn class="btn-outline-light --w120" :disabled="step == 0" @click="goToPrevStep">Back</Btn>
                    <div class="l-auth__footer-progress d-none d-md-flex">
                        <div class="progress mr-2" style="width: 55px">
                            <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                        </div>
                        {{ completed }}% Process Completed
                    </div>
                    <div style="white-space: nowrap">
                        <Btn class="btn-secondary --w120" @click="goToNextStep" v-if="step < stepsCount - 1">Next</Btn>
                        <Btn class="btn-secondary --w120" :loading="loading.save" @click="saveProject" v-else>Submit</Btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {maxLength, required} from 'vuelidate/lib/validators'

import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";
import BadgeSelect from "~/components/BadgeSelect.vue";
import ImagesUploaderCrop from "~/components/ImageUploaderCrop.vue";
import FormRadio from "~/components/FormRadio.vue";
import FormDate from "~/components/FormDate.vue";
import DocumentsUploader from "~/components/DocumentsUploader.vue";

import PrivacyInviteIcon from "~/assets/icons/privacy_invite.svg?inline";
import PrivacyInviteLockIcon from "~/assets/icons/privacy_invite_lock.svg?inline";
import CheckIcon from "~/assets/icons/check.svg?inline";
import LockIcon from "~/assets/icons/lock.svg?inline";
import Clock2Icon from "~/assets/icons/clock2.svg?inline";
import FormTextarea from "~/components/FormTextarea.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";
import MessageIcon from "~/assets/icons/message.svg?inline";
import FreelancersIcon from "~/assets/icons/freelancers.svg?inline";
import SuccessIcon from "~/assets/icons/success.svg?inline";
import ArrowCUpIcon from "~/assets/icons/arrow-curved-up.svg?inline";
import ArrowCDownIcon from "~/assets/icons/arrow-curved-down.svg?inline";

import Steps from "~/components/Steps.vue";
import Step from "~/components/Step.vue";
import XImgset from "~/components/XImgSet.vue";
import AttributeSelect from "~/components/AttributeSelect.vue";


export default {
    name: 'JobForm',
    components: {
        AttributeSelect,
        XImgset,
        Step,
        Steps,
        FormCheckbox,
        FormTextarea, DocumentsUploader, FormDate, FormRadio, CountrySelect,
        FormInput, ImagesUploaderCrop, BadgeSelect, OnboardingCard, Btn,
        Bubbles, PrivacyInviteIcon, PrivacyInviteLockIcon, CheckIcon, LockIcon, Clock2Icon,
        MessageIcon, FreelancersIcon, SuccessIcon, ArrowCUpIcon, ArrowCDownIcon
    },
    head: {
        title: 'Job Form - BOLG',
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            stepsCount: 0,
            step: 0,
            initial: false,
            finished: false,
            attributes: {},
            project: {
                id: null,
                type: "job",
                title: "",
                description: "",
                documents: [],
                skills: '',
                privacy: 'public',
                invitations: [],
                price_min: 50,
                price_max: 5000,
                duration_min: 1,
                duration_max: 25,
                hours_type: 'week',
                hours_min: 1,
                hours_max: 25,
                payment_method: 'fixed',
                attributes: {
                    english_language_level: null,
                    education: null,
                    color_code_categories: [],
                    services: [],
                    expertise_level: null,
                    expertise_areas: [],
                    location: [],
                    hours_of_work: null,
                    total_earnings: null,
                    rating: null,
                    reviews: null,
                    member_since: null,
                    number_of_revisions: '',
                    service_level: null,
                }
            }
        }
    },
    validations: {
        project: {
            title: {required, maxLength: maxLength(50)},
            description: {maxLength: maxLength(500)},
        },
        steps0: ['project.title', 'project.description']
    },
    computed: {
        completed() {
            return (this.step / (this.stepsCount - 1) * 100).toFixed(0);
        },
    },
    created: function () {
        if (!this.$route.params.id) {
            this.initial = true
        }
        this.getData();
    },
    methods: {
        goToPrevStep() {
            if (this.loading.data) {
                return;
            }
            if (this.step > 0) {
                this.step--
            }
        },
        goToNextStep() {
            if (this.$v[`steps${this.step}`]) {
                this.$v[`steps${this.step}`].$touch();
                if (this.$v[`steps${this.step}`].$invalid === true) {
                    return false;
                }
            }
            if (this.loading.data) {
                return;
            }
            if (this.step <= this.stepsCount) {
                this.step++;
            }
        },
        async getData() {
            this.loading.data = true;
            await this.$axios.post("/api/get-attributes-options", {
                attribute_code: ['english_language_level', 'education', 'color_code_categories', 'services', 'expertise_level',
                    'hours_of_work', 'total_earnings', 'rating', 'reviews', 'member_since', 'service_level']
            })
                .then((res) => {
                    this.attributes = res;
                })

            if (this.$route.params.id) {
                await this.$axios.post("/account/projects/data", {
                    id: this.$route.params.id
                })
                    .then((res) => {
                        let data = res.data;
                        data.price_min = data.price[0];
                        data.price_max = data.price[1];
                        delete data.price;
                        data.duration_min = data.duration[0];
                        data.duration_max = data.duration[1];
                        delete data.duration;
                        data.hours_min = data.hours[0];
                        data.hours_max = data.hours[1];
                        delete data.hours;
                        this.project = {...this.project, ...res.data}
                    });
            }
            this.loading.data = false;
        },
        saveProject() {
            this.loading.save = true;
            this.$axios.post("/account/projects/save", this.project)
                .then((res) => {
                    this.loading.save = false;
                    this.finished = true;
                })
                .catch((res) => {
                    this.loading.save = false;
                })
        },
        goToHome() {
            this.$router.push({name: 'home'})
        },
        stepsChange(count) {
            this.stepsCount = count
        },
        addAttributeOption(option, attribute) {
            if (!this.project.attributes[attribute].some(i => i.id == option.id)) {
                this.project.attributes[attribute].push(option);
            }
        },
    }
}
</script>

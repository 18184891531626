<template>
    <div class="">
        <div class="container">
            <div class="l-page-content">
                <div class="row">
                    <div class="col-md-6 d-flex flex-column">
                        <div class="l-page-title">
                            <span class="--underlined">Learning</span> materials
                        </div>
                        <div style="flex: 1; display: flex;align-items: flex-end;">
                            <div class="tabs">
                                <ul class="nav">
                                    <li class="nav-item">
                                        <NuxtLink :to="{name: 'blog.articles'}" class="nav-link">Articles</NuxtLink>
                                    </li>
                                    <li class="nav-item">
                                        <NuxtLink :to="{name: 'blog.videos'}" class="nav-link">Videos</NuxtLink>
                                    </li>
                                    <li class="nav-item">
                                        <NuxtLink :to="{name: 'blog.animations'}" class="nav-link">Animations</NuxtLink>
                                    </li>
                                    <li class="nav-item">
                                        <NuxtLink :to="{name: 'blog.courses'}" class="nav-link">Online Courses</NuxtLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="/images/blog.png">
                    </div>
                </div>
            </div>
            <div>
                <NuxtChild :key="$route.path" keep-alive :nuxt-child-key="$route.path"/>
            </div>

            <Newsletter/>
        </div>
    </div>
</template>

<script>

import Newsletter from "../../partials/Newsletter.vue";

export default {
    name: 'ArticlesListLayout',
    components: {Newsletter},
    props: ['data'],
}
</script>

<template>
    <div class="header__actions-item" v-if="user_loaded" :class="{'active': user, 'loading-icon': loading.user}">
        <client-only>
            <tippy ref="dropdown"
                   v-if="user && user.id"
                   class="dropdown"
                   trigger="click"
                   placement="bottom-end"
                   theme="light"
                   :interactive="true"
                   :arrow="true"
            >
                <template #trigger>
                    <a class="header__actions-item__btn --user-btn" role="button">
                        <div class="user-avatar mr-2">
                            <img v-if="user.isAgency" :src="user.company?.image ? $utils.getImage(user.company.image, 32, 32, false) : '/images/avatar.png'" class="user-avatar-img">
                            <img v-else-if="user.image" :src="$utils.getImage(user.image, 32, 32, false)" class="user-avatar-img">
                            <img v-else src="/images/avatar.png" height="32" width="32" class="user-avatar-img">
                        </div>
                        <span class="d-none d-md-inline header-user-name">{{ user.isAgency ? user.company.name : user.firstname }}</span>
                        <CaretIcon/>
                    </a>
                </template>
                <div class="c-dropdown-menu --user-menu">
                    <div class="c-dropdown-menu__item --header">
                        <div style="width: 100%">
                            <div class="user-avatar mb-2">
                                <div class="user-avatar mr-2">
                                    <img v-if="user.isAgency" :src="user.company?.image ? $utils.getImage(user.company.image, 40, 40, false) : '/images/avatar.png'" class="user-avatar-img mr-2">
                                    <img v-else-if="user.image" :src="$utils.getImage(user.image, 40, 40, false)" class="user-avatar-img mr-2">
                                    <img v-else src="/images/avatar.png" height="40" width="40" class="user-avatar-img">
                                </div>
                                <span>{{ user.isAgency ? user.company.name : user.firstname }}</span>
                            </div>

                            <Btn :to="{name: 'account.dashboard'}" class="btn-block btn-white">Account Settings</Btn>
                        </div>
                    </div>
                    <a @click="selectProfile(profile)" class="c-dropdown-menu__item" v-if="profile.client.id !== user.id" v-for="profile in profiles">
                        <div class="user-avatar mr-2">
                            <img v-if="profile.client.isAgency" :src="profile.client.company?.image ? $utils.getImage(profile.client.company.image, 40, 40, false) : '/images/avatar.png'" class="user-avatar-img mr-2">
                            <img v-else-if="profile.client.image" :src="$utils.getImage(profile.client.image, 40, 40, false)" class="user-avatar-img mr-2">
                            <img v-else src="/images/avatar.png" height="40" width="40" class="user-avatar-img">
                            <span>{{ getClientName(profile.client) }}</span>
                        </div>
                    </a>
                    <a class="c-dropdown-menu__item --footer" @click="logout"><LogOutIcon height="16" width="16"/> Logout</a>
                </div>
            </tippy>
            <template v-else>
                <nuxt-link class="header__actions-item__btn btn btn-outline-light" role="button" :to="{name: 'auth.login'}">
                    <UserIcon height="20"/>
                    Login
                </nuxt-link>
                <nuxt-link class="header__actions-item__btn btn btn-primary" role="button" :to="{name: 'auth.user-type'}">
                    <b>Sign up</b>
                </nuxt-link>
            </template>

        </client-only>
    </div>
</template>

<script>

import {mapState} from 'vuex'
import Btn from "@/components/Btn.vue";
import {getClientName} from "../utils/helpers";

export default {
    name: 'AccountDropdown',
    components: {Btn},
    watch: {
        '$route': function () {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    },
    computed: {
        ...mapState({
            user: state => state.user,
            profiles: state => state.profiles,
            user_loaded: state => state.loaded.user,
            loading: state => state.loading,
        })
    },
    methods: {
        getClientName,
        logout() {
            this.$store.dispatch('logout')
            this.$auth.destroyToken()
            this.$acl.change('public');
            if (this.$route.meta.rule === 'isLogged' && this.$route.meta.rule !== 'isAny') {
                this.$router.push({name: 'auth.login'})
            }
        },
        async selectProfile(profile) {
            this.$auth.setProfile(profile)
            this.$auth.setRole(profile.role)
            if (this.$route.name !== 'account.dashboard') {
                await this.$router.push({name: 'account.dashboard'});
            }
            window.location.reload()
        },
    },
}
</script>

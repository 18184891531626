<template>
    <div>
        <template v-if="data && data.data && data.data.length > 0">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Invoice No.</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th width="140"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="invoice in data.data" v-bind:key="invoice.id">
                        <td>
                            <div class="d-flex align-items-center">
                                <InvoiceIcon class="mr-1"/>
                                <span>Invoice No. </span>
                                {{ invoice.number }}
                            </div>
                        </td>
                        <td>
                            ${{ invoice.amount }}
                        </td>
                        <td>
                            {{ invoice.date }}
                        </td>
                        <td>
                            {{ invoice.due_date }}
                        </td>
                        <td>
                            {{ invoice.recipient }}
                        </td>
                        <td>
                            <template v-if="invoice.status == 0">
                                <Btn class="btn btn-outline-light" @click="cancelInvoice(invoice)">Reject</Btn>
                            </template>
                            <Btn class="btn btn-secondary" @click="downloadInvoice(invoice)">Download</Btn>
                            <template v-if="invoice.status == 0">
                                <Btn class="btn btn-primary" @click="payInvoice(invoice)">Pay Now</Btn>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <div class="card-body" v-else-if="data && !data.data.length">
            <h4>No invoices</h4>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
    </div>
</template>

<script>
import InvoiceIcon from "~/assets/icons/account/invoice.svg?inline";
import Btn from "~/components/Btn.vue";
import {modelListMixin} from "@/mixins/modelListMixin";

export default {
    name: "InvoicesList",
    props: ['project_id'],
    mixins: [modelListMixin],
    components: { InvoiceIcon, Btn },
    data() {
        return {

        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            const project_id = this.project_id
            this.$axios.post("/account/projects/invoices", {
                project_id: project_id
            })
                .then((res) => {
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        downloadInvoice($invoice) {

        },
        cancelInvoice($invoice) {
            $invoice.loading = true;
            const project_id = this.project_id
            this.$axios.post("/account/projects/invoices", {
                project_id: project_id
            })
                .then((res) => {
                    this.data = res.data;
                    $invoice.loading = false;
                })
                .catch((error) => {
                    $invoice.loading = false;
                });
        },
        payInvoice($invoice) {

        },
    },
    mounted() {
        this.getData();
    }
}
</script>

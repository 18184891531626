<template>
    <div class="l-projects-sorting">
        <a class="l-projects-sorting__item" v-for="value in values" @click="setSorting(value)">{{ value.label }}</a>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'SortingDropdownMobile',
    data() {
        return {
            sorting: 1,
        }
    },
    computed: {
        ...mapState({
            value: state => state.itemsSort,
            values: state => state.itemsSortOptions,
        })
    },
    methods: {
        setSorting(value){
            this.$emit('onChange', value)
            localStorage.setItem('sort', JSON.stringify(value))
            this.$store.commit('setSort', value)
        }
    }
}
</script>

(function (Vue) {
    let settings_list = [];
    let global_settings = {
        stripe: {
            key: ''
        }
    }

    const Settings = {
        install: function (Vue, options) {
            Vue.prototype.$settings = this
            Vue.$settings = this
        },
        setSettings: function (settings) {
            settings_list = settings;
        },
        removeSettings: function () {
            settings_list = [];
        },
        getGlobalValue: function (module, setting) {
            if (typeof global_settings[module] === "undefined") {
                return false;
                    }
            return global_settings[module][setting];
        },
        getValue: function(module, setting) {
            if (typeof settings_list[module] === "undefined") {
                return false;
            }
            return settings_list[module][setting];
        },
        isActive: function(module) {
            if (typeof settings_list[module] === "undefined") {
                return false;
            }
            return settings_list[module].module_status;
        },
    };

    if (typeof exports == "object") {
        module.exports = Settings;
    }
})();

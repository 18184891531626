<template>
    <div>
        <div class="l-page-hero --small --blue">
            <div>
                <div class="l-page-hero__title mb-2">Ready to get <span class="--underlined">started</span>?</div>
                <div class="l-page-hero__subtitle">Choose a Plan That Suits Your Requirements</div>
                <div class="text-center">
                    <div class="l-page-hero__toggle-wrapper">
                        <SwirleIcon class="l-page-hero__toggle-swirle"/>
                        <div class="l-page-hero__toggle-discount">20% Off</div>
                        <ToggleNav class="l-page-hero__toggle"
                                   :options="[
                                       {label: 'Monthly', value: 'month'},
                                       {label: 'Annualy', value: 'year'}
                                   ]"
                                   v-model="frequency"
                        >
                        </ToggleNav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" :class="{'loading': loading.data}">
            <div class="l-page-content mt-4 mb-5">
                <div class="row plans-list">
                    <div class="col-md-3" v-for="plan in plans" v-if="!plan.frequency || plan.frequency == frequency">
                        <div class="plan-card" :class="{'popular': plan.name === 'Silver'}">
                            <div class="plan-card__name">{{ plan.name }} <span v-if="plan.name === 'Silver'" class="popular-badge"><PopularIcon/> Popular</span></div>
                            <div class="plan-card__price">
                                <template v-if="plan.price">{{ plan.price | toPrice }}<small> / {{ frequency === 'year' ? 'Year' : 'Month' }}</small></template>
                                <template v-else>Free<small> / All Time</small></template>
                                <div v-if="plan.taxes.total"><small>{{ plan.price_with_taxes | toPrice}} (incl. Taxes) / {{ frequency === 'year' ? 'Year' : 'Month' }}</small></div>
                            </div>
                            <ul class="plan-card__list">
                                <li v-if="typeof plan.settings.limit_job_applications != 'undefined'"><Check2Icon/><template v-if="plan.settings.limit_job_applications"> You can apply to {{ plan.settings.limit_job_applications }} jobs</template><template v-else>You can apply to unlimited number of jobs</template></li>
                                <li v-if="typeof plan.settings.limit_projects != 'undefined'"><Check2Icon/><template v-if="plan.settings.limit_projects"> Post {{ plan.settings.limit_projects }} projects</template><template v-else>Post unlimited number of projects</template></li>

                                <li v-if="typeof plan.settings.display_logo != 'undefined'"><Check2Icon/><template v-if="plan.settings.display_logo"> The logo will be featured beside the written company name</template><template v-else>The written company name will appear (without logo) and be linked to the homepage</template></li>

                                <li v-if="typeof plan.settings.limit_invitations != 'undefined'"><Check2Icon/><template v-if="plan.settings.limit_invitations">You can send {{ plan.settings.limit_invitations }} invitations per project </template><template v-else>You can send unlimited number of invitations per project</template></li>
                                <li v-if="typeof plan.settings.limit_messages != 'undefined'"><Check2Icon/><template v-if="plan.settings.limit_messages">You can send {{ plan.settings.limit_messages }} messages per day </template><template v-else>You can send unlimited number of messages per day</template></li>
                                <li v-if="typeof plan.settings.limit_description != 'undefined'"><Check2Icon/>The company description is up to {{ plan.settings.limit_description }} characters (with spaces)</li>
                                <li v-if="typeof plan.settings.limit_addresses != 'undefined'"><Check2Icon/>{{plan.settings.limit_addresses > 1 ? 'Up to ' + plan.settings.limit_addresses : '1' }} company locations including the country and city can be added</li>

                                <li v-if="typeof plan.settings.bid_rate != 'undefined'"><Check2Icon/>You can see the bid rate (maximum and minimum) and have a featured profile</li>
                                <li v-if="typeof plan.settings.logo_link != 'undefined'"><Check2Icon/>The logo will link to the company home page</li>
                                <li v-if="typeof plan.settings.promoted != 'undefined'"><Check2Icon/>The company will appear near/at the top of the search list</li>
                                <li v-if="typeof plan.settings.background != 'undefined'"><Check2Icon/>The company background will be a {{ plan.settings.background }} color.</li>
                            </ul>
                            <Btn v-if="user.id"  @click="selectPlan(plan)" :loading="plan.loading" :disabled="loading.select" :class="selected_plan?.id === plan.id ? 'btn-secondary' : (plan.name === 'Silver' ? 'btn-white' : 'btn-outline-secondary')">{{ selected_plan?.id === plan.id ? 'Selected' : 'Choose Plan' }}</Btn>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2 text-right">
                    <Btn class="btn-secondary" :disabled="!selected_plan" :loading="loading.select" @click="activatePlan">Activate Plan</Btn>
                </div>
            </div>
        </div>
        <PaymentMethodCard/>
    </div>
</template>

<script>

import FormInput from "../components/FormInput.vue";
import Btn from "../components/Btn.vue";
import ToggleNav from "../components/ToggleNav.vue";
import Check2Icon from "~/assets/icons/check-circle2.svg?inline";
import PopularIcon from "~/assets/icons/popular.svg?inline";
import SwirleIcon from "~/assets/icons/swirle.svg?inline";
import {mapState} from "vuex";
import PaymentMethodCard from "@/components/PaymentMethodCard.vue";
import {paypalMixin} from "~/mixins/paypalMixin";

export default {
    name: 'SubscriptionPlans',
    mixins: [paypalMixin],
    components: {PaymentMethodCard, ToggleNav, Btn, FormInput, Check2Icon, PopularIcon, SwirleIcon},
    props: ['data'],
    data() {
        return {
            loading: {
                data: false,
                select: false,
            },
            frequency: 'month',
            plans: [],
            selected_plan: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    watch: {
        frequency() {
            this.selected_plan = null;
        },
    },
    async fetch () {
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        async getData () {
            this.loading.data = true;
            await this.$axios.get('/subscription-plans')
                .then((res) => {
                    this.plans = res.data
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        selectPlan(plan) {
            this.selected_plan = plan
        },
        async showPaypal() {
            await window.paypal.Buttons({
                createSubscription: async (data, actions) => {
                    return await this.$axios.post('/account/subscriptions/paypal/select-subscription-plan', {
                        plan_id: this.selected_plan.id,
                    })
                        .then((res) => {
                            return res.subscription.paypal_id
                        })
                        .catch((error) => {
                            console.log(error)
                            this.loading.select = false;
                        });
                },
                onApprove: async (data, actions) => {
                    return await this.$axios.post('/account/subscriptions/paypal/approve-subscription', {
                        id: data.subscriptionID,
                        order_id: data.orderID
                    })
                        .then((res) => {
                            this.$modal.hide('paypalModal');
                            this.$router.push({name: 'account.payments'});
                            this.loading.select = false;
                        })
                        .catch((error) => {
                            console.log(error)
                            this.loading.select = false;
                        });
                }
            }).render('#paypal-button-container');
        },
        async activatePlan() {
            if (!this.selected_plan) {
                return;
            }
            this.loading.select = true;

            if (this.selected_plan.price === 0) {
                return await this.$axios.post('/account/subscriptions/paypal/select-subscription-plan', {
                    plan_id: this.selected_plan.id,
                })
                    .then((res) => {
                        this.$router.push({name: 'account.payments'});
                        this.loading.select = false;
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading.select = false;
                    });
                return;
            }
            await this.initPaypal({components: ['buttons'], vault: true});
            this.openPaypalPopup();
        },
    }
}
</script>

var render = function render(_c,_vm){return _c('FormGroup',{staticClass:"form-input",class:[_vm.data.staticClass],attrs:{"help":_vm.props.help,"label":_vm.props.label}},[_c('div',{staticStyle:{"min-height":"38px","display":"flex","align-items":"center"}},[_c('div',{staticClass:"checkbox checkbox-primary"},[_c('input',{staticClass:"form-check-input",class:{'is-invalid' : _vm.props.v ? _vm.props.v.$error : null},attrs:{"id":_vm.props.id ? _vm.props.id : _vm.props.text ? _vm.props.text.replace(/[^\w-]+/g,'') : _vm.props.label.replace(/[^\w-]+/g,''),"type":"checkbox","name":_vm.props.name,"readonly":_vm.props.readonly,"disabled":_vm.props.disabled},domProps:{"checked":Array.isArray(_vm.props.value) ? _vm.props.value.findIndex(v => {
                       if(typeof v === 'object'){
                            return v.id == _vm.props.fieldValue.id;
                       }
                       return v == _vm.props.fieldValue;
                   }) > -1 : _vm.props.value},on:{"change":($event) => {
                       var $$a = _vm.props.value,
                           value,
                           $$i,
                           $$el = $event.target,
                           $$c = $$el.checked ? true : false;
                       if (Array.isArray($$a)) {
                           value = $$a
                           if ($$el.checked) {
                               if(typeof _vm.props.fieldValue === 'object'){
                                  $$i = $$a.findIndex( v => v.id == _vm.props.fieldValue.id);
                               }else{
                                  $$i = $$a.indexOf(_vm.props.fieldValue);
                               }
                               if($$i === -1){
                                   value = $$a.concat([_vm.props.fieldValue])
                               }
                           } else {
                                if(typeof _vm.props.fieldValue === 'object'){
                                  $$i = $$a.findIndex( v => v.id == _vm.props.fieldValue.id);
                               }else{
                                  $$i = $$a.indexOf(_vm.props.fieldValue);
                               }
                               if($$i > -1){
                                   value = $$a.slice(0, $$i).concat($$a.slice($$i + 1));
                               }
                           }
                       } else {
                           value = $$c;
                       }
                       _vm.listeners.input && _vm.listeners.input(value);
                       _vm.listeners.change && _vm.listeners.change(value);
                       if(_vm.props.v){
                           _vm.props.v.$touch();
                       }
                  }}}),_vm._v(" "),_c('label',{staticClass:"form-check-label",class:{'--lg': _vm.props.size === 'lg'},attrs:{"for":_vm.props.id ? _vm.props.id : _vm.props.text ? _vm.props.text.replace(/[^\w-]+/g,'') : _vm.props.label.replace(/[^\w-]+/g,'')}},[_vm._v(_vm._s(_vm.props.text)+" "),_vm._t("labelslot")],2),_vm._v(" "),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
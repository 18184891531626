<template functional>
    <form-group :label="props.label" :help="props.help" class="form-input" v-bind:class="[data.staticClass, {'error' : props.v ? props.v.$error : null}]">
        <select class="form-control"
                :class="props.inputClass"
                :placeholder="props.label"
                :value="props.value"
                :name="props.name"
                :disabled="props.disabled"
                :readonly="props.readonly"
                v-on:change="($event) => {
                    listeners.input && listeners.input($event.target.value);
                    listeners.change && listeners.change($event.target.value);
                    if(props.v){
                       props.v.$touch();
                    }
                }"
            >
            <option disabled v-if="props.placeholder">{{ props.placeholder }}</option>
            <template v-for="option in props.options">
                <option v-if="typeof option === 'object'" :value="option.value">{{ option.label }}</option>
                <option v-else :value="option">{{ option }}</option>
            </template>
        </select>
    </form-group>
</template>

<script>
    export default {
        name: 'FormSelect',
    }
</script>

<template>
    <div class="app-wrapper">
        <div class="header-wrapper">
            <div class="container">
                <div class="header">
                    <div class="header__logo">
                        <NuxtLink :to="{name: 'home'}">
                            <img class="img-fluid" src="/assets/logo.svg" :width="438" :height="150" alt="BOLG"/>
                        </NuxtLink>
                    </div>
                    <div class="header__actions text-right">

                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper d-flex flex-column" id="contentWrapper" :class="{'loading': loadingInit}">
            <template v-if="inited">
<!--            <FadeInOut entry="center" exit="center" :duration="200"  mode="out-in">&ndash;&gt;-->
                    <NuxtChild keep-alive :nuxt-child-key="$route.meta.routeKey ? $route.meta.routeKey : $route.path"/>
<!--            </FadeInOut>-->
            </template>
        </div>
    </div>
</template>

<script>
    import { initMixin } from '@/mixins/initMixin'
    import {metaMixin} from "@/mixins/metaMixin";

    export default {
        name: 'LayoutAuth',
        mixins: [initMixin, metaMixin],
        head: {
            title: 'Authentication - BOLG',
        },
    }
</script>


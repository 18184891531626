export const profile_objects = {
    employment_history: {
        name: '',
        type: '',
        description: '',
        company: '',
        city_name: '',
        county_name: '',
        country: null,
        period: {
            from: null,
            to: null,
            present: false
        },
        documents: []
    },
    education_history: {
        level: null,
        level_other: '',
        description: '',
        institute: '',
        city_name: '',
        country: null,
        period: {
            from: null,
            to: null,
            present: false
        },
        documents: []
    },
    certificates: {
        title: null,
        description: '',
        issued_by: '',
        period: {
            from: null,
            to: null,
            present: false
        },
        documents: []
    },
    publications: {
        title: null,
        authors: [],
        description: '',
        year: '',
        volume_number: '',
        issue_number: '',
    },
    awards: {
        title: null,
        description: '',
        awarded_by: '',
        date: '',
        documents: []
    },
}

var render = function render(_c,_vm){return _c('form-group',{staticClass:"form-input",class:[_vm.data.staticClass, {'error' : _vm.props.v ? _vm.props.v.$error : null}],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('select',{staticClass:"form-control",class:_vm.props.inputClass,attrs:{"placeholder":_vm.props.label,"name":_vm.props.name,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"change":($event) => {
                _vm.listeners.input && _vm.listeners.input($event.target.value);
                _vm.listeners.change && _vm.listeners.change($event.target.value);
                if(_vm.props.v){
                   _vm.props.v.$touch();
                }
            }}},[(_vm.props.placeholder)?_c('option',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.props.placeholder))]):_vm._e(),_vm._v(" "),_vm._l((_vm.props.options),function(option){return [(typeof option === 'object')?_c('option',{domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))]):_c('option',{domProps:{"value":option}},[_vm._v(_vm._s(option))])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
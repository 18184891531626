var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"drop":function($event){$event.preventDefault();return _vm.addImage.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"image-uploader-crop mx-auto",class:{'loading' : _vm.loading.upload},style:(_vm.imageSize)},[_c('ClientOnly',[(_vm.image)?[_c('cropper',{ref:"cropper",attrs:{"src":_vm.image.base_file,"image-restriction":"stencil","canvas":false,"stencil-props":{
                             handlers: {},
                             movable: false,
                             resizable: false,
                         },"minWidth":_vm.size,"minHeight":_vm.size,"stencilComponent":_vm.CircleStencil,"stencil-size":{
                             width: _vm.size,
                             height: _vm.size
                         },"resize-image":{
                             adjustStencil: false
                        }},on:{"ready":_vm.setCoordinates}})]:_c('ImageIcon',{attrs:{"width":"24","height":"24"}})],2)],1),_vm._v(" "),(_vm.image)?_c('label',{staticStyle:{"cursor":"pointer","display":"block"}},[_c('input',{ref:"input",staticStyle:{"display":"none"},attrs:{"type":"file","name":"file","accept":"image/*","multiple":false,"disabled":_vm.loading.upload},on:{"change":_vm.addImage}}),_vm._v(" "),_c('div',{staticClass:"btn btn-block btn-outline-light"},[_vm._v("Change Photo")])]):_c('label',{staticStyle:{"cursor":"pointer","display":"block"}},[_c('input',{ref:"input",staticStyle:{"display":"none"},attrs:{"type":"file","name":"file","accept":"image/*","multiple":false,"disabled":_vm.loading.upload},on:{"change":_vm.addImage}}),_vm._v(" "),_c('div',{staticClass:"btn btn-block btn-outline-light"},[_vm._v("Select Photo")])]),_vm._v(" "),(_vm.image)?_c('Btn',{staticClass:"btn-block btn-secondary-light",attrs:{"loading":_vm.loading.save},on:{"click":_vm.saveCrop}},[_vm._v("Save Photo Position")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
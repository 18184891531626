<template>
    <div class="o-dropdown">
        <ClientOnly>
            <tippy ref="dropdown" class="dropdown" trigger="click" placement="bottom" :interactive="true" theme="light" :arrow="true">
                <template #trigger>
                    <EditDotsIcon height="24"/>
                </template>
                <div class="c-dropdown-menu" :class="{'loading': this.loading}">
                    <a href="#" @click.prevent="openDetails" class="c-dropdown-menu__item">View Project Details</a>
                    <template v-if="user.type === 'freelancer'">
                        <NuxtLink :to="{name: 'add-review', params: { username: job.client.username, applicationId: job.application_id }}" v-if="job.status === 3 && job.can_review" class="c-dropdown-menu__item">Leave Review</NuxtLink>
                    </template>
                    <template v-else-if="user.type === 'client'">
                        <NuxtLink :to="{name: 'account.jobs.form', params: { id: job.id }}" v-if="job.status < 2" class="c-dropdown-menu__item">Edit Project</NuxtLink>
                        <a href="#" class="c-dropdown-menu__item" v-if="job.status === 2" @click.prevent="markAsAchieved()">Mark as Achieved</a>
                        <NuxtLink :to="{name: 'add-review', params: { username: job.freelancer.username, applicationId: job.application_id }}" v-if="job.status === 3 && job.can_review"  class="c-dropdown-menu__item">Leave Review</NuxtLink>
                        <a href="#" class="c-dropdown-menu__item text-danger" @click.prevent="deleteProject()">Delete Project</a>
                    </template>
                </div>
            </tippy>
        </ClientOnly>
    </div>

</template>

<script>

import {mapState} from "vuex";

export default {
    name: 'JobDetailsDropdown',
    props: ['job'],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        openDetails() {
            this.$emit('onViewDetails')
            this.closeDropdown();
        },
        deleteProject() {
            this.$dialog.confirm('Are you sure you want to delete this project?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/delete', {id: this.job.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                            this.closeDropdown();
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        markAsAchieved() {
            this.$dialog.confirm('Are you sure you want to mark this project as achieved and close it?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.loading = true;
                    this.$axios.post('/account/projects/finish-project', {id: this.job.id})
                        .then((res) =>{
                            this.$emit('onReload')
                            this.loading = false;
                            this.closeDropdown();
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                });
        },
        // saveStatus(status) {
        //     this.loading = true;
        //     this.$axios.post('/account/projects/save-status', {id: this.job.id, status})
        //         .then((res) =>{
        //             this.$emit('onReload')
        //             this.loading = false;
        //             this.closeDropdown();
        //         })
        //         .catch((error) => {
        //             this.loading = false;
        //         });
        // },
        closeDropdown() {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    }
}
</script>

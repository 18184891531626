import Vue from 'vue';
import VueRouter from 'vue-router'

import Layout from '~/pages/Layout'

import HomeView from '~/pages/HomeView'
import AboutUs from '~/pages/AboutUs'
import HowItWorks from '~/pages/HowItWorks'
import ContactUs from '~/pages/ContactUs'
import Partnership from '~/pages/Partnership'

import SubscriptionPlans from '~/pages/SubscriptionPlans'

import FreelancersList from '~/pages/Freelancers/FreelancersList'
import FreelancerDetails from '~/pages/Freelancers/FreelancerDetails'

import CompaniesList from '~/pages/Companies/CompaniesList'
import CompanyDetails from '~/pages/Companies/CompanyDetails'

import ProjectsList from '~/pages/Projects/ProjectsList'

import JobsList from '~/pages/Jobs/JobsList'
import JobDetails from '~/pages/Jobs/JobDetails'

import ArticlesListLayout from '~/pages/Articles/ArticlesListLayout'
import ArticlesList from '~/pages/Articles/ArticlesList'
import ArticleDetails from '~/pages/Articles/ArticleDetails'
import InfoPage from '~/pages/InfoPage'
import FAQ from '~/pages/FAQ'

import LayoutAuth from '~/pages/Auth/LayoutAuth'
import Auth from '~/pages/Auth/Auth'
import AuthUserSelect from '~/pages/Auth/AuthUserSelect'
import AuthConfirmEmail from "./pages/Auth/AuthConfirmEmail.vue";

import AuthOnboarding from "./pages/Auth/AuthOnboarding";
import AccountLayout from '~/pages/Account/AccountLayout'
import Groups from '~/pages/Account/Groups'
import GroupDetails from '@/pages/Account/GroupDetails.vue'
import AccountInfo from '~/pages/Account/AccountInfo'
import Dashboard from '~/pages/Account/Dashboard'
import Notifications from '~/pages/Account/Notifications'
import LinkedAccounts from "~/pages/Account/LinkedAccounts.vue";
import Members from "~/pages/Account/Members.vue";
import Employees from "~/pages/Account/Employees.vue";
// import TaxInformation from "~/pages/Account/TaxInformation.vue";
import AccountProjects from "~/pages/Account/AccountProjects.vue";
import AccountJobs from "~/pages/Account/AccountJobs.vue";
import AccountJobDetails from "~/pages/Account/AccountJobDetails.vue";
import JobsCatalog from "~/pages/Account/JobsCatalog.vue";
import Appointments from "~/pages/Account/Appointments.vue";
import AppointmentForm from "~/pages/Account/AppointmentForm.vue";
import Invitations from "~/pages/Account/Invitations.vue";
import ProjectForm from '~/pages/Account/ProjectForm.vue'
import JobForm from '~/pages/Account/JobForm.vue'
// import PaymentMethods from "@/pages/Account/PaymentMethods.vue";
// import BillingHistory from "~/pages/Account/BillingHistory.vue";
import Settings from "~/pages/Account/Settings.vue";
import AddReview from "~/pages/AddReview.vue";
import JobApplications from "@/pages/Account/JobApplications.vue";
import ProjectOffers from "@/pages/Account/ProjectOffers.vue";
import ProjectOfferDetails from "@/pages/Account/ProjectOfferDetails.vue";
import Messaging from "@/pages/Account/Messaging.vue";
import AccountCalendar from "@/pages/Account/AccountCalendar.vue";
import Payments from "@/pages/Account/Payments.vue";
import AccountOffers from "@/pages/Account/AccountOffers.vue";

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    base: '/',
    prefetchLinks: false,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { x: 0, y: 0, behaviour: "smooth" }
    },
    routes: [
        {
            path: '/social-login/:provider/callback',
            component: {
                template: '<div class="auth-component"></div>'
            },
            meta: {
                auth: false,
                rule: 'isAny',
            }
        },
        {
            path: '/auth/login',
            name: 'auth.login',
            component: Auth,
            meta: {
                title: 'Login',
                auth: false,
                redirect: { name: 'account.info' },
                rule: 'isPublic',
            }
        },
        {
            path: '/auth/sign-up/:type',
            name: 'auth.register',
            component: Auth,
            meta: {
                title: 'Sign Up',
                auth: false,
                redirect: { name: 'account.info' },
                rule: 'isAny',
            }
        },
        {
            path: '/auth/reset',
            name: 'auth.reset',
            component: Auth,
            meta: {
                title: 'Reset password',
                auth: false,
                redirect: { name: 'account.info' },
                rule: 'isPublic',
            }
        },
        {
            path: '/auth',
            component: LayoutAuth,
            children: [
                {
                    path: 'confirm-email',
                    name: 'auth.confirm',
                    component: AuthConfirmEmail,
                    meta: {
                        title: 'Confirm email',
                        rule: 'isLogged',
                    }
                },
                {
                    path: 'onboarding',
                    name: 'auth.onboarding',
                    component: AuthOnboarding,
                    meta: {
                        title: 'Onboarding',
                        rule: 'isLogged',
                    }
                },
            ],
            meta: {
                rule: 'isLogged',
            }
        },
        {
            path: '',
            component: Layout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: HomeView,
                    meta: {
                        title: 'Home',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/about-us',
                    name: 'about-us',
                    component: AboutUs,
                    meta: {
                        title: 'About us',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/how-it-works',
                    name: 'how-it-works',
                    component: HowItWorks,
                    meta: {
                        title: 'How it works',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/contact-us',
                    name: 'contact-us',
                    component: ContactUs,
                    meta: {
                        title: 'Contact',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/establish-partnership',
                    name: 'partnership',
                    component: Partnership,
                    meta: {
                        title: 'Establish partnership',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/subscription-plans',
                    name: 'subscription-plans',
                    component: SubscriptionPlans,
                    meta: {
                        title: 'Subscription plans',
                        rule: 'isLogged',
                    }
                },
                {
                    path: '/projects',
                    name: 'projects.list',
                    component: ProjectsList,
                    meta: {
                        title: 'Projects',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/project/:slug',
                    name: 'projects.details',
                    component: JobDetails,
                    meta: {
                        title: 'Project details',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/jobs',
                    name: 'jobs.list',
                    component: JobsList,
                    meta: {
                        title: 'Jobs',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/jobs/:slug',
                    name: 'jobs.details',
                    component: JobDetails,
                    meta: {
                        title: 'Job details',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/experts',
                    name: 'freelancers.list',
                    component: FreelancersList,
                    meta: {
                        title: 'Experts',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/experts/:username',
                    name: 'freelancers.details',
                    component: FreelancerDetails,
                    meta: {
                        title: 'Expert details',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/biotech-companies',
                    name: 'companies.list',
                    component: CompaniesList,
                    meta: {
                        title: 'Companies',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/biotech-companies/:username',
                    name: 'companies.details',
                    component: CompanyDetails,
                    meta: {
                        title: 'Company',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/learning-materials',
                    component: ArticlesListLayout,
                    children: [
                        {
                            path: '',
                            name: 'blog.articles',
                            component: ArticlesList,
                            meta: { title: 'Articles', rule: 'isAny', type: 'post', routeKey: 'post'  }
                        },
                        {
                            path: '/videos',
                            name: 'blog.videos',
                            component: ArticlesList,
                            meta: { title: 'Videos', rule: 'isAny', type: 'video', routeKey: 'video' }
                        },
                        {
                            path: '/animations',
                            name: 'blog.animations',
                            component: ArticlesList,
                            meta: { title: 'Animations', rule: 'isAny', type: 'animation', routeKey: 'animation' }
                        },
                        {
                            path: '/courses',
                            name: 'blog.courses',
                            component: ArticlesList,
                            meta: { title: 'Online courses', rule: 'isAny', type: 'course', routeKey: 'course' }
                        },
                    ],
                    meta: {
                        rule: 'isAny',
                    }
                },
                {
                    path: '/blog/:slug',
                    name: 'articles.details',
                    component: ArticleDetails,
                    meta: {
                        rule: 'isAny',
                    }
                },
                {
                    path: '/info/:slug',
                    name: 'page.details',
                    component: InfoPage,
                    meta: {
                        rule: 'isAny',
                    }
                },
                {
                    path: '/faq',
                    name: 'faq',
                    component: FAQ,
                    meta: {
                        title: 'FAQ',
                        rule: 'isAny',
                    }
                },
                {
                    path: '/auth/user-type',
                    name: 'auth.user-type',
                    component: AuthUserSelect,
                    meta: {
                        title: 'Select user type',
                        auth: false,
                        redirect: { name: 'account.info' },
                        rule: 'isAny',
                    }
                },
            ],
            meta: {
                rule: 'isAny',
            }
        },
        {
            path: '/account',
            component: AccountLayout,
            children: [
                {
                    path: '/',
                    name: 'account.dashboard',
                    component: Dashboard,
                    meta: { title: 'Dashboard', rule: 'isLogged' }
                },
                {
                    path: 'info',
                    name: 'account.info',
                    component: AccountInfo,
                    meta: { title: 'Account Settings', rule: 'isLogged' }
                },
                {
                    path: 'jobs',
                    name: 'account.jobs',
                    component: AccountJobs,
                    meta: { title: 'Jobs', rule: 'isLogged' }
                },
                {
                    path: 'jobs/:id/applications',
                    name: 'account.jobs.applications',
                    component: JobApplications,
                    meta: {title: 'Job Applicants', rule: 'isLogged'}
                },
                {
                    path: 'jobs/:id/details',
                    name: 'account.jobs.details',
                    component: AccountJobDetails,
                    meta: { title: 'Jobs', rule: 'isLogged' }
                },
                {
                    path: 'offers',
                    name: 'account.offers',
                    component: AccountOffers,
                    meta: { title: 'Offers', rule: 'isLogged' }
                },
                {
                    path: 'projects-list',
                    name: 'account.projects',
                    component: AccountProjects,
                    meta: { title: 'Projects', rule: 'isLogged' }
                },
                {
                    path: 'projects/:id/offers',
                    name: 'account.projects.offers',
                    component: ProjectOffers,
                    meta: {title: 'Project Offers', rule: 'isLogged'}
                },
                {
                    path: 'projects/offers/:id',
                    name: 'account.offer.details',
                    component: ProjectOfferDetails,
                    meta: { title: 'Project Offer Details', rule: 'isLogged' }
                },
                {
                    path: 'job-catalog',
                    name: 'account.jobs-catalog',
                    component: JobsCatalog,
                    meta: { title: 'Job Catalog', rule: 'isLogged' }
                },
                {
                    path: 'appointments',
                    name: 'account.appointments',
                    component: Appointments,
                    meta: { title: 'Appointments', rule: 'isLogged' }
                },
                {
                    path: 'new-appointment',
                    name: 'account.new-appointment',
                    component: AppointmentForm,
                    meta: { title: 'New Appointment', rule: 'isLogged' }
                },
                {
                    path: 'invitations',
                    name: 'account.invitations',
                    component: Invitations,
                    meta: { title: 'Invitations', rule: 'isLogged' }
                },
                {
                    path: 'groups',
                    name: 'account.groups',
                    component: Groups,
                    meta: { title: 'Groups', rule: 'isLogged' }
                },
                {
                    path: 'groups/:id',
                    name: 'account.group.details',
                    component: GroupDetails,
                    meta: { title: 'Group', rule: 'isLogged' }
                },
                {
                    path: 'notification-settings',
                    name: 'account.notification-settings',
                    component: Notifications,
                    meta: { title: 'Groups', rule: 'isLogged' }
                },
                {
                    path: 'linked-accounts',
                    name: 'account.linked-accounts',
                    component: LinkedAccounts,
                    meta: { title: 'Linked Accounts', rule: 'isLogged' }
                },
                {
                    path: 'members',
                    name: 'account.members',
                    component: Members,
                    meta: { title: 'Members', rule: 'isLogged' }
                },
                {
                    path: 'employees',
                    name: 'account.employees',
                    component: Employees,
                    meta: { title: 'Agency Members', rule: 'isLogged' }
                },
                // {
                //     path: 'payment-methods',
                //     name: 'account.payment-methods',
                //     component: PaymentMethods,
                //     meta: { title: 'Payment Methods', rule: 'isLogged' }
                // },
                {
                    path: 'payments',
                    name: 'account.payments',
                    component: Payments,
                    meta: { title: 'Payment Settings', rule: 'isLogged' }
                },
                // {
                //     path: 'billing-history',
                //     name: 'account.billing-history',
                //     component: BillingHistory,
                //     meta: { title: 'Billing History', rule: 'isLogged' }
                // },
                // {
                //     path: 'tax-info',
                //     name: 'account.tax-info',
                //     component: TaxInformation,
                //     meta: { title: 'Tax Information', rule: 'isLogged' }
                // },
                {
                    path: 'settings',
                    name: 'account.settings',
                    component: Settings,
                    meta: { title: 'Settings', rule: 'isLogged' }
                },
                {
                    path: 'messages',
                    name: 'account.messages',
                    component: Messaging,
                    meta: { title: 'Messages', rule: 'isLogged' }
                },
                {
                    path: 'calendar',
                    name: 'account.calendar',
                    component: AccountCalendar,
                    meta: { title: 'Calendar', rule: 'isLogged' }
                },
            ],
            meta: {
                title: 'My account',
                auth: true,
                routeKey: 'account',
                rule: 'isLogged',
            }
        },
        {
            path: '/account/jobs',
            component: LayoutAuth,
            children: [
                {
                    path: 'new',
                    name: 'account.jobs.create',
                    component: JobForm,
                    meta: {title: 'Add Job', rule: 'isLogged'}
                },
                {
                    path: ':id/edit',
                    name: 'account.jobs.form',
                    component: JobForm,
                    meta: {title: 'Edit Job', rule: 'isLogged'}
                },
            ]
        },
        {
            path: '/account/projects',
            component: LayoutAuth,
            children: [
                {
                    path: 'new',
                    name: 'account.projects.create',
                    component: ProjectForm,
                    meta: {title: 'Add Project', rule: 'isLogged'}
                },
                {
                    path: ':id/edit',
                    name: 'account.projects.form',
                    component: ProjectForm,
                    meta: {title: 'Edit Project', rule: 'isLogged'}
                },
            ]
        },
        {
            path: '/add-review/:username/:applicationId?',
            component: LayoutAuth,
            children: [
                {
                    path: '/',
                    name: 'add-review',
                    component: AddReview,
                    meta: {
                        title: 'Add Review',
                        rule: 'isLogged'
                    }
                },
            ]
        },
    ]
});

// router.beforeEach((to, from, next) => {
//     console.log('beforeeach', to)
//     if (to.meta) {
//         store.commit('setMeta', to.meta);
//         if (to.meta.title) {
//             document.title = to.meta.title + ' | BOLG'
//         }else{
//             document.title =  'BOLG'
//         }
//     }
//
//     next();
// });

export {router}

export function createRouter() {
    return router
}

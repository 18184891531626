<template>
    <div :class="wrapperClass">
        <ul role="tablist" :class="navClass">
            <li
                v-for="(tab, i) in tabs"
                :key="i"
                :class="[ navItemClass, tab.isDisabled ? navItemDisabledClass : '', tab.isActive ? navItemActiveClass : '' ]"
                role="presentation"
            >
                <a v-html="tab.header"
                   :aria-controls="tab.hash"
                   :aria-selected="tab.isActive"
                   @click="selectTab(tab.hash, $event)"
                   :href="tab.hash"
                   :class="[ navItemLinkClass, tab.isDisabled ? navItemLinkDisabledClass : '', tab.isActive ? navItemLinkActiveClass : '' ]"
                   role="tab"
                ></a>
            </li>
        </ul>
        <div :class="panelsWrapperClass">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        cacheLifetime: {
            default: 5,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({
                useUrlFragment: false,
                defaultTabHash: null,
            }),
        },
        wrapperClass: {
            type: String,
            default: 'tabs'
        },
        panelsWrapperClass: {
            type: String,
            default: 'tab-content'
        },
        navClass: {
            type: String,
            default: 'nav'
        },
        navItemClass: {
            type: String,
            default: 'nav-item'
        },
        navItemDisabledClass: {
            type: String,
            default: ''
        },
        navItemActiveClass: {
            type: String,
            default: ''
        },
        navItemLinkClass: {
            type: String,
            default: 'nav-link'
        },
        navItemLinkActiveClass: {
            type: String,
            default: 'active'
        },
        navItemLinkDisabledClass: {
            type: String,
            default: 'disabled'
        },
        direction: {
            default: 'row',
        },
        cacheLifetime: {
            default: 5,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({
                useUrlFragment: false,
                defaultTabHash: null,
            }),
        },
    },
    data: () => ({
        tabs: [],
        activeTabHash: '',
        activeTabIndex: 0,
        lastActiveTabHash: '',
    }),
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        // window.addEventListener('hashchange', () => this.selectTab(window.location.hash));
        // if (this.findTab(window.location.hash)) {
        //     this.selectTab(window.location.hash);
        //     return;
        // }
        if(this.options.defaultTabHash !== null && this.findTab("#" + this.options.defaultTabHash)) {
            this.selectTab("#" + this.options.defaultTabHash);
            return;
        }
        if (this.tabs.length) {
            this.selectTab(this.tabs[0].hash);
        }
    },
    methods: {
        findTab(hash) {
            return this.tabs.find(tab => tab.hash === hash);
        },
        selectTab(selectedTabHash, event) {
            // See if we should store the hash in the url fragment.
            if (event && !this.options.useUrlFragment) {
                event.preventDefault();
            }
            const selectedTab = this.findTab(selectedTabHash);
            if (!selectedTab) {
                return;
            }
            if (selectedTab.isDisabled) {
                event.preventDefault();
                return;
            }
            if (this.lastActiveTabHash === selectedTab.hash) {
                this.$emit('clicked', { tab: selectedTab });
                return;
            }
            this.tabs.forEach(tab => {
                tab.isActive = (tab.hash === selectedTab.hash);
            });
            this.$emit('changed', {tab: selectedTab });
            this.activeTabHash = selectedTab.hash;
            this.activeTabIndex = this.getTabIndex(selectedTabHash);
            this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
            // window.location.hash = this.activeTabHash;
        },
        setTabVisible(hash, visible) {
            const tab = this.findTab(hash);
            if (! tab) {
                return;
            }
            tab.isVisible = visible;
            if (tab.isActive) {
                // If tab is active, set a different one as active.
                tab.isActive = visible;
                this.tabs.every((tab, index, array) => {
                    if (tab.isVisible) {
                        tab.isActive = true;
                        return false;
                    }
                    return true;
                });
            }
        },

        getTabIndex(hash){
            const tab = this.findTab(hash);

            return this.tabs.indexOf(tab);
        },

        getTabHash(index){
            const tab = this.tabs.find(tab => this.tabs.indexOf(tab) === index);

            if (!tab) {
                return;
            }

            return tab.hash;
        },

        getActiveTab(){
            return this.findTab(this.activeTabHash);
        },

        getActiveTabIndex() {
            return this.getTabIndex(this.activeTabHash);
        },
    },
}
</script>

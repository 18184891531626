<template>
    <div class="c-job-card --sm" @click="onSelect" v-if="job">
        <div class="d-flex align-items-center mb-1">
            <div class="c-job-card__title">{{ job.title }}</div>
            <div v-if="!!$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="c-job-card__stats mb-1">
            <span v-if="job.attributes.service_level">{{ job.attributes.service_level.value }}</span>
            <template v-if="job.payment_method === 'hourly'">
                <span v-if="job.price[0]">Hourly Rate: {{ job.price[0] | toPrice }}-{{ job.price[1] | toPrice }}</span>
                <span v-if="job.hours[0]">Hours required: {{ job.hours[0] }} to {{ job.hours[1] }} h</span>
                <span v-if="job.duration[0]">Duration: {{ job.duration[0] }} to {{ job.duration[1] }} Days</span>
            </template>
            <template v-if="job.payment_method === 'fixed'">
                <span v-if="job.price[0]">Est. Budget: {{ job.price[0] | toPrice }}-{{ job.price[1] | toPrice }}</span>
                <span v-if="job.duration[0]">Delivery Time: {{ job.duration[0] }} to {{ job.duration[1] }} Days</span>
            </template>
        </div>
        <div class="c-job-card__info-wrapper">
            <div class="c-job-card__info" v-if="job.client">
                <div v-if="job.active_user">
                    <UserAvatar :user="job.active_user" style="flex: 1" :displayName="true"/>
                </div>
                <div v-if="job.attributes.color_code_categories">
                    <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in job.attributes.color_code_categories.slice(0, 1)" :attr="attr"/>
                    <span class="badge badge-sm badge-secondary-light px-1" v-if="job.attributes.color_code_categories.length > 1">+{{ job.attributes.color_code_categories.length - 1}}</span>
                </div>
                <div v-if="job.client.address">
                    <div class="c-job-card__info-icon">
                        <LocationIcon height="20" width="20"/>
                    </div>
                    <div class="c-job-card__info-title">{{ [job.client.address.city_name, job.client.address.country_name].join(', ') }}</div>
                </div>
            </div>
            <div class="c-job-card__stats">
                <template v-if="job.status == 2">
                    <span>Paid: <span class="text-secondary">{{ job.stats?.paid | toPrice }}</span></span>
                    <span>In Escrow: <span class="text-secondary">{{ job.stats?.escrow | toPrice }}</span></span>
                    <span>Started: <span class="text-secondary">{{ job.stats?.started_at | moment('DD MMM, YYYY') }}</span></span>
                </template>
                <template v-if="job.status == 1">
                    <span>Saved: <span class="text-secondary">{{ job.stats?.saves }}</span></span>
                    <span>Viewed: <span class="text-secondary">{{ job.stats?.views }}</span></span>
                    <span>Applications: <span class="text-secondary">{{ job.stats?.applications }}</span></span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>


import UserAvatar from "./UserAvatar.vue";
import GroupIcon from "./GroupToggle.vue";
import ColorCodeAttribute from "./ColorCodeAttribute.vue";
import GroupToggle from "@/components/GroupToggle.vue";

export default {
    name: 'AccountJobSimpleCard',
    components: {GroupToggle, ColorCodeAttribute, GroupIcon, UserAvatar},
    props: ['job'],
    methods: {
        onSelect() {
            this.$emit('onSelect', this.job)
        },
    }
}
</script>

<template>
   <div v-if="project?.id">
       <modal class="offer-modal" :name="'offerModal_' + project.id" :scrollable="true" width="740px" height="auto" :adaptive="true" :pivotY="0.2">
           <div class="modal-dialog" style="max-width: 650px">
               <div class="modal-content" style="width: 650px">
                   <a style="cursor: pointer" class="close" @click="$modal.hide('addCardModal')">
                       <CloseIcon class="close"/>
                   </a>
                   <div class="modal-header">
                       <OfferImage height="130" width="240" class="mb-3"/>
                       <h4 class="modal-title">You are making an offer for the project</h4>
                       <div class="offer-project">
                           {{ project.title }}
                       </div>
                   </div>
                   <div class="modal-body">
                       <FormTextarea class="mb-2" label="Offer description" v-model="offer.description" :v="$v.offer.description"/>
                       <FormInput type="number" class="mb-2" label="How many revisions you require?*" v-model="offer.revisions" :v="$v.offer.revisions"/>
                       <FormGroup class="mb-2" label="You can attach a file if you want to add further details.">
                           <DocumentsUploader v-model="offer.documents" :multiple="true"/>
                       </FormGroup>

                       <template v-if="project.payment_method === 'hourly'">
                           <FormInput class="mb-2" label="Hourly rate*" v-model="offer.price" :v="$v.offer.price"/>
                           <FormGroup label="Duration of the Project:">
                               <div class="form-range-input">
                                   <FormInput style="width: 170px" v-model="offer.duration_min" :v="$v.offer.duration_min"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 170px" v-model="offer.duration_max" :v="$v.offer.duration_max"/>
                                   <span class="ml-2">Days</span>
                               </div>
                           </FormGroup>
                           <FormGroup label="Number of Hours Required">
                               <div class="form-range-input">
                                   <FormRadio v-model="offer.hours_type" class="mr-2" fieldValue="week" text="Per Week"/>
                                   <FormRadio v-model="offer.hours_type" class="mr-2" fieldValue="month" text="Per Month"/>
                                   <FormInput style="width: 120px" v-model="offer.hours_min" append_text="h"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 120px" v-model="offer.hours_max" append_text="h"/>
                               </div>
                           </FormGroup>
                       </template>

                       <template v-if="project.payment_method === 'fixed'">
                           <FormInput class="mb-2" label="Fixed Price*" v-model="offer.price"/>
                           <div class="form-group">
                               <label for="">Delivery Time:</label>
                               <div class="form-range-input">
                                   <FormInput style="width: 170px" v-model="offer.duration_min" :v="$v.offer.duration_min" append_text="days"/>
                                   <span class="form-range-input__separator"> - </span>
                                   <FormInput style="width: 170px" v-model="offer.duration_max" :v="$v.offer.duration_max" append_text="days"/>
                               </div>
                           </div>
                       </template>

                   </div>
                   <div class="modal-footer">
                       <Btn :loading="loading.send" class="btn btn-lg btn-secondary btn-block" @click="submitOffer()">Submit Your Offer</Btn>
                   </div>
               </div>
           </div>
       </modal>
   </div>
</template>

<script>
    import FormInput from "@/components/FormInput.vue";
    import Btn from "@/components/Btn.vue";
    import OfferImage from "~/assets/assets/application.svg?inline";
    import FormTextarea from "@/components/FormTextarea.vue";
    import DocumentsUploader from "@/components/DocumentsUploader.vue";
    import FormRadio from "@/components/FormRadio.vue";
    const { required } = require('vuelidate/lib/validators');

    export default {
        name: 'OfferModal',
        components: {FormRadio, DocumentsUploader, FormTextarea, Btn, FormInput, OfferImage},
        props: ['project'],
        data () {
            return {
                loading: {
                    send: false,
                },
                offer: {
                    description: '',
                    revisions: '',
                    documents: [],
                    price: '',
                    duration_min: 1,
                    duration_max: 25,
                    hours_type: 'week',
                    hours_min: 1,
                    hours_max: 25,
                    payment_method: 'fixed',
                },
            }
        },
        validations: {
            offer: {
                description: {},
                revisions: { required },
                price: { required },
                duration_min: {},
                duration_max: {},
            }
        },
        created() {
            if (this.project.payment_method === 'fixed') {
                this.offer.price = this.project.price[0];
                this.offer.duration_min = this.project.duration[0];
                this.offer.duration_max = this.project.duration[1];
            }
            if (this.project.payment_method === 'hourly') {
                this.offer.price = this.project.price[0];
                this.offer.hours_type = this.project.hours_type;
                this.offer.duration_min = this.project.duration[0];
                this.offer.duration_max = this.project.duration[1];
                this.offer.hours_min = this.project.hours[0];
                this.offer.hours_max = this.project.hours[1];
            }
        },
        methods: {
            submitOffer() {
                this.$v.offer.$touch();
                if (this.$v.offer.$invalid == true) {
                    return false;
                }
                this.loading.send = true;
                this.$axios.post("/projects/add-offer", {...this.offer, ...{
                    project_id: this.project.id
                }})
                    .then((res) => {
                        this.loading.send = false;
                        this.$modal.hide('offerModal_' + this.project.id);
                        this.$emit('onSubmit', res);
                    })
                    .catch((res) => {
                        this.loading.send = false;
                        if (res.no_payment_methods) {
                            this.$router.push({'name': 'account.payments'});
                        }
                    })
            },
        }
    };
</script>


<template>
    <div>
        <div :class="{'loading': loading.data}">
            <div class="projects-card-list" v-if="data && data.data && data.data.length > 0">
                <JobCard @click="$router.push({name: 'account.jobs.details', params: {id: job.id}})" class="mb-2" :key="job.id" v-for="job in data.data" :job="job">
                    <template #actions>
                        <JobDropdown  @onReload="getData" @onViewDetails="viewJobDetails(job)" @onViewApplication="viewApplicationDetails(job)" :job="job"/>
                    </template>
                </JobCard>
            </div>
            <div class="card-body" v-else-if="data && !data.data.length">
                <h4>No jobs</h4>
            </div>
        </div>
        <div class="d-flex mt-2" v-if="data && data.total > 1">
            <pagination :limit="10" :data="data" v-on:pagination-change-page="getPage"></pagination>
        </div>
        <ModalSidebar name="jobDetailsModal">
            <JobDetails :item="selected_job" :preview="true"/>
        </ModalSidebar>
        <ModalSidebar name="JobApplicationDetailsModal">
            <ApplicationDetails
                :item="selected_application"
            />
        </ModalSidebar>
    </div>
</template>

<script>
import {modelListMixin} from "@/mixins/modelListMixin";
import AccountJobSimpleCard from "@/components/AccountJobSimpleCard.vue";
import JobDropdown from "@/components/JobDropdown.vue";
import JobCard from "@/pages/Jobs/JobCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import JobDetails from "@/pages/Jobs/JobDetails.vue";
import ApplicationDetails from "@/pages/Account/ApplicationDetails.vue";

export default {
    name: "AccountJobsList",
    props: ['status'],
    mixins: [modelListMixin],
    components: {ApplicationDetails, JobDetails, ModalSidebar, JobCard, JobDropdown, AccountJobSimpleCard },
    data() {
        return {
            filters: {
                type: 'job',
                status: 2,
            },
            selected_job: null,
            selected_application: null,
        }
    },
    watch: {
        'filters.status': function() {
            this.page = 1;
            this.getPage();
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.filters.status = this.status;
            this.$axios.post('/account/projects', this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        viewJobDetails(job) {
            if (job.status >= 2) {
                this.$router.push({name: 'account.jobs.details', params: {id: job.id}})
            }else{
                this.selected_job = job;
                this.$modal.show('jobDetailsModal')
            }
        },
        viewApplicationDetails(job) {
            this.selected_application = job.application;
            this.$modal.show('JobApplicationDetailsModal')
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

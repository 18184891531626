<template>
    <div class="p-details__doc">
        <div class="p-details__doc-icon">
            <template v-if="document.mime">
                <ImageFileIcon v-if="document.mime.indexOf('image') > -1"/>
                <PdfFileIcon v-else-if="document.mime.indexOf('pdf') > -1"/>
            </template>
        </div>
        <div class="p-details__doc-info">
            <div class="p-details__doc-title">
                {{ document.file }}
                <div class="p-details__doc-label">{{ document.size | humanFileSize }}</div>
            </div>
            <a class="p-details__doc-action" :class="{'loading': document.loading}" :href="document.url" target="_blank">
                <DownloadIcon height="24" width="24"/>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    name: "DocumentCard",
    props: ['document'],
}
</script>

<template>
    <div class="l-auth__onboarding" :class="{'loading': loading.data}">
        <Bubbles class="l-auth__onboarding-bubbles"/>
        <OnboardingCard
            v-if="user.confirmed === false"
            style="max-width: 640px"
            title="Verify Your Email and Activate Your Account"
            icon="/images/send-email.svg"
        >
            <div class="mx-auto" style="max-width: 512px">
                <div class="card-subtitle">
                    We just sent an email to <span class="text-primary">{{ user.email }}</span>. <br>Please check your email and click on the link to verify your email
                </div>
                <div class="text-center mt-2">
                    <Btn class="btn-secondary --w380" :loading="loading.confirm" @click="sendConfirmationLink">Resend Verification Email</Btn>
                </div>
            </div>
        </OnboardingCard>
        <OnboardingCard
            v-else-if="user.confirmed === true"
            title="Your email has been verified"
            subtitle="Thank you!"
            icon="/images/success-check.svg"
        >
            <div class="text-center">
                <Btn class="btn-secondary --w380" @click="$router.push({name: 'auth.onboarding'})">Continue</Btn>
            </div>
        </OnboardingCard>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import Btn from "~/components/Btn.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";

export default {
    name: 'AuthConfirmEmail',
    components: {OnboardingCard, Btn, Bubbles},
    data() {
        return {
            loading: {
                data: false,
                confirm: false,
            },
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        sendConfirmationLink() {
            this.loading.confirm = true;
            this.$axios.get("/auth/send-email-verification")
                .then((res) =>{
                    this.loading.confirm = false;
                })
                .catch((res) =>{
                    this.loading.confirm = false;
                })
        }
    },
}
</script>
